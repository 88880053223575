import { cloneDeep, get, isEmpty, isEqual } from 'lodash';

import {
  DELETE,
  EMPTY_OBJECT,
  RESET_FORM_STATE,
  RESTART,
  SET_FIELD_ERRORS,
  SET_FIELD_TOUCHED,
  SET_FIELD_UNWATCHED,
  SET_FIELD_WATCHED,
  SET_FORM_STATE,
  START,
  SUBMIT_ERROR,
  SUBMIT_START,
  SUBMIT_SUCCESS,
  UPDATE_FIELD_VALUE,
  UPDATE_FIELD_VALUES_BY_QUERY,
  VALIDATE_START,
  VALIDATE_SUCCESS,
} from './constants';
import { Action, Reducer, State } from './types';
import {
  normalizeErrors,
  normalizeFieldPath,
  normalizeTouchedFields,
  update,
  updateFieldPathsCommands,
} from './utils';

const INITIAL_STATE = {
  forms: EMPTY_OBJECT,
};

const FORM_FLAGS_INITIAL_STATE = {
  isValidating: false,
  isManuallyValidated: false,
  isSubmitting: false,
  submitCount: 0,
  isSuccessfullyValidated: false,
  isSuccessfullySubmitted: false,
  isUnsuccessfullySubmitted: false,
  isValid: true,
};

const INITIAL_FORM_STATE = {
  currentValues: EMPTY_OBJECT,
  submittedValues: null,
  currentErrors: EMPTY_OBJECT,
  initialValues: EMPTY_OBJECT,
  touchedFieldPaths: EMPTY_OBJECT,
  watchedFieldPaths: EMPTY_OBJECT,
  validationSchema: null,
  flags: {
    ...FORM_FLAGS_INITIAL_STATE,
  },
};

export function getReducer<T>(): Reducer<T> {
  return (state: State<T> = INITIAL_STATE, action: Action<T>) => {
    switch (action.type) {
      case START: {
        const { initialValues, validationSchema } = action.payload;
        return {
          ...state,
          forms: {
            ...state.forms,
            [action.payload.formId]: {
              ...INITIAL_FORM_STATE,
              initialValues,
              currentValues: cloneDeep<T>(initialValues),
              validationSchema,
            },
          },
        };
      }
      case RESTART: {
        const { initialValues, options, validationSchema } = action.payload;
        const { cleanValuesOnReinitialize } = options;
        const previousForm = state.forms[action.payload.formId] || EMPTY_OBJECT;
        const previousFlags = get(
          previousForm,
          'flags',
          FORM_FLAGS_INITIAL_STATE,
        );
        const clonedInitialValues = cloneDeep(initialValues || EMPTY_OBJECT);
        let newCurrentValues = clonedInitialValues;

        // @ts-ignore
        const possiblePrevCurrentValues = previousForm.currentValues;
        if (options.mergeInitialValues) {
          newCurrentValues = options.mergeInitialValues(
            initialValues,
            possiblePrevCurrentValues || EMPTY_OBJECT,
          );
        } else if (possiblePrevCurrentValues != null) {
          newCurrentValues = !options.isValuesEmpty(possiblePrevCurrentValues)
            ? possiblePrevCurrentValues
            : cloneDeep<T | Record<string, any>>(initialValues || EMPTY_OBJECT);
        } else {
          newCurrentValues = clonedInitialValues;
        }

        return {
          ...state,
          forms: {
            ...state.forms,
            [action.payload.formId]: {
              ...INITIAL_FORM_STATE,
              initialValues,
              currentValues: cleanValuesOnReinitialize
                ? clonedInitialValues
                : newCurrentValues,
              // @ts-ignore
              touchedFieldPaths: previousForm.touchedFieldPaths || EMPTY_OBJECT,
              // @ts-ignore
              currentErrors: previousForm.currentErrors || EMPTY_OBJECT,
              // @ts-ignore
              watchedFieldPaths: previousForm.watchedFieldPaths || EMPTY_OBJECT,
              validationSchema,
              flags: {
                ...previousFlags,
                submitCount: previousFlags.submitCount || 0,
                isManuallyValidated: previousFlags.isManuallyValidated || false,
              },
            },
          },
        };
      }
      case UPDATE_FIELD_VALUE: {
        const { formId, changes } = action.payload;
        if (isEmpty(changes)) {
          return state;
        }
        const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
        const touchedFieldPaths = form.touchedFieldPaths || EMPTY_OBJECT;
        const { currentValues } = form;
        const updatedValues = update(
          currentValues,
          updateFieldPathsCommands(changes),
        );
        const newTouchedFields = normalizeTouchedFields(changes);
        return {
          ...state,
          forms: {
            ...state.forms,
            [formId]: {
              ...form,
              isSuccessfullyValidated: false,
              isSuccessfullySubmitted: false,
              isUnsuccessfullySubmitted: false,
              currentValues: updatedValues,
              touchedFieldPaths: {
                ...touchedFieldPaths,
                ...newTouchedFields,
              },
              // // XXX: had to empty errors since we dont calculate the query field maps
              // // TODO: map query to fieldPaths so we can eliminate the errors
              // currentErrors: {},
            },
          },
        };
      }
      case UPDATE_FIELD_VALUES_BY_QUERY: {
        const { formId, query } = action.payload;
        if (isEmpty(query)) {
          return state;
        }
        const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
        const { currentValues } = form;
        // @ts-ignore
        const updatedValues = update(currentValues, query);
        return {
          ...state,
          forms: {
            ...state.forms,
            [formId]: {
              ...form,
              currentValues: updatedValues,
              flags: {
                ...form.flags,
                isSuccessfullyValidated: false,
                isSuccessfullySubmitted: false,
                isUnsuccessfullySubmitted: false,
              },
              // // XXX: had to empty errors since we dont calculate the query field maps
              // // TODO: map query to fieldPaths so we can eliminate the errors
              // currentErrors: {},
            },
          },
        };
      }
      case SET_FIELD_TOUCHED: {
        const { formId, fieldPath } = action.payload;
        const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
        const touchedFieldPaths = form.touchedFieldPaths || EMPTY_OBJECT;
        const path = normalizeFieldPath(fieldPath);
        if (touchedFieldPaths[path] === true) {
          return state;
        }
        return {
          ...state,
          forms: {
            ...state.forms,
            [formId]: {
              ...form,
              touchedFieldPaths: {
                ...touchedFieldPaths,
                [path]: true,
              },
            },
          },
        };
      }
      case SET_FIELD_WATCHED: {
        const { formId, fieldPath } = action.payload;
        const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
        const watchedFieldPaths = form.watchedFieldPaths || EMPTY_OBJECT;
        const path = normalizeFieldPath(fieldPath);
        return {
          ...state,
          forms: {
            ...state.forms,
            [formId]: {
              ...form,
              watchedFieldPaths: {
                ...watchedFieldPaths,
                [path]: (watchedFieldPaths[path] || 0) + 1,
              },
            },
          },
        };
      }
      case SET_FIELD_UNWATCHED: {
        const { formId, fieldPath } = action.payload;
        const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
        const watchedFieldPaths = form.watchedFieldPaths || EMPTY_OBJECT;
        const path = normalizeFieldPath(fieldPath);
        return {
          ...state,
          forms: {
            ...state.forms,
            [formId]: {
              ...form,
              watchedFieldPaths: {
                ...watchedFieldPaths,
                [path]:
                  watchedFieldPaths[path] > 0
                    ? watchedFieldPaths[path] - 1
                    : watchedFieldPaths[path],
              },
            },
          },
        };
      }
      case SET_FIELD_ERRORS: {
        const { formId, errors } = action.payload;
        const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
        const currentErrors = normalizeErrors(errors);
        if (isEqual(currentErrors, form.currentErrors)) {
          return state;
        }
        return {
          ...state,
          forms: {
            ...state.forms,
            [formId]: {
              ...form,
              currentErrors,
              isValid: isEmpty(currentErrors),
            },
          },
        };
      }
      case SUBMIT_START: {
        const { formId } = action.payload;
        const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
        return {
          ...state,
          forms: {
            ...state.forms,
            [formId]: {
              ...form,
              flags: {
                ...form.flags,
                submitCount: form.flags.submitCount + 1,
                isValidating: true,
                isSubmitting: false,
                isManuallyValidated: false,
                isSuccessfullyValidated: false,
                isSuccessfullySubmitted: false,
                isUnsuccessfullySubmitted: false,
              },
            },
          },
        };
      }
      case SUBMIT_SUCCESS: {
        const { formId, submittedValues } = action.payload;
        const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
        return {
          ...state,
          forms: {
            ...state.forms,
            [formId]: {
              ...form,
              currentErrors: {},
              submittedValues,
              flags: {
                ...form.flags,
                isValidating: false,
                isSubmitting: true,
                isSuccessfullyValidated: true,
                isSuccessfullySubmitted: true,
                isUnsuccessfullySubmitted: false,
                isValid: true,
              },
            },
          },
        };
      }
      case SUBMIT_ERROR: {
        const { formId, errors, submittedValues } = action.payload;
        const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
        const currentErrors = normalizeErrors(errors);
        return {
          ...state,
          forms: {
            ...state.forms,
            [formId]: {
              ...form,
              currentErrors,
              submittedValues,
              flags: {
                ...form.flags,
                isValidating: false,
                isSubmitting: false,
                isSuccessfullyValidated: true,
                isSuccessfullySubmitted: false,
                isUnsuccessfullySubmitted: true,
                isValid: isEmpty(currentErrors),
              },
            },
          },
        };
      }
      case RESET_FORM_STATE: {
        const {
          formId,
          resetWatchedFields,
          resetTouchedFields,
          resetSubmitCount,
          resetErrors,
          resetValues,
          resetInitialValues,
        } = action.payload;
        const form = state.forms[formId] || INITIAL_FORM_STATE;
        const initialValues = resetInitialValues || form.initialValues;
        return {
          ...state,
          forms: {
            ...state.forms,
            [formId]: {
              ...form,
              initialValues,
              watchedFieldPaths:
                resetWatchedFields === true
                  ? EMPTY_OBJECT
                  : form.watchedFieldPaths,
              touchedFieldPaths:
                resetTouchedFields === true
                  ? EMPTY_OBJECT
                  : form.touchedFieldPaths,
              currentErrors:
                resetErrors === true ? EMPTY_OBJECT : form.currentErrors,
              currentValues:
                resetValues === true
                  ? cloneDeep(initialValues)
                  : form.currentValues,
              flags: {
                ...form.flags,
                submitCount:
                  resetSubmitCount === true ? 0 : form.flags.submitCount,
              },
            },
          },
        };
      }
      case SET_FORM_STATE: {
        const {
          formId,
          increaseSubmitCount,
          setUnsuccessfullySubmitted,
        } = action.payload;
        const form = state.forms[formId] || INITIAL_FORM_STATE;
        let {
          isSuccessfullySubmitted,
          isUnsuccessfullySubmitted,
          isSuccessfullyValidated,
        } = form.flags;
        if (setUnsuccessfullySubmitted) {
          isSuccessfullySubmitted = false;
          isUnsuccessfullySubmitted = true;
          isSuccessfullyValidated = true;
        }
        return {
          ...state,
          forms: {
            ...state.forms,
            [formId]: {
              ...form,
              flags: {
                ...form.flags,
                submitCount: increaseSubmitCount
                  ? form.flags.submitCount + 1
                  : form.flags.submitCount,
                isSuccessfullySubmitted,
                isUnsuccessfullySubmitted,
                isSuccessfullyValidated,
              },
            },
          },
        };
      }
      case DELETE: {
        return {
          ...state,
          forms: {
            ...state.forms,
            [action.payload.formId]: undefined,
          },
        };
      }
      case VALIDATE_START: {
        const { formId } = action.payload;
        const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
        return {
          ...state,
          forms: {
            ...state.forms,
            [formId]: {
              ...form,
              flags: {
                ...form.flags,
                isValidating: true,
                isManuallyValidated: false,
                isSubmitting: false,
                isSuccessfullyValidated: false,
              },
            },
          },
        };
      }
      case VALIDATE_SUCCESS: {
        const { formId, errors, onlyWatchedFields } = action.payload;
        const form = state.forms[action.payload.formId] || INITIAL_FORM_STATE;
        const currentErrors = normalizeErrors(
          errors,
          onlyWatchedFields ? form.watchedFieldPaths : undefined,
        );
        return {
          ...state,
          forms: {
            ...state.forms,
            [formId]: {
              ...form,
              currentErrors,
              flags: {
                ...form.flags,
                isValidating: false,
                isManuallyValidated: true,
                isSuccessfullyValidated: true,
                isUnsuccessfullySubmitted: !isEmpty(currentErrors)
                  ? true
                  : form.flags.isUnsuccessfullySubmitted,
                isValid: isEmpty(currentErrors),
              },
            },
          },
        };
      }
      default:
        return state;
    }
  };
}
