import { ContainerProps } from '@ui-system/interfaces-container';
import { OptionType } from '@ui-system/interfaces-form';
import UI from '@ui-system/ui';
import find from 'lodash/find';
import get from 'lodash/get';
import head from 'lodash/head';
import size from 'lodash/size';
import some from 'lodash/some';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import {
  ComponentForEditor,
  useComponentProps,
  useSetComponentProp,
} from 'react-page-editor/state';

export function useTextSelector(
  options: OptionType[],
): {
  TextSelector: React.FC;
  value: string;
  label: string;
} {
  const [value, setValue] = useState<string>(
    get(head(options), 'value', '') as string,
  );

  const TextSelector = useCallback(
    (props: ContainerProps) => {
      if (!options || size(options) === 1) return null;
      return (
        <UI.Container f={1} {...props}>
          <UI.Form.Select
            options={options}
            onChange={option => {
              setValue(option.value as string);
            }}
            value={{ value, label: value }}
          />
        </UI.Container>
      );
    },
    [options, value],
  );
  const label = useMemo<string>(
    () => get(find(options, { value }), 'label') as string,
    [options, value],
  );

  useEffect(() => {
    const newValue = get(head(options), 'value', '') as string;
    if (newValue) {
      setValue(newValue);
    }
  }, [options]);

  return { TextSelector, value, label };
}
export function useTextSelection<C extends ComponentForEditor, P>(
  component: C,
  options: OptionType[] | null,
  textPropPath = 'text',
): {
  TextSelector: React.FC;
  text: string;
  setTextValue: (t: string) => void;
} {
  const [text, setText] = useState('');
  const setComponentProp = useSetComponentProp();
  const savedProps = useComponentProps<P>(component.config.id);
  const textFromSavedProps = get(savedProps, textPropPath);
  const startText = useMemo<string | null>(() => {
    if (!options) return '';
    return get(head(options), 'value') as string;
  }, [options]);

  useEffect(() => {
    if (textFromSavedProps) {
      setText(textFromSavedProps);
      setComponentProp(component.config, textFromSavedProps, textPropPath);
    } else if (startText) {
      setText(startText);
      setComponentProp(component.config, startText, textPropPath);
    }
    if (options && !some(options, op => op.value === textFromSavedProps)) {
      const txt = (head(options)?.value as string) || startText;
      setText(txt);
      setComponentProp(component.config, txt, textPropPath);
    }
  }, [
    component.config,
    options,
    setComponentProp,
    startText,
    textFromSavedProps,
    textPropPath,
  ]);

  const setTextValue = useCallback((t: string) => {
    setText(t);
  }, []);

  const TextSelector: React.FC = useCallback(() => {
    if (!options) return null;
    return (
      <UI.Container>
        <UI.Form.Select
          options={options}
          placeholder={{ value: '', label: 'Testing label' }}
          onChange={option => {
            setText(option.value as string);
            setComponentProp(
              component.config,
              option.value as string,
              textPropPath,
            );
          }}
          value={{ value: text, label: text }}
        />
      </UI.Container>
    );
  }, [component.config, options, setComponentProp, text, textPropPath]);

  return { TextSelector, text, setTextValue };
}
