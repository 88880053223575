import { getRegionNameByCountry } from '@libs/constants-country-regions';
import { ContactEditor } from '@pro-page-components/contact';
import {
  FooterEditor,
  HeaderEditor,
} from '@pro-page-components/header-and-footer';
import { HighlightsRECEditor } from '@pro-page-components/highlights-rec';
import { RecCertifiedSolarProfessionalsEditor } from '@pro-page-components/rec-certified-solar-professionals';
import { RecGroupEditor } from '@pro-page-components/rec-group';
import { RecProTrustWarrantyEditor } from '@pro-page-components/rec-pro-trust-warranty';
import { RecProductsEditor } from '@pro-page-components/rec-products';
import { SocialMediaEditor } from '@pro-page-components/social-media';
import {
  TutorialProvider,
  useEditorTutorialSteps,
} from '@pro-page-components/tutorials';
import { useYourCompanyTutorial } from '@pro-page-components/tutorials/useYourCompanyTutorialSteps';
import { usePageRestrictionCountry } from '@pro-page-components/utils/useRestrictComponentByRegion';
import { YourCompanyEditor } from '@pro-page-components/your-company';
import {
  ReactPageEditorRenderer,
  TreeSelect,
} from '@react-page-editor/renderer';
import {
  CLICK_AND_BUILD_YOUR_PAGE,
  SELECT_HERE_THE_CONTENT_YOU_WOULD_LIKE,
} from '@rec/i18n';
import * as I18Ns from '@rec/i18n/components';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import map from 'lodash/map';
import values from 'lodash/values';
import * as React from 'react';
import { useMemo } from 'react';
import { useIsEditorEmpty } from 'react-page-editor/state/module';

import { COMPONENTS_CONFIGS } from './componentsMap';

const STYLE = css`
  height: calc(100vh - 60px);
`;

const EmptyEditorText = () => {
  const isEditorEmpty = useIsEditorEmpty();
  if (!isEditorEmpty) return null;
  return (
    <UI.Container p={4} align="center" gap={2}>
      <UI.Icon name="back" size={48} color="gray.A500" />
      <UI.Container direction="column">
        <UI.H4 i18n={CLICK_AND_BUILD_YOUR_PAGE} modifiers="grayA500" />
        <UI.Body2 i18n={SELECT_HERE_THE_CONTENT_YOU_WOULD_LIKE} />
      </UI.Container>
    </UI.Container>
  );
};

const I18N_ARRAY = values(I18Ns);

export function useDeactivatedIds(): string[] {
  const country = usePageRestrictionCountry();
  return useMemo(
    () =>
      map(
        filter(COMPONENTS_CONFIGS, item =>
          includes(item.deactivated, getRegionNameByCountry(country)),
        ),
        'id',
      ),
    [country],
  );
}

const Steps = () => {
  const steps = useEditorTutorialSteps();
  const deactivatedIds = useDeactivatedIds();
  return (
    <TutorialProvider steps={steps}>
      <UI.Container f={3} bg="gray.A100">
        <TreeSelect i18ns={I18N_ARRAY} deactivatedIds={deactivatedIds} />
      </UI.Container>
    </TutorialProvider>
  );
};

export const EditorBody: React.FC = () => {
  const yourCompanyTutorial = useYourCompanyTutorial();
  return (
    <UI.Container style={STYLE}>
      <Steps />
      <UI.Container f={10} shadow={2} direction="column">
        <ReactPageEditorRenderer
          Footer={FooterEditor}
          Header={HeaderEditor}
          Component={EmptyEditorText}
        >
          <ReactPageEditorRenderer.Item
            Component={HighlightsRECEditor}
            i18ns={I18N_ARRAY}
          />
          <ReactPageEditorRenderer.Item
            Component={YourCompanyEditor}
            tutorialConfig={yourCompanyTutorial}
            i18ns={I18N_ARRAY}
          />
          <ReactPageEditorRenderer.Item
            Component={RecGroupEditor}
            i18ns={I18N_ARRAY}
          />
          <ReactPageEditorRenderer.Item
            Component={RecCertifiedSolarProfessionalsEditor}
            i18ns={I18N_ARRAY}
          />
          <ReactPageEditorRenderer.Item
            Component={RecProTrustWarrantyEditor}
            i18ns={I18N_ARRAY}
          />
          <ReactPageEditorRenderer.Item
            Component={RecProductsEditor}
            i18ns={I18N_ARRAY}
          />
          <ReactPageEditorRenderer.Item
            Component={SocialMediaEditor}
            i18ns={I18N_ARRAY}
          />
          <ReactPageEditorRenderer.Item
            Component={ContactEditor}
            i18ns={I18N_ARRAY}
          />
        </ReactPageEditorRenderer>
      </UI.Container>
    </UI.Container>
  );
};
