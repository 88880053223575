import { CreatedDraftDTO } from '@page-creator/services-create-draft/CreatedDraftDTO';
import { CreateDraftDTO } from '@page-creator/services-create-draft/CreateDraftDTO';
import {
  INITIAL_STATE as _INITIAL_STATE,
  State as _State,
} from '@redux-async-module/interfaces';

export const NAMESPACE = 'page-creator-draft';
export const INITIAL_STATE = _INITIAL_STATE;
export type StartPayload = CreateDraftDTO;
export type SuccessPayload = CreatedDraftDTO;

export type State = _State<StartPayload, SuccessPayload>;

export interface RootState {
  [NAMESPACE]: State;
}
