import { isTestOrDevEnvironment } from '@lib/config-loader';
import { ContainerProps, Style } from '@ui-system/interfaces';
import UI from '@ui-system/ui';
import filter from 'lodash/filter';
import get from 'lodash/get';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import values from 'lodash/values';
import React, { useMemo } from 'react';
import { ContextComponent } from 'react-page-editor/state';

import { ReactPageContentContextProvider } from './PageContext';

interface ReactPageFinalRendererProps {
  language: string | null;
  country: string | null;
  components: Record<string, ContextComponent>;
  componentsMap: Record<string, React.FC>;
  containerStyle?: Style;
  containerProps?: ContainerProps;
  wrapInAContainer?: boolean;
  gridTemplateRows?: string;
  Header?: React.FC | null;
  Footer?: React.FC | null;
  ExtraComponent?: React.FC | null;
  contents: Record<string, any> | null;
}

function getRenderItem(componentsMap: Record<string, React.FC>) {
  return function renderItem(item: ContextComponent) {
    const Component = get(componentsMap, item.id);
    if (!Component) {
      if (isTestOrDevEnvironment()) {
        throw new Error(`Please setup ${item.name} component`);
      }
      return null;
    }
    return <Component {...item.props} key={item.id} />;
  };
}
export const ReactPageFinalRenderer: React.FC<ReactPageFinalRendererProps> = ({
  components,
  componentsMap,
  wrapInAContainer,
  containerProps,
  containerStyle,
  gridTemplateRows,
  Header,
  Footer,
  ExtraComponent,
  language,
  country,
  contents,
}: ReactPageFinalRendererProps) => {
  const sortedAndFilteredComponents = useMemo(
    () => sortBy(filter(values(components), { visible: true }), ['position']),
    [components],
  );
  if (!wrapInAContainer) {
    return (
      <ReactPageContentContextProvider
        components={components}
        contents={contents}
        language={language}
        country={country}
      >
        <UI.Page gridTemplateRows={gridTemplateRows}>
          {Header && <Header />}
          <UI.Page.Content>
            <UI.Container direction="column">
              {map(sortedAndFilteredComponents, getRenderItem(componentsMap))}
              {ExtraComponent && <ExtraComponent />}
            </UI.Container>
          </UI.Page.Content>
          {Footer && <Footer />}
        </UI.Page>
      </ReactPageContentContextProvider>
    );
  }
  return (
    <ReactPageContentContextProvider
      components={components}
      language={language}
      contents={contents}
      country={country}
    >
      <UI.Container bg="white" style={containerStyle} {...containerProps}>
        <UI.Page gridTemplateRows={gridTemplateRows}>
          {Header && <Header />}
          <UI.Page.Content>
            <UI.Container direction="column" w="100%">
              {map(sortedAndFilteredComponents, getRenderItem(componentsMap))}
            </UI.Container>
          </UI.Page.Content>
          {Footer && <Footer />}
        </UI.Page>
      </UI.Container>
    </ReactPageContentContextProvider>
  );
};

ReactPageFinalRenderer.defaultProps = {
  containerProps: {},
  gridTemplateRows: '80px auto 0',
};
