import { hashObject } from '@hasher/object-hash';
import { Actions, Epic, Selectors } from '@redux-async-module/interfaces';
import { selectors as languageSelectors } from '@resource/redux-language/reducer';
import get from 'lodash/get';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

export default function buildExtraEpics<StartPayload, SuccessPayload>(
  actions: Actions<StartPayload, SuccessPayload>,
  selectors: Selectors<StartPayload, SuccessPayload>,
): Epic[] {
  const onLanguageOrBundleNamesChange: Epic = (action$, state$) =>
    state$.pipe(
      map(state => {
        const language = languageSelectors.currentLanguage(state);
        const lastStartPayload = selectors.lastStartPayload(state);
        return hashObject({ language, lastStartPayload });
      }),
      distinctUntilChanged(),
      filter(() => {
        const lastStartPayload = selectors.lastStartPayload(state$.value);
        const bundleNames = get(lastStartPayload, 'bundleNames');
        const language = languageSelectors.currentLanguage(state$.value);
        return !!(bundleNames && language);
      }),
      map(() => {
        const lastStartPayload = selectors.lastStartPayload(state$.value);
        const bundleNames = get(lastStartPayload, 'bundleNames');
        return actions.requestStart(({
          bundleNames,
        } as unknown) as StartPayload);
      }),
    );

  return [onLanguageOrBundleNamesChange];
}
