import { I18nShape } from '@i18n/core';
import { DE } from '@i18n/utils';
import { LABELS } from '@resource/bundle-names';

const NAMESPACE = 'label';

export const FULL_NAME: I18nShape = {
  key: `${NAMESPACE}.fullName`,
  text: 'Full name',
  bundleName: LABELS,
  translation: {
    [DE]: 'Vor- und Zuname',
  },
  metadata: {
    description: 'User full name label',
  },
};

export const FIRST_NAME: I18nShape = {
  key: `${NAMESPACE}.firstName`,
  text: 'First name',
  bundleName: LABELS,
  translation: {
    [DE]: 'Vorname',
  },
  metadata: {
    description: 'User first name label',
  },
};

export const LAST_NAME: I18nShape = {
  key: `${NAMESPACE}.lastName`,
  text: 'Last name',
  bundleName: LABELS,
  translation: {
    [DE]: 'Nachname',
  },
  metadata: {
    description: 'User last name label',
  },
};

export const TAN_CODE_LABEL: I18nShape = {
  key: `${NAMESPACE}.tanCode`,
  text: 'Please enter the security token sent to your email',
  bundleName: LABELS,
  translation: {
    [DE]:
      'Bitte gebe hier den Sicherheitstoken ein, der an deine E-Mail geschickt wurde.',
  },
  metadata: {
    description: 'Tan code field label',
  },
};

export const COMPANY_NAME: I18nShape = {
  key: `${NAMESPACE}.companyName`,
  text: 'Company name',
  bundleName: LABELS,

  metadata: {
    description: 'Company name label',
  },
};

export const COMPANY_ADDRESS: I18nShape = {
  key: `${NAMESPACE}.companyAddress`,
  text: 'Company address',
  bundleName: LABELS,

  metadata: {
    description: 'Company address label',
  },
};
