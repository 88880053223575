import { FastFormInput } from '@fast-form/components';
import React from 'react';

import { TANCodeFieldProps, TANCodeFieldType } from '../core';
import { FIELD_PATHS } from './TANCodeFormData';

const TANCodeField: TANCodeFieldType = ({ placeholder }: TANCodeFieldProps) => (
  <FastFormInput
    fieldPath={FIELD_PATHS.tanCode}
    placeholder={placeholder}
    modifiers="lg"
    keyboardType="default"
    autoCapitalize="characters"
    autoCorrect={false}
    maxLength={8}
    dynamicFontSize
  />
);

export default TANCodeField;
