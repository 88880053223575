import { Bundle, I18nShape } from '@i18n/core';
import { splitWithNoFalsyValues } from '@lib/utils-string';
import { join, memoize, size, tail } from 'lodash';

export const IGNORE_I18N_SHAPE_KEY = 'IGNORE_I18N_SHAPE_KEY';

export const IGNORE_I18N_BUNDLE_NAME = 'IGNORE_I18N_BUNDLE_NAME';

const DOT = '.';

function internalFindMessage(i18n: I18nShape, bundle: Bundle): string {
  if (!i18n) return '';
  if (!bundle) return i18n.text;
  const { key, text } = i18n;
  if (IGNORE_I18N_SHAPE_KEY === key) {
    return text;
  }
  let splitted = splitWithNoFalsyValues(key, DOT);
  let message;
  while (size(splitted) > 0) {
    if (message) splitted = [];
    const internalKey = join(splitted, DOT);
    if (size(splitted) === 1) {
      message = bundle[internalKey] || text || '';
    }
    if (bundle[internalKey]) {
      message = bundle[internalKey];
    } else {
      splitted = tail(splitted);
    }
  }
  return message;
}

export const findMessage = memoize(
  internalFindMessage,
  (i18n: I18nShape, bundle: Bundle) =>
    `${i18n?.key || i18n}-${i18n?.bundleName || ''}-${
      bundle?.hash || Date.now()
    }`,
);
