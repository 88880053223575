import { fromString } from '@lib/utils-regex';
import {
  ValidationArguments,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator';

@ValidatorConstraint({ name: 'isRegex', async: false })
export class IsRegex implements ValidatorConstraintInterface {
  async validate(text: string, _args: ValidationArguments): Promise<boolean> {
    try {
      const regex = fromString(text);
      return regex != null && regex instanceof RegExp;
    } catch (error) {
      return false;
    }
  }

  defaultMessage(_args: ValidationArguments): string {
    return 'Value ($value) provided is not a valid regular expression!';
  }
}
