import { createAction } from '@redux-basic-module/actions-utils';
import { Handlers } from '@redux-basic-module/interfaces';
import { createReducer } from '@redux-basic-module/reducer-utils';
import { createSelector } from '@redux-basic-module/selector-utils';
import defaultTo from 'lodash/defaultTo';
import get from 'lodash/get';

import {
  AuthData,
  AuthStatus,
  LoginAttemptData,
  LogoutData,
  NAMESPACE,
  Selectors,
  State,
} from './types';

interface Reducer {
  (state: State, action: Action): State;
}

export const saveLoginAttemptData = createAction<
  'auth/auth-data/save-login-attempt-data',
  LoginAttemptData
>('auth/auth-data/save-login-attempt-data');

export const saveAuthData = createAction<
  'auth/auth-data/save-auth-data',
  AuthData
>('auth/auth-data/save-auth-data');

export const logout = createAction<'auth/auth-data/logout', LogoutData | null>(
  'auth/auth-data/logout',
);

export type Action =
  | ReturnType<typeof saveLoginAttemptData>
  | ReturnType<typeof saveAuthData>;

const INITIAL_AUTH_STATUS_STATE = {
  sessionExpired: false,
};

const INITIAL_STATE: State = {
  loginAttemptData: null,
  authData: null,
  authStatus: INITIAL_AUTH_STATUS_STATE,
};

export const selectors: Selectors = {
  loginAttemptData: createSelector<LoginAttemptData>([
    NAMESPACE,
    'loginAttemptData',
  ]),
  authData: createSelector<AuthData>([NAMESPACE, 'authData']),
  authStatus: createSelector<AuthStatus>([NAMESPACE, 'authStatus']),
};

const HANDLERS: Handlers<State, LoginAttemptData | AuthData | LogoutData> = {
  [saveLoginAttemptData.type]: (state, { payload }) => ({
    ...state,
    authStatus: {
      ...defaultTo(state.authStatus, INITIAL_AUTH_STATUS_STATE),
      sessionExpired: false,
    },
    loginAttemptData: {
      ...defaultTo(state.loginAttemptData, {}),
      ...defaultTo(payload, {}),
    },
  }),
  [saveAuthData.type]: (state, { payload }) => ({
    ...state,
    loginAttemptData: null,
    authData: {
      ...defaultTo(state.authData, {}),
      ...defaultTo(payload, {}),
    },
  }),
  [logout.type]: (_state, { payload }) => ({
    ...INITIAL_STATE,
    authStatus: {
      ...INITIAL_AUTH_STATUS_STATE,
      sessionExpired: get(payload, 'sessionExpired', false),
    },
  }),
};

const reducer: Reducer = createReducer<State, Action>(
  NAMESPACE,
  INITIAL_STATE,
  HANDLERS,
);

export default reducer;
