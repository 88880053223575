import { createSelector } from '@redux-basic-module/selector-utils';

import { NAMESPACE, State } from './types';

export const emailSelector = createSelector<State['email']>([
  NAMESPACE,
  'email',
]);

export const userInfoSelector = createSelector<State>([NAMESPACE]);

export const languageSelector = createSelector<State['language']>([
  NAMESPACE,
  'language',
]);
