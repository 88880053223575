import { useParams, useQueryParams } from '@lib/navigation';
import { COMPONENTS_MAP } from '@page-creator/context/componentsMap';
import { Footer, Header } from '@pro-page-components/header-and-footer';
import { ReactPageFinalRenderer } from '@react-page-editor/final-renderer';
import { useDeepCompareEffect } from '@react-utils/hooks';
import { usePageByUrlPath } from '@rec/logic-live';
import { useChangeLanguageInternal } from '@resource/redux-language';
import { EMPTY_OBJECT } from '@shared-utils/object';
import * as React from 'react';
import {
  AutoUpdateViewMode,
  ViewModeContextProvider,
} from 'react-page-editor/state';

import {
  CookieConsent,
  CookieConsentContextProvider,
} from '../../components/cookie-consent';

interface ChangeLanguageByMetadataProps {
  language: string | null;
}
const ChangeLanguageByMetadata: React.FC<ChangeLanguageByMetadataProps> = ({
  language,
}: ChangeLanguageByMetadataProps) => {
  const changeLanguage = useChangeLanguageInternal();
  useDeepCompareEffect(() => {
    if (language) changeLanguage({ language });
  }, [language]);
  return null;
};

export const Main: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { iframe } = useQueryParams<{ iframe: boolean }>();
  const { result, language, contents, country } = usePageByUrlPath(id);
  return (
    <CookieConsentContextProvider>
      <ViewModeContextProvider>
        <AutoUpdateViewMode />
        {language && <ChangeLanguageByMetadata language={language} />}
        <ReactPageFinalRenderer
          contents={contents}
          components={result || EMPTY_OBJECT}
          componentsMap={COMPONENTS_MAP}
          Header={!iframe ? Header : null}
          Footer={!iframe ? Footer : null}
          language={language}
          country={country}
        />
      </ViewModeContextProvider>
      <CookieConsent />
    </CookieConsentContextProvider>
  );
};
