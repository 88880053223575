import { hasItemInArray } from '@shared-utils/array';
import concat from 'lodash/concat';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import { useCallback, useState } from 'react';

import { useDeepCompareCallback } from './useDeepCompareCallback';

interface UseSelectedItemsReturn<I> {
  isSelected: (item: I) => boolean;
  selectedItems: I[];
  toggleItem: (item: I) => void;
}

const EMPTY_ARRAY = [];

export function useSelectedItems<I>(
  initialValues: I[] = EMPTY_ARRAY,
): UseSelectedItemsReturn<I> {
  const [selectedItems, setSelectedItems] = useState<I[]>(initialValues);
  const isSelected = useDeepCompareCallback(
    (item: I) => hasItemInArray<I>(selectedItems, item),
    [selectedItems],
  );
  const toggleItem = useCallback(
    (item: I) => {
      if (isSelected(item)) {
        setSelectedItems(prev => without(prev, item));
      } else {
        setSelectedItems(prev => uniq(concat(prev, item)));
      }
    },
    [isSelected],
  );
  return {
    selectedItems,
    isSelected,
    toggleItem,
  };
}
