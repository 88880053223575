import { EMPTY_ARRAY } from '@shared-utils/array';
import { EMPTY_OBJECT } from '@shared-utils/object';
import { O, U } from '@utils/ts';
import noop from 'lodash/noop';
import React from 'react';

export interface ComponentConfig {
  name: string;
  id: string;
  path: string;
  parent?: string;
  deactivated?: string[];
}

export interface ComponentHandlerProps {
  position?: number;
}
export type ComponentHandlerType<P = O.Object> = React.FC<
  P & ComponentHandlerProps
>;

export type Children = React.FC & {
  config: ComponentConfig;
  children?: Children[];
};

export type ComponentForEditor<P = O.Object> = React.FC<P> & {
  children?: Children[];
  config: ComponentConfig;
};

export interface ContextComponent extends ComponentConfig {
  position: number;
  visible: boolean;
  props?: Record<string, any>;
  children?: Record<string, ContextComponent>;
}
export enum ModifiedBy {
  FRAMEWORK = 'FRAMEWORK',
  USER = 'USER',
}

export class ReactPageEditorContextValue {
  components: Record<string, ContextComponent> = EMPTY_OBJECT;

  modifiedBy: ModifiedBy;

  setComponents: (
    components: Record<string, ContextComponent>,
    modifiedBy?: ModifiedBy,
  ) => void = noop;

  componentsIds: string[] = EMPTY_ARRAY;

  registerComponent: (
    component: ComponentForEditor,
    position: number,
  ) => void = noop;

  toggleComponent: (component: ComponentConfig) => void = noop;

  toggleAllComponents: () => void = noop;

  setComponentProps: (
    component: ComponentConfig,
    props: Record<string, any>,
  ) => void = noop;

  setComponentProp: (
    component: ComponentConfig,
    prop: any,
    path: string,
  ) => void = noop;

  moveComponent: (
    component: ComponentConfig,
    options: { direction: 'up' | 'down' },
  ) => void = noop;

  moveComponentUp: (component: ComponentConfig) => void = noop;

  moveComponentDown: (component: ComponentConfig) => void = noop;

  getComponentById: (id: string) => U.Nullable<ContextComponent> = () => null;

  onSaveConfiguration: () => void = noop;

  isComponentVisible: (component: ComponentConfig) => boolean = () => false;

  canMoveUp: (component: ComponentConfig) => boolean = () => false;

  canMoveDown: (component: ComponentConfig) => boolean = () => false;

  isPreviewActive = false;

  allSelected = false;

  togglePreviewActive: () => void = noop;
}

export interface UseEditorContextValueConfig {
  onSave: (components: Record<string, ContextComponent>) => void;
  initialVisibleState: boolean;
  onComponentsChanged?: (components: Record<string, ContextComponent>) => void;
  onReconcileComponents?: (
    newComponents: Record<string, ContextComponent>,
    prevState: Record<string, ContextComponent>,
  ) => Record<string, ContextComponent>;
}

export interface EditorContextProviderProps
  extends UseEditorContextValueConfig {
  children: React.ReactNode;
}

export enum PreviewMode {
  MOBILE = 'MOBILE',
  DESKTOP = 'DESKTOP',
}

export class ViewModeContextValue {
  mode: PreviewMode = PreviewMode.DESKTOP;

  setDesktopMode: VoidFunction = noop;

  setMobileMode: VoidFunction = noop;

  setMode: (mode: PreviewMode) => void = noop;

  isMobile = false;

  isDesktop = true;
}
