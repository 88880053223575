import {
  ConsoleLogFunction,
  FormatLogMessageOptions,
  getMessageChannel,
  LogLevel,
  Message,
} from '@logger/core';
import { getTimeStamp, log } from '@logger/utils';
import { inspectArg } from '@shared-utils/object';
import lFilter from 'lodash/filter';
import flatten from 'lodash/flatten';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isNull from 'lodash/isNull';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import lMap from 'lodash/map';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import trim from 'lodash/trim';
import { filter, map } from 'rxjs/operators';

const DEFAULT_GET_TRACE_ID = (m: Message) => m.traceId;

const DEFAULT_EXECUTE_LOG = (
  fn: ConsoleLogFunction,
  output: any[],
  params: any[],
): void => {
  fn.apply(null, [output.join(' '), ...params]);
};

function filterArg(arg: any) {
  return !(isUndefined(arg) || isEmpty(arg));
}

function transformObject(object: any, omitParams?: string[]) {
  const transformed = omitBy(omitBy(object, isNull), isUndefined);
  if (omitParams) {
    return omit(transformed, omitParams);
  }
  return transformed;
}

function transformParams(
  messageArgs: any[],
  params: Record<string, any>,
  options: ConsoleLoggerTransportOptions,
) {
  let all = [];
  if (isEmpty(messageArgs) && isEmpty(params)) {
    return all;
  }
  if (isArray(messageArgs)) {
    all = all.concat(messageArgs);
  } else if (isObject(messageArgs)) {
    const transformed = transformObject(messageArgs);
    if (transformed) {
      all.push(transformed);
    }
  }
  if (!isEmpty(params)) {
    const transformed = transformObject(params, options.omitParams);
    if (transformed) {
      all.push(transformed);
    }
  }
  return lMap(lFilter(all, filterArg), inspectArg);
}

function applyColor(
  message: any,
  key: keyof FormatLogMessageOptions,
  colors?: FormatLogMessageOptions,
) {
  if (!message) {
    return message;
  }
  if (colors != null && isFunction(colors[key])) {
    // @ts-ignore due to level field it causes error
    return colors[key](message);
  }
  return message;
}

function applyLevelColor(level: LogLevel, colors?: FormatLogMessageOptions) {
  if (!level) {
    return level;
  }
  if (colors != null && isFunction(colors.level)) {
    return colors.level(level);
  }
  return level;
}

function createSubscribeToLogger(options: ConsoleLoggerTransportOptions) {
  const { formatters, executeLogFn } = options;

  function subscribeToLogger(msg: Message) {
    const { date, group, message, level, logOptions, operation } = msg;
    const colors = logOptions?.formatters || formatters;
    const operationName = operation?.name;
    const operationStatus = operation?.status;
    const operationTime = operation?.time;
    const getTraceId = options?.getTraceId || DEFAULT_GET_TRACE_ID;
    const outputArr = flatten([
      applyColor(getTimeStamp(date), 'date', colors),
      applyColor(getTraceId(msg), 'traceId', colors),
      applyLevelColor(level, colors),
      applyColor(trim(group), 'group', colors),
      applyColor(operationName, 'operationName', colors),
      applyColor(operationStatus, 'operationStatus', colors),
      applyColor(operationTime, 'operationTime', colors),
      applyColor(message, 'message', colors),
    ]);

    const fn = log(msg.level) || log(LogLevel.INFO);

    const output = outputArr.filter(Boolean);

    const executeLog = executeLogFn || DEFAULT_EXECUTE_LOG;
    executeLog(
      fn,
      output,
      transformParams(msg.messageArgs, msg.params, options),
    );
  }
  return subscribeToLogger;
}

export type ConsoleLoggerTransportOptions = {
  staticParams?: Record<string, unknown>;
  omitParams?: string[];
  formatters?: FormatLogMessageOptions;
  getTraceId?: (msg: Message) => string | null;
  filter?: (msg: Message) => boolean;
  executeLogFn?: (fn: ConsoleLogFunction, output: any[], params: any[]) => void;
  fullMessage?: boolean;
};

function createTransformMessage(options: ConsoleLoggerTransportOptions) {
  const { staticParams, fullMessage: enableFullMessage } = options;
  function transformMessage(msg: Message) {
    if (!staticParams && !enableFullMessage) {
      return msg;
    }
    return {
      ...msg,
      message: enableFullMessage ? msg.fullMessage || msg.message : msg.message,
      params: {
        ...(staticParams || {}),
        ...(msg.params || {}),
      },
    };
  }
  return transformMessage;
}

export function registerConsoleLoggerTransport(
  options: ConsoleLoggerTransportOptions,
): void {
  const subscribeToLogger = createSubscribeToLogger(options);
  const transformMessage = createTransformMessage(options);
  getMessageChannel()
    .pipe(
      filter(Boolean),
      map(transformMessage),
      filter(options?.filter || Boolean),
    )
    .subscribe(subscribeToLogger);
}
