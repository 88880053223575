import { EMPTY_ARRAY } from '@shared-utils/array';
import { EMPTY_OBJECT } from '@shared-utils/object';
import updateImmutability from 'immutability-helper';
import {
  isEmpty,
  map,
  memoize,
  merge,
  pickBy,
  reduce,
  reduceRight,
  replace,
  split,
} from 'lodash';

import {
  Command,
  FieldPath,
  FieldPathToValue,
  FormUpdate,
  PathValueOrCommandValue,
  ValidationError,
} from '../core';

// import logger from '../log';

export function updateFieldPathCommand<T>(
  formChange: FormUpdate,
): Record<string, any> {
  if (isEmpty(formChange) || isEmpty(formChange.fieldPath)) {
    // logger.error('Error, no field path informed', formChange);
    return EMPTY_OBJECT;
  }
  const change = {
    ...formChange,
    command: formChange.command || '$set',
  };
  const splitedFieldPaths = split(change.fieldPath, '.');
  return reduceRight<FieldPath, PathValueOrCommandValue<T>>(
    splitedFieldPaths,
    // @ts-ignore
    (currCommands: PathValueOrCommandValue<T>, fieldName: FieldPath) => ({
      [fieldName]: currCommands,
    }),
    { [change.command]: change.value },
  );
  // logger.trace('Generated command', change, comm);
  // return comm;
}

export function updateFieldPathsCommands(
  changes: Array<FormUpdate>,
): Record<Command, never> {
  const commandsArray = map(changes, updateFieldPathCommand);
  // @ts-ignore
  return merge(...commandsArray);
  // logger.trace('Generated update merged command', commands);
  // return commands;
}

export const normalizeFieldPath = memoize((fieldPath: FieldPath) =>
  replace(replace(fieldPath, /\[/g, '.'), /]/g, ''),
);

export function update<T>(
  currentValues: T | Record<string, any>,
  query: PathValueOrCommandValue<T>,
): Record<string, any> {
  try {
    // @ts-ignore
    return updateImmutability(currentValues, query);
  } catch (error) {
    // logger.error('Error during update execution', error, currentValues);
    return currentValues;
  }
}

export const normalizeErrors = (
  errors: Array<ValidationError> | null | void,
  watchedFieldPaths?: FieldPathToValue<number>,
): FieldPathToValue<ValidationError> => {
  const currentErrors: FieldPathToValue<ValidationError> = reduce(
    errors || EMPTY_ARRAY,
    (obj, error: ValidationError) => ({
      ...obj,
      [normalizeFieldPath(error.path)]: error,
    }),
    EMPTY_OBJECT,
  );
  if (!watchedFieldPaths) {
    return currentErrors;
  }
  const currentWatchedFields: FieldPathToValue<number> = pickBy(
    watchedFieldPaths,
    value => value > 0,
  );
  return pickBy(
    currentErrors,
    (value, key) => currentWatchedFields[key] != null,
  );
};

export function normalizeTouchedFields(
  changes: Array<FormUpdate> | null | void,
): FieldPathToValue<boolean> {
  return reduce(
    changes || EMPTY_ARRAY,
    (obj, change) => ({
      ...obj,
      [normalizeFieldPath(change.fieldPath)]: true,
    }),
    EMPTY_OBJECT,
  );
}
