import { EMPTY_ARRAY } from '@shared-utils/array';
import {
  ArrayNotEmpty as OriginalArrayNotEmpty,
  ArrayUnique as OriginalArrayUnique,
  IsAlpha as OriginalIsAlpha,
  IsBoolean as OriginalIsBoolean,
  IsDate as OriginalIsDate,
  IsEmail as OriginalIsEmail,
  IsEnum as OriginalIsEnum,
  IsInstance as OriginalIsIstance,
  IsISO31661Alpha2 as OriginalIsISO31661Alpha2,
  IsNotEmpty as OriginalIsNotEmpty,
  IsNotEmptyObject as OriginalIsNotEmptyObject,
  IsString as OriginalIsString,
  Length as OriginalLength,
  Max as OriginalMax,
  MaxLength as OriginalMaxLength,
  Min as OriginalMin,
  Validate,
  ValidationArguments as _ValidationArguments,
  ValidationOptions as _ValidationOptions,
  ValidatorConstraintInterface,
} from 'class-validator';
import isEmpty from 'lodash/isEmpty';
import { F, L } from 'ts-toolbelt';
import { ObjectType } from 'typedi';
import ValidatorJS from 'validator';

import { IsRegex as OriginalIsRegex } from './IsRegex';

export {
  IsOptional,
  registerDecorator,
  validate,
  ValidateIf,
  ValidateNested,
  validateOrReject,
  ValidatePromise,
  ValidationError,
} from 'class-validator';

export type ValidationArguments = _ValidationArguments;
export type ValidationOptions = _ValidationOptions;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createArgsArray(...args: any): any[] {
  if (!isEmpty(args)) {
    const array = [];
    args.forEach(arg => {
      if (arg) {
        array.push(arg);
      }
    });
    return array;
  }
  return EMPTY_ARRAY;
}

type TranslateFn<P extends L.List> = (
  validator:
    | F.Function<P, PropertyDecorator>
    | ObjectType<ValidatorConstraintInterface>,
  args: P,
  validationArgs: ValidationArguments,
  name: string,
) => string;

const defaultTranslate: TranslateFn<any> = validator => validator.name;

export const SINGLETON = {
  translate: defaultTranslate,
};

export function configure({
  translate,
}: {
  translate: TranslateFn<any>;
}): void {
  if (translate) {
    SINGLETON.translate = translate;
  }
}

export const IsNotEmpty = (
  validationOptions?: ValidationOptions,
): PropertyDecorator =>
  OriginalIsNotEmpty({
    ...validationOptions,
    message: validationArgs =>
      SINGLETON.translate(
        OriginalIsNotEmpty,
        createArgsArray(validationOptions),
        validationArgs,
        'IsNotEmpty',
      ),
  });

export const IsEmail = (
  options?: ValidatorJS.IsEmailOptions,
  validationOptions?: ValidationOptions,
): PropertyDecorator =>
  OriginalIsEmail(options, {
    ...validationOptions,
    message: validationArgs =>
      SINGLETON.translate(
        OriginalIsEmail,
        createArgsArray(options, validationOptions),
        validationArgs,
        'IsEmail',
      ),
  });

export const Length = (
  min: number,
  max?: number,
  validationOptions?: ValidationOptions,
): PropertyDecorator =>
  OriginalLength(min, max, {
    ...validationOptions,
    message: validationArgs =>
      SINGLETON.translate(
        OriginalLength,
        createArgsArray(min, max, validationOptions),
        validationArgs,
        'Length',
      ),
  });

export const IsEnum = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  entity: Object,
  validationOptions?: ValidationOptions,
): PropertyDecorator =>
  OriginalIsEnum(entity, {
    ...validationOptions,
    message: validationArgs =>
      SINGLETON.translate(
        OriginalIsEnum,
        createArgsArray(entity, validationOptions),
        validationArgs,
        'IsEnum',
      ),
  });

export const IsNotEmptyObject = (
  options?: {
    nullable?: boolean;
  },
  validationOptions?: ValidationOptions,
): PropertyDecorator =>
  OriginalIsNotEmptyObject(options, {
    ...validationOptions,
    message: validationArgs =>
      SINGLETON.translate(
        OriginalIsNotEmptyObject,
        createArgsArray(validationOptions),
        validationArgs,
        'IsNotEmptyObject',
      ),
  });

export const MaxLength = (
  max: number,
  validationOptions?: ValidationOptions,
): PropertyDecorator =>
  OriginalMaxLength(max, {
    ...validationOptions,
    message: validationArgs =>
      SINGLETON.translate(
        OriginalMaxLength,
        createArgsArray(max, validationOptions),
        validationArgs,
        'MaxLength',
      ),
  });

export const Max = (
  maxValue: number,
  validationOptions?: ValidationOptions,
): PropertyDecorator =>
  OriginalMax(maxValue, {
    ...validationOptions,
    message: validationArgs =>
      SINGLETON.translate(
        OriginalMax,
        createArgsArray(maxValue, validationOptions),
        validationArgs,
        'Max',
      ),
  });

export const Min = (
  minValue: number,
  validationOptions?: ValidationOptions,
): PropertyDecorator =>
  OriginalMin(minValue, {
    ...validationOptions,
    message: validationArgs =>
      SINGLETON.translate(
        OriginalMin,
        createArgsArray(minValue, validationOptions),
        validationArgs,
        'Min',
      ),
  });

export const IsISO31661Alpha2 = (
  validationOptions?: ValidationOptions,
): PropertyDecorator =>
  OriginalIsISO31661Alpha2({
    ...validationOptions,
    message: validationArgs =>
      SINGLETON.translate(
        OriginalIsISO31661Alpha2,
        createArgsArray(validationOptions),
        validationArgs,
        'IsISO31661Alpha2',
      ),
  });

export const IsInstance = (
  targetType: new (...args: any[]) => any,
  validationOptions?: ValidationOptions,
): PropertyDecorator =>
  OriginalIsIstance(targetType, {
    ...validationOptions,
    message: validationArgs =>
      SINGLETON.translate(
        OriginalIsIstance,
        createArgsArray(targetType, validationOptions),
        validationArgs,
        'IsInstance',
      ),
  });

export const IsDate = (
  validationOptions?: ValidationOptions,
): PropertyDecorator =>
  OriginalIsDate({
    ...validationOptions,
    message: validationArgs =>
      SINGLETON.translate(
        OriginalIsDate,
        createArgsArray(validationOptions),
        validationArgs,
        'IsDate',
      ),
  });

export const ArrayNotEmpty = (
  validationOptions?: ValidationOptions,
): PropertyDecorator =>
  OriginalArrayNotEmpty({
    ...validationOptions,
    message: validationArgs =>
      SINGLETON.translate(
        OriginalArrayNotEmpty,
        createArgsArray(validationOptions),
        validationArgs,
        'ArrayNotEmpty',
      ),
  });

export const ArrayUnique = (
  validationOptions?: ValidationOptions,
): PropertyDecorator =>
  OriginalArrayUnique({
    ...validationOptions,
    message: validationArgs =>
      SINGLETON.translate(
        OriginalArrayUnique,
        createArgsArray(validationOptions),
        validationArgs,
        'ArrayUnique',
      ),
  });

export const IsString = (
  validationOptions?: ValidationOptions,
): PropertyDecorator =>
  OriginalIsString({
    ...validationOptions,
    message: validationArgs =>
      SINGLETON.translate(
        OriginalIsString,
        createArgsArray(validationOptions),
        validationArgs,
        'IsString',
      ),
  });

export const IsBoolean = (
  validationOptions?: ValidationOptions,
): PropertyDecorator =>
  OriginalIsBoolean({
    ...validationOptions,
    message: validationArgs =>
      SINGLETON.translate(
        OriginalIsBoolean,
        createArgsArray(validationOptions),
        validationArgs,
        'IsBoolean',
      ),
  });

export const IsRegex = (
  validationOptions?: ValidationOptions,
): PropertyDecorator =>
  Validate(OriginalIsRegex, {
    ...validationOptions,
    message: validationArgs =>
      SINGLETON.translate(
        OriginalIsRegex,
        createArgsArray(validationOptions),
        validationArgs,
        'IsRegex',
      ),
  });
export const IsAlpha = (
  validationOptions?: ValidationOptions,
): PropertyDecorator =>
  Validate(OriginalIsAlpha, {
    ...validationOptions,
    message: validationArgs =>
      SINGLETON.translate(
        OriginalIsAlpha,
        createArgsArray(validationOptions),
        validationArgs,
        'IsAlpha',
      ),
  });
