import { css } from '@ui-system/css';
import { createComponentModifiers } from '@ui-system/modifiers';

const componentModifiers = createComponentModifiers(({ theme }) => ({
  underline: css`
    text-decoration: underline;
  `,
  uppercase: css`
    text-transform: uppercase;
  `,
  capitalize: css`
    text-transform: capitalize;
  `,
  link: css`
    color: ${theme.colors[theme.mode].primary.main};
    text-decoration: underline;
    text-decoration-color: ${theme.colors[theme.mode].primary.main};
  `,
  primary: css`
    color: ${theme.colors[theme.mode].primary.main};
  `,
  error: css`
    color: ${theme.colors[theme.mode].error};
  `,
  warning: css`
    color: ${theme.colors[theme.mode].warning};
  `,
  success: css`
    color: ${theme.colors[theme.mode].success};
  `,
  secondary: css`
    color: ${theme.colors[theme.mode].secondary.main};
  `,
  grayA200: css`
    color: ${theme.colors[theme.mode].gray.A200};
  `,
  grayA300: css`
    color: ${theme.colors[theme.mode].gray.A300};
  `,
  grayA400: css`
    color: ${theme.colors[theme.mode].gray.A400};
  `,
  dark: css`
    color: ${theme.colors[theme.mode].black};
    text-decoration-color: ${theme.colors[theme.mode].black};
  `,
  light: css`
    color: ${theme.colors[theme.mode].white};
    text-decoration-color: ${theme.colors[theme.mode].white};
  `,
  center: css`
    text-align: center;
  `,
  left: css`
    text-align: left;
  `,
  right: css`
    text-align: right;
  `,
  justify: css`
    text-align: justify;
  `,
  // XXX: iOS only works bold like this
  bold: css`
    font-weight: 800;
    font-family: ${theme.typography.fontFamily['bold' || 'regular']};
  `,
  italic: css`
    font-style: italic;
    font-family: ${theme.typography.fontFamily['italic' || 'regular']};
  `,
  thin: css`
    font-weight: 200;
    font-family: ${theme.typography.fontFamily['thin' || 'regular']};
  `,
  normal: css`
    font-weight: normal;
  `,
  thinItalic: css`
    font-weight: 200;
    font-family: ${theme.typography.fontFamily['thinItalic' || 'regular']};
  `,
  boldItalic: css`
    font-weight: 800;
    font-family: ${theme.typography.fontFamily['boldItalic' || 'regular']};
  `,
  letterSpacing1: css`
    letter-spacing: 2px;
  `,
}));

export default componentModifiers;
