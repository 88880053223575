import {
  applyAlign,
  applyBackgroundColor,
  applyBorderBottomLeftRadius,
  applyBorderBottomRightRadius,
  applyBorderRadius,
  applyBorderTopLeftRadius,
  applyBorderTopRightRadius,
  applyDevBorder,
  applyDirection,
  applyFlex,
  applyHeight,
  applyJustify,
  applyMargin,
  applyOpacity,
  applyPadding,
  applyShadow,
  applyWidth,
  styled,
} from '@ui-system/css';
import { ContainerProps } from '@ui-system/interfaces-container';

// Individual render/view
export const BaseContainer = styled.div<ContainerProps>`
  flex-direction: row;
  ${applyMargin.fromProps};
  ${applyPadding.fromProps};
  ${applyAlign.fromProps};
  ${applyDirection.fromProps};
  ${applyJustify.fromProps};
  ${applyFlex.fromProps};
  ${applyBackgroundColor.fromProps};
  ${applyBorderRadius.fromProps};
  ${applyBorderBottomRightRadius.fromProps};
  ${applyBorderBottomLeftRadius.fromProps};
  ${applyBorderTopRightRadius.fromProps};
  ${applyBorderTopLeftRadius.fromProps};
  ${applyShadow.fromProps};
  ${applyDevBorder.fromProps};
  ${applyHeight.fromProps};
  ${applyWidth.fromProps};
  ${applyOpacity.fromProps};
  display: ${({ visible = true }) => (visible ? 'flex' : 'none')};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
`;

export const BaseContainerPressable = BaseContainer;
export const LinearGradient = BaseContainer;
