import {
  Bundle,
  ContextProps,
  ContextValue,
  DateFormatMap,
  Locale,
  Resolver,
  UseTranslatedMessage,
  UseTranslateFunction,
} from '@i18n/core';
import defaultResolver, {
  defaultMessageResolver,
} from '@i18n/default-resolver';
import { formatDate } from '@i18n/utils';
import { useDeepCompareCallback, useDeepCompareMemo } from '@react-utils/hooks';
import { MemoHook } from '@react-utils/hooks-types';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import React, { createContext, useContext, useMemo } from 'react';

const DEFAULT_DATE_FORMAT_MAP = {
  en: 'MM/DD/YYYY',
  de: 'DD.MM.YYYY',
};
const INITIAL_VALUE: ContextValue = {
  resolver: defaultResolver,
  bundle: null,
  locale: null,
  dateFormatMap: DEFAULT_DATE_FORMAT_MAP,
};

const ResourceResolverContext = createContext<ContextValue>(INITIAL_VALUE);

export const useResourceResolverContext = (): ContextValue =>
  useContext(ResourceResolverContext);

export const useTranslateFunction: UseTranslateFunction = () => {
  const ctx = useResourceResolverContext();
  return useDeepCompareCallback(
    (i18nOrText, values) => {
      if (isObject(i18nOrText)) {
        return ctx.resolver(i18nOrText, ctx.bundle, ctx.locale, values);
      }
      return defaultMessageResolver(i18nOrText, ctx.locale, values);
    },
    [ctx],
  );
};

export const useTranslatedMessage: UseTranslatedMessage = (i18n, values) => {
  const translate = useTranslateFunction();
  return useMemo(() => {
    if (isEmpty(i18n)) return null;
    return translate(i18n, values);
  }, [i18n, translate, values]);
};

const useValue: MemoHook<
  [Resolver, Bundle, Locale, DateFormatMap],
  ContextValue
> = (resolver, bundle, locale, dateFormatMap) =>
  useDeepCompareMemo(
    () => ({
      resolver: resolver || defaultResolver,
      bundle,
      locale,
      dateFormatMap: dateFormatMap || DEFAULT_DATE_FORMAT_MAP,
    }),
    [bundle, dateFormatMap, locale, resolver],
  );

const ResourceResolverContextProvider: React.FC<ContextProps> = ({
  children,
  resolver,
  bundle,
  locale,
  dateFormatMap,
}: ContextProps) => {
  const value = useValue(resolver, bundle, locale, dateFormatMap);
  return (
    <ResourceResolverContext.Provider value={value}>
      {children}
    </ResourceResolverContext.Provider>
  );
};

export const useFormattedDate = (date: string | Date): string => {
  const { locale, dateFormatMap } = useResourceResolverContext();
  return formatDate(date, locale, dateFormatMap[locale]);
};

export default ResourceResolverContextProvider;
