import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { FormId } from '../../../core';
import {
  getFormIsSuccessfullyValidatedSelector,
  getFormIsUnsuccessfullySubmittedSelector,
} from '../../stateSelector';
import { RootState } from '../../types';
import useFastFormProviderContext from '../useFastFormProviderContext';
import useFormSubmitCount from '../useFormSubmitCount';

function useIsUnsuccessfullySubmitted<T>(formId: FormId) {
  const selector = useCallback(
    (state: RootState<T>) =>
      getFormIsUnsuccessfullySubmittedSelector(state, formId),
    [formId],
  );
  return useSelector(selector);
}

function useIsSuccessfullyValidated<T>(formId: FormId) {
  const selector = useCallback(
    (state: RootState<T>) =>
      getFormIsSuccessfullyValidatedSelector(state, formId),
    [formId],
  );
  return useSelector(selector);
}

function useOnSubmitErrorCallback(): void {
  const { formId, onSubmitError } = useFastFormProviderContext();
  const isError = useIsUnsuccessfullySubmitted(formId);
  const submitCount = useFormSubmitCount();
  const isValidated = useIsSuccessfullyValidated(formId);
  useEffect(() => {
    if (!isError) {
      return;
    }
    if (!isValidated) {
      return;
    }
    if (submitCount > 0) {
      onSubmitError();
    }
  }, [onSubmitError, isError, submitCount, isValidated]);
}

export default useOnSubmitErrorCallback;
