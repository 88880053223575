import { isTestOrDevEnvironment } from '@lib/config-loader';
import { LogLevel } from '@logger/core';
import { INTERNAL_ERROR } from '@resource/i18n-shapes';
import { getReasonPhrase, StatusCodes } from 'http-status-codes';
import pick from 'lodash/pick';

import { HttpError, isHttpError } from './HttpError';
import { HttpErrorJSON } from './HttpErrorJSON';

export class InternalError extends HttpError implements isHttpError {
  private readonly internalMessage: string;

  private readonly originalError?: Error;

  constructor(internalMessage: string, originalError?: Error) {
    super(StatusCodes.INTERNAL_SERVER_ERROR);
    this.internalMessage = internalMessage;
    this.originalError = originalError;
  }

  toJSON(toClient?: boolean): HttpErrorJSON {
    const json = this.convertToHttpErrorJSON({
      status: this.httpCode,
      type: !toClient ? InternalError.name : getReasonPhrase(this.httpCode),
      reason: isTestOrDevEnvironment()
        ? this.internalMessage
        : getReasonPhrase(this.httpCode),
      i18n: INTERNAL_ERROR,
      level: LogLevel.ERROR,
    });
    if (!toClient && this.originalError != null) {
      const err = this.originalError;
      json.originalError = pick(err, Object.getOwnPropertyNames(err));
    }
    return json;
  }
}

// XXX: to use this when error handling fails ... and we need some data to send back, rare case.
export const DEFAULT_INTERNAL_ERROR_JSON = new InternalError(
  'Internal error',
).toJSON(true);
