import { Locale } from '@i18n/core';
import { IntlMessageFormat } from 'intl-messageformat';
import isEmpty from 'lodash/isEmpty';
import memoize from 'lodash/memoize';
import qs from 'qs';

function internalParseValues(
  message: string,
  values: Record<string, any>,
  locale?: Locale,
): string {
  if (isEmpty(values)) return message;
  return new IntlMessageFormat(message, locale).format(values);
  // const keysToParse = keys(values);
  //
  // let messageToParse = message;
  //
  // forEach(keysToParse, k => {
  //   messageToParse = replace(messageToParse, `{${k}}`, values[k]);
  // });
  // return messageToParse;
}

export const parseValues = memoize(
  internalParseValues,
  (message: string, values: Record<string, any>, locale?: Locale) =>
    qs.stringify({ message, values, locale }),
);
