import { deepmerge, EMPTY_OBJECT } from '@shared-utils/object';
import { Style } from '@ui-system/interfaces';
import get from 'lodash/get';
import { createContext, useContext, useMemo } from 'react';

export type ComponentUuid = string;

export class RootStylesContextValue {
  [componentUuid: string]: Style;
}

const INITIAL_VALUE = new RootStylesContextValue();

export const RootStylesContext = createContext<RootStylesContextValue>(
  INITIAL_VALUE,
);

export const useRootStylesContextValue = (
  styles: RootStylesContextValue,
): RootStylesContextValue => styles;

export const useRootStylesContext = (): RootStylesContextValue =>
  useContext(RootStylesContext);

export const useFeatureStylesProviderValue = (
  partialStyles: Partial<RootStylesContextValue>,
): RootStylesContextValue => {
  const styles = useRootStylesContext();
  return useRootStylesContextValue(deepmerge(styles, partialStyles));
};

export const useComponentStyleFromCtx = (name: ComponentUuid): Style => {
  const ctx = useRootStylesContext();
  return useMemo(() => get(ctx, name, EMPTY_OBJECT), [ctx, name]);
};
