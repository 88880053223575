import 'tippy.js/dist/tippy.css'; // optional

import Tippy from '@tippyjs/react';
import UI from '@ui-system/ui';
import React from 'react';

import { TooltipProps, TooltipType } from './types';

function getOverlay(i18n: TooltipProps['i18n']) {
  return (
    <UI.Container>
      <UI.Caption modifiers="light" i18n={i18n} />
    </UI.Container>
  );
}
const STYLE = {
  display: 'flex',
};

export const Tooltip: TooltipType = ({
  children,
  i18n,
  overlay,
  ...rest
}: TooltipProps) => (
  <Tippy content={overlay || getOverlay(i18n)}>
    <div style={STYLE}>
      <UI.Container {...rest}>{children}</UI.Container>
    </div>
  </Tippy>
);
