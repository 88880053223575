import { useDeepCompareEffect } from '@react-utils/hooks';
import {
  ActionCreator,
  BaseMeta,
  CreateDispatchHookReturn,
} from '@redux-basic-module/interfaces';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export function createDispatchHook<Payload, Meta extends BaseMeta = BaseMeta>(
  actionCreator: ActionCreator<string, Payload, Meta>,
): CreateDispatchHookReturn<Payload, Meta> {
  return function useDispatchHook() {
    const dispatch = useDispatch();
    return useCallback(
      (payload, meta) => {
        dispatch(actionCreator(payload, meta));
      },
      [dispatch],
    );
  };
}

export function createDispatchHookOnMount<
  Payload,
  Meta extends BaseMeta = BaseMeta
>(
  actionCreator: ActionCreator<string, Payload, Meta>,
): (payload?: Payload, meta?: Meta) => void {
  const useDispatchHook = createDispatchHook<Payload, Meta>(actionCreator);
  return function useDispatchHookOnMount(payload: Payload, meta: Meta) {
    const callback = useDispatchHook();
    useDeepCompareEffect(() => {
      callback(payload, meta);
    }, [callback, payload]);
  };
}
