import { css } from '@ui-system/css';
import {
  CheckboxProps,
  FORM_CHECKBOX_CHECK_UUID,
  FORM_CHECKBOX_UUID,
  FORM_INPUT_UUID,
  FORM_SELECT_ARROW_DOWN_UUID,
  FORM_SELECT_DISABLED_UUID,
  FORM_SELECT_PLACEHOLDER_UUID,
  FORM_SELECT_UUID,
  Style,
} from '@ui-system/interfaces';
import pxPerfect from '@ui-system/px-perfect';
import { makeStyle } from '@ui-system/style';
import { isString, join } from 'lodash';

import { makeFinalStyleHook, makeStyleHookWithMergedCtx } from './internal';

// INPUT
const useInputDefaultStyle = makeStyle(
  ({ theme, utils }) => css`
    background-color: transparent;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: ${theme.colors[theme.mode].primary.main};
    border-radius: 0;
    border-width: 1px;
    width: 100%;
    color: ${theme.typography.colors[theme.mode].onBackground};
    font-family: ${theme.typography.fontFamily.regular};
    font-size: ${pxPerfect(20)};
    ${utils.applyPadding(theme, '2, 0')}
  `,
);

export const useInputStyle = makeFinalStyleHook(
  FORM_INPUT_UUID,
  useInputDefaultStyle,
);

// SELECT
const useSelectDefaultStyle = makeStyle(
  ({ theme }) => css`
    font-family: ${theme.typography.fontFamily.regular};
    color: ${theme.typography.colors[theme.mode].onBackground};
    width: 100%;
    height: ${pxPerfect(45)};
    z-index: 1;
    border-bottom-color: ${theme.colors[theme.mode].primary.main};
    border-bottom-width: 1px;
    border-style: solid;
    padding-left: ${pxPerfect(10)};
  `,
);

export const useSelectStyle = makeFinalStyleHook(
  FORM_SELECT_UUID,
  useSelectDefaultStyle,
);

export const useDisabledDefaultStyle = makeStyle<{ disabled: boolean }>(
  ({ theme, props }) => css`
    ${props.disabled
      ? `border-bottom-color: ${theme.colors[theme.mode].gray.A100};`
      : ''}
  `,
);

export const useDisabledStyle = makeStyleHookWithMergedCtx(
  FORM_SELECT_DISABLED_UUID,
  useDisabledDefaultStyle,
);

export const usePlaceholderDefaultStyle = makeStyle<Style>(
  ({ theme, props }) => ({
    fontFamily: isString(theme.typography.fontFamily.regular)
      ? theme.typography.fontFamily.regular
      : join(theme.typography.fontFamily.regular, ''),
    color: theme.typography.colors[theme.mode].placeholder,
    textTransform: props?.textTransform,
  }),
);

export const usePlaceholderStyle = makeStyleHookWithMergedCtx(
  FORM_SELECT_PLACEHOLDER_UUID,
  usePlaceholderDefaultStyle,
);

type ArrowProps = {
  disabled: boolean;
};

export const useDefaultDownArrowStyle = makeStyle<ArrowProps>(
  ({ theme, utils, props }) => css`
    ${utils.applyColor(theme, props?.disabled ? 'gray.A100' : 'primary')}
    position: absolute;
    right: 10px;
    z-index: 1;
  `,
);

export const useDownArrowStyle = makeStyleHookWithMergedCtx<ArrowProps>(
  FORM_SELECT_ARROW_DOWN_UUID,
  useDefaultDownArrowStyle,
);

// CHECKBOX
const useCheckboxDefaultStyle = makeStyle(
  () => css`
    justify-content: center;
  `,
);

export const useCheckboxStyle = makeFinalStyleHook(
  FORM_CHECKBOX_UUID,
  useCheckboxDefaultStyle,
);

const useCheckboxCheckDefaultStyle = makeStyle<Partial<CheckboxProps>>(
  ({ props, theme, utils }) => css`
    ${utils.applyColor(theme, props?.disabled ? 'gray.A300' : 'primary')}
  `,
);

export const useCheckboxCheckStyle = makeFinalStyleHook(
  FORM_CHECKBOX_CHECK_UUID,
  useCheckboxCheckDefaultStyle,
);
