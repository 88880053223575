import { TextField } from '@material-ui/core';
import { useDeepCompareMemo } from '@react-utils/hooks';
import componentModifiers from '@ui-system/default-modifiers/form/input';
import defaultProps from '@ui-system/default-props/form/input';
import { useInputStyle } from '@ui-system/default-styles';
import { useTranslatedMessage } from '@ui-system/deps';
import { InputProps } from '@ui-system/interfaces-form/input';
import UI from '@ui-system/ui';
import React from 'react';

export const Input: React.FC<InputProps> = ({
  value,
  error,
  label,
  onChange,
  accessoryLeft,
  accessoryRight,
  placeholder,
  caption,
  textAlign,
  style,
  modifiers,
  multiline,
  ...props
}: InputProps) => {
  const renderLabel = () =>
    label ? <UI.Typography i18n={label} variant="overline" /> : null;

  const renderAccessoryRight = (): any => accessoryRight || null;

  const renderAccessoryLeft = (): any => accessoryLeft || null;
  const inputProps = useDeepCompareMemo(
    () => ({
      ...props,
      textAlign,
    }),
    [textAlign, props],
  );

  const finalStyle = useInputStyle(
    style,
    error ? `${modifiers}, error` : modifiers,
    componentModifiers,
  );

  const MuiInputProps = {
    startAdornment: renderAccessoryLeft,
    endAdornment: renderAccessoryRight,
    style: finalStyle,
  };
  const placeholderText = useTranslatedMessage(placeholder);
  return (
    // @ts-ignore
    <TextField
      fullWidth
      error={error}
      value={value as string}
      label={renderLabel}
      placeholder={placeholderText}
      helperText={error && <UI.Typography i18n={caption} variant="caption" />}
      InputProps={MuiInputProps as Record<string, any>}
      onChange={e => onChange(e.target.value)}
      multiline={multiline}
      {...inputProps}
    />
  );
};

Input.defaultProps = defaultProps;

export default Input;
