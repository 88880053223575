import { MarketingCategory } from '@rec/marketing-data';
import {
  INITIAL_STATE as _INITIAL_STATE,
  State as _State,
} from '@redux-async-module/interfaces';
import { U } from '@utils/ts';

export const NAMESPACE = 'marketing-categories';
export const INITIAL_STATE = {
  ..._INITIAL_STATE,
  selected: null,
  panels: null,
};
export type StartPayload = undefined;
export type SuccessPayload = {
  panels: MarketingCategory[];
  categories: MarketingCategory[];
};
export type SelectCategoryPayload = { category: MarketingCategory };

export type State = _State<StartPayload, MarketingCategory[]> & {
  selected: U.Nullable<MarketingCategory>;
  panels: U.Nullable<MarketingCategory[]>;
};

export interface RootState {
  [NAMESPACE]: State;
}

export enum WorldRegion {
  EMEA = 'EMEA',
  APAC = 'APAC',
  AMER = 'AMER',
}
