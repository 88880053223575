import { I18nShape } from '@i18n/core';
import { IGNORE_I18N_SHAPE_KEY } from '@i18n/utils';
import { CAPTION as bundleName } from '@resource/bundle-names';
import {
  validateOrReject,
  ValidationError as ClassValidatorValidationError,
} from 'class-validator-i18n';
import { head, map, values } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { nanoid } from 'nanoid';
import { ObjectSchema } from 'yup';

import { ValidationError } from '../core';

export type ErrorType = Array<ClassValidatorValidationError>;

export function normalizeClassValidatorErrors(
  errors: ErrorType,
  bypass?: boolean,
): any {
  if (bypass) return errors;
  return map(errors, _error => {
    const errorsArr = values(_error.constraints);
    const message = head(errorsArr);
    return {
      name: _error.property,
      value: _error.value,
      type: _error.property,
      errors: errorsArr,
      inner: normalizeClassValidatorErrors(_error.children),
      message,
      path: _error.property,
      params: {
        value: _error.value,
        path: _error.property,
        initialValue: _error.value,
      },
    };
  });
}

// https://github.com/jquense/yup#mixedvalidatevalue-any-options-object-promiseany-validationerror
const YUP_OPTIONS = { abortEarly: false };

export async function handleValidation(
  validationSchema: ObjectSchema<any>,
  currentValues: Record<string, any>,
): Promise<Record<string, any>> {
  if (!validationSchema) {
    await validateOrReject(currentValues);
    return new Promise(resolve => {
      resolve(currentValues);
    });
  }
  return validationSchema.validate(currentValues, YUP_OPTIONS);
}

export const getFastFormErrorMessage = (
  errors: ValidationError | null,
): I18nShape | null => {
  if (!isEmpty(errors?.message)) {
    return {
      text: errors.message,
      key: `${IGNORE_I18N_SHAPE_KEY}-${nanoid()}`,
      bundleName,
    };
  }
  return null;
};
