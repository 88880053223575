import { SERVICE_CONTEXT_ADDED_LOG_PARAMS } from '@service-layer-utils/constants';
import { EMPTY_ARRAY } from '@shared-utils/array';
import keys from 'lodash/keys';

export type BaseLoggerOptions = {
  staticParams?: Record<string, unknown>;
  console?: {
    omitStaticParams?: boolean;
    omitServiceContextParams?: boolean;
    omitParams?: string[];
  };
};

export function createOmitParamsForConsole(
  options?: BaseLoggerOptions,
): string[] | null {
  if (!options) {
    return null;
  }
  const { staticParams, console: consoleOptions } = options;
  if (!consoleOptions) {
    return null;
  }

  const {
    omitStaticParams,
    omitServiceContextParams,
    omitParams,
  } = consoleOptions;

  return [
    ...(omitStaticParams ? keys(staticParams) : EMPTY_ARRAY),
    ...(omitServiceContextParams
      ? SERVICE_CONTEXT_ADDED_LOG_PARAMS
      : EMPTY_ARRAY),
    ...(omitParams || EMPTY_ARRAY),
  ];
}
