import { getApiBasePath } from '@controller-layer-utils/core';
import { registerApiLoggerTransport } from '@logger/api-transport';
import { Message } from '@logger/core';
import { LOG_API_PATH } from '@logger/utils';
import { BaseLoggerOptions } from '@service-layer-utils/configure-logger-utils';

export type ConfigureApiLoggerOptions = {
  getHeaders: () => Record<string, string>;
  addParams?: (msg: Message) => Record<string, any>;
} & BaseLoggerOptions;

export function configureApiLogger(options: ConfigureApiLoggerOptions): void {
  const { staticParams, getHeaders, addParams } = options;
  registerApiLoggerTransport({
    staticParams,
    addParams,
    apiUrl: `${getApiBasePath()}${LOG_API_PATH}`,
    getHeaders,
  });
}
