import { Avatar as MuiAvatar } from '@material-ui/core';
import defaultProps from '@ui-system/default-props/avatar';
import { AvatarProps, AvatarType } from '@ui-system/interfaces-avatar';
import React from 'react';

const Avatar: AvatarType = ({ variant }: AvatarProps) => (
  <MuiAvatar variant={variant} />
);
Avatar.defaultProps = defaultProps;

export default Avatar;
