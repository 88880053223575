import {
  AuthenticateByEmailTanRequestDTO,
  AuthenticateByEmailTanResultDTO,
} from '@authentication/dtos';
import { State } from '@redux-async-module/interfaces';

export const NAMESPACE = 'auth/authenticate-by-email-tan';

export type AuthenticateByEmailTanStartPayload = Pick<
  AuthenticateByEmailTanRequestDTO,
  'tanCode'
>;
export type AuthenticateByEmailTanSuccessPayload = AuthenticateByEmailTanResultDTO;

export interface RootState {
  [NAMESPACE]: State<
    AuthenticateByEmailTanStartPayload,
    AuthenticateByEmailTanSuccessPayload
  >;
}
