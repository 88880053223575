import { I18nShape } from '@i18n/core';
import { DE } from '@i18n/utils';
import { VALIDATION_ERRORS } from '@resource/bundle-names';

export const DEFAULT: I18nShape = {
  bundleName: VALIDATION_ERRORS,
  translation: {
    [DE]: 'Diese Angabe ist ungültig',
  },
  key: `isNotValid`,
  text: 'Field is not valid',
  metadata: {
    description: 'Used when no specific validation message is set',
  },
};

export const IS_NOT_EMPTY: I18nShape = {
  bundleName: VALIDATION_ERRORS,
  translation: {
    [DE]: 'Bitte hier ausfüllen',
  },
  key: `isNotEmpty`,
  text: 'Must be filled',
  metadata: {
    description: 'Used when validation to check if input field is empty fails',
  },
};

export const IS_EMAIL: I18nShape = {
  bundleName: VALIDATION_ERRORS,
  translation: {
    [DE]: 'Bitte gib eine gültige E-Mail an',
  },
  key: `isEmail`,
  text: 'Inform a valid email',
  metadata: {
    description:
      'Used when validation to check if the value informed must have a email shape fails',
  },
};

export const INVALID_LENGTH: I18nShape = {
  bundleName: VALIDATION_ERRORS,
  translation: {
    [DE]: 'Ungültige Länge',
  },
  key: `invalidLength`,
  text: 'Value invalid length',
  metadata: {
    description:
      'Used when validation to check if the informed value match a max or min length fails',
  },
};

export const IS_ENUM: I18nShape = {
  bundleName: VALIDATION_ERRORS,
  translation: {
    [DE]: 'Dieser Wert ist nicht gültig',
  },
  key: `isEnum`,
  text: 'Value informed is not valid',
  metadata: {
    description:
      'Used when validation to check if the informed value match one of a pre defined enum values fails',
  },
};

export const IS_NOT_EMPTY_OBJECT: I18nShape = {
  bundleName: VALIDATION_ERRORS,
  translation: {
    [DE]: 'Das hier darf nicht leer sein',
  },
  key: `isNotEmptyObject`,
  text: "Value can't be a empty object",
  metadata: {
    description:
      'Used when validation to check if the informed value is not an empty object fails',
  },
};

export const MAX_LENGTH: I18nShape = {
  bundleName: VALIDATION_ERRORS,
  translation: {
    [DE]: 'Hier wurde die maximal erlaubte Länge überschritten',
  },
  key: `maxLength`,
  text: 'Value informed exceeds the max length permitted',
  metadata: {
    description:
      'Used when validation to check if the informed value exceeds the max length fails',
  },
};

export const MAX_VALUE: I18nShape = {
  bundleName: VALIDATION_ERRORS,
  translation: {
    [DE]: 'Dieser Wert ist größer als erlaubt',
  },
  key: `max`,
  text: 'Value is bigger than the max allowed',
  metadata: {
    description:
      'Used when validation to check if the informed value exceeds the max allowed fails',
  },
};

export const MIN_VALUE: I18nShape = {
  bundleName: VALIDATION_ERRORS,
  translation: {
    [DE]: 'Dieser Wert ist geringer als erlaubt',
  },
  key: `min`,
  text: 'Value is lesser than the min allowed',
  metadata: {
    description:
      'Used when validation to check if the informed value is lesser then the min allowed fails',
  },
};

export const IS_NOT_EMPTY_ARRAY: I18nShape = {
  bundleName: VALIDATION_ERRORS,
  translation: {
    [DE]: 'Das hier darf nicht leer sein',
  },
  key: `isNotEmptyArray`,
  text: "Value can't be a empty array",
  metadata: {
    description:
      'Used when validation to check if the informed value is not an empty array fails',
  },
};

export const INVALID_COUNTRY: I18nShape = {
  bundleName: VALIDATION_ERRORS,
  translation: {
    [DE]: 'Das Land scheint uns nicht zulässig',
  },
  key: `isISO31661Alpha2`,
  text: 'Invalid informed country',
  metadata: {
    description:
      'Used when validation to check if the informed country is valid fails',
  },
};

export const IS_DATE: I18nShape = {
  bundleName: VALIDATION_ERRORS,
  translation: {
    [DE]: 'Bitte gültiges Datum eingeben',
  },
  key: `isDate`,
  text: 'Value must be a valid date',
  metadata: {
    description:
      'Used when validation to check if the informed date value is valid fails',
  },
};

export const IS_STRING: I18nShape = {
  bundleName: VALIDATION_ERRORS,
  translation: {
    [DE]: 'Bitte hier nur Text eingeben',
  },
  key: `isString`,
  text: 'Value must be string',
  metadata: {
    description:
      'Used when validation to check if the informed value is string fails',
  },
};

export const IS_BOOLEAN: I18nShape = {
  bundleName: VALIDATION_ERRORS,
  translation: {
    [DE]: 'Hier entweder true oder false ;-)',
  },
  key: `isBoolean`,
  text: 'Value must be either true or false',
  metadata: {
    description:
      'Used when validation to check if the informed value is a boolean fails',
  },
};

export const IS_TRUE: I18nShape = {
  bundleName: VALIDATION_ERRORS,
  translation: {
    [DE]: 'Hier ist nur true als Wert zulässig',
  },
  key: `isTrue`,
  text: 'Value must be true',
  metadata: {
    description: 'Used when validation to check if a boolean is true',
  },
};

export const MUST_ACCEPT_TERMS: I18nShape = {
  bundleName: VALIDATION_ERRORS,

  key: `mustAcceptTerms`,
  text: 'You must accept the terms and conditions',
};
