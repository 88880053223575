export const DE = 'de';
export const EN = 'en';
export const PL = 'pl';
export const NL = 'nl';
export const ES = 'es';
export const IT = 'it';
export const FR = 'fr';
export const JA = 'ja';
export const NO = 'no';
export const SV = 'sv';

export const EN_US = 'en-US';

export const LANGUAGE_CODES = {
  DE,
  EN,
  NL,
  FR,
  IT,
  ES,
  PL,
  JA,
  NO,
  SV,
};
