import { I18nShape } from '@i18n/core';
import { DE } from '@i18n/utils';
import { DEVICE as bundleName } from '@resource/bundle-names';

export const REQUESTING_FOR_CAMERA_PERMISSION: I18nShape = {
  key: `requestingForCameraPermission`,
  text: 'Request for camera permission',
  bundleName,
  translation: {
    [DE]: 'Bitte Kamera Zugriff erlauben',
  },
};

export const NO_ACCESS_TO_CAMERA: I18nShape = {
  key: `noAccessToCamera`,
  text: 'No access to camera',
  bundleName,
  translation: {
    [DE]: 'Leider kein Zugriff zur Kamera',
  },
};

export const SELECT_DEFAULT_PLACEHOLDER: I18nShape = {
  key: 'defaultSelectPlaceholder',
  text: 'Select an item',
  bundleName,
  translation: {
    [DE]: 'Bitte wählen',
  },
};

export const UPDATE_APP_TITLE: I18nShape = {
  key: 'updateAppTitle',
  text: 'New updated is available',
  bundleName,
  translation: {
    [DE]: 'Wir haben ein neues Update',
  },
};

export const UPDATE_APP_MESSAGE: I18nShape = {
  key: 'updateAppMessage',
  text:
    'A new update is available for download. Do you want to update the App now? This will close and re-open the App.',
  bundleName,
  translation: {
    [DE]:
      'Wir haben ein neues Update der App für dich. Willst du es jetzt installieren? Die App wird dafür automatisch geschlossen und neu gestartet.',
  },
};
