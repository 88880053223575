export const LABELS = 'Labels';
export const PLACEHOLDERS = 'Placeholders';
export const INTERACTIONS = 'Interactions';
export const COMMON = 'Common';
export const VALIDATION_ERRORS = 'ValidationErrors';
export const DEVICE = 'Device';
export const AUTHENTICATION = 'Authentication';
export const COMMON_SENTENCES = 'CommonSentences';
export const CAPTION = 'Caption';
export const API_ERRORS = 'ApiErrors';
export const TYC = 'TyC';
export const REC_PRO_PAGE = 'RecProPage';
