import { css } from '@ui-system/css';
import { TABLE_ROW_UUID, TABLE_UUID, TableProps } from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';

import { makeFinalStyleHook } from './internal';

const useTableDefaultStyle = makeStyle<Partial<TableProps>>(() => css``);

const useTableRowDefaultStyle = makeStyle<Partial<TableProps>>(
  () => css`
    cursor: pointer;
  `,
);

export const useTableStyle = makeFinalStyleHook<Partial<TableProps>>(
  TABLE_UUID,
  useTableDefaultStyle,
);

export const useTableRowStyle = makeFinalStyleHook<Partial<TableProps>>(
  TABLE_ROW_UUID,
  useTableRowDefaultStyle,
);
