import { ErrorCard } from '@ui-system/error-card';
import * as React from 'react';

import { ErrorProps, ErrorType } from '../core';

const Error: ErrorType = ({ error, onDismiss }: ErrorProps) => (
  <ErrorCard error={error} onDismiss={onDismiss} />
);

export default Error;
