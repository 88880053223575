import { EN, EN_US } from '@i18n/utils';
import { useQueryParams } from '@lib/navigation';
import {
  useReactPageContentContextContents,
  useReactPageContentContextLanguage,
} from '@react-page-editor/final-renderer';
import { useDeepCompareMemo } from '@react-utils/hooks';
import { useJSONDataFromStatic } from '@rec/static';
import { useCurrentLanguage } from '@resource/redux-language';
import { extractLanguageAndCountryFromLocaleString } from '@resource/utils';
import { EMPTY_ARRAY } from '@shared-utils/array';
import { last, split } from 'lodash';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import { ComponentConfig } from 'react-page-editor/state';

export function useComponentOptions<Props>(
  config: ComponentConfig,
): {
  options: Record<string, Props> | null;
  loading: boolean;
  error: Error | null;
} {
  const path = `/components/${config.id}/options.json`;
  const { data, error, loading } = useJSONDataFromStatic<Record<string, Props>>(
    { path },
  );
  return useDeepCompareMemo(
    () => ({
      options: data,
      error,
      loading,
    }),
    [data, error, loading],
  );
}
interface Options {
  omitLocalKeys?: string[];
  omitServerKeys?: string[];
}
export function useComponentServerProps<Props>(
  config: ComponentConfig,
  localProps: Props,
  options: Options = {},
): Props | null {
  const { language } = useQueryParams<{ language: string }>();
  const systemLanguage = useCurrentLanguage();
  const pageLanguage = useReactPageContentContextLanguage();
  const contents = useReactPageContentContextContents();
  const newId = last(split(config.id, '.children.'));
  const path = `/components/${newId}/options.json`;
  const { data } = useJSONDataFromStatic<Record<string, Props>>({ path });
  return useDeepCompareMemo<Props>(() => {
    if (!data) return localProps;
    let languageToUse = extractLanguageAndCountryFromLocaleString(
      language || pageLanguage || systemLanguage,
    ).language;

    const shouldLanguageByENUS =
      languageToUse === EN &&
      (contents?.country === 'US' ||
        extractLanguageAndCountryFromLocaleString(navigator?.language)
          .country === 'US');
    if (shouldLanguageByENUS) {
      languageToUse = EN_US;
    }
    const serverProps = data[languageToUse] || data[EN];
    return merge(
      // @ts-ignore
      omit(localProps, options.omitLocalKeys || EMPTY_ARRAY) as Props,
      // @ts-ignore
      omit(serverProps, options.omitServerKeys || EMPTY_ARRAY) as Props,
    );
  }, [
    contents?.country,
    data,
    language,
    localProps,
    options.omitLocalKeys,
    options.omitServerKeys,
    pageLanguage,
    systemLanguage,
  ]);
}
