import {
  API_CONTEXT_PATH,
  API_HOST,
  APP_VERSION,
} from '@controller-layer-utils/core';
import { GA_TRACKING_ID } from 'ga';
import { Container } from 'typedi';

import pkg from '../package.json';

Container.set(API_CONTEXT_PATH, process.env.REACT_APP_API_CONTEXT_PATH);
Container.set(APP_VERSION, pkg.version);
Container.set(API_HOST, process.env.REACT_APP_API_HOST);
if (process.env.REACT_APP_GA_TRACKING_ID) {
  Container.set(GA_TRACKING_ID, process.env.REACT_APP_GA_TRACKING_ID);
}
