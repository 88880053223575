import {
  createDispatchHook,
  createSelectorHook,
} from '@redux-basic-module/hooks-utils';

import { createUpdateDraft } from './actions';
import { dataSelector, loadingSelector } from './selectors';

export const useCreateUpdateDraftData = createSelectorHook(dataSelector);
export const useIsLoadingCreateUpdateDraft = createSelectorHook(
  loadingSelector,
);

export const useCreateUpdateDraft = createDispatchHook(createUpdateDraft.start);

export function generateKey(email: string): string {
  return email;
}
