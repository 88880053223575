import domains from 'disposable-email-domains';
import wildcards from 'disposable-email-domains/wildcard';
import find from 'lodash/find';
import indexOf from 'lodash/indexOf';
import isEmpty from 'lodash/isEmpty';
import memoize from 'lodash/memoize';
import split from 'lodash/split';
import trim from 'lodash/trim';

function internalIsDisposableEmail(inputEmail: string): boolean {
  const email = trim(inputEmail);
  if (isEmpty(email)) {
    return false;
  }
  const [, provider] = split(email, '@');
  if (isEmpty(provider)) {
    return false;
  }
  const foundDomain = find(
    domains,
    domain => domain.toLowerCase() === provider.toLowerCase(),
  );
  if (foundDomain) {
    return true;
  }

  const foundWildcard = find(
    wildcards,
    domain => indexOf(provider.toLowerCase(), domain.toLowerCase()) !== -1,
  );

  return !!foundWildcard;
}

export const isDisposableEmail = memoize(internalIsDisposableEmail);
