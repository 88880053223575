import { EMPTY_ARRAY } from '@shared-utils/array';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { FieldPath, ValidationError } from '../../../core';
import { getFieldErrorsByFieldPathSelector } from '../../stateSelector';
import { RootState } from '../../types';
import { useWatchField } from '../internal';
import useFormId from '../useFormId';

function useErrorMessages<T>(fieldPath: FieldPath): ValidationError | null {
  useWatchField(fieldPath);
  const formId = useFormId();
  const selector = useCallback(
    (state: RootState<T>) =>
      getFieldErrorsByFieldPathSelector(state, formId, fieldPath, EMPTY_ARRAY),
    [formId, fieldPath],
  );
  return useSelector(selector);
}

export default useErrorMessages;
