import { I18nShape } from '@i18n/core';
import { COPIED, COPY_TEXT } from '@resource/i18n-shapes';
import UI from '@ui-system/ui';
import delay from 'lodash/delay';
import noop from 'lodash/noop';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { STRING_HTML_REGEX } from 'regex';

interface CopyToClipboardButtonProps {
  value: string;
  i18n?: I18nShape;
  iconName?: string;
}

function copyRichText(text) {
  function listener(ev) {
    ev.preventDefault();
    ev.clipboardData.setData('text/html', text);
    ev.clipboardData.setData('text/plain', text);
  }
  document.addEventListener('copy', listener);
  document.execCommand('copy');
  document.removeEventListener('copy', listener);
}

const HTML = { format: 'text/html' };

export const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  value,
  i18n,
  iconName,
  ...rest
}: CopyToClipboardButtonProps) => {
  const [copySucceeded, setCopySucceeded] = useState(false);
  const onCopy = useCallback(() => {
    copyRichText(value);
    setCopySucceeded(true);
  }, [value]);
  useEffect(() => {
    if (copySucceeded) {
      delay(() => setCopySucceeded(false), 700);
    }
  }, [copySucceeded]);
  const isHtml = STRING_HTML_REGEX.test(value);
  return (
    <CopyToClipboard onCopy={onCopy} text={value} options={isHtml && HTML}>
      <UI.Button
        onClick={noop}
        i18n={copySucceeded ? COPIED : i18n || COPY_TEXT}
        modifiers={copySucceeded ? 'success' : ''}
        accessoryLeft={copySucceeded ? 'check' : iconName}
        iconColor={copySucceeded ? 'white' : null}
        iconSize={18}
        {...rest}
      />
    </CopyToClipboard>
  );
};

CopyToClipboardButton.defaultProps = {
  i18n: COPY_TEXT,
  iconName: 'copy',
};
