import { useIsMountedRef } from '@react-utils/hooks';
import { useIsLoadingRetrieveDraft } from '@rec/redux-retrieve-draft';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import delay from 'lodash/delay';
import Lottie from 'lottie-react-web';
import * as React from 'react';
import { useEffect, useState } from 'react';

import animationData from './loader.json';

const OPTIONS = {
  animationData,
};

const STYLE = css`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99999999;
  align-items: center;
  justify-content: center;
`;

export const Loader: React.FC = () => {
  const isLoading = useIsLoadingRetrieveDraft();
  const [internalLoader, setInternalLoader] = useState(false);
  const isMounted = useIsMountedRef();
  useEffect(() => {
    if (isLoading) {
      setInternalLoader(true);
    } else {
      delay(() => {
        if (isMounted.current) {
          setInternalLoader(false);
        }
      }, 2500);
    }
  }, [isLoading, isMounted]);

  if (!internalLoader) return null;
  return (
    <UI.Container style={STYLE}>
      <UI.Container h={200}>
        <Lottie options={OPTIONS} />
      </UI.Container>
    </UI.Container>
  );
};
