import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import {
  useComponentOptions,
  useComponentServerProps,
  useSetPropsByLanguage,
} from '@pro-page-components/utils';
import { getStaticUrlWithParams } from '@rec/static';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import map from 'lodash/map';
import * as React from 'react';
import { useMemo } from 'react';
import { ComponentForEditor, useComponentProps } from 'react-page-editor/state';
import { Carousel } from 'react-responsive-carousel';

import config from './config.json';
import defaultProps from './defaultProps.json';

interface HighlightsRECProps {
  // eslint-disable-next-line react/no-unused-prop-types
  images: { original: string; originalHeight?: number; text?: string }[];
}

const STYLE = css`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  bottom: 0;
  right: 0;
`;

const OPTIONS = {
  omitLocalKeys: ['images'],
};

export const HighlightsREC: ComponentForEditor<HighlightsRECProps> = (
  props: HighlightsRECProps,
) => {
  const serverProps = useComponentServerProps<HighlightsRECProps>(
    config,
    props,
    OPTIONS,
  );
  const items = useMemo(
    () =>
      map(serverProps?.images, image => ({
        original: getStaticUrlWithParams(image.original),
        text: image.text,
        originalHeight: 250,
      })),
    [serverProps?.images],
  );
  return (
    <UI.Container direction="column" h={250}>
      <Carousel autoPlay infiniteLoop showStatus={false} showThumbs={false}>
        {map(items, item => (
          <UI.Container direction="column" key={item.original}>
            <UI.Image
              src={item.original}
              height={item.originalHeight}
              width="100%"
              resizeMode="cover"
            />
            {item.text && (
              <UI.Container p="2, 4" style={STYLE}>
                <UI.H5 modifiers="uppercase, bold">{item.text}</UI.H5>
              </UI.Container>
            )}
          </UI.Container>
        ))}
      </Carousel>
    </UI.Container>
  );
};

HighlightsREC.defaultProps = defaultProps;
HighlightsREC.config = config;

export const HighlightsRECEditor: ComponentForEditor<HighlightsRECProps> = () => {
  const { options } = useComponentOptions<HighlightsRECProps>(
    HighlightsREC.config,
  );
  useSetPropsByLanguage(HighlightsREC.config, options);
  const componentProps = useComponentProps<HighlightsRECProps>(
    HighlightsREC.config.id,
  );
  return <HighlightsREC {...componentProps} />;
};
HighlightsRECEditor.defaultProps = HighlightsREC.defaultProps;
HighlightsRECEditor.config = config;
