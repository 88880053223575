/* eslint-disable no-console */
import { ConsoleLogFunction } from '@logger/core';
import { LogLevel } from '@logger/core/LogLevel';
import { noop } from 'lodash';

export * from './constants';
export * from './LogDataDTOClient';
export * from './LogGcpLevel';

const DEFAULT_CONSOLE_LOG = console ? console.log : noop;

export function padDate(time: string): string {
  return time.padStart(2, '0');
}

export function getTimeStamp(givenDate?: Date): string {
  const date = givenDate || new Date();
  const hours = padDate(date.getHours().toString());
  const minutes = padDate(date.getMinutes().toString());
  const secs = padDate(date.getSeconds().toString());
  return `${hours}:${minutes}:${secs}`;
}

export function log(level: LogLevel): ConsoleLogFunction {
  // XXX: removed console.error and console.warn -> causing problems in EXPO
  switch (level) {
    case LogLevel.TRACE:
      return console && console.debug ? console.debug : DEFAULT_CONSOLE_LOG;
    case LogLevel.DEBUG:
      return console && console.debug ? console.debug : DEFAULT_CONSOLE_LOG;
    case LogLevel.INFO:
      return console && console.info ? console.info : DEFAULT_CONSOLE_LOG;
    case LogLevel.WARN:
      return console && console.info ? console.info : DEFAULT_CONSOLE_LOG;
    case LogLevel.ERROR:
      return console && console.info ? console.info : DEFAULT_CONSOLE_LOG;
    default:
      return DEFAULT_CONSOLE_LOG;
  }
}

// https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function jsonFriendlyErrorReplacer(key: any, value: any) {
  if (value && value instanceof Error) {
    return {
      // Pull all enumerable properties, supporting properties on custom Errors
      ...value,
      // Explicitly pull Error's non-enumerable properties
      name: value.name,
      message: value.message,
      stack: value.stack,
    };
  }
  return value;
}

export function strigifyParams(params: Array<any> | void): string | null {
  if (!params || !params.length) {
    return null;
  }
  try {
    return JSON.stringify(params, jsonFriendlyErrorReplacer, 2);
  } catch (error) {
    return null;
  }
}
