import {
  createRecProductVideoComponents,
  RecProduct,
  RecProductProps,
} from '@pro-page-components/rec-product';
import { useChildrenVisibilityEditor } from '@pro-page-components/utils/useChildrenVisibility';
import { useRestrictComponentByRegion } from '@pro-page-components/utils/useRestrictComponentByRegion';
import { ScaleProps } from '@rec/section-wrapper';
import { Color } from '@ui-system/css';
import * as React from 'react';
import { ComponentForEditor } from 'react-page-editor/state';

import config from './config.json';
import defaultProps from './defaultProps.json';

interface RecAlphaSeriesProps extends ScaleProps {
  title?: string;
  text?: string;
  video?: string;
  videoTitle?: string;
  datasheet?: string;
  datasheetThumbnail?: string;
  datasheetText?: string;
  image?: string;
  bg?: Color;
}

const { Video, VideoEditor } = createRecProductVideoComponents({
  name: 'REC Alpha Series Video',
  id: 'REC_ALPHA_SERIES_VIDEO',
  path: '.....',
  parent: config.id,
});
export const RecAlphaSeries: ComponentForEditor<RecProductProps> = (
  props: RecProductProps,
) => {
  const restrict = useRestrictComponentByRegion(config);
  if (restrict) return null;
  return <RecProduct VideoComponent={Video} {...props} />;
};

export const RecAlphaSeriesEditor: ComponentForEditor<RecProductProps> = (
  props: RecProductProps,
) => {
  // eslint-disable-next-line no-underscore-dangle,react/destructuring-assignment
  const visible = useChildrenVisibilityEditor(props.__config);
  if (!visible) return null;
  return (
    <RecAlphaSeries
      scale={0.9}
      skipVisibilityCheck
      VideoComponent={VideoEditor}
      {...props}
    />
  );
};

RecAlphaSeries.children = [Video];
RecAlphaSeries.defaultProps = defaultProps as RecAlphaSeriesProps;
RecAlphaSeries.config = config;
RecAlphaSeriesEditor.defaultProps = defaultProps as RecAlphaSeriesProps;
RecAlphaSeriesEditor.config = config;
RecAlphaSeriesEditor.children = [VideoEditor];
