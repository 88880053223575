import {
  CreatedLiveDTO,
  CreateLiveDTO,
} from '@page-creator/services-create-live';
import {
  INITIAL_STATE as _INITIAL_STATE,
  State as _State,
} from '@redux-async-module/interfaces';

export enum PublishMode {
  STARTED = 'STARTED',
  SUCCEEDED = 'SUCCEEDED',
  IDLE = 'IDLE',
}
export const NAMESPACE = 'page-creator-live';
export const INITIAL_STATE: State = {
  ..._INITIAL_STATE,
  publishMode: PublishMode.IDLE,
};
export type StartPayload = CreateLiveDTO;
export type SuccessPayload = CreatedLiveDTO;

export type State = _State<StartPayload, SuccessPayload> & {
  publishMode: PublishMode;
};

export interface RootState {
  [NAMESPACE]: State;
}
