import {
  FastFormOptions,
  FieldPath,
  FormId,
  FormUpdate,
  PathValueOrCommandValue,
  ValidationError,
  ValidationSchema,
} from '../core';
import {
  DELETE,
  RESET_FORM_STATE,
  RESTART,
  SET_FIELD_ERRORS,
  SET_FIELD_TOUCHED,
  SET_FIELD_UNWATCHED,
  SET_FIELD_WATCHED,
  SET_FORM_STATE,
  START,
  SUBMIT_ERROR,
  SUBMIT_START,
  SUBMIT_SUCCESS,
  UPDATE_FIELD_VALUE,
  UPDATE_FIELD_VALUES_BY_QUERY,
  VALIDATE_START,
  VALIDATE_SUCCESS,
} from './constants';
import {
  DeleteAction,
  ResetFormStateAction,
  ResetFormStateOptions,
  RestartAction,
  SetFieldErrorsAction,
  SetFieldTouchedAction,
  SetFieldUnwatchedAction,
  SetFieldWatchedAction,
  SetFormStateAction,
  SetFormStateOptions,
  StartAction,
  SubmitErrorAction,
  SubmitStartAction,
  SubmitSuccessAction,
  UpdateFieldValueAction,
  UpdateFieldValuesByQueryAction,
  ValidateStartAction,
  ValidateSuccessAction,
} from './types';

export function startAction<T>(
  formId: FormId,
  initialValues: T,
  options: FastFormOptions<T>,
  validationSchema: ValidationSchema<T> | null,
): StartAction<T> {
  return {
    type: START,
    payload: {
      formId,
      initialValues,
      options,
      validationSchema,
    },
  };
}

export function restartAction<T>(
  formId: FormId,
  initialValues: T,
  options: FastFormOptions<T>,
  validationSchema: ValidationSchema<T> | null,
): RestartAction<T> {
  return {
    type: RESTART,
    payload: {
      formId,
      initialValues,
      options,
      validationSchema,
    },
  };
}

export function deleteAction(formId: FormId): DeleteAction {
  return {
    type: DELETE,
    payload: {
      formId,
    },
  };
}

export function setFieldValueAction<T>(
  formId: FormId,
  fieldPath: FieldPath,
  value: T,
): UpdateFieldValueAction {
  return {
    type: UPDATE_FIELD_VALUE,
    payload: {
      formId,
      changes: [
        {
          fieldPath,
          value,
          command: '$set',
        },
      ],
    },
  };
}

export function setFieldValuesAction(
  formId: FormId,
  changes: Array<FormUpdate>,
): UpdateFieldValueAction {
  return {
    type: UPDATE_FIELD_VALUE,
    payload: {
      formId,
      changes,
    },
  };
}

export function setFieldValuesByQueryAction<T>(
  formId: FormId,
  query: PathValueOrCommandValue<T>,
): UpdateFieldValuesByQueryAction<T> {
  return {
    type: UPDATE_FIELD_VALUES_BY_QUERY,
    payload: {
      formId,
      query,
    },
  };
}

export function setFieldTouchedAction(
  formId: FormId,
  fieldPath: FieldPath,
): SetFieldTouchedAction {
  return {
    type: SET_FIELD_TOUCHED,
    payload: {
      formId,
      fieldPath,
    },
  };
}

export function setFieldWatchedAction(
  formId: FormId,
  fieldPath: FieldPath,
): SetFieldWatchedAction {
  return {
    type: SET_FIELD_WATCHED,
    payload: {
      formId,
      fieldPath,
    },
  };
}

export function setFieldUnwatchedAction(
  formId: FormId,
  fieldPath: FieldPath,
): SetFieldUnwatchedAction {
  return {
    type: SET_FIELD_UNWATCHED,
    payload: {
      formId,
      fieldPath,
    },
  };
}

export function resetFormStateAction<T>(
  formId: FormId,
  states: ResetFormStateOptions<T>,
): ResetFormStateAction<T> {
  return {
    type: RESET_FORM_STATE,
    payload: {
      formId,
      ...states,
    },
  };
}

export function setFormStateAction(
  formId: FormId,
  states: SetFormStateOptions,
): SetFormStateAction {
  return {
    type: SET_FORM_STATE,
    payload: {
      formId,
      ...states,
    },
  };
}

export function setFieldErrorsAction(
  formId: FormId,
  errors: Array<ValidationError> | null,
): SetFieldErrorsAction {
  return {
    type: SET_FIELD_ERRORS,
    payload: {
      formId,
      errors,
    },
  };
}

export function updateAction(
  formId: FormId,
  changes: Array<FormUpdate>,
): UpdateFieldValueAction {
  return {
    type: UPDATE_FIELD_VALUE,
    payload: {
      formId,
      changes,
    },
  };
}

export function submitStartAction<T>(
  formId: FormId,
  validationSchema: ValidationSchema<T>,
): SubmitStartAction<T> {
  return {
    type: SUBMIT_START,
    payload: {
      formId,
      validationSchema,
    },
  };
}

export function submitSuccessAction<T>(
  formId: FormId,
  submittedValues: T,
): SubmitSuccessAction<T> {
  return {
    type: SUBMIT_SUCCESS,
    payload: {
      formId,
      submittedValues,
    },
  };
}

export function submitErrorAction<T>(
  formId: FormId,
  submittedValues: T,
  errors: Array<ValidationError>,
): SubmitErrorAction<T> {
  return {
    type: SUBMIT_ERROR,
    payload: {
      formId,
      submittedValues,
      errors,
    },
  };
}

export function validateStartAction<T>(
  formId: FormId,
  validationSchema: ValidationSchema<T>,
  onlyWatchedFields: boolean,
): ValidateStartAction<T> {
  return {
    type: VALIDATE_START,
    payload: {
      formId,
      validationSchema,
      onlyWatchedFields,
    },
  };
}

export function validateSuccessAction(
  formId: FormId,
  errors: Array<ValidationError>,
  onlyWatchedFields: boolean,
): ValidateSuccessAction {
  return {
    type: VALIDATE_SUCCESS,
    payload: {
      formId,
      errors,
      onlyWatchedFields,
    },
  };
}
