import once from 'lodash/once';
import { useEffect } from 'react';

import initialize from './initialize';

const initOnce = once(initialize);

export function useInitializeOnMount(): void {
  useEffect(() => {
    initOnce();
  }, []);
}
