import { Style } from '@ui-system/interfaces';
import { nanoid } from 'nanoid';
import React, { CSSProperties } from 'react';

export interface PageProps {
  gridTemplateRows?: string;
  children?: React.ReactNode;
  overflow?: CSSProperties['overflow'];
  overflowX?: CSSProperties['overflowX'];
  overflowY?: CSSProperties['overflowY'];
}
export interface ContentProps {
  children?: React.ReactNode;
  overflow?: CSSProperties['overflow'];
  overflowX?: CSSProperties['overflowX'];
  overflowY?: CSSProperties['overflowY'];
  style?: Style;
}

export interface HeaderProps {
  height?: string;
  style?: Style;
}

export interface FooterProps {
  height?: string;
}

interface PageStatics {
  Header: React.FC<HeaderProps>;
  Content: React.FC<ContentProps>;
  Footer: React.FC<FooterProps>;
}

export const PAGE_UUID = nanoid();

export type PageType = React.FC<PageProps> & PageStatics;
