import HtmlParser from '@html-parser/core';
import {
  useComponentOptions,
  useComponentServerProps,
  useSetPropsByLanguage,
} from '@pro-page-components/utils';
import { ScaleProps, SectionWrapper } from '@rec/section-wrapper';
import { getStaticUrlWithParams } from '@rec/static';
import { css } from '@ui-system/css';
import { OptionType } from '@ui-system/interfaces-form';
import UI from '@ui-system/ui';
import { map } from 'lodash';
import * as React from 'react';
import {
  ComponentForEditor,
  useComponentProps,
  useViewModeContext,
} from 'react-page-editor/state';

import config from './config.json';
import defaultProps from './defaultProps.json';

const STYLE = css`
  letter-spacing: 3px;
  line-height: 0.9;
`;

const MOBILE_STYLE = css`
  letter-spacing: 4px;
`;

interface RecProTrustWarrantyProps extends ScaleProps {
  image?: string;
  title?: string;
  text?: string;
  options?: Array<{
    message: string;
    label: string;
  }>;
}
export const RecProTrustWarranty: ComponentForEditor<RecProTrustWarrantyProps> = ({
  scale,
  ...rest
}: RecProTrustWarrantyProps) => {
  const {
    image,
    text,
    title,
    options,
  } = useComponentServerProps<RecProTrustWarrantyProps>(config, rest);
  const content = HtmlParser(text);
  const titleContent = HtmlParser(title);
  const { isMobile } = useViewModeContext();
  return (
    <SectionWrapper bg="primary" padding scale={scale}>
      <UI.Container direction="column">
        <UI.Container
          justify="space-between"
          direction={isMobile ? 'column' : 'row'}
        >
          <UI.Container
            f={2}
            justify={isMobile ? 'center' : 'flex-start'}
            m={isMobile ? '0, 0, 4, 0' : '0'}
            p="0, 4, 0 ,0"
          >
            <UI.Image
              src={getStaticUrlWithParams(image)}
              height={250}
              width="auto"
            />
          </UI.Container>
          <UI.Container f={6} direction="column">
            <UI.Container m="0, 0, 3, 0">
              {isMobile ? (
                <UI.H5 modifiers="light, bold, uppercase" style={MOBILE_STYLE}>
                  {titleContent}
                </UI.H5>
              ) : (
                <UI.H4 modifiers="light, bold, uppercase" style={STYLE}>
                  {titleContent}
                </UI.H4>
              )}
            </UI.Container>
            <UI.Body2>{content}</UI.Body2>
          </UI.Container>
        </UI.Container>
        <UI.Container
          direction={isMobile ? 'column' : 'row'}
          justify="space-between"
          gap={4}
        >
          {map(options, option => {
            const message = HtmlParser(option.message);
            return (
              <UI.Container direction="column">
                <UI.Typography modifiers="bold">{option.label}</UI.Typography>
                <UI.Body2>{message}</UI.Body2>
              </UI.Container>
            );
          })}
        </UI.Container>
      </UI.Container>
    </SectionWrapper>
  );
};

RecProTrustWarranty.defaultProps = defaultProps;
RecProTrustWarranty.config = config;

export const RecProTrustWarrantyEditor: ComponentForEditor<RecProTrustWarrantyProps> = () => {
  const { options } = useComponentOptions<Record<string, OptionType[]>>(
    RecProTrustWarranty.config,
  );

  useSetPropsByLanguage(RecProTrustWarranty.config, options);
  const componentProps = useComponentProps<RecProTrustWarrantyProps>(
    RecProTrustWarranty.config.id,
  );

  return <RecProTrustWarranty scale={0.9} {...componentProps} />;
};
RecProTrustWarrantyEditor.defaultProps = RecProTrustWarranty.defaultProps;
RecProTrustWarrantyEditor.config = config;
