import { ContainerProps } from '@ui-system/interfaces-container';
import { nanoid } from 'nanoid';
import React from 'react';

import { CheckboxType } from './checkbox';
import { InputType } from './input';
import { SelectType } from './select';

export interface FormTypeStatics {
  Input: InputType;
  Checkbox: CheckboxType;
  Select: SelectType;
}

export type FormType = React.FC<ContainerProps> & FormTypeStatics;
export const FORM_INPUT_UUID = nanoid();

export const FORM_SELECT_UUID = nanoid();
export const FORM_SELECT_DISABLED_UUID = nanoid();
export const FORM_SELECT_PLACEHOLDER_UUID = nanoid();
export const FORM_SELECT_ARROW_DOWN_UUID = nanoid();

export const FORM_CHECKBOX_UUID = nanoid();
export const FORM_CHECKBOX_CHECK_UUID = nanoid();

export * from './checkbox';
export * from './input';
export * from './select';
