import { getCreateUpdateDraftUrl as getPath } from '@page-creator/shared-routes';
import { createEpic } from '@redux-async-module/epic-utils';
import { RequestType } from '@redux-async-module/interfaces';

import { createUpdateDraft } from './actions';
import { RootState, StartPayload, SuccessPayload } from './types';

export const EPICS = createEpic<RootState, StartPayload, SuccessPayload>({
  actions: createUpdateDraft,
  requestOptions: {
    type: RequestType.post,
    getPath,
    getBody({ key, contents }) {
      return {
        contents,
        key,
      };
    },
  },
});
