import { ColorMode, ThemeColors, ThemeMode } from './interfaces';

export const BASE_LIGHT_MODE: ColorMode = {
  primary: {
    main: '#3366FF',
  },
  secondary: {
    main: '#000',
  },
  black: '#000',
  white: '#FFF',
  info: '#02C4FF',
  success: '#376E37',
  error: '#ff1919',
  warning: '#FF6700',
  gray: {
    A100: '#E8EBED',
    A200: '#C9CDD2',
    A300: '#9EA4AA',
    A400: '#72787F',
    A500: '#454C53',
    A600: '#26282B',
    A700: '#1B1D1F',
    A800: '#222323',
    A900: '#121212',
  },
  background: '#fff',
};

export const BASE_DARK_MODE: ColorMode = {
  primary: {
    main: '#3366FF',
  },
  secondary: {
    main: '#000',
  },
  black: '#000',
  white: '#FFF',
  info: '#02C4FF',
  success: '#376E37',
  error: '#ff1919',
  warning: '#FF6700',
  gray: {
    A100: '#E8EBED',
    A200: '#C9CDD2',
    A300: '#9EA4AA',
    A400: '#72787F',
    A500: '#454C53',
    A600: '#26282B',
    A700: '#1B1D1F',
    A800: '#222323',
    A900: '#121212',
  },
  background: '#000',
};

const BASE_COLORS: ThemeColors = {
  [ThemeMode.LIGHT]: BASE_LIGHT_MODE,
  [ThemeMode.DARK]: BASE_DARK_MODE,
};

export default BASE_COLORS;
