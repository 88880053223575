import { Selector } from '@redux-basic-module/interfaces';
import get from 'lodash/get';
import join from 'lodash/join';

export function createSelector<ItemType>(
  entries: string[],
  defaultValue?: ItemType,
): Selector<ItemType> {
  return function selector(state: Record<string, any>): ItemType {
    return get(state, join(entries, '.'), defaultValue);
  };
}
