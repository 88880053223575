import { useContext } from 'react';
import invariant from 'tiny-invariant';

import FastFormContext from '../../context';
import { FastFormContextValue } from '../../core';

const ERROR_MESSAGE = 'You must use this inside <FastFormProvider>';

function useFastFormProviderContext<T>(): FastFormContextValue<T> {
  const context = useContext(FastFormContext);
  invariant(context, ERROR_MESSAGE);
  return context;
}

export default useFastFormProviderContext;
