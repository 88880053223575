import { I18nShape } from '@i18n/core';
import { REC_PRO_PAGE as bundleName } from '@resource/bundle-names';

import { TUTORIAL } from './constants';

export const TUTORIAL_STEP_ONE: I18nShape = {
  key: `${TUTORIAL}.stepOne`,
  text:
    'Click on the content that you want to show on your REC ProPage. The selected content will instantly appear on the right side where you are able to rearrange and edit.',
  bundleName,
};

export const TUTORIAL_STEP_TWO: I18nShape = {
  key: `${TUTORIAL}.stepTwo`,
  text: 'Upload your business logo and customize your REC ProPage',
  bundleName,
};

export const TUTORIAL_STEP_THREE: I18nShape = {
  key: `${TUTORIAL}.stepThree`,
  text:
    'Upload images to showcase your REC installations, your REC certified installer team or other information you want to share with your customers.',
  bundleName,
};

export const TUTORIAL_STEP_FOUR: I18nShape = {
  key: `${TUTORIAL}.stepFour`,
  text:
    'In this space you can tell your story, describe your business and tell visitors about your experience with REC products and installations.',
  bundleName,
};

export const TUTORIAL_STEP_FIVE: I18nShape = {
  key: `${TUTORIAL}.stepFive`,
  text:
    'Use this menu to save, preview and publish your REC ProPage. Let your customers know about your quality products and services.',
  bundleName,
};

export const CHOOSE_A_OPTION_BELOW: I18nShape = {
  key: `${TUTORIAL}.chooseOptionBelow`,
  text:
    'Choose one of the below options to build your website, the way you want it:',
  bundleName,
};

export const WATCH_VIDEO: I18nShape = {
  key: `${TUTORIAL}.watchVideo`,
  text: 'Watch video',
  bundleName,
};
