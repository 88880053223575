import { I18nShape } from '@i18n/core';
import { DE } from '@i18n/utils';
import { API_ERRORS as bundleName } from '@resource/bundle-names';

export const REQUEST_ERROR: I18nShape = {
  bundleName,
  translation: {
    [DE]:
      'Irgendwas lief schief, bitte prüfe deine Verbindung und versuche es später erneut.',
  },
  key: `requestError`,
  text:
    'An error has occurred with you request, please check your data and connection and try again',
};

export const TOO_MANY_REQUESTS_ERROR: I18nShape = {
  bundleName,
  translation: {
    [DE]:
      'Dein Netzwerk schickt uns zu viele Anfragen ... bist du in einem öffentlichen Netz? Bitte versuche es später noch einmal.',
  },
  key: `tooManyRequestsError`,
  text:
    'It was detected too many application requests from your network. Are you in a public network? Please try again later.',
};

export const VALIDATION_ERROR: I18nShape = {
  bundleName,
  translation: {
    [DE]:
      'Leider lief etwas schief bei der Bearbeitung deiner Anfrage. Bitte prüfe ob deine Daten korrekt sind. Hast du die neueste Version unserer App installiert? Dieser Fehler wurde uns gemeldet. Bitte versuche es später noch einmal oder melde dich bei uns.',
  },
  key: `validationError`,
  text:
    'An error was detected when submitting your request. Please check if all the data is correct. Is your application updated? If everything is correct, please contact us, or try again later, since your problem may be corrected automatically by us.',
};

export const CONFIGURATION_INVALID_ERROR: I18nShape = {
  bundleName,
  translation: {
    [DE]:
      'Leider lief etwas schief bei der Bearbeitung deiner Anfrage. Bitte prüfe ob deine Daten korrekt sind. Hast du die neueste Version unserer App installiert? Dieser Fehler wurde uns gemeldet. Bitte versuche es später noch einmal oder melde dich bei uns.',
  },
  key: `configurationInvalidError`,
  text:
    'An error was detected when submitting your request. Please check if all the data is correct. Is your application updated? If everything is correct, please contact us, or try again later, since your problem may be corrected automatically by us.',
};

export const INTERNAL_ERROR: I18nShape = {
  bundleName,
  translation: {
    [DE]:
      'Leider lief etwas schief bei der Bearbeitung deiner Anfrage. Hast du eine Verbindung zum Internet? Bitte versuche es später noch einmal oder melde dich bei uns falls der Fehler bestehen bleibt.',
  },
  key: `internalError`,
  text:
    'An error was detected when submitting your request. Is your application updated? Are you connected to the Internet? If everything is correct, please contact us, or try again later, since your problem may be corrected automatically by us.',
};

export const BAD_CREDENTIALS: I18nShape = {
  bundleName,
  translation: {
    [DE]:
      'Deine Zugangsdaten sind nicht OK. Bitte schau ob du alles richtig eingegeben hast und versuche es erneut.',
  },
  key: `badCredentials`,
  text:
    'Your credentials are not correct. Are you sure you informed everything correctly?',
};

export const EMAIL_UNAUTHORIZED: I18nShape = {
  bundleName,
  key: `emailUnauthorized`,
  text: 'Invalid email address.',
};

export const INVALID_OR_MISSING_TOKEN: I18nShape = {
  bundleName,
  translation: {
    [DE]:
      'Du bist nicht eingeloggt. Vielleicht ist deine Sitzung abgelaufen. Bitte logge dich neu ein.',
  },
  key: `invalidOrMissingToken`,
  text:
    'You are not logged in or your session just expired. Please login again.',
};

export const FORBIDDEN: I18nShape = {
  bundleName,
  translation: {
    [DE]: 'Du hast keine Berechtigung für diesen Inhalt.',
  },
  key: `forbidden`,
  text: 'Your credentials have no access to what you are trying to access.',
};

export const INVALID_RECAPTCHA_ERROR: I18nShape = {
  bundleName,
  translation: {
    [DE]:
      'Mist, dieser Fehler sollte nicht passieren. Kannst du es bitte nochmal versuchen? Sollte der Fehler bestehen bleiben melde dich bitte bei uns.',
  },
  key: `invalidCaptchaError`,
  text:
    'Sorry, this error should never occur. The captcha code sent was not considered valid. Can you retry this again? If this error happens again, please contact us.',
};

export const RECAPTCHA_REQUIRED_ERROR: I18nShape = {
  bundleName,
  translation: {
    [DE]:
      'Wir müssen hier prüfen ob du eine echte Person bist. Bitte gebe das Captcha ein.',
  },
  key: `recaptchaRequiredError`,
  text:
    'The system requires you to inform a captcha. If you are using the application normally and this message occurs, please consult us, this should never happen.',
};

export const REQUEST_ABORTED_ERROR: I18nShape = {
  bundleName,
  translation: {
    [DE]: 'Deine Anfrage wurde abgebrochen.',
  },
  key: `requestAbortedError`,
  text: 'Your request was aborted',
};

export const REQUEST_BLOCKED_ERROR: I18nShape = {
  bundleName,
  translation: {
    [DE]:
      'Deine Anfrage wurde blockiert. Entweder sitzt du hinter einer Firewall oder deine Anfrage wurde anderweitig abgebrochen.',
  },
  key: `requestBlockedError`,
  text:
    'Your request was blocked. This means that either a local firewall blocked access, or request was cancelled.',
};

export const METHOD_NOT_ALLOWED_ERROR: I18nShape = {
  bundleName,
  translation: {
    [DE]:
      'Irgendwas lief schief mit deiner Anfrage. Vielleicht ein Server Fehler. Bitte versuche es erneut.',
  },
  key: `methodNotAllowedError`,
  text:
    'Your request was not accepted. This can be an server error, or a misconfiguration.',
};

export const NOT_FOUND_ERROR: I18nShape = {
  bundleName,
  translation: {
    [DE]:
      'Deine Anfrage kam nicht richtig bei uns an. Vielleicht musst du die App aktualisieren. Hast du die letzte Version installiert?',
  },
  key: `notFoundError`,
  text:
    'Your request was not found. This may be a misconfiguration, or your app needs to be updated.',
};

export const NOT_ACCEPTABLE_ERROR: I18nShape = {
  bundleName,
  translation: {
    [DE]:
      'Deine Anfrage kam nicht richtig bei uns an. Vielleicht musst du die App aktualisieren. Hast du die letzte Version installiert?',
  },
  key: `notAcceptableError`,
  text:
    'Your request was not considered acceptable. This may be a misconfiguration, or your app needs to be updated.',
};

export const BAD_REQUEST_ERROR: I18nShape = {
  bundleName,
  translation: {
    [DE]:
      'Deine Anfrage kam nicht richtig bei uns an. Vielleicht musst du die App aktualisieren. Hast du die letzte Version installiert?',
  },
  key: `badRequestError`,
  text:
    'Your request was not considered correct. This may be a misconfiguration, or your app needs to be updated.',
};

export const EXPIRED_CONTENTS_ERROR: I18nShape = {
  bundleName,
  translation: {},
  key: `expiredContentError`,
  text: 'The data you are trying to access is expired',
};
