import {
  Checkbox as MuiCheckbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import componentModifiers from '@ui-system/default-modifiers/form/checkbox';
import {
  useCheckboxCheckStyle,
  useCheckboxStyle,
} from '@ui-system/default-styles';
import { CheckboxProps, CheckboxType } from '@ui-system/interfaces-form';
import Typography from '@ui-system/material-typography';
import isString from 'lodash/isString';
import React, { useCallback } from 'react';

export const Checkbox: CheckboxType = ({
  checked,
  caption,
  onChange,
  error,
  label: Label,
  labelI18n,
  disabled,
  style,
  checkStyle,
  ...props
}: CheckboxProps) => {
  const handleChange = useCallback(() => {
    onChange(!checked);
  }, [onChange, checked]);
  const finalStyle = useCheckboxStyle(style, '', componentModifiers);
  const checkFinalStyle = useCheckboxCheckStyle(
    checkStyle,
    '',
    componentModifiers,
    {
      disabled,
    },
  );
  const renderLabel = () => {
    if (labelI18n) {
      return <Typography i18n={labelI18n} />;
    }
    if (Label) {
      return isString(Label)
        ? Label
        : // @ts-ignore
          ((<Label />) as React.ReactElement);
    }
    return null;
  };
  return (
    <FormControl
      required
      error={error}
      component="fieldset"
      disabled={disabled}
      style={finalStyle}
    >
      <FormControlLabel
        disabled={disabled}
        control={
          <MuiCheckbox
            checked={checked}
            onChange={handleChange}
            style={checkFinalStyle}
            disabled={disabled}
            {...props}
          />
        }
        label={renderLabel()}
      />
      <FormHelperText>
        <Typography i18n={caption} modifiers={error ? 'error' : undefined} />
      </FormHelperText>
    </FormControl>
  );
};

export default Checkbox;
