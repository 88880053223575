import {
  createDispatchHook,
  createSelectorHook,
} from '@redux-basic-module/hooks-utils';

import { retrieveDraft } from './actions';
import { dataSelector, loadingSelector } from './selectors';

export const useRetrieveDraftData = createSelectorHook(dataSelector);
export const useIsLoadingRetrieveDraft = createSelectorHook(loadingSelector);

export const useRetrieveDraft = createDispatchHook(retrieveDraft.start);
