import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { FieldPath } from '../../../core';
import { getIsFieldTouchedOrFormSubmittedSelector } from '../../stateSelector';
import useFormId from '../useFormId';

function useIsFieldTouchedOrFormSubmitted<T>(fieldPath: FieldPath): boolean {
  const formId = useFormId();
  const selector = useCallback(
    state =>
      getIsFieldTouchedOrFormSubmittedSelector<T>(state, formId, fieldPath),
    [formId, fieldPath],
  );
  return useSelector(selector);
}

export default useIsFieldTouchedOrFormSubmitted;
