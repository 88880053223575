import { Locale } from '@i18n/core';
import moment from 'moment';

export function formatDate(
  date: string | Date,
  locale: Locale,
  format?: string,
): string {
  moment.locale(locale);
  if (!date) return '';
  return moment(date).format(format);
}
