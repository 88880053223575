import HtmlParser from '@html-parser/core';
import { I18nShape } from '@i18n/core';
import { EN } from '@i18n/utils';
import { saveToLocalStorage } from '@lib/local-storage';
import { useParams } from '@lib/navigation';
import { PerfectScroll } from '@perfect-scroll/core';
import { useDeepCompareEffect, useIsOpen } from '@react-utils/hooks';
import { usePageByUrlPath } from '@rec/logic-live';
import { getStaticUrlWithParams } from '@rec/static';
import { nanoid } from '@reduxjs/toolkit';
import { ACCEPT_ALL, DECLINE } from '@resource/i18n-shapes';
import { css } from '@ui-system/css';
import { Style } from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';
import UI from '@ui-system/ui';
import { F, O } from '@utils/ts';
import axios from 'axios';
import update from 'immutability-helper';
import filter from 'lodash/filter';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import * as React from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useAsync } from 'react-async-hook';
import { useViewModeContext } from 'react-page-editor/state';

interface CookieItem {
  displayName: string;
  key: string;
  value: boolean;
  description: string;
}
const DEFAULT_CONFIG: CookieConfig = {
  title: 'Cookie Preference Center',
  text: `When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer. Data obtained from cookies is not shared nor sold with third parties. <a href="https://www.recgroup.com/en/privacy-policy">More information</a>`,
  contentTitle: 'Manage Consent Preferences',
  cookieItems: [
    {
      displayName: 'Analytics',
      key: 'analytcs',
      value: true,
      description:
        'These cookies allow us to count visits and traffic sources, so we can measure and improve the performance of our site. They help us know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies, we will not know when you have visited our site.',
    },
    {
      displayName: 'Third-Party',
      key: 'third-party',
      value: true,
      description:
        'When you visit our website you may notice some cookies that are not related to us. When you visit a page that contains embedded content, for example from YouTube, you may be sent cookies from these websites. We do not control the setting of such cookies and we advise you to check the third-party websites for more information about these cookies and how to manage them.',
    },
  ],
};
interface CookieConfig {
  title: string;
  text: string;
  contentTitle: string;
  cookieItems: CookieItem[];
}
export const LOCAL_STORAGE_KEY = 'cookies-preferences';

const fetchCookiesConfig = async (language?: string): Promise<CookieConfig> => {
  try {
    const response = await axios.get(
      getStaticUrlWithParams('/cookies/', language || EN, '.json'),
    );
    return response.data;
  } catch (e) {
    const response = await axios.get(
      getStaticUrlWithParams('/cookies/', EN, '.json'),
    );
    return response.data;
  }
};

const useCookiesConfig = () => {
  const { id } = useParams<{ id: string }>();
  const { language } = usePageByUrlPath(id);
  return useAsync(fetchCookiesConfig, [language]);
};
class CookieConsentContextValue {
  toggle: F.Function = () => null;

  onAccept: F.Function<[CookieItem]> = () => null;

  onDecline: F.Function<[CookieItem]> = () => null;

  onSaveAll: F.Function = () => null;

  currentConfig: CookieConfig = DEFAULT_CONFIG;

  isOpen = true;
}
const CookieConsentContext = createContext<CookieConsentContextValue>(
  new CookieConsentContextValue(),
);

export const CookieConsentContextProvider: React.FC<
  React.PropsWithChildren<O.Object>
> = ({ children }: React.PropsWithChildren<O.Object>) => {
  const [currentConfig, setCurrentConfig] = useState(DEFAULT_CONFIG);
  const { isOpen, toggle, show } = useIsOpen();

  const onSaveAll = useCallback(async () => {
    await saveToLocalStorage(LOCAL_STORAGE_KEY, JSON.stringify(currentConfig));
    await saveToLocalStorage('accepted-cookies', true);
    toggle();
    document.location.reload();
  }, [currentConfig, toggle]);

  const toggleItem = useCallback((item: CookieItem) => {
    setCurrentConfig(prevState => {
      const items = filter(prevState.cookieItems, ci => ci.key !== item.key);
      const newCookieItems = sortBy(
        [...items, { ...item, value: !item.value }],
        ['displayName'],
      );
      return update(prevState, {
        cookieItems: {
          $set: newCookieItems,
        },
      });
    });
  }, []);
  /* useEffect(() => {
    (async () => {
      try {
        const data = await getFromLocalStorage<string>(LOCAL_STORAGE_KEY);
        const parsed = JSON.parse(data);
        if (!isEmpty(parsed)) {
          setCurrentConfig(parsed);
        } else {
          delay(toggle, 500);
        }
      } catch (e) {
        delay(toggle, 500);
      }
    })();
  }, [toggle]); */

  const { result } = useCookiesConfig();

  useDeepCompareEffect(() => {
    const acceptedCookies = localStorage.getItem('accepted-cookies');
    if ((acceptedCookies === 'false' || !acceptedCookies) && result) {
      show();
      setCurrentConfig(result);
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(result));
    }
  }, [result, toggle]);

  const value = useMemo(
    () => ({
      isOpen,
      toggle,
      currentConfig,
      onSaveAll,
      onDecline: toggleItem,
      onAccept: toggleItem,
    }),
    [currentConfig, isOpen, onSaveAll, toggle, toggleItem],
  );

  return (
    <CookieConsentContext.Provider value={value}>
      {children}
    </CookieConsentContext.Provider>
  );
};
const useStyle = makeStyle<{ selected: boolean }>(
  ({ theme, getColorByName, props }) => css`
    border: 1px solid
      ${getColorByName(
        props.selected ? 'gray.A400' : 'gray.A200',
        theme.colors[theme.mode],
      )};
  `,
);
export const useCookieConsentContext = (): CookieConsentContextValue =>
  useContext(CookieConsentContext);

interface CookieItemComponentProps {
  item: CookieItem;
  style?: Style;
  selected: boolean;
  onSelect: F.Function<[string]>;
}
// const STYLE_DENSE = css`
//   padding: 0;
// `;
const CookieItemComponent: React.FC<CookieItemComponentProps> = ({
  item,
  style,
  selected,
  onSelect,
}: CookieItemComponentProps) => {
  const borderStyle = useStyle({ selected });
  return (
    <UI.Container style={style} direction="column">
      <UI.Container
        p="2, 4"
        style={borderStyle}
        f={1}
        justify="space-between"
        align="center"
      >
        <UI.Container p="0, 2">
          <UI.Typography>{item.displayName}</UI.Typography>
        </UI.Container>
        {/* <UI.Form.Checkbox */}
        {/*  checked={item.value} */}
        {/*  onChange={() => { */}
        {/*    if (item.value) { */}
        {/*      onDecline(item); */}
        {/*    } else { */}
        {/*      onAccept(item); */}
        {/*    } */}
        {/*  }} */}
        {/*  label={() => ( */}
        {/*    <UI.Container p="0, 2"> */}
        {/*      <UI.Typography>{item.displayName}</UI.Typography> */}
        {/*    </UI.Container> */}
        {/*  )} */}
        {/*  checkStyle={STYLE_DENSE} */}
        {/* /> */}
        <UI.Icon name="down" size={15} onClick={() => onSelect(item.key)} />
      </UI.Container>
      {selected && (
        <UI.Animation>
          <UI.Container p={4} bg="gray.A100">
            <UI.Caption>{item.description}</UI.Caption>
          </UI.Container>
        </UI.Animation>
      )}
    </UI.Container>
  );
};

CookieItemComponent.defaultProps = {
  style: {},
};

const CONTENT_STYLE = { maxHeight: '90vh' };
const CONTENT_STYLE_MOBILE = { maxHeight: '90vh', maxWidth: '100vw' };
const MODAL_STYLE = { width: 400, margin: '0 auto' };
const MODAL_STYLE_MOBILE = { width: '100%', margin: '0 auto' };

export const CookieConsent: React.FC = () => {
  const {
    currentConfig,
    onSaveAll,
    isOpen,
    toggle,
  } = useCookieConsentContext();
  const Title = HtmlParser(currentConfig.title, {
    Component: UI.Body2,
    modifiers: 'bold',
  });
  const ContentTitle = HtmlParser(currentConfig.contentTitle, {
    Component: UI.Body2,
    modifiers: 'bold',
  });

  const textI18n: I18nShape = {
    key: nanoid(),
    text: currentConfig.text,
    bundleName: nanoid(),
  };

  const [selected, setSelected] = useState<string | null>(null);
  const { isMobile } = useViewModeContext();
  const width = isMobile ? '100%' : 400;
  const onBack = useCallback(async () => {
    await saveToLocalStorage('accepted-cookies', false);
    toggle();
  }, [toggle]);
  return (
    <UI.Modal
      visible={isOpen}
      toggle={toggle}
      style={isMobile ? MODAL_STYLE_MOBILE : MODAL_STYLE}
    >
      <UI.Container
        direction="column"
        style={isMobile ? CONTENT_STYLE : CONTENT_STYLE_MOBILE}
        align="center"
        justify="center"
        bg="white"
      >
        <PerfectScroll>
          <UI.Container direction="column" gap={2} bg="white" w={width} p={4}>
            <UI.Container>{Title}</UI.Container>
            <UI.Container>
              <UI.Caption i18n={textI18n} />
            </UI.Container>
            <UI.Container direction="column" gap={2}>
              <UI.Container m="1, 0">{ContentTitle}</UI.Container>
              <UI.Container direction="column" f={1} gap={1}>
                {map(currentConfig.cookieItems, item => (
                  <CookieItemComponent
                    item={item}
                    key={item.key}
                    selected={selected === item.key}
                    onSelect={(key: string) =>
                      setSelected(prev => (key === prev ? null : key))
                    }
                  />
                ))}
              </UI.Container>
            </UI.Container>
          </UI.Container>
        </PerfectScroll>
        <UI.Container justify="space-between" bg="white" w={width} p={4}>
          <UI.Button onClick={onBack} i18n={DECLINE} variant="text" />
          <UI.Button onClick={onSaveAll} i18n={ACCEPT_ALL} />
        </UI.Container>
      </UI.Container>
    </UI.Modal>
  );
};
