import { css } from '@ui-system/css';
import {
  BUTTON_UUID,
  FORM_INPUT_UUID,
  FORM_SELECT_UUID,
} from '@ui-system/interfaces';
import { makeStyles } from '@ui-system/style';
import { RootStylesProvider } from '@ui-system/styles-provider';
import React from 'react';

const useStyles = makeStyles(({ theme, utils }) => ({
  [BUTTON_UUID]: css`
    border: 2px solid ${theme.colors[theme.mode].primary.main};
    border-color: ${theme.colors[theme.mode].primary.main};
    ${utils.applyShadow(theme, 1)}
  `,
  [FORM_INPUT_UUID]: css`
    padding: 2px 10px;
    border: 1px solid ${theme.colors[theme.mode].black};
    font-size: 12px;
    ${utils.applyBackgroundColor(theme, 'white')}
  `,
  [FORM_SELECT_UUID]: css`
    padding: 0 10px;
    width: 100%;
    text-transform: uppercase;
    border: 2px solid ${theme.colors[theme.mode].primary.main};
    ${utils.applyBackgroundColor(theme, 'white')}
  `,
}));

export const StylesProvider: React.FC<React.PropsWithChildren<any>> = ({
  children,
}: React.PropsWithChildren<any>) => {
  const styles = useStyles();
  return <RootStylesProvider styles={styles}>{children}</RootStylesProvider>;
};
