import { BehaviorSubject } from 'rxjs';

import { Message, Namespaces } from '../types';

export const ALL_OPTION = '*';

type GlobalVariables = {
  DISABLED: boolean;
  ENABLED_GROUPS: Array<string | RegExp>;
  SKIPPED_GROUPS: Array<RegExp>;
  CURRENT_NAMESPACES: Namespaces;
  MESSAGE_CHANNEL: BehaviorSubject<Message | void>;
};

export const SINGLETON: GlobalVariables = {
  DISABLED: false,
  ENABLED_GROUPS: [ALL_OPTION],
  SKIPPED_GROUPS: [],
  CURRENT_NAMESPACES: null,
  MESSAGE_CHANNEL: new BehaviorSubject<Message | void>(null),
};
