import { Style } from '@ui-system/interfaces/types';
import { ContainerProps } from '@ui-system/interfaces-container';
import { nanoid } from 'nanoid';
import * as React from 'react';

export type SpinnerSize = 'tiny' | 'small' | 'medium' | 'large' | 'giant';
export type SpinnerColor = 'inherit' | 'primary' | 'secondary' | 'light';

export interface SpinnerProps extends ContainerProps {
  visible: boolean;
  size?: SpinnerSize;
  color?: SpinnerColor;
  style?: Style;
}

export const SPINNER_UUID = nanoid();

export type SpinnerType = React.FC<SpinnerProps>;
