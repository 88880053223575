import { useDeepCompareMemo } from '@react-utils/hooks';
import { css } from '@ui-system/css';
import {
  GradientEnum,
  LinearGradientProps,
  LinearGradientType,
} from '@ui-system/interfaces-container';
import isString from 'lodash/isString';
import map from 'lodash/map';
import merge from 'lodash/merge';
import split from 'lodash/split';
import trim from 'lodash/trim';
import * as React from 'react';

import { LinearGradient } from './ContainerView';

const LINEAR = {
  start: { x: 0, y: 0 },
  end: { x: 0, y: 1 },
};

const LINEAR_REVERSED = {
  start: { x: 0, y: 1 },
  end: { x: 1, y: 0 },
};

const HORIZONTAL = {
  start: { x: 0, y: 0 },
  end: { x: 1, y: 0 },
};

const DIAGONAL = {
  start: { x: 0, y: 0 },
  end: { x: 1, y: 1 },
};

const GRADIENT_CONFIGS = {
  [GradientEnum.Linear]: LINEAR,
  [GradientEnum.LinearReversed]: LINEAR_REVERSED,
  [GradientEnum.Horizontal]: HORIZONTAL,
  [GradientEnum.Diagonal]: DIAGONAL,
};

const Gradient: LinearGradientType = ({
  children,
  ...props
}: LinearGradientProps) => {
  const enhancedProps = useDeepCompareMemo(() => {
    const { style, height, colors, gradientType } = props;
    let startAndEnd = {};
    if (gradientType) {
      startAndEnd = GRADIENT_CONFIGS[gradientType];
    }
    return {
      ...props,
      ...startAndEnd,
      colors: isString(colors) ? map(split(colors, ','), trim) : colors,
      style: merge(style, { height }),
    };
  }, [props]);
  return <LinearGradient {...enhancedProps}>{children}</LinearGradient>;
};

Gradient.defaultProps = {
  colors: 'black, white',
  start: { x: 0, y: 0 },
  end: { x: 0, y: 1 },
  gradientType: undefined,
  style: css`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  `,
  height: 200,
};

export default Gradient;
