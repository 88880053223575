import { useSubmitStart } from '@fast-form/module';
import { SUBMIT } from '@resource/i18n-shapes';
import { ButtonProps } from '@ui-system/interfaces-button';
import UI from '@ui-system/ui';
import * as React from 'react';

type Props = Omit<ButtonProps, 'onClick'>;

const FastFormSubmitButton: React.FC<Props> = ({ i18n, ...props }: Props) => {
  const submit = useSubmitStart();

  return <UI.Button i18n={i18n || SUBMIT} onClick={submit} {...props} />;
};

export default FastFormSubmitButton;
