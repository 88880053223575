import { I18nShape } from '@i18n/core';
import { DE } from '@i18n/utils';
import { COMMON } from '@resource/bundle-names';

export const START: I18nShape = {
  key: `start`,
  text: 'Start',
  bundleName: COMMON,
  translation: {
    [DE]: 'Start',
  },
};

export const DOWNLOAD: I18nShape = {
  key: 'download',
  text: 'Download',
  bundleName: COMMON,
  translation: {
    [DE]: 'Download',
  },
};

export const PROFILE: I18nShape = {
  key: `profile`,
  text: 'Profile',
  bundleName: COMMON,
  translation: {
    [DE]: 'Profil',
  },
};

export const ADDRESS: I18nShape = {
  key: `address`,
  text: 'Address',
  bundleName: COMMON,
  translation: {
    [DE]: 'Adresse',
  },
};

export const ADDRESSES: I18nShape = {
  key: `addresses`,
  text: 'Addresses',
  bundleName: COMMON,
  translation: {
    [DE]: 'Adressen',
  },
};

export const REVIEWS: I18nShape = {
  key: `reviews`,
  text: 'Reviews',
  bundleName: COMMON,
  translation: {
    [DE]: 'Rezensionen',
  },
};

export const FILTER: I18nShape = {
  key: `filter`,
  text: 'Filter',
  bundleName: COMMON,
  translation: {
    [DE]: 'Suche',
  },
};

export const REWARDS: I18nShape = {
  key: `rewards`,
  text: 'Rewards',
  bundleName: COMMON,
  translation: {
    [DE]: 'Prämien',
  },
};

export const REVIEW: I18nShape = {
  key: `review`,
  text: 'Review',
  bundleName: COMMON,
  translation: {
    [DE]: 'Rezension',
  },
};

export const LOADING: I18nShape = {
  key: `loading`,
  text: '...loading',
  bundleName: COMMON,
  translation: {
    [DE]: '... wird geladen',
  },
};

export const RESOURCES: I18nShape = {
  key: `resources`,
  text: 'Resources',
  bundleName: COMMON,
  translation: {
    [DE]: 'Texte',
  },
};

export const LOAD_MORE: I18nShape = {
  key: `loadMore`,
  text: 'Load more',
  bundleName: COMMON,
  translation: {
    [DE]: 'Mehr anzeigen',
  },
};

export const ACTIONS: I18nShape = {
  key: `actions`,
  text: 'Actions',
  bundleName: COMMON,
  translation: {
    [DE]: 'Aktionen',
  },
};

export const SEARCH: I18nShape = {
  key: `search`,
  text: 'Search',
  bundleName: COMMON,
  translation: {
    [DE]: 'Suchen',
  },
};

export const QUERY: I18nShape = {
  key: `query`,
  text: 'Query',
  bundleName: COMMON,
  translation: {
    [DE]: 'Suche',
  },
};

export const RATING: I18nShape = {
  key: `rating`,
  text: 'Rating',
  bundleName: COMMON,
  translation: {
    [DE]: 'Bewertung',
  },
};

export const SERIAL_NUMBER: I18nShape = {
  key: `serialNumber`,
  text: 'Serial number',
  bundleName: COMMON,
  translation: {
    [DE]: 'Seriennummer',
  },
};

export const REWARD: I18nShape = {
  key: `reward`,
  text: 'Reward',
  bundleName: COMMON,
  translation: {
    [DE]: 'Prämie',
  },
};

export const DETAILS: I18nShape = {
  key: `details`,
  text: 'Details',
  bundleName: COMMON,
  translation: {
    [DE]: 'Details',
  },
};

export const DASHBOARD: I18nShape = {
  key: `dashboard`,
  text: 'Dashboard',
  bundleName: COMMON,
  translation: {
    [DE]: 'Dashboard',
  },
};

export const COMPLETED: I18nShape = {
  key: `completed`,
  text: 'Completed',
  bundleName: COMMON,
  translation: {
    [DE]: 'Gültig',
  },
};

export const VERIFYING: I18nShape = {
  key: `verifying`,
  text: 'Verifying',
  bundleName: COMMON,
  translation: {
    [DE]: 'In Prüfung',
  },
};

export const FINISH: I18nShape = {
  key: `finish`,
  text: 'Finish',
  bundleName: COMMON,
  translation: {
    [DE]: 'Abschliessen',
  },
};

export const ON: I18nShape = {
  key: 'on',
  text: 'on',
  bundleName: COMMON,
};

export const SEND: I18nShape = {
  key: `send`,
  text: 'Send',
  bundleName: COMMON,
  translation: {
    [DE]: 'Abschicken',
  },
};

export const CANCEL: I18nShape = {
  key: `cancel`,
  text: 'Cancel',
  bundleName: COMMON,
  translation: {
    [DE]: 'Abbrechen',
  },
};

export const CONFIRM: I18nShape = {
  key: `confirm`,
  text: 'Confirm',
  bundleName: COMMON,
  translation: {
    [DE]: 'Bestätigen',
  },
};

export const SAVE: I18nShape = {
  key: `save`,
  text: 'Save',
  bundleName: COMMON,
  translation: {
    [DE]: 'Speichern',
  },
};

export const STREET_NAME: I18nShape = {
  key: `street`,
  text: 'Street',
  bundleName: COMMON,
  translation: {
    [DE]: 'Strasse',
  },
};

export const NUMBER: I18nShape = {
  key: `number`,
  text: 'Nº',
  bundleName: COMMON,
  translation: {
    [DE]: 'Hausnummer',
  },
};

export const CITY: I18nShape = {
  key: `city`,
  text: 'City',
  bundleName: COMMON,
  translation: {
    [DE]: 'Stadt',
  },
};

export const COUNTRY: I18nShape = {
  key: `country`,
  text: 'Country',
  bundleName: COMMON,
  translation: {
    [DE]: 'Land',
  },
};

export const ZIPCODE: I18nShape = {
  key: `zipcode`,
  text: 'Zipcode',
  bundleName: COMMON,
  translation: {
    [DE]: 'PLZ',
  },
};

export const TYPE: I18nShape = {
  key: `type`,
  text: 'Type',
  bundleName: COMMON,
  translation: {
    [DE]: 'Typ',
  },
};

export const COMPLEMENT: I18nShape = {
  key: `complement`,
  text: 'Complement',
  bundleName: COMMON,
  translation: {
    [DE]: 'Adresszusatz',
  },
};

export const PRIMARY: I18nShape = {
  key: `primary`,
  text: 'Primary',
  bundleName: COMMON,
  translation: {
    [DE]: 'Primär',
  },
};

export const EMAIL: I18nShape = {
  key: `email`,
  text: 'Email',
  bundleName: COMMON,
  translation: {
    [DE]: 'E-Mail',
  },
};

export const PHONE: I18nShape = {
  key: 'phone',
  text: 'Phone',
  bundleName: COMMON,
};

export const WEB: I18nShape = {
  key: 'web',
  text: 'Web',
  bundleName: COMMON,
};

export const PASSWORD: I18nShape = {
  key: `password`,
  text: 'Password',
  bundleName: COMMON,
  translation: {
    [DE]: 'Kennwort',
  },
};

export const ID: I18nShape = {
  key: `id`,
  text: 'ID',
  bundleName: COMMON,
  translation: {
    [DE]: 'ID',
  },
};

export const POINTS: I18nShape = {
  key: `points`,
  text: '{points, plural, one {# point} other {# points}}',
  bundleName: COMMON,
  translation: {
    [DE]: '{points, plural, one {# Punkt} other {# Punkte}}',
  },
  metadata: {
    description:
      'Plural or normal form of points resource based on received value',
  },
};

export const OK: I18nShape = {
  key: `ok`,
  text: 'Ok',
  bundleName: COMMON,
  translation: {
    [DE]: 'Ok',
  },
};

export const ORDER_NUMBER: I18nShape = {
  key: `orderNumber`,
  text: 'Order Number',
  bundleName: COMMON,
  translation: {
    [DE]: 'Vorgangsnummer',
  },
};

export const BUNDLE_NAME: I18nShape = {
  key: `bundleName`,
  text: 'Bundle Name',
  bundleName: COMMON,
  translation: {
    [DE]: 'Bundle',
  },
};

export const PARTICIPATION_DATE: I18nShape = {
  key: 'participationDate',
  text: 'Participation Date',
  bundleName: COMMON,
  translation: {
    [DE]: 'Eingangsdatum',
  },
};

export const PARTICIPATION_STATUS: I18nShape = {
  key: 'participationStatus',
  text: 'Participation Status',
  bundleName: COMMON,
  translation: {
    [DE]: 'Status',
  },
};

export const VALIDATION_STATUS: I18nShape = {
  key: 'validationStatus',
  text: 'Validation Status',
  bundleName: COMMON,
  translation: {
    [DE]: 'Validierungsstatus',
  },
};

export const USER_ID: I18nShape = {
  key: 'userId',
  text: 'User ID',
  bundleName: COMMON,
  translation: {
    [DE]: 'Benutzer ID',
  },
};

export const CAMPAIGN_ID: I18nShape = {
  key: 'campaignId',
  text: 'Campaign ID',
  bundleName: COMMON,
  translation: {
    [DE]: 'Challenge ID',
  },
};

export const PRODUCT_ID: I18nShape = {
  key: 'productId',
  text: 'Product ID',
  bundleName: COMMON,
  translation: {
    [DE]: 'Produkt ID',
  },
};

export const LANGUAGE: I18nShape = {
  key: 'language',
  text: 'Language',
  bundleName: COMMON,
  translation: {
    [DE]: 'Sprache',
  },
};

export const RESOURCE_KEY: I18nShape = {
  key: 'resourceKey',
  text: 'Resource Key',
  bundleName: COMMON,
  translation: {
    [DE]: 'Text Resource ID',
  },
};

export const TEXT: I18nShape = {
  key: 'text',
  text: 'Text',
  bundleName: COMMON,
  translation: {
    [DE]: 'Text',
  },
};

export const METADATA: I18nShape = {
  key: 'metadata',
  text: 'Metadata',
  bundleName: COMMON,
  translation: {
    [DE]: 'Metadaten',
  },
};

export const TRANSLATE: I18nShape = {
  key: 'translate',
  text: 'Translate',
  bundleName: COMMON,
  translation: {
    [DE]: 'Übersetzen',
  },
};

export const TUTORIAL: I18nShape = {
  key: `tutorial`,
  text: 'Tutorial',
  bundleName: COMMON,
  translation: {
    [DE]: 'Tutorium',
  },
};

export const VERSION: I18nShape = {
  key: 'version',
  text: 'Version',
  bundleName: COMMON,
  translation: {
    [DE]: 'Version',
  },
};

export const AVAILABLE: I18nShape = {
  key: `available`,
  text: 'Available',
  bundleName: COMMON,
  translation: {
    [DE]: 'Verfügbar',
  },
};

export const REJECTED: I18nShape = {
  key: `rejected`,
  text: 'Rejected',
  bundleName: COMMON,
  translation: {
    [DE]: 'Abgelehnt',
  },
};

export const IN_PROGRESS: I18nShape = {
  key: `inProgress`,
  text: 'In Progress',
  bundleName: COMMON,
  translation: {
    [DE]: 'In Bearbeitung',
  },
};

export const IMPRINT: I18nShape = {
  key: `imprint`,
  text: 'Imprint',
  bundleName: COMMON,
  translation: {
    [DE]: 'Impressum',
  },
};

export const COPIED: I18nShape = {
  key: `copied`,
  text: 'Copied',
  bundleName: COMMON,
};

export const TERMS_OF_USE: I18nShape = {
  key: `termsOfUse`,
  text: 'Terms of use',
  bundleName: COMMON,
};

export const PRIVACY_POLICY: I18nShape = {
  key: `privacyPolicy`,
  text: 'Privacy policy',
  bundleName: COMMON,
};

export const COOKIES_SETTINGS: I18nShape = {
  key: `cookiesSettings`,
  text: 'Cookies settings',
  bundleName: COMMON,
};

export const CONTACT: I18nShape = {
  key: 'contact',
  text: 'Contact',
  bundleName: COMMON,
};

export const LEGAL_INFORMATION: I18nShape = {
  key: 'legalInformation',
  text: 'Legal information',
  bundleName: COMMON,
};

export const NEXT: I18nShape = {
  key: 'next',
  text: 'Next',
  bundleName: COMMON,
};

export const CLOSE: I18nShape = {
  key: 'close',
  text: 'Close',
  bundleName: COMMON,
};

export const SKIP_TUTORIAL: I18nShape = {
  key: 'skipTutorial',
  text: 'Skip Tutorial',
  bundleName: COMMON,
};
