import { Button as MuiButton } from '@material-ui/core';
import { call } from '@shared-utils/function';
import { Icon } from '@ui-system/common-icon';
import componentModifiers from '@ui-system/default-modifiers/button';
import defaultProps from '@ui-system/default-props/button';
import { useButtonStyle } from '@ui-system/default-styles';
import { useTranslatedMessage } from '@ui-system/deps';
import { ButtonProps, ButtonType } from '@ui-system/interfaces-button';
import { IconProps } from '@ui-system/interfaces-icon';
import UI from '@ui-system/ui';
import React, { useCallback } from 'react';

const Button: ButtonType = ({
  i18n,
  onClick,
  variant,
  color,
  accessoryLeft,
  accessoryRight,
  iconSize,
  modifiers,
  style,
  children,
  iconColor,
  loading,
  disabled,
  ...props
}: ButtonProps) => {
  // @ts-ignore
  const iconRight: React.FC<IconProps> = useCallback(
    // @ts-ignore
    () => (
      <Icon
        name={accessoryRight}
        size={iconSize}
        color={disabled ? 'gray.A100' : iconColor}
      />
    ),
    [accessoryRight, disabled, iconColor, iconSize],
  );

  const iconLeft: React.FC<IconProps> = useCallback(
    // @ts-ignore
    () => (
      <Icon
        name={accessoryLeft}
        size={iconSize}
        color={disabled ? 'gray.A100' : iconColor}
      />
    ),
    [accessoryLeft, disabled, iconColor, iconSize],
  );
  const text = useTranslatedMessage(i18n);
  const buttonStyle = useButtonStyle(style, modifiers, componentModifiers);
  return (
    <MuiButton
      onClick={onClick}
      variant={variant}
      color={color}
      // @ts-ignore
      startIcon={call(iconLeft)}
      // @ts-ignore
      endIcon={!loading ? call(iconRight) : <UI.Spinner visible size="tiny" />}
      style={buttonStyle}
      disabled={disabled || loading}
      {...props}
    >
      {children || text}
    </MuiButton>
  );
};
Button.defaultProps = defaultProps;

export default Button;
