export enum ResourceType {
  TEXT = 'TEXT',
  HANDLEBARS = 'HANDLEBARS', // to use with parameters
  MJML = 'MJML', // https://mjml.io/
  MJML_HANDLEBARS = 'MJML_HANDLEBARS',
}

export interface ResourceMetadataParamDTO {
  fieldName: string;
  description: string;
}

export interface ResourceMetadataDTO {
  description: string;
  params?: ResourceMetadataParamDTO[];
}

export interface I18n {
  key: string;
  bundleName: string;
  text: string;
  type: ResourceType;
  metadata?: ResourceMetadataDTO;
}

// USAGE ON CLIENT-SIDE
export interface I18nShape {
  key: string;
  bundleName: string;
  text: string;
  type?: ResourceType;
  values?: Record<string, string | number>;
  metadata?: ResourceMetadataDTO;
}
