import {
  createDispatchHook,
  createSelectorHook,
} from '@redux-basic-module/hooks-utils';

import { createUpdateLive, setPublishMode } from './actions';
import {
  dataSelector,
  loadingSelector,
  publishModeSelector,
} from './selectors';
import { PublishMode } from './types';

export const useCreateUpdateLiveData = createSelectorHook(dataSelector);
export const useIsLoadingCreateUpdateLive = createSelectorHook(loadingSelector);
export const usePublishMode = createSelectorHook(publishModeSelector);

export const useCreateUpdateLive = createDispatchHook(createUpdateLive.start);
export const useSetPublishMode = createDispatchHook(setPublishMode);

export function useIsPublishModeStarted(): boolean {
  const publishMode = usePublishMode();
  return publishMode === PublishMode.STARTED;
}

export function useIsPublishModeSucceeded(): boolean {
  const publishMode = usePublishMode();
  return publishMode === PublishMode.SUCCEEDED;
}

export function useIsPublishModeIdle(): boolean {
  const publishMode = usePublishMode();
  return publishMode === PublishMode.IDLE;
}

export function useIsPublishModeNotIdle(): boolean {
  const publishMode = usePublishMode();
  return publishMode !== PublishMode.IDLE;
}
