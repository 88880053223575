import {
  createArgsArray,
  registerDecorator,
  SINGLETON,
  ValidationArguments,
  ValidationOptions,
} from 'class-validator-i18n';
import noop from 'lodash/noop';

import { isDisposableEmail } from './isDisposableEmail';

export function NotDisposableEmail(validationOptions?: ValidationOptions) {
  // @typescript-eslint/explicit-module-boundary-types
  return function createNotDisposableEmail(
    // eslint-disable-next-line @typescript-eslint/ban-types
    object: Object,
    propertyName: string,
  ): void {
    const customValidationOptions = {
      ...validationOptions,
      message: validationArgs =>
        SINGLETON.translate(
          NotDisposableEmail,
          createArgsArray(validationOptions),
          validationArgs,
          'NotDisposableEmail',
        ),
    };
    registerDecorator({
      name: 'notDisposableEmail',
      target: object.constructor,
      propertyName,
      options: customValidationOptions,
      validator: {
        validate(value: any, args: ValidationArguments) {
          noop(args);
          return typeof value === 'string' && !isDisposableEmail(value);
        },
      },
    });
  };
}
