import { Theme } from '@ui-system/theme';
import { F } from '@utils/ts';

import createFromProps from '../create-from-props';
import { FromProps, Padding, Shadow } from '../types';

const applyShadow: F.Function<[Theme, Padding]> & FromProps = (
  theme,
  shadow: Shadow,
) => {
  if (theme && theme.shadows[shadow]) {
    return theme.shadows[shadow];
  }
  return '';
};
applyShadow.fromProps = createFromProps(applyShadow, 'shadow');

export default applyShadow;
