import { useDeepCompareMemo } from '@react-utils/hooks';
import { css } from '@ui-system/css';
import * as utils from '@ui-system/css/utils';
import { Style } from '@ui-system/interfaces';
import { devices, useMediaQueries } from '@ui-system/media-query';
import {
  MakeStyleParam,
  MakeStyleParamWithoutProps,
  UseCustomProps,
  UseStyle,
  UseStyles,
} from '@ui-system/styles/types';
import { useTheme } from '@ui-system/theme';
import { getColorByName } from '@ui-system/theme/colors';
import { useMemo } from 'react';

export function makeStyle<Props extends Record<string, any> | null = null>(
  callback: (param: MakeStyleParam<Props>) => Style,
): UseStyle<Props> {
  return function useStyle(props: Props extends null ? null : Props): Style {
    const theme = useTheme();
    const mediaQueries = useMediaQueries();
    const memoProps = useDeepCompareMemo(() => props, [props]);
    return useMemo(
      () =>
        callback({
          theme,
          props: memoProps,
          utils,
          mediaQueries,
          devices,
          css,
          getColorByName,
        }),
      [mediaQueries, memoProps, theme],
    );
  } as UseStyle<Props>;
}

export function makeCustomProps<
  Props extends Record<string, any> | null = null
>(
  callback: (param: MakeStyleParam<Props>) => Record<string, any>,
): UseCustomProps<Props> {
  return function useCustomProp(
    props: Props extends null ? null : Props,
  ): Record<string, any> {
    const theme = useTheme();
    const mediaQueries = useMediaQueries();
    const memoProps = useDeepCompareMemo(() => props, [props]);
    return useMemo<Record<string, any>>(
      () =>
        callback({
          theme,
          props: memoProps,
          utils,
          mediaQueries,
          devices,
          css,
          getColorByName,
        }),
      [mediaQueries, memoProps, theme],
    );
  } as UseCustomProps<Props>;
}

export function makeStyles(
  callback: (param: MakeStyleParamWithoutProps) => Record<string, Style>,
): UseStyles {
  return function useStyles(): Style {
    const theme = useTheme();
    const mediaQueries = useMediaQueries();
    return useMemo(
      () =>
        callback({ theme, utils, mediaQueries, devices, css, getColorByName }),
      [mediaQueries, theme],
    );
  } as UseStyles;
}
