import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { FieldPath } from '../../../core';
import { setFieldValueAction } from '../../actions';
import useFormId from '../useFormId';

export type SetValueCb<T> = (value: T) => void;

function useSetValue<T>(fieldPath: FieldPath): SetValueCb<T> {
  const dispatch = useDispatch();
  const formId = useFormId();
  return useCallback(
    (value: T) => dispatch(setFieldValueAction(formId, fieldPath, value)),
    [dispatch, fieldPath, formId],
  );
}

export default useSetValue;
