import { Epic } from '@redux-async-module/interfaces';
import forEach from 'lodash/forEach';
import uuid from 'uuid-random';

// XXX: done to avoid epic logic dynamic module to consider all epics equal
export function fixEpicToString(epic: Epic): Epic {
  const id = uuid();
  // eslint-disable-next-line no-param-reassign
  epic.toString = function customToString() {
    return id;
  };
  return epic;
}

export function fixEpicArrayToString(epics: Epic[]): Epic[] {
  forEach(epics, fixEpicToString);
  return epics;
}
