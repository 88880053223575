import { selectors } from '@authentication/redux-auth-data/reducer';
import { getAuthHeaders } from '@authentication/redux-auth-data/utils';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { getEnvironment, isTestOrDevEnvironment } from '@lib/config-loader';
import { registerSentryReactLoggerTransport } from '@logger/sentry-react-transport';
import { configureApiLogger } from '@service-layer-utils/configure-api-logger';
import { configureBrowserLogger } from '@service-layer-utils/configure-browser-logger';
import { Store } from 'redux';

import pkgJSON from '../package.json';

export async function configureLogger(store: Store): Promise<void> {
  if (isTestOrDevEnvironment()) {
    configureBrowserLogger({
      console: {
        omitServiceContextParams: true,
        omitStaticParams: true,
      },
    });
  }

  const fp = await FingerprintJS.load();

  const result = await fp.get();
  const { visitorId } = result;

  const staticParams = {
    applicationId: 'rec-propage-www',
    environment: getEnvironment(),
    version: pkgJSON.version,
    deviceId: visitorId,
  };

  function addParams(): Record<string, unknown> {
    const authData = selectors.authData(store.getState());

    if (authData) {
      return {
        userId: authData.userId,
      };
    }
    return {};
  }

  if (!isTestOrDevEnvironment()) {
    configureApiLogger({
      staticParams,
      getHeaders: () => getAuthHeaders(store.getState()),
      addParams,
    });

    registerSentryReactLoggerTransport({
      staticParams,
      addParams,
    });
  }
}
