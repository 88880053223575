import { LogLevel } from '@logger/core';
import { INTERNAL_ERROR } from '@resource/i18n-shapes';
import { plainToClass } from 'class-transformer';
import { getReasonPhrase } from 'http-status-codes';
import { ExtendableError } from 'ts-error';

import { HttpErrorJSON } from './HttpErrorJSON';

export abstract class HttpError extends ExtendableError implements isHttpError {
  httpCode: number;

  protected constructor(httpCode: number, message?: string) {
    super(message);
    this.httpCode = httpCode;
  }

  protected convertToHttpErrorJSON(
    data: Partial<HttpErrorJSON>,
  ): HttpErrorJSON {
    try {
      return plainToClass(HttpErrorJSON, data);
    } catch (error) {
      const json = new HttpErrorJSON();
      Object.assign(json, data);
      return json;
    }
  }

  toJSON(toClient?: boolean): HttpErrorJSON {
    return this.convertToHttpErrorJSON({
      status: this.httpCode,
      type: !toClient ? HttpError.name : getReasonPhrase(this.httpCode),
      reason: getReasonPhrase(this.httpCode),
      i18n: INTERNAL_ERROR,
      level: LogLevel.ERROR,
    });
  }
}

export interface isHttpError {
  toJSON: (toClient?: boolean) => HttpErrorJSON;
}
