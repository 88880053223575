import { Handler } from '@redux-basic-module/interfaces';
import { createReducer } from '@redux-basic-module/reducer-utils';
import update from 'immutability-helper';
import set from 'lodash/set';

import { setUserInfo } from './actions';
import { INITIAL_STATE, NAMESPACE, SetUserInfoPayload, State } from './types';

const HANDLERS = {};

const SET_USER_INFO_HANDLER: Handler<State, Partial<SetUserInfoPayload>> = (
  state,
  action,
) =>
  update(state, {
    language: { $set: action.payload.language || state.language },
    email: { $set: action.payload.email || state.email },
    country: { $set: action.payload.country || state.country },
  });

set(HANDLERS, setUserInfo.type, SET_USER_INFO_HANDLER);

export const reducer = createReducer(NAMESPACE, INITIAL_STATE, HANDLERS);
