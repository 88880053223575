import { useTranslateFunction } from '@i18n/context';
import { I18nShape } from '@i18n/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useHeaderConfigState } from '@page-creator/context';
import { PerfectScroll } from '@perfect-scroll/core';
import { Toggle } from '@pro-page-components/tutorials';
import {
  useDeepCompareEffect,
  useDeepCompareMemo,
  useSelectedItems,
} from '@react-utils/hooks';
import { SUPPORTED_LANGUAGES } from '@rec/constants';
import {
  CONTENT,
  LANGUAGES,
  REC_RCSP_LOGO,
  REC_RCSP_LOGO_TOOLTIP,
  SELECT_ALL,
} from '@rec/i18n';
import {
  generateKey,
  useCreateUpdateDraft,
  useCreateUpdateDraftData,
} from '@rec/redux-page-creator-draft';
import { useRetrieveDraftData } from '@rec/redux-retrieve-draft';
import { useUserInfo } from '@rec/user-info';
import { useLanguagesList } from '@resource/languages-list';
import { useCurrentLanguage } from '@resource/redux-language';
import { css } from '@ui-system/css';
import { CustomProps, Tree, TreeDataItem } from '@ui-system/tree';
import UI from '@ui-system/ui';
import filter from 'lodash/filter';
import find from 'lodash/find';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import values from 'lodash/values';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Tooltip } from 'tooltip';

import { ContextComponent, useEditorContext } from '../state';

const Right: React.FC<CustomProps> = ({ item }: CustomProps) => {
  const { isComponentVisible, components } = useEditorContext();
  const isVisible = isComponentVisible({
    id: item.key,
    name: components[item.key]?.name,
    path: components[item.key]?.path,
  });
  const onClick = useCallback(() => {
    const el = document.getElementById(item.key);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }, [item.key]);
  return (
    <UI.Container gap={2}>
      {isVisible && (
        <UI.Container onClick={onClick}>
          <UI.Icon size={15} name="view" color="black" />
        </UI.Container>
      )}
    </UI.Container>
  );
};

function resolveChildren(
  components: ContextComponent[],
  path = '',
  translate: ReturnType<typeof useTranslateFunction>,
  i18ns: I18nShape[] = [],
  deactivatedIds: string[] = [],
): TreeDataItem[] {
  return map(
    filter(components, component => !includes(deactivatedIds, component.id)),
    item => {
      const key = `${path}${path ? '.children.' : ''}${item.id}`;
      const label = translate(find(i18ns, { key: `components.${item.id}` }));
      return {
        label,
        key,
        children: resolveChildren(
          filter(
            item.children,
            component => !includes(deactivatedIds, component.id),
          ),
          key,
          translate,
          i18ns,
        ),
        selected: item.visible,
      };
    },
  );
}

const Label = () => <UI.Caption i18n={SELECT_ALL} modifiers="uppercase" />;

const STYLE_DENSE = css`
  padding: 4px;
`;

interface TreeSelectProps {
  i18ns: I18nShape[];
  deactivatedIds?: string[];
}

const SupportedLanguagesSelector = () => {
  const currentLanguage = useCurrentLanguage();
  const createOrUpdateDraft = useCreateUpdateDraft();
  const retrieveDraftData = useRetrieveDraftData();
  const createUpdateDraftData = useCreateUpdateDraftData();
  const { language } = useUserInfo();
  const draft = useDeepCompareMemo(
    () => createUpdateDraftData || retrieveDraftData,
    [retrieveDraftData, createUpdateDraftData],
  );

  const { selectedItems, isSelected, toggleItem } = useSelectedItems<string>(
    draft?.contents?.supportedLanguages || [language],
  );
  const userInfo = useUserInfo();

  const languages = useLanguagesList(currentLanguage, SUPPORTED_LANGUAGES);
  const update = useCallback(
    (items: string[]) => {
      if (draft && !isEmpty(items)) {
        createOrUpdateDraft({
          key: generateKey(userInfo?.email),
          ...draft,
          contents: {
            ...(draft?.contents || {}),
            supportedLanguages: items,
          },
        });
      }
    },
    [createOrUpdateDraft, draft, userInfo?.email],
  );
  useDeepCompareEffect(() => {
    update(selectedItems);
  }, [selectedItems]);
  useEffect(() => {
    if (!isSelected(language)) {
      toggleItem(language);
    }
  }, [isSelected, language, toggleItem]);
  return (
    <UI.Container direction="column" p="0, 2">
      {map(languages, lang => (
        <UI.Container key={lang.code} m={0}>
          <UI.Form.Checkbox
            checked={isSelected(lang.code)}
            disabled={lang.code === language}
            onChange={() => toggleItem(lang.code)}
            checkStyle={STYLE_DENSE}
            label={() => (
              <UI.Caption modifiers="uppercase">
                {`${lang.name} ${lang.code === language ? '(default)' : ''}`}
              </UI.Caption>
            )}
          />
        </UI.Container>
      ))}
    </UI.Container>
  );
};

const RECRCSPLogoCheckbox = () => {
  const {
    headerConfig: { hideHeaderTopRightLogo },
    updateHeaderConfig,
  } = useHeaderConfigState();
  const onChange = useCallback(
    () =>
      updateHeaderConfig({
        hideHeaderTopRightLogo: !hideHeaderTopRightLogo,
      }),
    [hideHeaderTopRightLogo, updateHeaderConfig],
  );
  const scrollToHeader = useCallback(() => {
    const el = document.getElementById('HEADER');
    if (el) el.scrollIntoView({ behavior: 'smooth' });
  }, []);
  return (
    <>
      <UI.Container align="center" justify="space-between" p="0, 2, 0, 2">
        <UI.Form.Checkbox
          checked={!hideHeaderTopRightLogo}
          onChange={onChange}
          label={() => (
            <Tooltip i18n={REC_RCSP_LOGO_TOOLTIP}>
              <UI.Caption i18n={REC_RCSP_LOGO} modifiers="uppercase" />
            </Tooltip>
          )}
          checkStyle={STYLE_DENSE}
        />
        <UI.Container gap={2}>
          {!hideHeaderTopRightLogo && (
            <UI.Container onClick={scrollToHeader}>
              <UI.Icon size={15} name="view" color="black" />
            </UI.Container>
          )}
        </UI.Container>
      </UI.Container>
      <UI.Divider color="white" />
    </>
  );
};
export const TreeSelect: React.FC<TreeSelectProps> = ({
  i18ns,
  deactivatedIds,
}: TreeSelectProps) => {
  const {
    components,
    toggleComponent,
    toggleAllComponents,
    allSelected,
  } = useEditorContext();
  const [lastToggledItem, setLastToggledItem] = useState(null);
  const translate = useTranslateFunction();
  const data = useMemo<TreeDataItem[]>(
    () =>
      resolveChildren(
        sortBy(values(components), ['position']),
        '',
        translate,
        i18ns,
        deactivatedIds,
      ),
    [components, translate, i18ns, deactivatedIds],
  );

  const onChange = useCallback(
    (id: string) => {
      toggleComponent({
        id,
        name: components[id]?.name,
        path: components[id]?.path,
      });
      setLastToggledItem(id);
    },
    [components, toggleComponent],
  );
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (lastToggledItem) {
        const el = document.getElementById(lastToggledItem);
        if (el) el.scrollIntoView({ behavior: 'smooth' });
        else {
          setLastToggledItem(null);
        }
      }
    }, 200);
    return () => {
      clearTimeout(timeout);
    };
  }, [lastToggledItem]);
  const [value, setValue] = useState(0);

  const handleChange = useCallback(
    (event: React.ChangeEvent, newValue: number) => {
      setValue(newValue);
    },
    [],
  );

  return (
    <UI.Container direction="column" f={1} className="my-first-step">
      <UI.Container
        p="0, 1, 0, 2"
        direction="row"
        justify="space-between"
        align="center"
      >
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label={
              <UI.Caption i18n={CONTENT} modifiers="primary, bold, uppercase" />
            }
          />
          <Tab
            label={
              <UI.Caption
                i18n={LANGUAGES}
                modifiers="primary, bold, uppercase"
              />
            }
          />
        </Tabs>
        <Toggle />
      </UI.Container>
      <UI.Divider color="white" />
      {value === 0 && (
        <UI.Container align="center" justify="space-between" p="0, 2, 0, 2">
          <UI.Form.Checkbox
            checked={allSelected}
            onChange={toggleAllComponents}
            label={Label}
            checkStyle={STYLE_DENSE}
          />
        </UI.Container>
      )}
      <UI.Divider color="white" />
      <PerfectScroll>
        {value === 0 && <RECRCSPLogoCheckbox />}
        {value === 0 ? (
          <Tree data={data} onChange={onChange} Right={Right} dense />
        ) : (
          <SupportedLanguagesSelector />
        )}
      </PerfectScroll>
    </UI.Container>
  );
};

TreeSelect.defaultProps = {
  deactivatedIds: [],
};
