import logger from '@logger/core';
import { Epic } from '@redux-basic-module/interfaces';
import { ofType } from '@redux-operators/of-type';
import type { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { ValidationError } from '../../core';
import {
  ErrorType,
  handleValidation,
  normalizeClassValidatorErrors,
} from '../../utils';
import { submitErrorAction, submitSuccessAction } from '../actions';
import { SUBMIT_START } from '../constants';
import { allFormsSelector } from '../stateSelector';
import type {
  AllForms,
  RootState,
  SubmitErrorAction,
  SubmitStartAction,
  SubmitSuccessAction,
} from '../types';

const log = logger('@fast-form');

type PipeData = [SubmitStartAction<any>, AllForms<any>];

// @ts-ignore
const onSubmitStart: Epic = (
  action$: ActionsObservable<SubmitStartAction<any>>,
  state$: Observable<RootState<any>>,
): Observable<SubmitSuccessAction<any> | SubmitErrorAction<any>> =>
  action$.pipe(
    ofType(SUBMIT_START),
    withLatestFrom(state$.pipe(map(allFormsSelector))),
    tap(() => {
      log.info('Submit form start');
    }),
    switchMap(
      ([
        {
          payload: { formId, validationSchema },
        },
        allForms,
      ]: PipeData) =>
        from(
          // @ts-ignore
          handleValidation(validationSchema, allForms[formId].currentValues),
        ).pipe(
          tap(() => {
            log.info('Validated form');
          }),
          map(values => {
            log.info('Validated successfully');
            return submitSuccessAction(formId, values);
          }),
          catchError((error: ValidationError) => {
            const errorPayload = validationSchema
              ? error.inner
              : normalizeClassValidatorErrors((error as unknown) as ErrorType);
            log.info('Validated with error');
            return of(
              submitErrorAction(
                formId,
                // @ts-ignore
                allForms[formId].currentValues,
                errorPayload,
              ),
            );
          }),
        ),
    ),
  );

export default onSubmitStart;
