import { createHandlers } from '@redux-async-module/handlers-utils';
import { createReducer } from '@redux-basic-module/reducer-utils';
import update from 'immutability-helper';

import { createUpdateLive, setPublishMode } from './actions';
import { INITIAL_STATE, NAMESPACE, State } from './types';

const HANDLERS = createHandlers(createUpdateLive, INITIAL_STATE);
const PUBLISH_HANDLERS = {
  [setPublishMode.type]: (
    state: State,
    action: ReturnType<typeof setPublishMode>,
  ) => update(state, { publishMode: { $set: action.payload } }),
};

export const reducer = createReducer(NAMESPACE, INITIAL_STATE, {
  ...HANDLERS,
  ...PUBLISH_HANDLERS,
});
