import { getSignInByEmailUrl } from '@authentication/shared-routes';
import { CallbackHook } from '@react-utils/hooks-types';
import { RequestType } from '@redux-async-module/interfaces';
import { createModule } from '@redux-async-module/module-utils';
import { selectors as languageSelectors } from '@resource/redux-language/reducer';
import { useCallback } from 'react';

import {
  NAMESPACE,
  RootState,
  SignInByEmailStartPayload,
  SignInByEmailSuccessPayload,
} from './types';

const {
  getDynamicModule,
  selectors,
  actions,
  dispatchHooks,
  selectorHooks,
} = createModule<
  RootState,
  SignInByEmailStartPayload,
  SignInByEmailSuccessPayload
>({
  namespace: NAMESPACE,
  actionName: 'SIGN_IN_BY_EMAIL',
  mainEpicConfig: {
    requestOptions: {
      type: RequestType.post,
      getPath(payload, state) {
        const language = languageSelectors.currentLanguage(state);
        return getSignInByEmailUrl(language);
      },
    },
  },
});

export const signInByEmailSelectors = selectors;
export const signInByEmailActions = actions;

export const {
  useIsLoading: useSignInByEmailIsLoading,
  useError: useSignInByEmailError,
  useSuccess: useSignInByEmailSuccess,
  useLastStartPayload: useSignInByEmailLastStartPayload,
} = selectorHooks;

export const {
  useAsyncStart: useSignInByEmailStart,
  useDismissError: useSignInByEmailDismissError,
  useResetModule,
} = dispatchHooks;

export const useReSendTANCode: CallbackHook = () => {
  const lastPayload = useSignInByEmailLastStartPayload();
  const submitEmail = useSignInByEmailStart();
  return useCallback(() => {
    submitEmail(lastPayload);
  }, [lastPayload, submitEmail]);
};
export const getSignInByEmailModule = getDynamicModule;
