import { Epic, Module } from '@redux-basic-module/interfaces';

import { NAMESPACE } from './constants';
import epics from './epics';
import { getReducer } from './reducer';
import { State } from './types';

export * from './actions';
export * from './constants';
export * from './hooks';
export * from './reducer';
export * from './types';

interface OwnState {
  [NAMESPACE]: State<any>;
}
interface Epics {
  epics: Epic[];
}
export function getFastFormModule(): Module<OwnState> & Epics {
  return {
    id: NAMESPACE,
    reducerMap: {
      [NAMESPACE]: getReducer(),
    },
    epics,
  };
}
