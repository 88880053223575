import { CircularProgress as MuiSpinner } from '@material-ui/core';
import { Container } from '@ui-system/common-container';
import defaultProps from '@ui-system/default-props/spinner';
import {
  SpinnerProps,
  SpinnerSize,
  SpinnerType,
} from '@ui-system/interfaces-spinner';
import React from 'react';

const SIZES_MAP: Record<SpinnerSize, number> = {
  tiny: 15,
  small: 25,
  medium: 40,
  large: 50,
  giant: 70,
};

const Spinner: SpinnerType = ({
  color,
  size,
  style,
  visible,
  ...props
}: SpinnerProps) => (
  <Container visible={visible} justify="center" f={1} align="center" {...props}>
    <MuiSpinner
      color={color as 'primary' | 'secondary' | 'inherit'}
      size={SIZES_MAP[size]}
      style={style}
    />
  </Container>
);
Spinner.defaultProps = defaultProps;

export default Spinner;
