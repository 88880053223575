import {
  DE,
  EN,
  ES,
  FR,
  IT,
  // JA,
  NL,
  NO,
  PL,
  SV,
} from '@i18n/utils';

export const SUPPORTED_LANGUAGES = [
  DE,
  EN,
  PL,
  NL,
  ES,
  IT,
  FR,
  // JA,
  NO,
  SV,
];
