export const EDITOR = '/editor';
export const VERSION = '/version';
export const MAIN = '/';
export const MAIN_RENDERER = '/:id';

const ROUTES = {
  EDITOR,
  VERSION,
  MAIN,
  MAIN_RENDERER,
};

// !!! For new routes, certify to put it in the right allowedRotes property
export const AUTHENTICATED_ROUTES_CONFIG = {
  logged: {
    baseRoute: MAIN,
    allowedRoutes: [MAIN, EDITOR, VERSION],
  },
  notLogged: {
    baseRoute: MAIN_RENDERER,
    allowedRoutes: [MAIN, EDITOR, MAIN_RENDERER, VERSION],
  },
};

export default ROUTES;
