import { useCurrentLanguage } from '@resource/redux-language';
import { extractLanguageAndCountryFromLocaleString } from '@resource/utils';
import keys from 'lodash/keys';
import map from 'lodash/map';
import { useMemo } from 'react';

import { getCountries } from '.';
import { CountriesList, CountriesMap, CountriesOptionsList } from './types';

export function useCountriesMapByLanguage(): CountriesMap {
  const language = useCurrentLanguage();
  return useMemo(
    () =>
      getCountries(
        extractLanguageAndCountryFromLocaleString(language).language,
      ),
    [language],
  );
}

export function useCountriesList(): CountriesList {
  const countries = useCountriesMapByLanguage();
  return useMemo(
    () =>
      map(keys(countries), key => ({
        code: key,
        name: countries[key],
      })),
    [countries],
  );
}
export function useCountriesOptionsList(): CountriesOptionsList {
  const countries = useCountriesList();
  return useMemo(
    () =>
      map(countries, country => ({
        value: country.code,
        label: country.name,
      })),
    [countries],
  );
}
