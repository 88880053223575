import {
  createRecProductVideoComponents,
  RecProduct,
  RecProductProps,
} from '@pro-page-components/rec-product';
import { useChildrenVisibilityEditor } from '@pro-page-components/utils/useChildrenVisibility';
import { useRestrictComponentByRegion } from '@pro-page-components/utils/useRestrictComponentByRegion';
import * as React from 'react';
import { ComponentForEditor } from 'react-page-editor/state';

import config from './config.json';
import defaultProps from './defaultProps.json';

const { Video, VideoEditor } = createRecProductVideoComponents({
  name: 'REC Twin Peak 4 Video',
  id: 'REC_TWIN_PEAK_4_VIDEO',
  path: '.....',
  parent: config.id,
});

export const RecTwinPeak4Series: ComponentForEditor<RecProductProps> = (
  props: RecProductProps,
) => {
  const restrict = useRestrictComponentByRegion(config);
  if (restrict) return null;
  return <RecProduct VideoComponent={Video} {...props} />;
};

export const RecTwinPeak4SeriesEditor: ComponentForEditor<RecProductProps> = (
  props: RecProductProps,
) => {
  // eslint-disable-next-line no-underscore-dangle,react/destructuring-assignment
  const visible = useChildrenVisibilityEditor(props.__config);
  if (!visible) return null;
  return (
    <RecTwinPeak4Series
      scale={0.9}
      skipVisibilityCheck
      VideoComponent={VideoEditor}
      {...props}
    />
  );
};

RecTwinPeak4Series.defaultProps = defaultProps as RecProductProps;
RecTwinPeak4Series.config = config;
RecTwinPeak4SeriesEditor.defaultProps = defaultProps as RecProductProps;
RecTwinPeak4SeriesEditor.config = config;
RecTwinPeak4Series.children = [Video];
RecTwinPeak4SeriesEditor.children = [VideoEditor];
