import { nanoid } from 'nanoid';
import React, { CSSProperties } from 'react';
import { ImageStyle } from 'react-native';

export type ResizeMode = 'contain' | 'cover' | 'stretch' | 'center';

export interface ImageProps<T = string> {
  src: T | string;
  preview?: string | number | void;
  style?: CSSProperties | ImageStyle;
  height: number | string;
  width: number | string;
  resizeMode?: ResizeMode;
  ignorePxPerfect?: boolean;
  noCache?: boolean;
}

export type NativeImageProps = {
  placeholderStyle?: Record<string, any>;
  PlaceholderContent?: React.ReactNode;
  containerStyle?: Record<string, any>;
  children?: React.ReactNode;
  transition?: boolean;
  onLoad?: (e: Event) => void;
} & ImageProps<Record<string, any> | number>;

export type ImageType = React.FC<ImageProps>;

export const IMAGE_DEFAULT_PROPS: Partial<ImageProps> = {
  height: 100,
  width: 100,
  resizeMode: 'contain',
  ignorePxPerfect: false,
  noCache: false,
};

export const IMAGE_UUID = nanoid();
