import {
  DefaultIconLevelsNames,
  DefaultIconSetNames,
  IconSetType,
} from './interfaces';
import {
  back,
  calendar,
  check,
  close,
  copy,
  desktop,
  down,
  download,
  downSolid,
  drag,
  edit,
  error,
  facebook,
  find,
  flipCam,
  gallery,
  hide,
  instagram,
  link,
  linkedin,
  login,
  menu,
  mobile,
  next,
  person,
  pinterest,
  place,
  previous,
  question,
  search,
  snapchat,
  star,
  starSolid,
  tiktok,
  trash,
  twitter,
  up,
  upload,
  upSolid,
  view,
  youtube,
} from './svgs';

const DEFAULT_ICONS_SET: IconSetType<
  DefaultIconSetNames,
  DefaultIconLevelsNames
> = {
  drag,
  find,
  trash,
  error,
  link,
  menu,
  next,
  desktop,
  mobile,
  previous,
  close,
  question,
  flipCam,
  gallery,
  calendar,
  back,
  login,
  person,
  search,
  place,
  upload,
  download,
  linkedin,
  facebook,
  instagram,
  twitter,
  tiktok,
  pinterest,
  snapchat,
  copy,
  up: {
    regular: up,
    solid: upSolid,
  },
  down: {
    regular: down,
    solid: downSolid,
  },
  star: {
    regular: star,
    solid: starSolid,
  },
  edit,
  view,
  hide,
  check,
  youtube,
};

export default DEFAULT_ICONS_SET;
