import { I18nShape } from '@ui-system/deps';
import { Style } from '@ui-system/interfaces/types';
import { nanoid } from 'nanoid';
import * as React from 'react';

export type TypographyColor =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'success'
  | 'warning';

export type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button'
  | 'overline';

export interface TypographyProps {
  i18n?: I18nShape | string;
  children?: string | string[] | React.ReactNode;
  variant?: TypographyVariant;
  style?: Style;
  onClick?: (param: any | void) => void;
  modifiers?: string;
  suffix?: string;
  prefix?: string;
  values?: Record<string, any>;
  date?: Date | string;
}

export type TypographyType<Other = Record<string, any>> = React.FC<
  TypographyProps & Other
>;

export interface TypographySet<Other = Record<string, any>> {
  H1: TypographyType<Other>;
  H2: TypographyType<Other>;
  H3: TypographyType<Other>;
  H4: TypographyType<Other>;
  H5: TypographyType<Other>;
  H6: TypographyType<Other>;
  Subtitle1: TypographyType<Other>;
  Subtitle2: TypographyType<Other>;
  Body1: TypographyType<Other>;
  Body2: TypographyType<Other>;
  Caption: TypographyType<Other>;
  ButtonText: TypographyType<Other>;
  Overline: TypographyType<Other>;
}

export const TYPOGRAPHY_UUID = nanoid();
