import FastFormProvider from '@lib/fast-form/context/FastFormProvider';
import { ContainerType } from '@ui-system/interfaces-container';
import UI from '@ui-system/ui';
import { noop } from 'lodash';
import React from 'react';

import {
  DidNotReceiveSendAgainType,
  ErrorType,
  SubmitButtonType,
  TANCodeFieldType,
} from '../core';
import DidNotReceiveSendAgain from './DidNotReceiveSendAgain';
import Error from './Error';
import SubmitButton from './SubmitButton';
import TANCodeField from './TANCodeField';
import { TANCodeFormData } from './TANCodeFormData';

interface AuthStatics {
  Container: ContainerType;
  TANCodeField: TANCodeFieldType;
  SubmitButton: SubmitButtonType;
  DidNotReceiveSendAgain: DidNotReceiveSendAgainType;
  Error: ErrorType;
}

const OPTIONS = {
  enableReinitialize: true,
};
interface Props {
  children: React.ReactNode;
  onSuccess?: (values: TANCodeFormData) => void;
  onFail?: () => void;
  id?: string;
}

const FastFormData = new TANCodeFormData();

const TANCode: React.FC<Props> & AuthStatics = ({
  children,
  onSuccess,
  onFail,
  id,
}: Props) => (
  <FastFormProvider
    initialValues={FastFormData}
    onSubmitSuccess={onSuccess}
    onSubmitError={onFail}
    formId={id}
    options={OPTIONS}
  >
    {children}
  </FastFormProvider>
);

TANCode.defaultProps = {
  onSuccess: noop,
  onFail: noop,
  id: undefined,
};
// @ts-ignore
const Container: AuthStatics['Container'] = props => (
  <UI.Container {...props} />
);

TANCode.SubmitButton = SubmitButton;
TANCode.TANCodeField = TANCodeField;
TANCode.DidNotReceiveSendAgain = DidNotReceiveSendAgain;
TANCode.Container = Container;
TANCode.Error = Error;

export default TANCode;
