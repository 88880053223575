import {
  AuthenticateByEmailTanResultDTO,
  AuthenticateByMMWRequestDTO,
} from '@authentication/dtos';
import { State } from '@redux-async-module/interfaces';

export const NAMESPACE = 'auth/authenticate-by-sso';

export type AuthenticateBySSOTokenStartPayload = AuthenticateByMMWRequestDTO;
export type AuthenticateBySSOTokenSuccessPayload = AuthenticateByEmailTanResultDTO;

export interface RootState {
  [NAMESPACE]: State<
    AuthenticateBySSOTokenStartPayload,
    AuthenticateBySSOTokenSuccessPayload
  >;
}
