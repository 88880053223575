import { NotDisposableEmail } from '@emailer/disposable-checker';
import { IsEmail, IsOptional, Length } from 'class-validator-i18n';

export class AuthenticateByBaseRequestDTO {
  @Length(0, 255)
  @IsEmail()
  @NotDisposableEmail()
  email: string;

  @IsOptional()
  recaptchaResponse?: string;
}
