import dotFlow from 'dotenv-flow';

import { getEnvironment } from './utils';

/**
 * This module needs to be imported as the first,
 * in order for configs to be imported from .env files to process.env.
 * https://github.com/kerimdzhanov/dotenv-flow#readme
 */
dotFlow.config({
  node_env: getEnvironment(),
});
