import { applyShadow, Color, css } from '@ui-system/css';
import { SCREEN_HEADER_UUID, SCREEN_UUID, Style } from '@ui-system/interfaces';
import pxPerfect from '@ui-system/px-perfect';
import { makeStyle } from '@ui-system/style';

import { makeFinalStyleHook } from './internal';

const useScreenDefaultStyle = makeStyle<{
  backgroundColor: Color;
}>(({ props, utils, theme }) => ({
  ...css`
    ${utils.applyBackgroundColor(theme, props?.backgroundColor)};
  `,
  ...props,
}));

const useScreenHeaderDefaultStyle = makeStyle<{ style: Style }>(
  ({ props, theme }) => ({
    ...css`
      z-index: 999;
      height: ${pxPerfect(65, false, 'height')};
      background-color: transparent;
      ${applyShadow(theme, 7)};
    `,
    ...props,
  }),
);

export const useScreenStyle = makeFinalStyleHook(
  SCREEN_UUID,
  useScreenDefaultStyle,
);

export const useScreenHeaderStyle = makeFinalStyleHook(
  SCREEN_HEADER_UUID,
  useScreenHeaderDefaultStyle,
);

// const useButtonTextDefaultStyle = makeStyle(() => css``);
// export const useButtonTextStyle = makeFinalStyleHook(
//   BUTTON_TEXT_UUID,
//   useButtonTextDefaultStyle,
// );
