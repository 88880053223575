import { createSelector } from '@redux-basic-module/selector-utils';

import { NAMESPACE, State } from './types';

export const dataSelector = createSelector<State['data']>([NAMESPACE, 'data']);
export const panelsSelector = createSelector<State['panels']>([
  NAMESPACE,
  'panels',
]);
export const loadingSelector = createSelector<State['isLoading']>([
  NAMESPACE,
  'isLoading',
]);
export const selectedSelector = createSelector<State['selected']>([
  NAMESPACE,
  'selected',
]);
