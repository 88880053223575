import { ReactText } from 'react';

import useFastFormProviderContext from './useFastFormProviderContext';

function useFormId(): ReactText {
  const context = useFastFormProviderContext();
  return context.formId;
}

export default useFormId;
