import { LogLevel } from '@logger/core';
import { REQUEST_BLOCKED_ERROR } from '@resource/i18n-shapes';
import {
  HttpError,
  HttpErrorJSON,
  isHttpError,
} from '@service-layer-utils/errors';

export class RequestBlockedError extends HttpError implements isHttpError {
  private readonly internalMessage: string;

  constructor(internalMessage: string) {
    super(0);
    this.internalMessage = internalMessage;
  }

  toJSON(): HttpErrorJSON {
    return this.convertToHttpErrorJSON({
      status: this.httpCode,
      type: RequestBlockedError.name,
      reason: this.internalMessage,
      i18n: REQUEST_BLOCKED_ERROR,
      level: LogLevel.INFO,
    });
  }
}
