import { useMemo } from 'react';
import { MediaQueryAllQueryable, useMediaQuery } from 'react-responsive';

import { mediaQueryParser, mediaQueryParserNoop } from './parser';
import { MediaQueries } from './types';

const XS: { query?: string } = {
  query: '(max-width: 575px)',
};
const SM: { query?: string } = {
  query: '(max-width: 767px)',
};
const MD: { query?: string } = {
  query: '(max-width: 991px)',
};
const LG: { query?: string } = {
  query: '(max-width: 1199.9px)',
};
const XL: { query?: string } = {
  query: '(min-width: 1200px)',
};
const RETINA: Partial<MediaQueryAllQueryable> = { minResolution: '2dppx' };
const PORTRAIT: Partial<MediaQueryAllQueryable> = {
  orientation: 'portrait',
};
const LANDSCAPE: Partial<MediaQueryAllQueryable> = {
  orientation: 'landscape',
};

// // Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) { ... }
//
// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) { ... }
//
// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) { ... }
//
// // Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) { ... }

export function useMediaQueries(): MediaQueries {
  const xs = useMediaQuery(XS);
  const sm = useMediaQuery(SM);
  const md = useMediaQuery(MD);
  const lg = useMediaQuery(LG);
  const xl = useMediaQuery(XL);
  const isPortrait = useMediaQuery(PORTRAIT);
  const isLandscape = useMediaQuery(LANDSCAPE);
  const isRetina = useMediaQuery(RETINA);
  return useMemo(
    () => ({
      xs: xs ? mediaQueryParser : mediaQueryParserNoop,
      sm: sm ? mediaQueryParser : mediaQueryParserNoop,
      md: md ? mediaQueryParser : mediaQueryParserNoop,
      lg: lg ? mediaQueryParser : mediaQueryParserNoop,
      xl: xl ? mediaQueryParser : mediaQueryParserNoop,
      portrait: isPortrait ? mediaQueryParser : mediaQueryParserNoop,
      landscape: isLandscape ? mediaQueryParser : mediaQueryParserNoop,
      retina: isRetina ? mediaQueryParser : mediaQueryParserNoop,
    }),
    [isLandscape, isPortrait, isRetina, lg, md, sm, xl, xs],
  );
}
