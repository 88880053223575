import { css } from '@ui-system/css';
import {
  ComponentModifiersFunction,
  Modifiers,
  Style,
  TYPOGRAPHY_UUID,
} from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';
import { U } from '@utils/ts';

import { useComponentFinalStyle } from './internal';

const useDefaultStyle = makeStyle(
  ({ theme }) => css`
    color: ${theme.typography.colors[theme.mode].onBackground};
    font-family: ${theme.typography.fontFamily.regular};
  `,
);

export const useTypographyStyle = (
  styleFromProps: U.Nullable<Style>,
  modifiers: U.Nullable<Modifiers>,
  componentModifiers: ComponentModifiersFunction,
): Style => {
  const defaultStyle = useDefaultStyle();
  return useComponentFinalStyle(
    defaultStyle,
    styleFromProps,
    modifiers,
    componentModifiers,
    TYPOGRAPHY_UUID,
  );
};
