import size from 'lodash/size';
import slice from 'lodash/slice';
import { useCallback, useState } from 'react';

function canGoBack(current: number) {
  return current > 0;
}
function canGoNext(ammount: number, current: number, itemNumbers: number) {
  return itemNumbers + current < ammount;
}
export function useControledCarousel<T extends any[]>(
  arr: T,
  itemNumbers = 3,
  initial = 0,
): {
  goNext: VoidFunction;
  goBack: VoidFunction;
  isPossibleToGoBack: boolean;
  isPossibleToGoNext: boolean;
  items: T;
} {
  const [current, setCurrent] = useState(initial);
  const goNext = useCallback(() => {
    setCurrent(prev => {
      if (!canGoNext(size(arr), prev, itemNumbers)) return prev;
      return prev + 1;
    });
  }, [arr, itemNumbers]);
  const goBack = useCallback(() => {
    setCurrent(prev => {
      if (!canGoBack(prev)) return prev;
      return prev - 1;
    });
  }, []);

  return {
    goNext,
    goBack,
    isPossibleToGoBack: canGoBack(current),
    isPossibleToGoNext: canGoNext(size(arr), current, itemNumbers),
    items: slice(arr, current, current + itemNumbers) as T,
  };
}
