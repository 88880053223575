import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getFormCurrentErrorsSelector } from '../../stateSelector';
import { RootState } from '../../types';
import useFormId from '../useFormId';

function useFormCurrentErrors<T>(): T {
  const formId = useFormId();
  const selector = useCallback(
    (state: RootState<T>) => getFormCurrentErrorsSelector(state, formId),
    [formId],
  );
  return useSelector(selector);
}

export default useFormCurrentErrors;
