import { Contact } from '@pro-page-components/contact';
import { HighlightsREC } from '@pro-page-components/highlights-rec';
import { RecAlphaBlackSeries } from '@pro-page-components/rec-alpha-black-series';
import { RecAlphaPure2Series } from '@pro-page-components/rec-alpha-pure-2-series';
import { RecAlphaPureRSeries } from '@pro-page-components/rec-alpha-pure-r-series';
import { RecAlphaPureRxSeries } from '@pro-page-components/rec-alpha-pure-rx-series';
import { RecAlphaPureSeries } from '@pro-page-components/rec-alpha-pure-series';
import { RecAlphaSeries } from '@pro-page-components/rec-alpha-series';
import { RecCertifiedSolarProfessionals } from '@pro-page-components/rec-certified-solar-professionals';
import { RecGroup } from '@pro-page-components/rec-group';
import { RecNPeak2BlackSeries } from '@pro-page-components/rec-n-peak-2-black-series';
import { RecNPeak2Series } from '@pro-page-components/rec-n-peak-2-series';
import { RecNPeak3BlackSeries } from '@pro-page-components/rec-n-peak-3-black-series';
import { RecNPeakBlackSeries } from '@pro-page-components/rec-n-peak-black-series';
import { RecNPeakSeries } from '@pro-page-components/rec-n-peak-series';
import { RecProTrustWarranty } from '@pro-page-components/rec-pro-trust-warranty';
import { RecProducts } from '@pro-page-components/rec-products';
import { RecTwinPeak4BlackSeries } from '@pro-page-components/rec-twin-peak-4-black-series';
import { RecTwinPeak4Series } from '@pro-page-components/rec-twin-peak-4-series';
import { RecTwinPeak5BlackSeries } from '@pro-page-components/rec-twin-peak-5-black-series';
import { RecTwinPeak5Series } from '@pro-page-components/rec-twin-peak-5-series';
import { SocialMedia } from '@pro-page-components/social-media';
import { YourCompany } from '@pro-page-components/your-company';
import { ComponentConfig, ComponentForEditor } from 'react-page-editor/state';

export const COMPONENTS_MAP: Record<string, ComponentForEditor> = {
  [HighlightsREC.config.id]: HighlightsREC,
  [YourCompany.config.id]: YourCompany,
  [RecGroup.config.id]: RecGroup,
  [RecCertifiedSolarProfessionals.config.id]: RecCertifiedSolarProfessionals,
  [RecProTrustWarranty.config.id]: RecProTrustWarranty,
  [RecProducts.config.id]: RecProducts,
  [RecAlphaPureSeries.config.id]: RecAlphaPureSeries,
  [RecAlphaPure2Series.config.id]: RecAlphaPure2Series,
  [RecAlphaPureRSeries.config.id]: RecAlphaPureRSeries,
  [RecAlphaPureRxSeries.config.id]: RecAlphaPureRxSeries,
  [RecAlphaSeries.config.id]: RecAlphaSeries,
  [RecAlphaBlackSeries.config.id]: RecAlphaBlackSeries,
  [RecNPeakSeries.config.id]: RecNPeakSeries,
  [RecNPeakBlackSeries.config.id]: RecNPeakBlackSeries,
  [RecNPeak2Series.config.id]: RecNPeak2Series,
  [RecNPeak2BlackSeries.config.id]: RecNPeak2BlackSeries,
  [RecNPeak3BlackSeries.config.id]: RecNPeak3BlackSeries,
  [RecTwinPeak4Series.config.id]: RecTwinPeak4Series,
  [RecTwinPeak4BlackSeries.config.id]: RecTwinPeak4BlackSeries,
  [RecTwinPeak5Series.config.id]: RecTwinPeak5Series,
  [RecTwinPeak5BlackSeries.config.id]: RecTwinPeak5BlackSeries,
  [Contact.config.id]: Contact,
  [SocialMedia.config.id]: SocialMedia,
};

export const COMPONENTS_CONFIGS: ComponentConfig[] = [
  HighlightsREC.config,
  YourCompany.config,
  RecGroup.config,
  RecCertifiedSolarProfessionals.config,
  RecProTrustWarranty.config,
  RecProducts.config,
  RecAlphaPureSeries.config,
  RecAlphaPure2Series.config,
  RecAlphaPureRSeries.config,
  RecAlphaPureRxSeries.config,
  RecAlphaSeries.config,
  RecAlphaBlackSeries.config,
  RecNPeakSeries.config,
  RecNPeakBlackSeries.config,
  RecNPeak2Series.config,
  RecNPeak2BlackSeries.config,
  RecNPeak3BlackSeries.config,
  RecTwinPeak4Series.config,
  RecTwinPeak4BlackSeries.config,
  RecTwinPeak5Series.config,
  RecTwinPeak5BlackSeries.config,
  Contact.config,
  SocialMedia.config,
];

export type RecProPageComponents = typeof COMPONENTS_MAP;
