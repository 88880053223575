import memoize from 'lodash/memoize';

export const REGEX_TESTER_PATTERN = /^\/(.*)\/(.*)$/;

export function toString(value: RegExp): string {
  return value.toString();
}

function internalFromString(value: string): RegExp {
  const match = value.match(REGEX_TESTER_PATTERN);
  if (match) {
    const [, pattern, flags] = match;
    return new RegExp(pattern, flags);
  }
  throw new Error(`"${value}" is not a regular expression`);
}

export const fromString = memoize(internalFromString);
