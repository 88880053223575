/* eslint-disable no-nested-ternary */
import { HybridCache } from '@cache/hybrid';
import { CachedResult } from '@cache/types';
import logger, { LogLevel } from '@logger/core';
import {
  EpicConfig,
  RequestStartMeta,
  RequestType,
  Selectors,
} from '@redux-async-module/interfaces';
import { Action, BaseMeta, Epic } from '@redux-basic-module/interfaces';
import { ofType } from '@redux-operators/of-type';
import { HttpErrorJSON } from '@service-layer-utils/errors';
import { call } from '@shared-utils/function';
import { plainToClass } from 'class-transformer';
import { MeasuredTime, measureTime } from 'hybrid-measure-time';
import defaultTo from 'lodash/defaultTo';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import moment from 'moment';
import qs from 'qs';
import { StateObservable } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import { ajax, AjaxError } from 'rxjs/ajax';
import {
  catchError,
  concatMap,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

import { fixEpicToString } from './fixEpicToString';
import { RequestBlockedError } from './RequestBlockedError';

const reduxLog = logger('logic-utils');

type Data<I> = Record<string, any> & { response: I };

const DEFAULT_LOG_LEVELS = {
  start: LogLevel.DEBUG,
  success: LogLevel.INFO,
  error: LogLevel.ERROR,
};

const DEFAULT_LOG_OPTIONS = {
  requestParams: false,
  result: false,
  levels: DEFAULT_LOG_LEVELS,
};

export function createEpic<
  RootState extends Record<string, any>,
  StartPayload,
  SuccessPayload,
  Meta extends BaseMeta = BaseMeta
>(
  epicConfig: EpicConfig<RootState, StartPayload, SuccessPayload, Meta>,
  selectors?: Selectors<StartPayload, SuccessPayload>,
): [Epic, Epic] {
  const logOptions = epicConfig.logs || DEFAULT_LOG_OPTIONS;
  const log = reduxLog.extend(epicConfig.actions.start.type);
  const logLevels = logOptions.levels || DEFAULT_LOG_LEVELS;

  const cacheService = new HybridCache({
    namespace: epicConfig.actions.requestStart.type,
  });

  function logReceiveAction(action: Action<string, StartPayload, Meta>) {
    log[logLevels.start]({
      message: `Received action type=${action.type}`,
      params: {
        actionType: action.type,
        traceId: action.meta?.actionId,
        actionId: action.meta?.actionId,
        meta: action.meta,
      },
    });
  }

  function logStart(
    { payload, meta }: Action<string, StartPayload, Meta>,
    path: string,
    method: string,
  ) {
    log[logLevels.start]({
      message: `Will perform ajax request on ${method} ${path}`,
      params: {
        actionType: epicConfig.requestOptions.type,
        meta,
        path,
        payload: logOptions.requestParams
          ? isFunction(logOptions.requestParams)
            ? logOptions.requestParams(payload)
            : payload
          : undefined,
        actionId: meta?.actionId,
      },
    });
  }

  function logSuccess(
    { payload, meta }: Action<string, StartPayload, Meta>,
    path: string,
    method: string,
    data: any,
    getElapsed: () => MeasuredTime,
  ) {
    log[logLevels.success]({
      message: `Success on ajax response on ${method} ${path} in ${
        getElapsed().milliseconds
      }ms`,
      params: {
        actionType: epicConfig.requestOptions.type,
        meta,
        path,
        result: logOptions.result
          ? isFunction(logOptions.result)
            ? logOptions.result(data)
            : payload
          : undefined,
        actionId: meta?.actionId,
      },
    });
  }

  function logError(
    { meta }: Action<string, StartPayload, Meta>,
    path: string,
    method: string,
    level: LogLevel,
    error: any,
    getElapsed: () => MeasuredTime,
  ) {
    log[level]({
      message: `Error on ajax response on ${method} ${path}, error=${
        error?.type || error?.name
      } in ${getElapsed().milliseconds}ms`,
      params: {
        actionType: epicConfig.requestOptions.type,
        meta,
        path,
        actionId: meta?.actionId,
        error,
      },
    });
  }

  function createFilterEpic(state$) {
    return function filterEpic({ payload }) {
      if (epicConfig.filter) {
        return epicConfig.filter(payload, state$.value);
      }
      return true;
    };
  }

  function createCacheKey(payload: any) {
    const prefix = epicConfig.actions.start.type;
    if (isEmpty(payload)) {
      return prefix;
    }
    const key = qs.stringify(payload);
    return `${prefix}:${key}`;
  }

  async function loadResultFromCache<T>(
    payload: any,
  ): Promise<CachedResult<T>> {
    return cacheService.get(createCacheKey(payload));
  }

  async function storeResultIntoCache(
    payload: any,
    meta: any,
    result: any,
  ): Promise<void> {
    const msTtl = meta?.cache?.msTtl || epicConfig.cache?.msTtl;
    if (!msTtl) {
      return;
    }
    if (isEmpty(result)) {
      return;
    }
    await cacheService.set(createCacheKey(payload), result, msTtl);
  }

  const mainEpic: Epic<StartPayload, SuccessPayload> = (action$, state$) =>
    // @ts-ignore
    action$.pipe(
      ofType(epicConfig.actions.start.type),
      tap(logReceiveAction),
      // @ts-ignore
      filter(createFilterEpic(state$)),
      switchMap((action: Action<string, StartPayload, Meta>) => {
        const getElapsed = measureTime();

        const { payload, meta } = action;
        const body = defaultTo(
          call(epicConfig.requestOptions.getBody, payload, state$.value),
          payload,
        );
        const headers = {
          'Content-Type': 'application/json',
          'X-Action-Trace-Context': meta?.actionId,
          ...defaultTo(
            call(epicConfig.requestOptions.getHeaders, payload, state$.value),
            {},
          ),
        };
        const path = epicConfig.requestOptions.getPath(payload, state$.value);
        const method = epicConfig.requestOptions.type;

        logStart(action, path, method);

        const isGetRequest = method === RequestType.get;
        const promise = isGetRequest
          ? ajax.get(path, headers)
          : ajax.post(path, body, headers);

        return from(promise).pipe(
          map((data: Data<SuccessPayload>) => {
            const responseData = data.response;
            logSuccess(action, path, method, responseData, getElapsed);
            storeResultIntoCache(payload, meta, responseData).then(noop);
            return epicConfig.actions.success(responseData, meta);
          }),
          catchError(error => {
            let actualError = error;
            let level: LogLevel = logLevels.error;
            if (error instanceof AjaxError) {
              if (error.status === 0) {
                actualError = new RequestBlockedError(
                  'Response status 0 error',
                ).toJSON();
              } else {
                actualError = plainToClass(HttpErrorJSON, error.response);
                if (actualError?.level) {
                  level = actualError.level;
                }
              }
            }
            const foundError = actualError || error?.response || error;
            logError(action, path, method, level, foundError, getElapsed);
            return of(epicConfig.actions.error(foundError, meta));
          }),
        );
      }),
    );

  function hasAlreadyCachedDataIntoStore(
    expiresIn: Date,
    state$: StateObservable<any>,
  ) {
    if (!selectors) return false;
    if (!expiresIn) return false;
    const lastDate = selectors.lastCacheDate(state$.value);
    if (!lastDate) return false;
    return moment(lastDate).isBefore(expiresIn);
  }

  const requestEpic: Epic<StartPayload> = (action$, state$) =>
    action$.pipe(
      ofType(epicConfig.actions.requestStart.type),
      tap(logReceiveAction),
      // @ts-ignore
      filter(createFilterEpic(state$)),
      switchMap(
        ({
          payload,
          meta,
        }: // actionId,
        Action<string, StartPayload, RequestStartMeta & Meta>) => {
          const msTtl = meta?.cache?.msTtl || epicConfig.cache?.msTtl;
          const disabled = meta?.cache?.ignoreCache;

          if (msTtl && !disabled) {
            const promise = loadResultFromCache<SuccessPayload>(payload);
            return from(promise).pipe(
              concatMap((result: CachedResult<SuccessPayload>) => {
                const lastCacheDate = Date.now();
                if (
                  result.success &&
                  hasAlreadyCachedDataIntoStore(result.expiresIn, state$)
                ) {
                  return EMPTY;
                }
                if (result.success) {
                  return of(
                    epicConfig.actions.success(result.data, {
                      ...meta,
                      lastCacheDate,
                    }),
                  );
                }
                return of(epicConfig.actions.start(payload, meta));
              }),
              catchError(error => {
                log.error({
                  message: `Error loading data from cache`,
                  params: {
                    error,
                  },
                });
                return of(epicConfig.actions.start(payload, meta));
              }),
            );
          }
          return of(epicConfig.actions.start(payload, meta));
        },
      ),
    );
  return [fixEpicToString(mainEpic), fixEpicToString(requestEpic)];
}
