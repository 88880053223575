import {
  createRecProductVideoComponents,
  RecProduct,
  RecProductProps,
} from '@pro-page-components/rec-product';
import { useChildrenVisibilityEditor } from '@pro-page-components/utils/useChildrenVisibility';
import * as React from 'react';
import { ComponentForEditor } from 'react-page-editor/state';

import config from './config.json';
import defaultProps from './defaultProps.json';

const { Video, VideoEditor } = createRecProductVideoComponents({
  name: 'REC Alpha Pure-R Series Video',
  id: 'REC_ALPHA_PURE_R_VIDEO',
  path: '.....',
  parent: config.id,
});

export const RecAlphaPureRSeries: ComponentForEditor<RecProductProps> = (
  props: RecProductProps,
) => <RecProduct VideoComponent={Video} {...props} />;

export const RecAlphaPureRSeriesEditor: ComponentForEditor<RecProductProps> = (
  props: RecProductProps,
) => {
  // eslint-disable-next-line no-underscore-dangle,react/destructuring-assignment
  const visible = useChildrenVisibilityEditor(props.__config);
  if (!visible) return null;
  return (
    <RecAlphaPureRSeries
      scale={0.9}
      skipVisibilityCheck
      VideoComponent={VideoEditor}
      {...props}
    />
  );
};

// @ts-ignore
RecAlphaPureRSeries.children = [Video];
RecAlphaPureRSeries.defaultProps = (defaultProps as unknown) as RecProductProps;
RecAlphaPureRSeries.config = config;
RecAlphaPureRSeriesEditor.defaultProps = (defaultProps as unknown) as RecProductProps;
RecAlphaPureRSeriesEditor.config = config;
// @ts-ignore
RecAlphaPureRSeriesEditor.children = [VideoEditor];
