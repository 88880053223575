import {
  createArgsArray,
  registerDecorator,
  SINGLETON,
  ValidationOptions,
} from 'class-validator-i18n';
import { defaultTo } from 'lodash';

const MUST_ACCEPT_TERMS_DEFAULT_OPTIONS = {
  message: 'You must accept the terms and conditions',
};

export function MustAcceptTerms(validationOptions?: ValidationOptions) {
  return function register(
    object: Record<string, any>,
    propertyName: string,
  ): ReturnType<typeof registerDecorator> {
    const customValidationOptions = {
      ...validationOptions,
      message: validationArgs =>
        SINGLETON.translate(
          MustAcceptTerms,
          createArgsArray(validationOptions),
          validationArgs,
          'MustAcceptTerms',
        ),
    };
    registerDecorator({
      name: 'mustAcceptTerms',
      target: object.constructor,
      propertyName,
      constraints: [],
      options: {
        ...MUST_ACCEPT_TERMS_DEFAULT_OPTIONS,
        ...defaultTo(customValidationOptions, {}),
      },
      validator: {
        validate(value: any) {
          return typeof value === 'boolean' && value;
        },
      },
    });
  };
}
