import { Interpolation } from '@emotion/serialize';
import { hashObject } from '@hasher/object-hash';
import forEach from 'lodash/forEach';
import join from 'lodash/join';
import memoize from 'lodash/memoize';
import nth from 'lodash/nth';
import { CSSProperties } from 'react';
import parse from 'style-to-js';

export { Global } from '@emotion/core';
export { default as styled } from '@emotion/styled';

export function internalCss(
  template: TemplateStringsArray,
  ...args: Array<Interpolation<string>>
): CSSProperties {
  if (typeof template === 'string') {
    return parse(`${template}`);
  }
  const style: string[] = [];
  forEach(template, (line, i) => {
    style.push(join([line, nth(args, i)], ''));
  });
  return parse(join(style, ''));
}

export const css = memoize(internalCss, (template, ...args) =>
  hashObject({ template, args }),
);
