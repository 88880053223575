import { ConsoleLoggerTransportOptions } from '@logger/console-transport';
import { LogLevel } from '@logger/core';
import { DEFAULT_HEX_COLORS } from '@logger/utils';
import memoize from 'lodash/memoize';
import randomColor from 'random-color';

import { normalizeLevel } from './normalizeLevel';

function applyCss(text: string, colorHex: string): string[] {
  return [
    `%c${text}`,
    `color: #fff;font-weight:bold; background-color: ${colorHex}; padding: 3px 3px;`,
  ];
}

const generateRandomColor = memoize((_s: string) => randomColor().hexString());

export const DEFAULT_BROWSER_CONSOLE_LOGGER_TRANSPORT_OPTIONS: ConsoleLoggerTransportOptions = {
  // filter: (msg) => return true,
  // getTraceId: msg => return null,
  formatters: {
    group: (group: string): string[] =>
      applyCss(group, generateRandomColor(group)),
    level: (level: LogLevel): string[] => {
      const color =
        DEFAULT_HEX_COLORS[level] || DEFAULT_HEX_COLORS[LogLevel.INFO];
      return applyCss(normalizeLevel(level), color);
    },
    message: (m: string): string[] => applyCss(m, '#00bff9'),
    date: (d: string): string[] => applyCss(d, '#3e7df3'),
  },
};
