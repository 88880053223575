export const SERVICE_CONTEXT_ADDED_LOG_PARAMS = [
  'ctx',
  'cached',
  'cacheKey',
  'validationErrors',
  'time',
  'actionId',
  'traceId',
  'abilities',
];
