import { Theme } from '@ui-system/theme';
import { getColorByName } from '@ui-system/theme/colors';
import { F, U } from '@utils/ts';

import createFromProps from '../create-from-props';
import { Color, FromProps } from '../types';

const applyBackgroundColor: F.Function<[Theme, U.Nullable<Color>]> &
  FromProps = (theme, color) => {
  const hex = getColorByName(color, theme.colors[theme.mode]);
  if (hex) return `background-color: ${hex};`;
  return 'background-color: transparent;';
};

applyBackgroundColor.fromProps = createFromProps(applyBackgroundColor, 'bg');

export default applyBackgroundColor;
