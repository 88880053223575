import { I18nShape } from '@i18n/core';
import { DE } from '@i18n/utils';
import { AUTHENTICATION as bundleName } from '@resource/bundle-names';

const NAMESPACE = 'authentication';

export const DID_NOT_RECEIVE: I18nShape = {
  key: `${NAMESPACE}.tanCode.didNotReceive`,
  text: 'Did not receive',
  bundleName,
  translation: {
    [DE]: 'Leider nicht empfangen',
  },
  metadata: {
    description: 'Did not receive tan code helper text',
  },
};

export const SEND_AGAIN: I18nShape = {
  key: `${NAMESPACE}.tanCode.sendAgain`,
  text: 'SEND AGAIN',
  bundleName,
  translation: {
    [DE]: 'Nochmal schicken',
  },
  metadata: {
    description: 'Send tan code again helper link',
  },
};

export const RESEND_SECURITY_TOKEN: I18nShape = {
  key: `${NAMESPACE}.tanCode.resendSecurityToken`,
  text: 'Your security token has been send',
  bundleName,

  metadata: {
    description: 'Send tan code again helper link',
  },
};

export const SECURITY_REASONS_EXPLAIN: I18nShape = {
  key: `${NAMESPACE}.tanCode.securityReasonsExplain`,
  text:
    'For security reasons, please enter here the security token we just sent to your email.',
  bundleName,

  metadata: {
    description: 'Explaining about the security token sent by mail',
  },
};

export const THE_PROVIDED_SECURITY_TOKEN_APPEARS_TO_BE_INVALID: I18nShape = {
  key: `${NAMESPACE}.tanCode.theProvidedSecurityTokenAppearsToBeInvalid`,
  text:
    'The provided security token appears to be invalid. Please try again or resend the token.',
  bundleName,

  metadata: {
    description: 'Explaining about the security token beying invalid',
  },
};

export const ASTERISTICS_PLACEHOLDER: I18nShape = {
  key: `${NAMESPACE}.tanCode.asteristicsPlaceholder`,
  text: '********',
  bundleName,
  translation: {},
};
