import { useCurrentLanguage } from '@resource/redux-language';
import { extractLanguageAndCountryFromLocaleString } from '@resource/utils';
import { useEffect, useRef } from 'react';
import { ComponentConfig, useSetComponentProps } from 'react-page-editor/state';

export function useSetPropsByLanguage<P>(
  componentConfig: ComponentConfig,
  propsMap: Record<string, P>,
  midleware: (props: P) => P = props => props,
): void {
  const ref = useRef(null);
  const language = useCurrentLanguage();
  const set = useSetComponentProps();
  const parsedLanguage = extractLanguageAndCountryFromLocaleString(language)
    .language;
  useEffect(() => {
    if (!ref.current && propsMap && propsMap[parsedLanguage]) {
      set(componentConfig, midleware(propsMap[parsedLanguage]));
      ref.current = true;
    }
  }, [componentConfig, parsedLanguage, midleware, propsMap, set]);
}
