import { Bundle, I18nShape, Locale } from '@i18n/core';
import { findMessage, parseValues } from '@i18n/utils';

export function defaultMessageResolver(
  message: string | null,
  locale?: Locale,
  values?: Record<string, any>,
): string {
  if (!message) return '';
  return parseValues(message, values, locale);
}

export default function defaultResolver(
  i18n: I18nShape | null,
  bundle: Bundle,
  locale?: Locale,
  values?: Record<string, any>,
): string {
  const message = findMessage(i18n, bundle);
  return defaultMessageResolver(message, locale, values);
}
