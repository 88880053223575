import { createLogger } from './createLogger';

export * from './disable';
export * from './enable';
export * from './getMessageChannel';
export * from './getNamespaces';
export * from './LogLevel';
export * from './subscribe';
export * from './types';
export default createLogger;
export const defaultLogger = createLogger('');
