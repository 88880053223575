import {
  AuthenticateByEmailTanResultDTO,
  SignInByEmailRequestDTO,
} from '@authentication/dtos';
import { State } from '@redux-async-module/interfaces';

export const NAMESPACE = 'auth/sign-in-by-email';

export type SignInByEmailStartPayload = SignInByEmailRequestDTO;
export type SignInByEmailSuccessPayload = AuthenticateByEmailTanResultDTO;

export interface RootState {
  [NAMESPACE]: State<SignInByEmailStartPayload, SignInByEmailSuccessPayload>;
}
