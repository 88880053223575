import { containsAny } from '@lib/utils-string';
import {
  createArgsArray,
  registerDecorator,
  SINGLETON,
  ValidationOptions,
} from 'class-validator-i18n';
import defaultTo from 'lodash/defaultTo';

const VALID_URL_PATH = {
  message: 'Invalid url path',
};

const CHARS = [
  '@',
  '#',
  '_',
  '*',
  '=',
  '?',
  ';',
  ':',
  '.',
  ' ',
  '!',
  '€',
  '~',
  '^',
  '/',
  '<',
  '>',
  '|',
  '(',
  ')',
];

export function ValidUrlPath(validationOptions?: ValidationOptions) {
  return function register(
    object: Record<string, any>,
    propertyName: string,
  ): ReturnType<typeof registerDecorator> {
    const customValidationOptions = {
      ...validationOptions,
      message: validationArgs =>
        SINGLETON.translate(
          ValidUrlPath,
          createArgsArray(validationOptions),
          validationArgs,
          'ValidUrlPath',
        ),
    };
    registerDecorator({
      name: 'validUrlPath',
      target: object.constructor,
      propertyName,
      constraints: [],
      options: {
        ...VALID_URL_PATH,
        ...defaultTo(customValidationOptions, {}),
      },
      validator: {
        validate(value: any) {
          return !containsAny(value, ...CHARS);
        },
      },
    });
  };
}
