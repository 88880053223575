import { useHasAValidLogin } from '@authentication/redux-auth-data/hooks';
import { APP_VERSION } from '@controller-layer-utils/core';
import { useNavigateTo } from '@lib/navigation';
import { BACK_TO_TOP, CONTACT_REC, COPYRIGHT_REC_SOLAR } from '@rec/i18n';
import { ScaleProps, SectionWrapper } from '@rec/section-wrapper';
import {
  COOKIES_SETTINGS,
  IMPRINT,
  PRIVACY_POLICY,
  TERMS_OF_USE,
} from '@resource/i18n-shapes';
import { makeStyle } from '@ui-system/style';
import UI from '@ui-system/ui';
import { openUrl } from 'browser';
import noop from 'lodash/noop';
import * as React from 'react';
import {
  ComponentForEditor,
  useIsEditorEmpty,
  useViewModeContext,
} from 'react-page-editor/state';
import {
  FacebookButton,
  InstagramButton,
  LinkedinButton,
  TwitterButton,
} from 'rec-propage-editor/src/components/SocialMediaButtons';
import { useCookieConsentContext } from 'rec-propage-www/src/components/cookie-consent';
import { Container } from 'typedi';

import config from './configFooter.json';

const REC_LINKS = {
  imprint: () => openUrl('https://usa.recgroup.com/imprint'),
  termsOfUse: () => openUrl('https://usa.recgroup.com/terms-use'),
  privacyPolice: () => openUrl('https://usa.recgroup.com/privacy-policy'),
  contactRec: () => openUrl('https://usa.recgroup.com/contact'),
  cookiesSettings: () => openUrl('https://usa.recgroup.com/terms-use'),
  facebook: () => openUrl('https://www.facebook.com/RECGroupSolar/'),
  instagram: () => openUrl('https://www.instagram.com/RECGroupSolar/'),
  twitter: () => openUrl('https://twitter.com/recgroupmedia'),
  youtube: () => openUrl('https://www.youtube.com/user/RECGroupMedia'),
  linkedin: () => openUrl('https://www.linkedin.com/company/rec_'),
};
const useStyle = makeStyle(
  ({ css, theme, utils }) => css`
    border: 2px solid ${theme.colors[theme.mode].gray.A200};
    ${utils.applyBackgroundColor(theme, 'transparent')};
  `,
);

const ScrollTop = props => (
  <UI.Container
    direction="row"
    align="center"
    gap={1}
    onClick={() => window.scroll({ top: 0, behavior: 'smooth' })}
    {...props}
  >
    <UI.Typography
      i18n={BACK_TO_TOP}
      modifiers="primary, uppercase"
      variant="subtitle2"
    />
    <UI.Typography modifiers="grayA200, uppercase">|</UI.Typography>
    <UI.Container>
      <UI.Icon name="up" color="primary" size={16} />
    </UI.Container>
  </UI.Container>
);

const FOOTER_VERSION = Container.get(APP_VERSION);

interface Footer {
  hideScroll?: boolean;
}
export const Footer: ComponentForEditor<ScaleProps & Footer> = ({
  scale,
  hideScroll,
}: ScaleProps & Footer) => {
  const style = useStyle();
  const { isMobile } = useViewModeContext();
  const { toggle } = useCookieConsentContext();
  const hasAValidLogin = useHasAValidLogin();
  const navigate = useNavigateTo();
  return (
    <UI.Page.Footer>
      <SectionWrapper bg="black" scale={scale} h={isMobile ? 'auto' : 100}>
        <UI.Container
          f={1}
          direction={isMobile ? 'column' : 'row'}
          gap={isMobile ? 4 : null}
          align="center"
          justify="space-between"
          p="4, 2"
        >
          <UI.Container
            direction="column"
            gap={isMobile ? 3 : 1}
            w={isMobile ? '100%' : null}
            align={isMobile ? 'center' : null}
          >
            <UI.Container gap={2}>
              <UI.Caption i18n={COPYRIGHT_REC_SOLAR} modifiers="grayA200" />
              <UI.Caption
                modifiers="grayA200"
                onClick={hasAValidLogin ? () => navigate('/version') : noop}
              >
                v{FOOTER_VERSION}
              </UI.Caption>
            </UI.Container>
            <UI.Container
              direction={isMobile ? 'column' : 'row'}
              gap={3}
              align={isMobile ? 'center' : null}
            >
              <UI.Caption
                i18n={IMPRINT}
                modifiers="grayA200, underline"
                onClick={REC_LINKS.imprint}
              />
              <UI.Caption
                i18n={TERMS_OF_USE}
                modifiers="grayA200, underline"
                onClick={REC_LINKS.termsOfUse}
              />
              <UI.Caption
                i18n={PRIVACY_POLICY}
                modifiers="grayA200, underline"
                onClick={REC_LINKS.privacyPolice}
              />
              <UI.Caption
                i18n={CONTACT_REC}
                modifiers="grayA200, underline"
                onClick={REC_LINKS.contactRec}
              />
              <UI.Caption
                i18n={COOKIES_SETTINGS}
                modifiers="grayA200, underline"
                onClick={toggle}
              />
            </UI.Container>
          </UI.Container>
          <UI.Container
            direction="row"
            gap={isMobile ? 2 : 3}
            w={isMobile ? '100%' : null}
            justify={isMobile ? 'center' : null}
          >
            <FacebookButton
              style={style}
              color="gray.A200"
              size={25}
              onClick={REC_LINKS.facebook}
            />
            <InstagramButton
              style={style}
              color="gray.A200"
              size={25}
              onClick={REC_LINKS.instagram}
            />
            <TwitterButton
              style={style}
              color="gray.A200"
              size={25}
              onClick={REC_LINKS.twitter}
            />
            <LinkedinButton
              style={style}
              color="gray.A200"
              size={25}
              onClick={REC_LINKS.linkedin}
            />
          </UI.Container>
          {!hideScroll && <ScrollTop />}
        </UI.Container>
      </SectionWrapper>
    </UI.Page.Footer>
  );
};

export const FooterEditor: React.FC = () => {
  const isEditorEmpty = useIsEditorEmpty();
  if (isEditorEmpty) return null;
  return <Footer scale={0.9} hideScroll />;
};

Footer.config = config;
