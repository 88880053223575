import { mapAsync } from '@shared-utils/array';
import axios, { AxiosRequestConfig } from 'axios';
import imageCompression from 'browser-image-compression';
import last from 'lodash/last';
import split from 'lodash/split';
import { nanoid } from 'nanoid';
import { useCallback, useState } from 'react';
import { useDeepCompareCallback } from 'use-deep-compare';

interface CompressOptions {
  maxSizeMB?: number;
  maxWidthOrHeight?: number;
  useWebWorker?: boolean;
  maxIteration?: number;
  exifOrientation?: number;
  onProgress?: (progress: number) => void;
  fileType?: string;
  initialQuality?: number;
}

interface UploadFileToGCPConfig {
  bucketName: string;
  fileName?: string;
  compressOptions?: CompressOptions;
}

export const DEFAULT_COMPRESS_OPTIONS: CompressOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1024,
  useWebWorker: true,
};

export interface UploadInfo {
  kind: string;
  id: string;
  selfLink: string;
  mediaLink: string;
  name: string;
  bucket: string;
  generation: string;
  metageneration: string;
  contentType: string;
  storageClass: string;
  size: string;
  md5Hash: string;
  crc32c: string;
  etag: string;
  timeCreated: Date;
  updated: Date;
  timeStorageClassUpdated: Date;
}

export function getExtensionFromFileName(fileName: string): string {
  return last(split(fileName, '.'));
}

function handleFileName(file: File, fileName?: string) {
  const extension = getExtensionFromFileName(file.name);
  return `${fileName || nanoid()}.${extension}`;
}

interface UseUploadFileToGoogleStorage {
  (config: UploadFileToGCPConfig): {
    uploadFile: (file: File) => void;
    uploadFiles: (files: File[]) => void;
    deleteFile: () => Promise<void>;
    // cancelUpload: () => void,
    isLoading: boolean;
    progress: number;
    error: Error | null;
    uploadInfo: UploadInfo | null;
    uploadInfos: UploadInfo[] | null;
  };
}

export const useUploadFileToGoogleStorage: UseUploadFileToGoogleStorage = (
  config: UploadFileToGCPConfig,
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [uploadInfo, setUploadInfo] = useState<UploadInfo | null>(null);
  const [uploadInfos, setUploadInfos] = useState<UploadInfo[] | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const uploadFile = useCallback(
    async (file: File) => {
      let internalFile = file;
      const fileName = handleFileName(internalFile, config.fileName);
      const uploadUrl = `https://www.googleapis.com/upload/storage/v1/b/${config.bucketName}/o?uploadType=media&name=${fileName}`;
      const postConfig: AxiosRequestConfig = {
        onUploadProgress(progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          setProgress(percentCompleted);
        },
        // cancelToken: source.token,
        // headers,
      };

      try {
        if (config.compressOptions) {
          internalFile = await imageCompression(file, config.compressOptions);
        }
        setIsLoading(true);
        setError(null);
        const response = await axios.post(uploadUrl, internalFile, postConfig);
        setUploadInfo(response.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setError(err);
      }
    },
    [config.bucketName, config.compressOptions, config.fileName],
  );

  const uploadFiles = useCallback(
    async (files: File[]) => {
      try {
        setIsLoading(true);
        setError(null);
        const responses = await mapAsync(files, async file => {
          let internalFile = file;
          if (config.compressOptions) {
            internalFile = await imageCompression(file, config.compressOptions);
          }
          const fileName = handleFileName(internalFile, config.fileName);
          const uploadUrl = `https://www.googleapis.com/upload/storage/v1/b/${config.bucketName}/o?uploadType=media&name=${fileName}`;
          const postConfig: AxiosRequestConfig = {
            onUploadProgress(progressEvent) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              );
              setProgress(percentCompleted);
            },
            // cancelToken: source.token,
            // headers,
          };
          const response = await axios.post(
            uploadUrl,
            internalFile,
            postConfig,
          );
          return response.data;
        });
        setUploadInfos(responses);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setError(e);
      }
    },
    [config.bucketName, config.compressOptions, config.fileName],
  );

  const deleteFile = useDeepCompareCallback(async () => {
    const deleteUrl = !uploadInfo
      ? null
      : `https://www.googleapis.com/storage/v1/b/${config.bucketName}/o/${uploadInfo.name}`;
    if (deleteUrl) {
      try {
        setIsLoading(true);
        setError(null);
        await axios.delete(deleteUrl);
        setUploadInfo(null);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setError(err);
        setUploadInfo(null);
      }
    }
  }, [config.bucketName, uploadInfo]);

  // const cancelUpload = useDeepCompareCallback(() => {
  //   // source.cancel('Request canceled by user');
  //   // source = cancelToken.source();
  //   setIsLoading(false);
  //   setError(null);
  //   setUploadInfo(null);
  //   setProgress(0);
  // }, [cancelToken]);

  return {
    uploadFile,
    // cancelUpload,
    deleteFile,
    isLoading,
    progress,
    error,
    uploadInfo,
    uploadInfos,
    uploadFiles,
  };
};
