import { DE, EN, ES, FR, IT, JA, NL, NO, PL, SV } from '@i18n/utils';
import { filter, includes, isEmpty, map } from 'lodash';
import { useMemo } from 'react';

import de from './de.json';
import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import it from './it.json';
import ja from './ja.json';
import nl from './nl.json';
import no from './no.json';
import pl from './pl.json';
import sv from './sv.json';

export const LANGUAGES_I18N = new Map<string, Record<string, string>>();

LANGUAGES_I18N.set(EN, en);
LANGUAGES_I18N.set(DE, de);
LANGUAGES_I18N.set(NL, nl);
LANGUAGES_I18N.set(FR, fr);
LANGUAGES_I18N.set(IT, it);
LANGUAGES_I18N.set(ES, es);
LANGUAGES_I18N.set(PL, pl);
// LANGUAGES_I18N.set(JA, ja);
LANGUAGES_I18N.set(NO, no);
LANGUAGES_I18N.set(SV, sv);

interface Language {
  code: string;
  name: string;
}

const LANGUAGES: Language[] = [
  {
    code: EN,
    name: EN,
  },
  {
    code: DE,
    name: DE,
  },
  {
    code: NL,
    name: NL,
  },
  {
    code: FR,
    name: FR,
  },
  {
    code: IT,
    name: IT,
  },
  {
    code: ES,
    name: ES,
  },
  {
    code: PL,
    name: PL,
  },
  // {
  //   code: JA,
  //   name: JA,
  // },
  {
    code: NO,
    name: NO,
  },
  {
    code: SV,
    name: SV,
  },
];
export const useLanguagesList = (
  language: string = EN,
  supportedLanguages: string[] = [],
): Language[] =>
  useMemo(
    () =>
      map(
        filter(LANGUAGES, lg => {
          if (isEmpty(supportedLanguages)) return true;
          return includes(supportedLanguages, lg.code);
        }),
        item => {
          const name = LANGUAGES_I18N.get(language)
            ? LANGUAGES_I18N.get(language)[item.name]
            : LANGUAGES_I18N.get(EN)[item.name];
          return {
            code: item.code,
            name,
          };
        },
      ),
    [language, supportedLanguages],
  );
