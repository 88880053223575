import { getAuthHeadersFromEpicConfig } from '@authentication/redux-auth-data/utils';
import { getCreateUpdateLiveUrl as getPath } from '@page-creator/shared-routes';
import { createEpic } from '@redux-async-module/epic-utils';
import { RequestType } from '@redux-async-module/interfaces';
import { selectors as languageSelectors } from '@resource/redux-language/reducer';

import { createUpdateLive } from './actions';
import { RootState, StartPayload, SuccessPayload } from './types';

export const EPICS = createEpic<RootState, StartPayload, SuccessPayload>({
  actions: createUpdateLive,
  requestOptions: {
    type: RequestType.post,
    getPath: (_, state) => getPath(languageSelectors.currentLanguage(state)),
    getHeaders: getAuthHeadersFromEpicConfig,
  },
});
