import { css } from '@ui-system/css';
import { CARD_ACTION_AREA_UUID } from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';

import { makeFinalStyleHook } from './internal';

const useActionAreaDefaultStyle = makeStyle(
  () => css`
    flex: 1;
    flex-direction: row;
  `,
);

export const useActionAreaStyle = makeFinalStyleHook(
  CARD_ACTION_AREA_UUID,
  useActionAreaDefaultStyle,
);
