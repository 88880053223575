import { Selector } from '@redux-basic-module/interfaces';

export const NAMESPACE = 'language';

export interface ChangeLanguagePayload {
  language: string;
}

export type State = {
  currentLanguage: string | null;
};
export const INITIAL_STATE: State = {
  currentLanguage: null,
};

export interface RootState {
  [NAMESPACE]: State;
}

export interface Selectors {
  currentLanguage: Selector<string>;
}

export interface UseChangeCurrentLanguage {
  (): (payload: ChangeLanguagePayload) => void;
}
