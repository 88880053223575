import {
  AuthenticateByEmailPasswordRequestDTO,
  AuthenticateByEmailTanRequestDTO,
  AuthenticateByEmailTanResultDTO,
} from '@authentication/dtos';
import { Selector } from '@redux-basic-module/interfaces';

export const NAMESPACE = 'auth/auth-data';

export type LoginAttemptData = Partial<AuthenticateByEmailTanRequestDTO> &
  Partial<AuthenticateByEmailPasswordRequestDTO>;

export type AuthData = AuthenticateByEmailTanResultDTO;

export type AuthStatus = {
  sessionExpired: boolean;
};

export type LogoutData = {
  sessionExpired: boolean;
};

export interface State {
  loginAttemptData: LoginAttemptData | null;
  authData: AuthData | null;
  authStatus: AuthStatus | null;
}

export interface RootState {
  [NAMESPACE]: State;
}

export interface Selectors {
  loginAttemptData: Selector<LoginAttemptData>;
  authData: Selector<AuthData>;
  authStatus: Selector<AuthStatus>;
}
