import { createAction } from '@redux-basic-module/actions-utils';
import { Handlers } from '@redux-basic-module/interfaces';
import { createReducer } from '@redux-basic-module/reducer-utils';
import { createSelector } from '@redux-basic-module/selector-utils';

import {
  ChangeLanguagePayload,
  INITIAL_STATE,
  NAMESPACE,
  Selectors,
  State,
} from './types';

export const changeLanguage = createAction<
  'language/CHANGE_LANGUAGE',
  ChangeLanguagePayload
>('language/CHANGE_LANGUAGE');

export type Action = ReturnType<typeof changeLanguage>;

export const selectors: Selectors = {
  currentLanguage: createSelector<string>([NAMESPACE, 'currentLanguage']),
};

const HANDLERS: Handlers<State, ChangeLanguagePayload> = {
  [changeLanguage.type]: (state, action): State => ({
    ...state,
    currentLanguage: action.payload.language,
  }),
};
const reducer = createReducer<State, Action>(
  NAMESPACE,
  INITIAL_STATE,
  HANDLERS,
);

export default reducer;
