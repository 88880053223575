import { BaseSignInByEmailRequestDTO } from '@authentication/dtos';
import { getFieldPaths } from '@shared-utils/object';
import { MustAcceptTerms } from '@validation/common';
import { O } from 'ts-toolbelt';

export class EmailFormData extends BaseSignInByEmailRequestDTO {
  @MustAcceptTerms()
  terms = false;
}

export const FIELD_PATHS = getFieldPaths<
  EmailFormData,
  Record<keyof O.Paths<EmailFormData>[number], string>
>(new EmailFormData());
