import { EN } from '@i18n/utils';
import { countries } from 'countries-list';
import { head, isArray, keys, map, orderBy } from 'lodash';
import each from 'lodash/each';
import hasIn from 'lodash/hasIn';

import de from './langs/de.json';
import en from './langs/en.json';
import es from './langs/es.json';
import fr from './langs/fr.json';
import it from './langs/it.json';
import nl from './langs/nl.json';
import pl from './langs/pl.json';
import pt from './langs/pt.json';
import { CountriesList, CountriesMap } from './types';

const I18N = {
  de,
  en,
  es,
  fr,
  it,
  nl,
  pl,
  pt,
};

export function getCountriesList(language: string): CountriesList {
  const hasLanguage = hasIn(I18N, language);
  const getCountryName = (key: string) => {
    const name = hasLanguage
      ? I18N[language].countries[key]
      : I18N[EN].countries[key];
    if (isArray(name)) return head(name);
    return name;
  };

  return orderBy(
    map(keys(countries), key => ({
      code: key,
      name: getCountryName(key),
    })),
    ['name'],
    ['asc'],
  );
}

export function getCountries(language: string): CountriesMap {
  const mapping = {};
  each(getCountriesList(language), item => {
    mapping[item.code] = item.name;
  });
  return mapping as CountriesMap;
}

export function getName(country: string, language: string): string {
  if (!country) return '';
  return getCountries(language)[country?.toUpperCase()];
}

export const COUNTRIES = {
  getName,
  countries,
};
