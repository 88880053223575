import * as React from 'react';

import {
  RootStylesContext,
  RootStylesContextValue,
  useRootStylesContextValue,
} from './context';

interface Props extends React.PropsWithChildren<unknown> {
  styles: RootStylesContextValue;
}
export const RootStylesProvider: React.FC<Props> = ({
  styles,
  children,
}: Props) => {
  const value = useRootStylesContextValue(styles);
  return (
    <RootStylesContext.Provider value={value}>
      {children}
    </RootStylesContext.Provider>
  );
};
