import { useCallback } from 'react';

import useNavigateTo from './useNavigateTo';

export type UseNavigateToRouteReturn<S = Record<string, any>> = (
  state?: S,
) => void;

type UseNavigateToRoute = (route: string) => UseNavigateToRouteReturn;

const useNavigateToRoute: UseNavigateToRoute = (route: string) => {
  const navigate = useNavigateTo();
  return useCallback(
    state => {
      navigate(route, state);
    },
    [navigate, route],
  );
};

export default useNavigateToRoute;
