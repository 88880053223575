import { I18nShape } from '@i18n/core';
import { useDeepCompareMemo } from '@react-utils/hooks';
import { EMPTY_OBJECT } from '@shared-utils/object';
import UI from '@ui-system/ui';
import { O } from '@utils/ts';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, { memo } from 'react';
import {
  ComponentForEditor,
  ComponentHandlerType,
  useComponentsRenderPosition,
} from 'react-page-editor/state';

import { TutorialConfig, useComponentHandler } from './createComponentHandler';

interface Props {
  children: React.ReactNodeArray;
  Header?: React.FC;
  Footer?: React.FC;
  Component?: React.FC;
}

interface InternalComponentRenderer {
  id: string;
  enhancedChildren: React.ReactNodeArray;
}
const InternalComponentRenderer: React.FC<InternalComponentRenderer> = ({
  id,
  enhancedChildren,
}: InternalComponentRenderer) => {
  const Component = useDeepCompareMemo(
    () =>
      find(
        enhancedChildren,
        // @ts-ignore
        component => component.props.Component.config.id === id,
      ),
    [enhancedChildren, id],
  );
  if (!Component) return null;
  return React.cloneElement(
    Component as React.ReactElement<any, any>,
    // @ts-ignore
    Component.props.ComponentProps,
  );
};
const InternalComponentRendererMemo: React.FC<InternalComponentRenderer> = memo(
  InternalComponentRenderer,
);

export const ReactPageEditorRenderer: React.FC<Props> & {
  Item: React.FC<ReactPageEditorRendererItemProps>;
} = ({ children, Footer, Header, Component }: Props) => {
  const componentsRenderPosition = useComponentsRenderPosition();
  if (!isEmpty(componentsRenderPosition))
    return (
      <UI.Page.Content overflow="scroll">
        {Header && <Header />}
        {Component && <Component />}
        {map(componentsRenderPosition, item => (
          <InternalComponentRendererMemo
            id={item.id}
            key={item.id}
            enhancedChildren={children}
          />
        ))}
        {Footer && <Footer />}
      </UI.Page.Content>
    );
  return (
    <UI.Page.Content overflow="scroll">
      {Header && <Header />}
      {Component && <Component />}
      {React.Children.map(children, (child, i) => {
        if (!child) return null;
        return React.cloneElement(child as React.ReactElement<any, any>, {
          position: i,
        });
      })}
      {Footer && <Footer />}
    </UI.Page.Content>
  );
};

interface ReactPageEditorRendererItemProps<P = O.Object> {
  Component: ComponentForEditor<P>;
  customCreateComponentHandler?: (
    Component: ComponentForEditor<P>,
  ) => ComponentHandlerType<P>;
  ComponentProps?: P;
  position?: number;
  tutorialConfig?: TutorialConfig;
  i18ns?: I18nShape[];
}

export const ReactPageEditorRendererItem: React.FC<
  ReactPageEditorRendererItemProps<any>
> = ({
  Component,
  ComponentProps,
  position,
  customCreateComponentHandler,
  tutorialConfig,
  i18ns,
}: ReactPageEditorRendererItemProps) => {
  const EnhancedComponent = useComponentHandler(
    Component,
    tutorialConfig,
    customCreateComponentHandler,
    i18ns,
  );
  return <EnhancedComponent position={position} {...ComponentProps} />;
};

ReactPageEditorRendererItem.defaultProps = {
  ComponentProps: EMPTY_OBJECT,
};

ReactPageEditorRenderer.Item = ReactPageEditorRendererItem;
