import {
  NavigationRoute,
  NavigationRouter,
  NavigationSwitch,
} from '@lib/navigation';
import ROUTES from '@rec/routes';
import { HistoryListener } from 'ga';
import { useInitializeOnMount } from 'ga/useInitializeOnMount';
import React from 'react';

import { Main } from './pages';

const InitializeGA = () => {
  useInitializeOnMount();
  return null;
};

const Router: React.FC = () => {
  const acceptedCookies = localStorage.getItem('accepted-cookies');
  return (
    <NavigationRouter basename="/">
      {acceptedCookies && <InitializeGA />}
      <HistoryListener>
        <NavigationSwitch>
          <NavigationRoute path={ROUTES.MAIN_RENDERER} component={Main} />
        </NavigationSwitch>
      </HistoryListener>
    </NavigationRouter>
  );
};
export default Router;
