import { useCallback } from 'react';

import useHistory from './useHistory';
import useLocation from './useLocation';

type Return = (route: string, state?: Record<string, any>) => void;
type UseNavigateTo = () => Return;

const useNavigateTo: UseNavigateTo = () => {
  const history = useHistory();
  const location = useLocation();
  return useCallback(
    (route: string, state) => {
      if (history) {
        history.push(route, { ...state, lastRoute: location.pathname });
      }
    },
    [history, location.pathname],
  );
};

export default useNavigateTo;
