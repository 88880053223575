import { AsyncModuleConfig, RequestType } from '@redux-async-module/interfaces';
import { createModule } from '@redux-async-module/module-utils';
import { selectors as languageSelectors } from '@resource/redux-language/reducer';
import { RootState as _RootState } from '@resource/redux-language/types';
import { getResourceBundleUrl } from '@resource/routes';

import buildExtraEpics from './epics';
import {
  LoadMessagesStartPayload,
  LoadMessagesSuccessPayload,
  NAMESPACE,
  RootState,
} from './types';

// CREATES A ASYNC MODULE USING  createModule()

const CONFIG: AsyncModuleConfig<
  RootState & _RootState,
  LoadMessagesStartPayload,
  LoadMessagesSuccessPayload
> = {
  namespace: NAMESPACE,
  actionName: 'LOAD_BUNDLE',
  mainEpicConfig: {
    // cache: {
    //   msTtl: '5m',
    // },
    requestOptions: {
      type: RequestType.post,
      getBody: (payload, state) => {
        const language = languageSelectors.currentLanguage(state);
        const { bundleNames } = payload;
        return {
          bundleNames,
          language,
        };
      },
      getPath: getResourceBundleUrl,
    },
  },
  buildExtraEpics,
};
const {
  getDynamicModule,
  selectorHooks,
  dispatchHooks,
  selectors,
} = createModule<
  RootState & _RootState,
  LoadMessagesStartPayload,
  LoadMessagesSuccessPayload
>(CONFIG);

// EXPORTS DATA HOOKS WITH ANOTHER NAME FOR USAGE
export const { useData: useBundle } = selectorHooks;

export const { data: bundleSelector } = selectors;

// SAME FOR DISPATCH HOOKS
export const { useAsyncStartOnMount: useLoadBundleOnMount } = dispatchHooks;

// EXPORT getDynamicModule() TO USE WITH logic-dynamic-modules
export const getBundleModule = getDynamicModule;
