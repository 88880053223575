import { U } from '@utils/ts';
import nth from 'lodash/nth';
import split from 'lodash/split';

interface Return {
  language: U.Nullable<string>;
  country: U.Nullable<string>;
}
export function extractLanguageAndCountryFromLocaleString(
  locale?: string,
  { bypassFor }: { bypassFor?: string } = {},
): Return {
  const items = split(locale, '-');
  if (bypassFor && bypassFor.includes(locale)) {
    return {
      language: locale || null,
      country: nth(items, 1) || null,
    };
  }
  return {
    language: nth(items, 0) || null,
    country: nth(items, 1) || null,
  };
}
