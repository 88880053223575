import { hrtime } from './hrtime';

export interface MeasuredTime {
  seconds: number;
  milliseconds: number;
  millisecondsTotal: number;
}

export const measureTime = function createMeasureTime(): () => MeasuredTime {
  const start = hrtime();

  return function getMeasureTime(): MeasuredTime {
    const end = hrtime(start);

    const seconds = end[0];
    const milliseconds = Math.trunc(end[1] / 1_000_000);

    return {
      seconds,
      milliseconds,
      millisecondsTotal: seconds * 1_000 + milliseconds,
    };
  };
};
