import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  SnackbarProvider,
  SnackbarProviderProps,
  useSnackbar,
} from 'notistack';
import React, { useCallback } from 'react';

export * from 'notistack';

export const NotificationsProvider: React.FC<SnackbarProviderProps> = ({
  children,
  ...props
}: SnackbarProviderProps) => (
  <SnackbarProvider {...props}>{children}</SnackbarProvider>
);

export const useNotifications = (): {
  addNotification: (message: SnackbarMessage, options?: OptionsObject) => void;
  closeNotification: (key?: SnackbarKey) => void;
} => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const addNotification = useCallback(
    (message: SnackbarMessage, options?: OptionsObject) => {
      enqueueSnackbar(message, options);
    },
    [enqueueSnackbar],
  );

  const closeNotification = useCallback(
    (key?: SnackbarKey) => {
      closeSnackbar(key);
    },
    [closeSnackbar],
  );

  return {
    addNotification,
    closeNotification,
  };
};
