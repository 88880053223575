import logger from '@logger/core';
import { nanoid } from 'nanoid';
import hash from 'object-hash';

const log = logger('hasher-object');

export function hashObject(object: Record<string, any> | string): string {
  try {
    return hash(object);
  } catch (error) {
    log.error(`Error during hash of object`, error, object);
    return nanoid();
  }
}

export interface WithHash {
  hash: string;
}

export type Hashed<T> = {
  hash: string;
  data: T;
};
