import ColorTool from 'color';

import getContrastColor from './getContrastColor';

export type Hex = string;

export interface Color {
  main: Hex;
  light?: Hex;
  dark?: Hex;
  contrastText?: Hex;
}

export const generateLightenColor = (color: string, ratio = 0.5): string => {
  const mainColor = ColorTool(color);
  return mainColor.lighten(ratio).hex();
};

export const generateDarkenColor = (color: string, ratio = 0.5): string => {
  const mainColor = ColorTool(color);
  return mainColor.darken(ratio).hex();
};

export const generateColorKit = (color: string, ratio?: number): Color => {
  const mainColor = ColorTool(color).hex();
  return {
    main: mainColor,
    light: generateLightenColor(mainColor, ratio),
    dark: generateDarkenColor(mainColor, ratio),
    contrastText: getContrastColor(generateDarkenColor(mainColor, ratio)),
  };
};

export { default as getContrastColor } from './getContrastColor';
export default ColorTool;
