import { fixEpicArrayToString } from '@redux-async-module/epic-utils/fixEpicToString';
import { Module } from '@redux-basic-module/interfaces';

import { NAMESPACE } from './constants';
import epics from './epics';
import reducer, { hydrateStart } from './reducer';
import { RootState } from './types';

export function getPersistStoreModule(
  namespaces: string[],
  appId: string,
): Module<RootState> {
  return {
    id: NAMESPACE,
    reducerMap: {
      [NAMESPACE]: reducer,
    },
    initialActions: [hydrateStart({ namespaces, appId })],
    epics: fixEpicArrayToString(epics),
  };
}
