import { I18nShape } from '@i18n/core';
import { DE } from '@i18n/utils';
import { PLACEHOLDERS as bundleName } from '@resource/bundle-names';

const NAMESPACE = 'placeholder';

export const EMAIL_PLACEHOLDER: I18nShape = {
  key: `${NAMESPACE}.email`,
  text: 'Email',
  bundleName,
  translation: {
    [DE]: 'E-Mail',
  },
};

export const TAN_CODE_PLACEHOLDER: I18nShape = {
  key: `${NAMESPACE}.tanCode`,
  text: 'Enter token',
  bundleName,
  translation: {
    [DE]: 'Sicherheitstoken eingeben',
  },
};
