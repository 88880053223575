import { saveToLocalStorage } from '@lib/local-storage';
import { nanoid } from 'nanoid';

import { NAMESPACE as PERSISTED_NAMESPACE } from '../constants';
import keyGenerator from './keyGenerator';

export default async function persistHydrateAction<State>(
  namespace: string,
  state: State,
  appId: string,
): Promise<void> {
  try {
    const action = {
      type: `${namespace}_HYDRATE`,
      payload: state,
      meta: {
        actionId: nanoid(5),
      },
    };
    const key = keyGenerator(appId, PERSISTED_NAMESPACE, namespace);
    await saveToLocalStorage<typeof action>(key, action);
  } catch (e) {
    throw new Error(e);
  }
}
