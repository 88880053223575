import assign from 'lodash/assign';
import forOwn from 'lodash/forOwn';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import memoize from 'lodash/memoize';
import reduce from 'lodash/reduce';

import { ALL_OPTION as ALL, SINGLETON } from './internals/singleton';
import { LogLevel } from './LogLevel';
import { JsonLogData, LogFunction, Logger, LoggerCreator } from './types';

const ALL_LOG_LEVELS = [
  LogLevel.TRACE,
  LogLevel.DEBUG,
  LogLevel.INFO,
  LogLevel.WARN,
  LogLevel.ERROR,
  LogLevel.EMERG,
  LogLevel.FATAL,
];

function isGroupEnabled(group: string, expr: RegExp) {
  return expr.test(group);
}

function isGroupDisabled(group: string, expr: RegExp) {
  return expr.test(group);
}

function canLogWithParams(group: string, enabledGroups, skippedGroups) {
  if (!group) {
    return true;
  }
  if (!enabledGroups.length) {
    return false;
  }
  if (enabledGroups.length === 1 && enabledGroups[0] === ALL) {
    return true;
  }
  const skippedLen = skippedGroups.length;
  for (let i = 0; i < skippedLen; i += 1) {
    if (isGroupDisabled(group, skippedGroups[i])) {
      return false;
    }
  }
  const enabledLen = enabledGroups.length;
  for (let i = 0; i < enabledLen; i += 1) {
    if (
      enabledGroups[i] instanceof RegExp &&
      isGroupEnabled(group, enabledGroups[i])
    ) {
      return true;
    }
  }
  return false;
}

const memoizedCanLogWithParams = memoize(canLogWithParams);

function canLog(group: string) {
  return (
    !SINGLETON.DISABLED &&
    memoizedCanLogWithParams(
      group,
      SINGLETON.ENABLED_GROUPS,
      SINGLETON.SKIPPED_GROUPS,
    )
  );
}

function createLoggerFunction(
  group: string,
  defaultParams: Record<string, unknown>,
  givenLevel?: LogLevel,
): LogFunction {
  function log(
    messageOrData: string | JsonLogData,
    ...possibleMessageArgs: Array<never>
  ) {
    if (!canLog(group)) {
      return;
    }
    if (isObject(messageOrData)) {
      const {
        message,
        fullMessage,
        prefix,
        level,
        messageArgs,
        params,
        operation,
        logOptions,
        traceId,
        group: msgGroup,
        date,
      } = messageOrData;
      SINGLETON.MESSAGE_CHANNEL.next({
        group: msgGroup || group,
        message: !isEmpty(prefix) ? `${prefix}${message}` : message,
        fullMessage,
        messageArgs,
        level: level || givenLevel || LogLevel.INFO,
        date: date || new Date(),
        params: {
          ...(params || {}),
          ...(defaultParams || {}),
        },
        operation,
        logOptions,
        traceId,
      });
    } else {
      SINGLETON.MESSAGE_CHANNEL.next({
        group,
        message: messageOrData,
        messageArgs: possibleMessageArgs,
        level: givenLevel || LogLevel.INFO,
        date: new Date(),
        params: defaultParams,
      });
    }
  }
  return log;
}

export const createLogger: LoggerCreator = (group: string) => {
  const defaultParams = {};
  // @ts-ignore
  const newLogger = reduce<LogLevel, Logger>(
    ALL_LOG_LEVELS,
    (result, value: LogLevel) => {
      // eslint-disable-next-line no-param-reassign
      result[value] = createLoggerFunction(group, defaultParams, value);
      return result;
    },
    // @ts-ignore
    {},
  );

  newLogger.defaultParams = defaultParams;

  newLogger.log = newLogger.info;

  newLogger.extend = function extendLogger(newGroupName: string) {
    const groupName = !isEmpty(group)
      ? `${group}:${newGroupName}`
      : newGroupName;
    const newLog = createLogger(groupName);
    newLog.setDefaultParams(newLogger.defaultParams);
    return newLog;
  };
  newLogger.setDefaultParams = (data: any) => {
    forOwn(data, (value, key) => {
      newLogger.defaultParams[key] = value;
    });
    return newLogger;
  };
  newLogger.json = createLoggerFunction(group, defaultParams);
  newLogger.getNamespace = () => group;
  return assign(createLoggerFunction(group, defaultParams), newLogger);
};
