import { Container, Token } from 'typedi';

export const API_CONTEXT_PATH = new Token<string>('API_CONTEXT_PATH');

export const APP_VERSION = new Token<string>('APP_VERSION');

export const API_HOST = new Token<string>('API_HOST');

export function getApiBasePath(): string {
  return `${Container.get(API_HOST)}${Container.get(API_CONTEXT_PATH)}`;
}
