import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { FieldPath } from '../../../core';
import { setFieldValueAction } from '../../actions';
import useFormId from '../useFormId';

function useOnSelectChange(
  fieldPath: FieldPath,
): (event: React.FormEvent<HTMLSelectElement>) => void {
  const dispatch = useDispatch();
  const formId = useFormId();
  return useCallback(
    (event: React.FormEvent<HTMLSelectElement>) => {
      const { value } = event.currentTarget;
      dispatch(setFieldValueAction<string>(formId, fieldPath, value));
    },
    [dispatch, formId, fieldPath],
  );
}

export default useOnSelectChange;
