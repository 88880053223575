import Carousel from '@ui-system/common-carousel';
import { Container } from '@ui-system/common-container';
import Grid from '@ui-system/common-grid';
import { Icon } from '@ui-system/common-icon';
import { Image } from '@ui-system/common-image';
import List from '@ui-system/common-list';
import Page from '@ui-system/common-page';
import Rating from '@ui-system/common-rating';
import { Screen } from '@ui-system/common-screen';
import Skeleton from '@ui-system/common-skeleton';
import { Components } from '@ui-system/interfaces';
import Avatar from '@ui-system/material-avatar';
import Button from '@ui-system/material-button';
import Card from '@ui-system/material-card';
import Divider from '@ui-system/material-divider';
import { Form } from '@ui-system/material-form';
import Modal from '@ui-system/material-modal';
import Spinner from '@ui-system/material-spinner';
import Table from '@ui-system/material-table';
import * as TypographySet from '@ui-system/material-typography';
import Typography from '@ui-system/material-typography';

import ThemeProvider from './theme';

// @ts-ignore
export const components: Components = {
  ...TypographySet,
  Container,
  Typography,
  Button,
  Image,
  Grid,
  Form,
  Icon,
  Carousel,
  Card,
  Divider,
  Page,
  Modal,
  Rating,
  Avatar,
  Spinner,
  ThemeProvider,
  Screen,
  List,
  Table,
  Skeleton,
};
