import { Color } from '@ui-system/css';
import { ContainerProps, Style } from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';
import UI from '@ui-system/ui';
import * as React from 'react';

interface Props {
  color?: Color;
  size?: number;
  onClick?: ContainerProps['onClick'];
  style?: Style;
}

interface MakeStyleProps {
  style?: Style;
}
const useStyle = makeStyle<MakeStyleProps>(({ css, utils, theme, props }) => ({
  ...css`
    ${utils.applyBackgroundColor(theme, 'primary')}
  `,
  ...props?.style,
}));

interface SocialMediaButtonProps extends Props {
  name: string;
  style?: Style;
}
export const SocialMediaButton: React.FC<SocialMediaButtonProps> = ({
  color,
  size,
  name,
  style,
  ...rest
}: SocialMediaButtonProps) => {
  const containerStyle = useStyle({ style });
  return (
    <UI.Container p={2} style={containerStyle} {...rest}>
      <UI.Icon name={name} color={color} size={size as number} />
    </UI.Container>
  );
};
export const FacebookButton: React.FC<Props> = ({
  color,
  size,
  ...rest
}: Props) => (
  <SocialMediaButton
    name="facebook"
    color={color}
    size={size as number}
    {...rest}
  />
);
export const InstagramButton: React.FC<Props> = ({
  color,
  size,
  ...rest
}: Props) => (
  <SocialMediaButton
    name="instagram"
    color={color}
    size={size as number}
    {...rest}
  />
);
export const TwitterButton: React.FC<Props> = ({
  color,
  size,
  ...rest
}: Props) => (
  <SocialMediaButton
    name="twitter"
    color={color}
    size={size as number}
    {...rest}
  />
);
export const LinkedinButton: React.FC<Props> = ({
  color,
  size,
  ...rest
}: Props) => (
  <SocialMediaButton
    name="linkedin"
    color={color}
    size={size as number}
    {...rest}
  />
);

SocialMediaButton.defaultProps = {
  color: 'black',
  size: 10,
};
