import { Hex } from '@ui-system/color';
import { Color } from '@ui-system/css';
import { Style } from '@ui-system/interfaces/types';
import { nanoid } from 'nanoid';
import * as React from 'react';

export type ScreenBackgroundImage = 'string'; // URL

export interface CommonProps {
  children: React.ReactNode;
  gradientOptions?: GradientOptions;
  flex?: number;
}
export interface ScreenCommonProps {
  children: React.ReactNode;
  style?: Style & Record<string, any>;
  gradientOptions?: GradientOptions;
  flex?: number;
}

export interface ScreenProps extends ScreenCommonProps {
  backgroundColor?: Color | Hex;
  backgroundImage?: ScreenBackgroundImage;
}

export interface GradientOptions {
  colors: string[];
  start?: { x: number; y: number };
  end?: { x: number; y: number };
}

export interface ScreenHeaderProps extends ScreenCommonProps {
  renderLeft?: () => React.ReactNode;
  renderRight?: () => React.ReactNode;
  renderTitle?: () => React.ReactNode;
}

export interface ScreenContentProps extends ScreenCommonProps {
  scrollable?: boolean;
  style?: Style;
  innerRef?: React.Ref<any>;
  keyboardShouldPersistTaps?: 'always' | 'never' | 'handled' | false | true;
}

export interface ScreenBottomProps extends ScreenCommonProps {
  gridSize?: number;
}

export interface IScreenStatic {
  Header: React.FC<ScreenHeaderProps>;
  Content: React.FC<ScreenContentProps>;
  Bottom: React.FC<ScreenBottomProps>;
}

export type ScreenType = React.FC<ScreenProps> & IScreenStatic;

export const SCREEN_UUID = nanoid();
export const SCREEN_HEADER_UUID = nanoid();
