export const NAMESPACE = '@fast-form';

export const START = '@fast-form/START';
export const RESTART = '@fast-form/RESTART';
export const DELETE = '@fast-form/DELETE';
export const UPDATE_FIELD_VALUE = '@fast-form/UPDATE_FIELD_VALUE';
export const UPDATE_FIELD_VALUES_BY_QUERY =
  '@fast-form/UPDATE_FIELD_VALUES_BY_QUERY';
export const SET_FIELD_TOUCHED = '@fast-form/SET_FIELD_TOUCHED';
export const SET_FIELD_ERRORS = '@fast-form/SET_FIELD_ERRORS';
export const SET_FIELD_WATCHED = '@fast-form/SET_FIELD_WATCHED';
export const SET_FIELD_UNWATCHED = '@fast-form/SET_FIELD_UNWATCHED';
export const RESET_FORM_STATE = '@fast-form/RESET_FORM_STATE';
export const SET_FORM_STATE = '@fast-form/SET_FORM_STATE';
export const SUBMIT_START = '@fast-form/SUBMIT_START';
export const SUBMIT_SUCCESS = '@fast-form/SUBMIT_SUCCESS';
export const SUBMIT_ERROR = '@fast-form/SUBMIT_ERROR';
export const VALIDATE_START = '@fast-form/VALIDATE_START';
export const VALIDATE_SUCCESS = '@fast-form/VALIDATE_SUCCESS';

export const EMPTY_OBJECT = Object.freeze({});
export const EMPTY_ARRAY = Object.freeze([]);
