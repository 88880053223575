import { isTestOrDevEnvironment } from '@lib/config-loader';
import { registerConsoleLoggerTransport } from '@logger/console-transport';
import {
  BaseLoggerOptions,
  createOmitParamsForConsole,
  DEFAULT_BROWSER_CONSOLE_LOGGER_TRANSPORT_OPTIONS,
} from '@service-layer-utils/configure-logger-utils';
import startsWith from 'lodash/startsWith';

export type ConfigureBrowserLoggerOptions = BaseLoggerOptions;

const CSS_PREFIX = 'color:';

export function configureBrowserLogger(
  options: ConfigureBrowserLoggerOptions,
): void {
  const { staticParams } = options;
  if (isTestOrDevEnvironment()) {
    registerConsoleLoggerTransport({
      ...DEFAULT_BROWSER_CONSOLE_LOGGER_TRANSPORT_OPTIONS,
      staticParams,
      omitParams: createOmitParamsForConsole(options),
      executeLogFn: (fn, output, params) => {
        const actualOutput = output.filter(o => !startsWith(o, CSS_PREFIX));
        const colors = output.filter(o => startsWith(o, CSS_PREFIX));
        fn.apply(null, [actualOutput.join(' '), ...colors, ...params]);
      },
    });
  }
}
