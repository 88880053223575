import { getApiBasePath } from '@controller-layer-utils/core';
import {
  PAGE_CREATOR_PATH_IS_OWNED,
  PAGE_CREATOR_PATH_USER_URLS,
  PAGE_CREATOR_ROUTE_DRAFT,
  PAGE_CREATOR_ROUTE_LIVE,
  PUBLIC_PATH_IS_AVAILABLE,
  PUBLIC_ROUTE_LIVE,
} from '@page-creator/constants';
import qs from 'querystring';

export function getCreateUpdateDraftUrl(): string {
  return `${getApiBasePath()}${PAGE_CREATOR_ROUTE_DRAFT}`;
}

export function getCreateUpdateLiveUrl(language: string): string {
  return `${getApiBasePath()}${PAGE_CREATOR_ROUTE_LIVE}?${qs.stringify({
    language,
  })}`;
}

export function getRetrieveDraftUrl(key: string): string {
  return `${getCreateUpdateDraftUrl()}?${qs.stringify({ key })}`;
}

export function getPageLiveIsAvailableUrl(urlPath: string): string {
  return `${getApiBasePath()}${PUBLIC_ROUTE_LIVE}${PUBLIC_PATH_IS_AVAILABLE}?${qs.stringify(
    { urlPath },
  )}`;
}

export function getPageLiveIsOwnedUrl(urlPath: string): string {
  return `${getApiBasePath()}${PAGE_CREATOR_ROUTE_LIVE}${PAGE_CREATOR_PATH_IS_OWNED}?${qs.stringify(
    { urlPath },
  )}`;
}

export function getUserPageUrlsUrl(): string {
  return `${getApiBasePath()}${PAGE_CREATOR_ROUTE_LIVE}${PAGE_CREATOR_PATH_USER_URLS}`;
}

export function getPageLiveUrl(urlPath: string): string {
  return `${getApiBasePath()}${PUBLIC_ROUTE_LIVE}?${qs.stringify({ urlPath })}`;
}
