import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { FormId } from '../../../core';
import {
  getFormIsSuccessfullySubmittedSelector,
  getFormSubmittedValuesSelector,
} from '../../stateSelector';
import { RootState } from '../../types';
import useFastFormProviderContext from '../useFastFormProviderContext';

function useSubmittedValues<T>(formId: FormId) {
  const submittedValuesSelector = useCallback(
    (state: RootState<T>) => getFormSubmittedValuesSelector(state, formId),
    [formId],
  );
  return useSelector(submittedValuesSelector);
}

function useIsSuccessfullySubmitted<T>(formId: FormId) {
  const submittedValuesSelector = useCallback(
    (state: RootState<T>) =>
      getFormIsSuccessfullySubmittedSelector(state, formId),
    [formId],
  );
  return useSelector(submittedValuesSelector);
}

function useOnSubmitSuccessCallback(): void {
  const { formId, onSubmitSuccess } = useFastFormProviderContext();
  const submittedValues = useSubmittedValues(formId);
  const isSuccess = useIsSuccessfullySubmitted(formId);
  useEffect(() => {
    if (!isSuccess) {
      return;
    }
    onSubmitSuccess(submittedValues);
  }, [onSubmitSuccess, isSuccess, submittedValues]);
}

export default useOnSubmitSuccessCallback;
