import { NotDisposableEmail } from '@emailer/disposable-checker';
import { IsEmail, IsOptional, Length } from 'class-validator-i18n';

export class BaseSignInByEmailRequestDTO {
  @IsOptional()
  recaptchaResponse?: string;

  @Length(0, 255)
  @NotDisposableEmail()
  @IsEmail()
  email: string;
}
