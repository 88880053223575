import {
  createDispatchHook,
  createSelectorHook,
} from '@redux-basic-module/hooks-utils';
import { get } from 'lodash';

import { logout, selectors } from './reducer';
import { AuthData, AuthStatus } from './types';

export const useAuthData = createSelectorHook<AuthData>(selectors.authData);
export const useAuthStatus = createSelectorHook<AuthStatus>(
  selectors.authStatus,
);

export const useUserId = (): string => {
  const data = useAuthData();
  return get(data, 'userId');
};

export const useUserEmail = (): string => {
  const data = useAuthData();
  return get(data, 'userEmail');
};

export const useAccessToken = (): string => {
  const data = useAuthData();
  return get(data, 'token');
};

export const useHasLoggedUser = (): boolean => {
  const data = useAuthData();
  return !!data;
};

export const useSessionExpired = (): boolean => {
  const data = useAuthStatus();
  return data?.sessionExpired || false;
};

export function useHasAValidLogin(): boolean {
  const hasLoggedUser = useHasLoggedUser();
  const sessionExpired = useSessionExpired();
  return hasLoggedUser && !sessionExpired;
}
export const useLogout = createDispatchHook(logout);
