import { FastFormInput, FastFormSubmitButton } from '@fast-form/components';
import { useValue } from '@fast-form/module/hooks/fields';
import { useTranslatedMessage } from '@i18n/context';
import FastFormProvider from '@lib/fast-form/context/FastFormProvider';
import {
  BACK_TO_EDITOR,
  IF_YOU_WANT_TO_EMBED_YOUR_PROPAGE,
  IF_YOU_WANT_TO_USE_YOUR_PROPAGE,
  PUBLISH,
  THIS_URL_ITS_NOT_AVAILABLE,
  WAS_SUCCESSFULLY_PUBLISHED,
  WWW_REC_PROPAGE_COM,
  YOU_CHANGED_THE_URL_OF_YOUR_PROPAGE,
  YOUR_PATH,
  YOUR_REC_PRO_PAGE,
  YOUR_REC_PROPAGE_WILL_BE_PUBLISHED,
} from '@rec/i18n';
import { Form, usePublishFlow } from '@rec/logic-live';
import { useUserPageUrls } from '@rec/logic-live-is-available';
import {
  PublishMode,
  useIsLoadingCreateUpdateLive,
  useIsPublishModeIdle,
  useIsPublishModeStarted,
  useIsPublishModeSucceeded,
  useSetPublishMode,
} from '@rec/redux-page-creator-live';
import {
  COPY_LINK,
  COPY_SNIPPET,
  ON,
  REQUEST_ERROR,
} from '@resource/i18n-shapes';
import { CopyToClipboardButton } from '@ui-system/copy-to-clipboard-button';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import { openUrl } from 'browser';
import head from 'lodash/head';
import * as React from 'react';
import { useMemo } from 'react';

const STYLE = css`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 998;
`;

const BUTTON_STYLE = css`
  border: 1px solid black;
  margin-top: 15px;
  margin-left: 15px;
  width: 200px;
  height: 33px;
`;

const INPUT_STYLE = css`
  width: 200px;
`;

const OnSuccessfullyPublished: React.FC = () => {
  const value = useValue<string>(Form.FIELD_PATHS.path.$path);
  const baseUrl = useTranslatedMessage(WWW_REC_PROPAGE_COM);
  const finalLink = `https://${baseUrl}/${value}`;
  const snippet = `
    <iframe width="100%" height="1000px" frameborder="0" src="${finalLink}?iframe=true" />
  `;
  const setPublishMode = useSetPublishMode();

  return (
    <UI.Container direction="column" f={1}>
      <UI.Container p="3" bg="gray.A100" justify="center" gap={1}>
        <UI.Typography i18n={YOUR_REC_PRO_PAGE} />
        <UI.Typography
          i18n={WAS_SUCCESSFULLY_PUBLISHED}
          modifiers="success, bold"
        />
        <UI.Typography i18n={ON} />
        <UI.Typography modifiers="link" onClick={() => openUrl(finalLink)}>
          {finalLink}
        </UI.Typography>
      </UI.Container>
      <UI.Divider color="white" />
      <UI.Container
        p="3, 10"
        bg="gray.A100"
        justify="center"
        align="center"
        gap={2}
      >
        <UI.Container w={700}>
          <UI.Typography i18n={IF_YOU_WANT_TO_USE_YOUR_PROPAGE} />
        </UI.Container>
        <CopyToClipboardButton
          i18n={COPY_LINK}
          iconName="link"
          value={finalLink}
        />
      </UI.Container>
      <UI.Divider color="white" />
      <UI.Container
        p="3, 10"
        bg="gray.A100"
        justify="center"
        align="center"
        gap={2}
      >
        <UI.Container w={700}>
          <UI.Typography i18n={IF_YOU_WANT_TO_EMBED_YOUR_PROPAGE} />
        </UI.Container>
        <CopyToClipboardButton
          i18n={COPY_SNIPPET}
          iconName="copy"
          value={snippet}
        />
      </UI.Container>
      <UI.Divider color="white" />
      <UI.Container
        p="3, 10"
        bg="gray.A100"
        justify="center"
        align="center"
        gap={2}
      >
        <UI.Button
          i18n={BACK_TO_EDITOR}
          onClick={() => setPublishMode(PublishMode.IDLE)}
        />
      </UI.Container>
    </UI.Container>
  );
};

interface DifferentUrlOverwriteWarningProps {
  urlPath: string;
}

const DifferentUrlOverwriteWarning: React.FC<DifferentUrlOverwriteWarningProps> = ({
  urlPath,
}: DifferentUrlOverwriteWarningProps) => {
  const baseUrl = useTranslatedMessage(WWW_REC_PROPAGE_COM);
  const finalLink = `<strong>https://${baseUrl}/${urlPath}</strong>`;
  const value = useValue<string>(Form.FIELD_PATHS.path.$path);
  if (!urlPath || !value || value === urlPath) return null;
  return (
    <UI.Animation>
      <UI.Container bg="white" p={2} borderRadius={5} shadow={1}>
        <UI.Caption
          i18n={YOU_CHANGED_THE_URL_OF_YOUR_PROPAGE}
          values={{ lastUrl: finalLink }}
        />
      </UI.Container>
    </UI.Animation>
  );
};

export const PublishModalInternal: React.FC = () => {
  const { result, loading } = useUserPageUrls();
  const { isCheckingUrl, isUrlInUse, onPublish, error } = usePublishFlow();
  const isPublishModeStarted = useIsPublishModeStarted();
  const isPublishModeSucceeded = useIsPublishModeSucceeded();
  const isPublishModeIdle = useIsPublishModeIdle();
  const isLoading = useIsLoadingCreateUpdateLive();
  const lastUsedUrlPath = head(result);
  const initialValues = useMemo(() => {
    const form = new Form();
    form.path = lastUsedUrlPath || '';
    return form;
  }, [lastUsedUrlPath]);
  if (isPublishModeIdle) return null;
  return (
    <UI.Container style={STYLE}>
      <FastFormProvider
        initialValues={initialValues}
        onSubmitSuccess={onPublish}
      >
        <UI.Container
          bg="gray.A100"
          f={1}
          h={300}
          align={isPublishModeStarted ? 'center' : 'flex-end'}
          justify="center"
          p="2, 0"
          m={isPublishModeSucceeded ? '10, 0' : ''}
        >
          {isPublishModeSucceeded && <OnSuccessfullyPublished />}
          {isPublishModeStarted && (
            <UI.Container
              direction="column"
              justify="center"
              align="center"
              gap={1}
              m="6, 0, 0, 0"
            >
              <UI.Body2 i18n={YOUR_REC_PROPAGE_WILL_BE_PUBLISHED} />
              <UI.Container gap={1}>
                <UI.Container p="5, 0">
                  <UI.Body2 i18n={WWW_REC_PROPAGE_COM} suffix="/" />
                </UI.Container>
                <UI.Container style={INPUT_STYLE}>
                  <FastFormInput
                    fieldPath={Form.FIELD_PATHS.path.$path}
                    placeholder={YOUR_PATH}
                    disabled={isCheckingUrl || loading}
                  />
                </UI.Container>
                <FastFormSubmitButton
                  i18n={PUBLISH}
                  style={BUTTON_STYLE}
                  loading={isCheckingUrl || isLoading}
                />
              </UI.Container>
              {!isUrlInUse && (
                <DifferentUrlOverwriteWarning urlPath={lastUsedUrlPath} />
              )}
              {isUrlInUse && (
                <UI.Container
                  bg="white"
                  p={4}
                  borderRadius={5}
                  shadow={1}
                  m="2, 0"
                >
                  <UI.Caption i18n={THIS_URL_ITS_NOT_AVAILABLE} />
                </UI.Container>
              )}
              {error && (
                <UI.Container
                  bg="white"
                  p={4}
                  borderRadius={5}
                  shadow={1}
                  m="2, 0, 0, 0"
                >
                  <UI.Caption i18n={REQUEST_ERROR} modifiers="error" />
                </UI.Container>
              )}
            </UI.Container>
          )}
        </UI.Container>
      </FastFormProvider>
    </UI.Container>
  );
};

export const PublishModal: React.FC = () => {
  const isPublishModeIdle = useIsPublishModeIdle();
  if (isPublishModeIdle) return null;
  return <PublishModalInternal />;
};
