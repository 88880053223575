import { useUISystem } from '@ui-system/components-provider';
import {
  AvatarType,
  ButtonType,
  CardActionAreaProps,
  CardActionsProps,
  CardCommonProps,
  CardHeaderProps,
  CardMediaProps,
  CardType,
  CarouselType,
  ContainerType,
  DividerType,
  FormType,
  IconType,
  ImageType,
  ListType,
  ModalProps,
  ModalType,
  PageType,
  RatingType,
  ScreenCommonProps,
  ScreenContentProps,
  ScreenHeaderProps,
  ScreenProps,
  ScreenType,
  SkeletonType,
  SpinnerType,
  TableBodyProps,
  TableCellProps,
  TableHeadProps,
  TableProps,
  TableRowProps,
  TableType,
  TypographySet,
  TypographyType,
} from '@ui-system/interfaces';
import React from 'react';

export { Animation } from '@ui-system/animation';
export { default as Grid } from '@ui-system/common-grid';

// AVATAR
export const Avatar: AvatarType = props => {
  const UI = useUISystem();
  return <UI.Avatar {...props} />;
};
// BUTTON
export const Button: ButtonType = props => {
  const UI = useUISystem();
  return <UI.Button {...props} />;
};
// CARD
const ActionArea: CardType['ActionArea'] = ({
  children,
  ...props
}: CardActionAreaProps) => {
  const UI = useUISystem();
  return <UI.Card.ActionArea {...props}>{children}</UI.Card.ActionArea>;
};
const Header: CardType['Header'] = (props: CardHeaderProps) => {
  const UI = useUISystem();
  return <UI.Card.Header {...props} />;
};
const Media: CardType['Media'] = (props: CardMediaProps) => {
  const UI = useUISystem();
  return <UI.Card.Media {...props} />;
};
const Content: CardType['Content'] = ({
  children,
  ...props
}: CardCommonProps) => {
  const UI = useUISystem();
  return <UI.Card.Content {...props}>{children}</UI.Card.Content>;
};
const Actions: CardType['Actions'] = ({
  children,
  ...props
}: CardActionsProps) => {
  const UI = useUISystem();
  return <UI.Card.Content {...props}>{children}</UI.Card.Content>;
};
// @ts-ignore
export const Card: CardType = ({
  children,
  ...props
}: CardType['prototype']) => {
  const UI = useUISystem();
  return <UI.Card {...props}>{children}</UI.Card>;
};
Card.ActionArea = ActionArea;
Card.Header = Header;
Card.Media = Media;
Card.Content = Content;
Card.Actions = Actions;

// CAROUSEL
export const Carousel: CarouselType = props => {
  const UI = useUISystem();
  return <UI.Carousel {...props} />;
};
// CONTAINER
const LinearGradient: ContainerType['LinearGradient'] = ({
  children,
  ...props
}: ContainerType['LinearGradient']['prototype']) => {
  const UI = useUISystem();
  return (
    <UI.Container.LinearGradient {...props}>
      {children}
    </UI.Container.LinearGradient>
  );
};
// @ts-ignore
export const Container: ContainerType = props => {
  const UI = useUISystem();
  return <UI.Container {...props} />;
};
Container.LinearGradient = LinearGradient;

// DIVIDER
export const Divider: DividerType = props => {
  const UI = useUISystem();
  return <UI.Divider {...props} />;
};

// FORM
// @ts-ignore
export const Form: FormType = props => {
  const UI = useUISystem();
  return <UI.Form {...props} />;
};

const Input: FormType['Input'] = props => {
  const UI = useUISystem();
  return <UI.Form.Input {...props} />;
};

const Select: FormType['Select'] = props => {
  const UI = useUISystem();
  return <UI.Form.Select {...props} />;
};

const Checkbox: FormType['Checkbox'] = props => {
  const UI = useUISystem();
  return <UI.Form.Checkbox {...props} />;
};

Form.Input = Input;
Form.Select = Select;
Form.Checkbox = Checkbox;

// ICON
export const Icon: IconType = props => {
  const UI = useUISystem();
  return <UI.Icon {...props} />;
};

// IMAGE
export const Image: ImageType = props => {
  const UI = useUISystem();
  return <UI.Image {...props} />;
};

// LIST
export const List: ListType = props => {
  const UI = useUISystem();
  return <UI.List {...props} />;
};

// MODAL
export const Modal: ModalType = ({ children, ...props }: ModalProps) => {
  const UI = useUISystem();
  return <UI.Modal {...props}>{children}</UI.Modal>;
};

// PAGE
// @ts-ignore
export const Page: PageType = props => {
  const UI = useUISystem();
  return <UI.Page {...props} />;
};

const PageHeader: PageType['Header'] = props => {
  const UI = useUISystem();
  return <UI.Page.Header {...props} />;
};

const PageContent: PageType['Content'] = props => {
  const UI = useUISystem();
  return <UI.Page.Content {...props} />;
};

const PageFooter: PageType['Footer'] = props => {
  const UI = useUISystem();
  return <UI.Page.Footer {...props} />;
};

Page.Header = PageHeader;
Page.Content = PageContent;
Page.Footer = PageFooter;

// RATING
export const Rating: RatingType = props => {
  const UI = useUISystem();
  return <UI.Rating {...props} />;
};

// SCREEN
// @ts-ignore
export const Screen: ScreenType = ({ children, ...props }: ScreenProps) => {
  const UI = useUISystem();
  return <UI.Screen {...props}>{children}</UI.Screen>;
};

const ScreenHeader: ScreenType['Header'] = ({
  children,
  ...props
}: ScreenHeaderProps) => {
  const UI = useUISystem();
  return <UI.Screen.Header {...props}>{children}</UI.Screen.Header>;
};

const ScreenContent: ScreenType['Content'] = ({
  children,
  ...props
}: ScreenContentProps) => {
  const UI = useUISystem();
  return <UI.Screen.Content {...props}>{children}</UI.Screen.Content>;
};

const ScreenBottom: ScreenType['Bottom'] = ({
  children,
  ...props
}: ScreenCommonProps) => {
  const UI = useUISystem();
  return <UI.Screen.Bottom {...props}>{children}</UI.Screen.Bottom>;
};

Screen.Header = ScreenHeader;
Screen.Content = ScreenContent;
Screen.Bottom = ScreenBottom;

// TABLE
// @ts-ignore
export const Table: TableType = ({ children, ...props }: TableProps) => {
  const UI = useUISystem();
  return <UI.Table {...props}>{children}</UI.Table>;
};

const TableHead: TableType['Head'] = ({
  children,
  ...props
}: TableHeadProps) => {
  const UI = useUISystem();
  return <UI.Table.Head {...props}>{children}</UI.Table.Head>;
};

const TableBody: TableType['Body'] = ({
  children,
  ...props
}: TableBodyProps) => {
  const UI = useUISystem();
  return <UI.Table.Body {...props}>{children}</UI.Table.Body>;
};

const TableRow: TableType['Row'] = ({ children, ...props }: TableRowProps) => {
  const UI = useUISystem();
  return <UI.Table.Row {...props}>{children}</UI.Table.Row>;
};

const TableCell: TableType['Cell'] = ({
  children,
  ...props
}: TableCellProps) => {
  const UI = useUISystem();
  return <UI.Table.Cell {...props}>{children}</UI.Table.Cell>;
};

Table.Head = TableHead;
Table.Body = TableBody;
Table.Cell = TableCell;
Table.Row = TableRow;

// SPINNER
export const Spinner: SpinnerType = props => {
  const UI = useUISystem();
  return <UI.Spinner {...props} />;
};

// SPINNER
export const Skeleton: SkeletonType = props => {
  const UI = useUISystem();
  return <UI.Skeleton {...props} />;
};

// TYPOGRAPHY
export const Typography: TypographyType = props => {
  const UI = useUISystem();
  return <UI.Typography {...props} />;
};
export const H1: TypographySet['H1'] = props => {
  const UI = useUISystem();
  return <UI.H1 {...props} />;
};
export const H2: TypographySet['H2'] = props => {
  const UI = useUISystem();
  return <UI.H2 {...props} />;
};
export const H3: TypographySet['H3'] = props => {
  const UI = useUISystem();
  return <UI.H3 {...props} />;
};
export const H4: TypographySet['H4'] = props => {
  const UI = useUISystem();
  return <UI.H4 {...props} />;
};
export const H5: TypographySet['H5'] = props => {
  const UI = useUISystem();
  return <UI.H5 {...props} />;
};
export const H6: TypographySet['H6'] = props => {
  const UI = useUISystem();
  return <UI.H6 {...props} />;
};
export const Subtitle1: TypographySet['Subtitle1'] = props => {
  const UI = useUISystem();
  return <UI.Subtitle1 {...props} />;
};
export const Subtitle2: TypographySet['Subtitle2'] = props => {
  const UI = useUISystem();
  return <UI.Subtitle2 {...props} />;
};
export const Caption: TypographySet['Caption'] = props => {
  const UI = useUISystem();
  return <UI.Caption {...props} />;
};
export const Overline: TypographySet['Overline'] = props => {
  const UI = useUISystem();
  return <UI.Overline {...props} />;
};
export const Body1: TypographySet['Body1'] = props => {
  const UI = useUISystem();
  return <UI.Body1 {...props} />;
};
export const Body2: TypographySet['Body2'] = props => {
  const UI = useUISystem();
  return <UI.Body2 {...props} />;
};
export const ButtonText: TypographySet['ButtonText'] = props => {
  const UI = useUISystem();
  return <UI.ButtonText {...props} />;
};
