import { nanoid } from 'nanoid';
import * as React from 'react';

export interface SkeletonProps {
  count?: number;
  height?: number | string;
  width?: number | string;
  color?: string;
  highlightColor?: string;
}

export const SKELETON_UUID = nanoid();

export type SkeletonType = React.FC<SkeletonProps>;
