import { deepMergeAll, EMPTY_OBJECT } from '@shared-utils/object';
import {
  ComponentModifiersFunction,
  Modifiers,
  Style,
} from '@ui-system/interfaces';
import { useModifiers } from '@ui-system/modifiers';
import { UseStyle } from '@ui-system/styles/types';
import {
  ComponentUuid,
  useComponentStyleFromCtx,
} from '@ui-system/styles-provider';
import { O, U } from '@utils/ts';
import { useMemo } from 'react';

export function mergeStyles(
  defaultStyle: Style = EMPTY_OBJECT,
  styleFromCtx: Style = EMPTY_OBJECT,
  styleFromProps: U.Nullable<Style> = EMPTY_OBJECT,
): Style {
  return deepMergeAll([defaultStyle, styleFromCtx, styleFromProps as Style]);
}

export function useComponentFinalStyle(
  defaultStyle: Style,
  styleFromProps: U.Nullable<Style>,
  modifiers: U.Nullable<Modifiers>,
  componentModifiers: ComponentModifiersFunction,
  componentUuid: ComponentUuid,
): Style {
  const styleFromContext = useComponentStyleFromCtx(componentUuid);
  const style = useMemo(
    () => mergeStyles(defaultStyle, styleFromContext, styleFromProps),
    [defaultStyle, styleFromContext, styleFromProps],
  );
  return useModifiers(modifiers, componentModifiers, style);
}

export function makeStyleHookWithMergedCtx<
  Props extends O.Object | null = null
>(
  componentUuid: ComponentUuid,
  useDefaultStyle: UseStyle<Props>,
): UseStyle<Props> {
  return function useStyle(props: Props) {
    const styleFromContext = useComponentStyleFromCtx(componentUuid);
    // @ts-ignore
    const defaultStyle = useDefaultStyle(props);
    return useMemo<Style>(() => mergeStyles(defaultStyle, styleFromContext), [
      defaultStyle,
      styleFromContext,
    ]);
  } as UseStyle<Props>;
}
export function makeFinalStyleHook<Props extends O.Object | null = null>(
  componentUuid: ComponentUuid,
  useDefaultStyle: UseStyle<Props>,
) {
  return function useFinalStyle(
    styleFromProps: U.Nullable<Style>,
    modifiers: U.Nullable<Modifiers>,
    componentModifiers: ComponentModifiersFunction,
    // @ts-ignore
    props: Props = null,
  ): Style {
    // @ts-ignore
    const defaultStyle = useDefaultStyle(props);
    return useComponentFinalStyle(
      defaultStyle,
      styleFromProps,
      modifiers,
      componentModifiers,
      componentUuid,
    );
  };
}
