import { Hex } from '@ui-system/color';
import { Color } from '@ui-system/css';
import { U } from '@utils/ts';

import { getColorByName } from './getColorByName';
import { useColors } from './useColors';

export function useHex(color: U.Nullable<Color>): Hex {
  const colors = useColors();
  return getColorByName(color, colors);
}
