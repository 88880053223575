import { I18nShape } from '@i18n/core';
import { REC_PRO_PAGE as bundleName } from '@resource/bundle-names';

import { EDITOR_NAMESPACE } from './constants';

export const UPLOAD_YOUR_LOGO: I18nShape = {
  key: `${EDITOR_NAMESPACE}.uploadYourLogo`,
  text: 'Upload your company logo',
  bundleName,

  metadata: {
    description: 'Upload your logo text for editor',
  },
};

export const YOUR_LOGO: I18nShape = {
  key: `${EDITOR_NAMESPACE}.yourLogo`,
  text: 'Your logo',
  bundleName,
};

export const UPLOAD_IMAGES_OF_YOUR_BUSINESS: I18nShape = {
  key: `${EDITOR_NAMESPACE}.uploadImagesOfYourBusiness`,
  text: 'Upload your photos',
  bundleName,

  metadata: {
    description: 'Upload images of your business text for editor',
  },
};

export const UPLOAD_IMAGES_OF_YOUR_BUSINESS_DESCRIPTION: I18nShape = {
  key: `${EDITOR_NAMESPACE}.uploadImagesOfYourBusinessDescription`,
  text: 'Drag and drop photos of your installations here',
  bundleName,

  metadata: {
    description: 'Upload images of your business description text for editor',
  },
};
export const UPLOAD_YOUR_PHOTOS_TIP: I18nShape = {
  key: `${EDITOR_NAMESPACE}.uploadYourTip`,
  text:
    'Showcase images must be in the JPG or PNG format.<br />The recommended picture aspect ratio is 4:3, e.g. 1024×768px.<br />Smaller images sizes will reduce loading time.',
  bundleName,
};

export const UPLOAD_YOUR_LOGO_TIP: I18nShape = {
  key: `${EDITOR_NAMESPACE}.uploadYourLogoTip`,
  text:
    'Your logo image must be in the JPG or PNG format.<br />The recommended picture aspect ratio is 1:1, e.g. 1024×1024px.<br />Smaller images sizes will reduce loading time.',
  bundleName,
};

export const UPLOAD_YOUR_LOGO_DESCRIPTION: I18nShape = {
  key: `${EDITOR_NAMESPACE}.uploadYourLogoDescription`,
  text: 'Drag and drop here your company logo',
  bundleName,

  metadata: {
    description: 'Description for the upload logo card',
  },
};

export const FORMATS: I18nShape = {
  key: `${EDITOR_NAMESPACE}.formats`,
  text: 'FORMATS: jpg, png or svg',
  bundleName,

  metadata: {
    description: 'Formats description',
  },
};

export const UPLOAD_CARD_ALERT: I18nShape = {
  key: `${EDITOR_NAMESPACE}.uploadCardAlert`,
  text:
    'Show your team, your space and equipment. Attention: do not use photos of houses and facilities without proper authorization from the owners.',
  bundleName,

  metadata: {
    description: 'Upload card alert',
  },
};

export const SELECTED_CONTENTS: I18nShape = {
  key: `${EDITOR_NAMESPACE}.selectedContents`,
  text: 'Selected contents',
  bundleName,
};

export const SELECTED_PRODUCTS: I18nShape = {
  key: `${EDITOR_NAMESPACE}.selectedProducts`,
  text: 'Selected products',
  bundleName,
};

export const TEXT_ABOUT_YOUR_BUSINESS: I18nShape = {
  key: `${EDITOR_NAMESPACE}.textAboutYourBusiness`,
  text: 'Text about your business',
  bundleName,
};

export const DO_YOU_HAVE_HYPERLINKS_IN_YOUR_TEXT: I18nShape = {
  key: `${EDITOR_NAMESPACE}.doYouHaveHyperlinksInYourText`,
  text: 'Do you have hyperlinks in your text?',
  bundleName,
};
export const DO_YOU_HAVE_HYPERLINKS_IN_YOUR_TEXT_TOOLTIP: I18nShape = {
  key: `${EDITOR_NAMESPACE}.doYouHaveHyperlinksInYourTextTooltip`,
  text:
    'Mark the text and click in the link button on the editor toolbar to activate the hyperlink',
  bundleName,
};

export const SAVE_TEXT: I18nShape = {
  key: `${EDITOR_NAMESPACE}.saveText`,
  text: 'Save text',
  bundleName,
};

export const PUBLISH_REC_PRO_PAGE: I18nShape = {
  key: `${EDITOR_NAMESPACE}.publishRecProPage`,
  text: 'Publish REC ProPage',
  bundleName,
};

export const PREVIEW: I18nShape = {
  key: `${EDITOR_NAMESPACE}.preview`,
  text: 'Preview',
  bundleName,
};

export const DESKTOP_VIEW: I18nShape = {
  key: `${EDITOR_NAMESPACE}.desktopView`,
  text: 'Desktop View',
  bundleName,
};

export const MOBILE_VIEW: I18nShape = {
  key: `${EDITOR_NAMESPACE}.mobileView`,
  text: 'Mobile View',
  bundleName,
};

export const PUBLISH: I18nShape = {
  key: `${EDITOR_NAMESPACE}.publish`,
  text: 'Publish',
  bundleName,
};

export const OR_UPLOAD_IMAGE: I18nShape = {
  key: `${EDITOR_NAMESPACE}.orUploadImage`,
  text: 'Or upload image',
  bundleName,
};

export const CHANGE_IMAGE: I18nShape = {
  key: `${EDITOR_NAMESPACE}.changeImage`,
  text: 'Change image',
  bundleName,
};
export const SHOWCASE_YOUR_REC_PROTRUST: I18nShape = {
  key: `${EDITOR_NAMESPACE}.showcaseYourRecProtrust`,
  text:
    'Showcase your REC ProTrust installations.<br/><strong>Attention:</strong> do not use photos of houses and facilities without proper authorization from the owners.',
  bundleName,
};
export const YOUR_REC_PROPAGE_WILL_BE_PUBLISHED: I18nShape = {
  key: `${EDITOR_NAMESPACE}.yourRecPropageWillBePublished`,
  text: 'Your <strong>REC ProPage</strong> will be published under the link:',
  bundleName,
};

export const YOUR_PATH: I18nShape = {
  key: `${EDITOR_NAMESPACE}.yourPath`,
  text: 'Your path',
  bundleName,
};

export const YOUR_REC_PRO_PAGE: I18nShape = {
  key: `${EDITOR_NAMESPACE}.yourRecProPage`,
  text: 'Your <strong>REC ProPage</strong>',
  bundleName,
};

export const WAS_SUCCESSFULLY_PUBLISHED: I18nShape = {
  key: `${EDITOR_NAMESPACE}.wasSuccessfullyPublished`,
  text: 'was successfully published',
  bundleName,
};

export const WWW_REC_PROPAGE_COM: I18nShape = {
  key: `${EDITOR_NAMESPACE}.wwwRecProPageCom`,
  text: 'www.rec-propage.com',
  bundleName,
};

export const IF_YOU_WANT_TO_USE_YOUR_PROPAGE: I18nShape = {
  key: `${EDITOR_NAMESPACE}.ifYouWantToUseYourPropage`,
  text:
    'If you want to use your ProPage standalone just copy the link and share it with your customers.',
  bundleName,
};

export const IF_YOU_WANT_TO_EMBED_YOUR_PROPAGE: I18nShape = {
  key: `${EDITOR_NAMESPACE}.ifYouWantToEmbedYourPropage`,
  text:
    'If you want to embed your ProPage into your website, use the iFrame snippet.',
  bundleName,
};
export const THIS_URL_ITS_NOT_AVAILABLE: I18nShape = {
  key: `${EDITOR_NAMESPACE}.thisUrlItsNotAvailable`,
  text: 'This URL has already been taken, please choose another one.',
  bundleName,
};

export const CLICK_AND_BUILD_YOUR_PAGE: I18nShape = {
  key: `${EDITOR_NAMESPACE}.clickAndBuildYourPage`,
  text: 'Point and click to create your ProPage',
  bundleName,
};

export const YOU_CHANGED_THE_URL_OF_YOUR_PROPAGE: I18nShape = {
  key: `${EDITOR_NAMESPACE}.youChangedTheUrlOfYourProPageAdvise`,
  text:
    'You changed the URL of your ProPage. <br/>The URL {lastUrl} will not work anymore and may be registered by other users if you publish your page with the new URL. <br />Are you sure, you want to proceed?',
  bundleName,
};

export const SELECT_HERE_THE_CONTENT_YOU_WOULD_LIKE: I18nShape = {
  key: `${EDITOR_NAMESPACE}.selectHereTheContentYouWouldLike`,
  text:
    'Simply select the content you want on your REC ProPage from the menu and it will appear here immediately.',
  bundleName,
};

export const CONTACT_REC: I18nShape = {
  key: `${EDITOR_NAMESPACE}.contactRec`,
  text: 'Contact REC',
  bundleName,
};

export const BACK_TO_TOP: I18nShape = {
  key: `${EDITOR_NAMESPACE}.backToTop`,
  text: 'Back to top',
  bundleName,
};

export const DOWNLOAD_DATASHEET: I18nShape = {
  key: `${EDITOR_NAMESPACE}.downloadDatasheet`,
  text: 'Datasheet',
  bundleName,
};

export const DOWNLOAD_FACTSHEET: I18nShape = {
  key: `${EDITOR_NAMESPACE}.downloadFactsheet`,
  text: 'Factsheet',
  bundleName,
};

export const COPYRIGHT_REC_SOLAR: I18nShape = {
  key: `${EDITOR_NAMESPACE}.copyrightRecSolar`,
  text: 'Copyright © REC Solar Holdings AS',
  bundleName,
};

export const BACK_TO_EDITOR: I18nShape = {
  key: `${EDITOR_NAMESPACE}.backToEditor`,
  text: 'Back to editor',
  bundleName,
};

export const TERMS_AND_CONDITIONS_DESCRIPTION: I18nShape = {
  key: `${EDITOR_NAMESPACE}.termsAndConditionsDescription`,
  text:
    "This Service is provided to you free of charge and subject to the <a href='https://static.rec-propage.com/pdfs/terms-and-conditions/en.pdf'>ProPage Terms of Use</a> and <a href='https://www.recgroup.com/en/privacy-policy' target='_blank'>REC's Privacy Policy</a>",
  bundleName,
};

export const TERMS_AND_CONDITIONS_CHECKBOX_LABEL: I18nShape = {
  key: `${EDITOR_NAMESPACE}.termsAndConditionsCheckboxLabel`,
  text:
    "I accept the <a href='https://static.rec-propage.com/pdfs/terms-and-conditions/en.pdf'>ProPage Terms of Use</a> and <a href='https://www.recgroup.com/en/privacy-policy' target='_blank'>REC's Privacy Policy</a>.",
  bundleName,
};

export const INVALID_EMAIL_ADDRESS: I18nShape = {
  key: `${EDITOR_NAMESPACE}.invalidEmailAddress`,
  text:
    'Invalid email address. Please use the email address you are using to log into the REC ProPortal',
  bundleName,
};
