import { hashObject } from '@hasher/object-hash';
import { css } from '@ui-system/css';
import * as utils from '@ui-system/css/utils';
import {
  ComponentModifiers,
  ComponentModifiersFunction,
} from '@ui-system/interfaces';
import { devices, MediaQueries } from '@ui-system/media-query';
import { MakeStyleParamWithoutProps } from '@ui-system/styles/types';
import { Theme } from '@ui-system/theme';
import { getColorByName } from '@ui-system/theme/colors';
import memoize from 'lodash/memoize';
import noop from 'lodash/noop';

export type ModifiersCallback<T extends string = string> = (
  param: MakeStyleParamWithoutProps,
) => ComponentModifiers<T>;

export function createComponentModifiers<T extends string = string>(
  callback: ModifiersCallback = noop as ModifiersCallback,
): ComponentModifiersFunction<T> {
  function componentModifiers(
    theme: Theme,
    mediaQueries: MediaQueries,
  ): ComponentModifiers<T> {
    return callback({
      theme,
      mediaQueries,
      utils,
      devices,
      css,
      getColorByName,
    });
  }
  return memoize<ComponentModifiersFunction<T>>(componentModifiers, hashObject);
}
