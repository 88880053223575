import { O, U } from '@utils/ts';
import forEach from 'lodash/forEach';
import keys from 'lodash/keys';
import React from 'react';

export function createInstance<
  Props extends O.Object,
  Type extends React.FC<Props>,
  Statics
>(Component: Type, statics: U.Nullable<Statics> = null): Type & Statics {
  // @ts-ignore
  const Comp = (props: Props) => <Component {...props} />;
  forEach(keys(statics), item => {
    // @ts-ignore
    Comp[item] = statics[item];
  });
  return Comp as Type & Statics;
}
