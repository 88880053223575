// eslint-disable-next-line max-classes-per-file
import { getFieldPathsV2 } from '@shared-utils/object';
import { ValidUrlPath } from '@validation/common';
import { IsNotEmpty, MaxLength } from 'class-validator-i18n';

class _Form {
  @ValidUrlPath()
  @MaxLength(50)
  @IsNotEmpty()
  path = '';
}

export class Form extends _Form {
  static FIELD_PATHS = getFieldPathsV2(new _Form());
}

export const INITIAL_VALUE = new Form();
