import UI from '@ui-system/ui';
import React from 'react';

import {
  DidNotReceiveSendAgainProps,
  DidNotReceiveSendAgainType,
} from '../core';

const DidNotReceiveSendAgain: DidNotReceiveSendAgainType = ({
  label,
  linkLabel,
  onClick,
}: DidNotReceiveSendAgainProps) => (
  <UI.Container direction="row" justify="center" m={1} gap={1}>
    <UI.Caption i18n={label} modifiers="uppercase" />
    <UI.Caption
      i18n={linkLabel}
      onClick={onClick}
      modifiers="uppercase, link"
    />
  </UI.Container>
);

export default DidNotReceiveSendAgain;
