import { Epic } from '@redux-basic-module/interfaces';
import { ofType } from '@redux-operators/of-type';
import { validate } from 'class-validator-i18n';
import { get } from 'lodash';
import type { ActionsObservable } from 'redux-observable';
import { empty, from, Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { FormId } from '../../core';
import { normalizeClassValidatorErrors } from '../../utils';
import { setFieldErrorsAction } from '../actions';
import { UPDATE_FIELD_VALUE } from '../constants';
import { allFormsSelector } from '../stateSelector';
import { AllForms, RootState, UpdateFieldValueAction } from '../types';

type PipeData = [UpdateFieldValueAction, AllForms<any>];

// https://github.com/jquense/yup#mixedvalidatevalue-any-options-object-promiseany-validationerror
const YUP_OPTIONS = { abortEarly: false };

const validateAllChanges = async (formId: FormId, allForms: AllForms<any>) => {
  const form = allForms[formId];
  const validationSchema = get(form, 'validationSchema');
  const currentValues = get(form, 'currentValues');

  if (!validationSchema) {
    const errors = await validate(currentValues);
    // errors is an array of validation errors
    if (errors.length > 0) {
      return normalizeClassValidatorErrors(errors);
    }
    return null;
  }
  try {
    await validationSchema.validate(currentValues, YUP_OPTIONS);
    return null;
  } catch (error) {
    return error.inner;
  }
};

// @ts-ignore
const onUpdateFieldValidate: Epic = (
  action$: ActionsObservable<UpdateFieldValueAction>,
  state$: Observable<RootState<any>>,
): Observable<any> =>
  action$.pipe(
    ofType(UPDATE_FIELD_VALUE),
    debounceTime(50),
    withLatestFrom(state$.pipe(map(allFormsSelector))),
    switchMap(([{ payload: { formId } }, allForms]: PipeData) =>
      from(validateAllChanges(formId, allForms)).pipe(
        map(error => setFieldErrorsAction(formId, error)),
        catchError(() => of(empty())),
      ),
    ),
  );

export default onUpdateFieldValidate;
