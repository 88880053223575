import { O } from '@utils/ts';
import each from 'lodash/each';
import keys from 'lodash/keys';
import replace from 'lodash/replace';
import qs from 'querystring';
import { useMemo } from 'react';

import useLocation from './useLocation';

export function useQueryParams<Q extends O.Object = O.Object>(): O.Partial<Q> {
  const location = useLocation();
  return useMemo<O.Partial<Q>>(() => {
    const parsed = (qs.parse(location.search) as unknown) as O.Partial<Q>;
    const params = {} as O.Partial<Q>;
    each(keys(parsed), item => {
      const key: keyof Q = replace(item, '?', '');
      params[key] = parsed[item];
    });
    return params;
  }, [location.search]);
}
