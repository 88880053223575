import { createActions } from '@redux-async-module/actions-utils';
import { createAction } from '@redux-basic-module/actions-utils';

import {
  NAMESPACE,
  SelectCategoryPayload,
  StartPayload,
  SuccessPayload,
} from './types';

export const loadCategories = createActions<StartPayload, SuccessPayload>(
  NAMESPACE,
  'LOAD_CATEGORIES',
);

export const loadPanels = createActions<StartPayload, SuccessPayload>(
  NAMESPACE,
  'LOAD_PANELS',
);

export const selectCategory = createAction<
  'SELECT_CATEGORY',
  SelectCategoryPayload
>('SELECT_CATEGORY');
