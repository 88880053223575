import { hashObject } from '@hasher/object-hash';
import createLogger from '@logger/core';
import {
  COMPONENTS_CONFIGS,
  COMPONENTS_MAP,
} from '@page-creator/context/componentsMap';
import { useDeepCompareEffect, useDeepCompareMemo } from '@react-utils/hooks';
import { EMPTY_ARRAY } from '@shared-utils/array';
import { EMPTY_OBJECT } from '@shared-utils/object';
import { O, U } from '@utils/ts';
import deepEqual from 'fast-deep-equal';
import { unflatten } from 'flat';
import update, { Spec } from 'immutability-helper';
import { cloneDeep, isEqual } from 'lodash';
import each from 'lodash/each';
import filter from 'lodash/filter';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import hasIn from 'lodash/hasIn';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import join from 'lodash/join';
import keyBy from 'lodash/keyBy';
import keys from 'lodash/keys';
import map from 'lodash/map';
import merge from 'lodash/merge';
import noop from 'lodash/noop';
import set from 'lodash/set';
import size from 'lodash/size';
import some from 'lodash/some';
import sortBy from 'lodash/sortBy';
import split from 'lodash/split';
import take from 'lodash/take';
import values from 'lodash/values';
import xor from 'lodash/xor';
import { nanoid } from 'nanoid';
import { useEffect, useRef, useState } from 'react';
import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import {
  Children,
  ComponentConfig,
  ComponentForEditor,
  ContextComponent,
  ModifiedBy,
  ReactPageEditorContextValue,
} from './types';

const logger = createLogger('@react-page-editor');

const INITIAL_VISIBLE_STATE = false;

export function shouldUpdateState(
  obj1: Record<string, any>,
  obj2: Record<string, any>,
): boolean {
  return !deepEqual(obj1, obj2);
}

export function canReplaceComponents(
  obj1: Record<string, any>,
  obj2: Record<string, any>,
): boolean {
  if (isEmpty(obj1) && !isEmpty(obj1)) return true;
  return isEmpty(xor(keys(obj1), keys(obj2))) && shouldUpdateState(obj1, obj2);
}

export function traverseComponentsTree(
  components: Record<string, ContextComponent>,
  callback: (key: string, component: ContextComponent) => void,
  accKey = '',
): void {
  each(keys(components), item => {
    const key = accKey ? `${accKey}.children.${item}` : item;
    callback(key, components[item]);
    if (!isEmpty(components[item]?.children)) {
      traverseComponentsTree(components[item]?.children, callback, key);
    }
  });
}
function areAllComponentsSelected(
  components: Record<string, ContextComponent>,
) {
  let allSelected = true;
  traverseComponentsTree(components, (_, component) => {
    if (!component.visible) {
      allSelected = false;
    }
  });
  return allSelected;
}

function registerChildren(
  children: Children[],
  initialVisibleState: boolean,
): Record<string, ContextComponent> {
  return keyBy(
    map(children, item => ({
      ...item.config,
      position: 0,
      visible: initialVisibleState,
      children: isEmpty(item.children)
        ? undefined
        : registerChildren(item.children, initialVisibleState),
    })),
    'id',
  );
}

function toggleChildren(
  children: Record<string, ContextComponent>,
  prev: boolean,
): Record<string, ContextComponent> {
  if (!prev) return children;
  const newState: Record<string, ContextComponent> = {};
  traverseComponentsTree(children, (key, component) => {
    set(newState, key, {
      ...component,
      visible: !prev,
    });
  });
  return newState;
}

function defaultOnReconcileComponents(
  newComponents: Record<string, ContextComponent>,
  oldComponents: Record<string, ContextComponent>,
): Record<string, ContextComponent> {
  const newState: Record<string, ContextComponent> = {};
  traverseComponentsTree(newComponents, (key, component) => {
    set(newState, key, {
      ...component,
      name: get(oldComponents, `${key}.name`) || newComponents[key].name,
      children: get(oldComponents, `${key}.children`),
    });
  });
  if (canReplaceComponents(oldComponents, newComponents)) return newComponents;
  return oldComponents;
}
function toggleAllComponentsFn(
  components: Record<string, ContextComponent>,
): Record<string, ContextComponent> {
  const allSelected = areAllComponentsSelected(components);
  const newState: Record<string, ContextComponent> = {};
  traverseComponentsTree(components, (key, component) => {
    set(newState, key, {
      ...component,
      visible: !allSelected,
    });
  });
  return newState;
}

/* const COMPONENTS_TO_KEEP_PROPS_ON_RECONCILE = [
  Contact.config.id,
  YourCompany.config.id,
  SocialMedia.config.id,
];

function onReconcileComponents(
  newComponents: Record<string, ContextComponent>, // FROM DRAFT ON THE SERVER
  oldComponents: Record<string, ContextComponent>, // FROM REGISTERED COMPONENTS
): Record<string, ContextComponent> {
  const newState: Record<string, ContextComponent> = {};
  traverseComponentsTree(oldComponents, (key, component) => {
    const newComponent = get(newComponents, key, component);
    if (
      oldComponents[key] &&
      newComponents[key] &&
      includes(COMPONENTS_TO_KEEP_PROPS_ON_RECONCILE, key)
    ) {
      set(newState, key, {
        ...newComponent,
        name: component?.name || newComponent?.name,
        props: newComponent?.props,
      });
      return;
    }
    set(newState, key, {
      ...newComponent,
      name: component?.name || newComponent?.name,
      children: component.children,
    });
  });
  return newState;
} */

const CASH_KEY = nanoid();

export interface ReactPageEditorModuleProps {
  onSave: (components: Record<string, ContextComponent>) => void;
  initialVisibleState: boolean;
  onComponentsChanged?: (components: Record<string, ContextComponent>) => void;
  onReconcileComponents?: (
    newComponents: Record<string, ContextComponent>,
    prevState: Record<string, ContextComponent>,
  ) => Record<string, ContextComponent>;
  setupProps: (props: Omit<ReactPageEditorModuleProps, 'setupProps'>) => void;
}

export const useReactPageEditorModule = create<
  ReactPageEditorContextValue & ReactPageEditorModuleProps
>()(
  devtools(
    persist(
      (setState, getState) => ({
        components: EMPTY_OBJECT,

        modifiedBy: ModifiedBy.FRAMEWORK,

        setComponents(
          components: Record<string, ContextComponent>,
          modifiedBy?: ModifiedBy,
        ): void {
          logger.info('Will try to set components', components);
          if (modifiedBy) {
            setState({ modifiedBy });
          }
          setState({
            components: getState().onReconcileComponents(
              components,
              getState().components,
            ),
          });
          if (canReplaceComponents(getState().componentsIds, components)) {
            setState({
              componentsIds: map(components, 'config.id'),
            });
          }
        },

        componentsIds: EMPTY_ARRAY,

        registerComponent(
          component: ComponentForEditor,
          position: number,
        ): void {
          logger.info(
            'Will try to register',
            component.config.id,
            component.defaultProps,
          );

          if (!includes(getState().componentsIds, component.config.id)) {
            setState({
              componentsIds: update(getState().componentsIds, {
                $push: [component.config.id],
              }),
            });
          }
          if (
            getState().components &&
            !hasIn(getState().components, component.config.id)
          ) {
            logger.info('Registered', component.config.id);
            setState({
              components: update(getState().components, {
                [component.config.id]: {
                  $set: {
                    ...component.config,
                    position,
                    visible: INITIAL_VISIBLE_STATE,
                    props: component.defaultProps,
                    children: registerChildren(
                      component.children,
                      INITIAL_VISIBLE_STATE,
                    ),
                  },
                },
              }),
            });
          } else logger.info('Already registered', component.config.id);
        },

        toggleComponent(component: ComponentConfig): void {
          logger.info('Will toggle', component.id);
          const prev = get(getState().components, component.id);
          const ids = split(component.id, '.children.');
          const idsWithoutTheLast = take(ids, size(ids) - 1);
          const parentSpec = {};

          each(idsWithoutTheLast, (id, i) => {
            const key = join(take(idsWithoutTheLast, i + 1), '.children.');
            if (key) {
              set(parentSpec, key, {
                visible: { $set: true },
              });
            }
          });
          const spec = unflatten({
            [component.id]: {
              visible: { $set: !prev?.visible },
              children: {
                $set: toggleChildren(prev.children, prev?.visible),
              },
            },
            ...parentSpec,
          }) as Spec<Record<string, ContextComponent>>;

          setState({
            modifiedBy: ModifiedBy.USER,
            components: update(getState().components, spec),
          });
        },

        toggleAllComponents(): void {
          logger.info('Will toggle all components');
          setState({
            modifiedBy: ModifiedBy.USER,
            components: toggleAllComponentsFn(getState().components),
          });
        },

        setComponentProps(
          component: ComponentConfig,
          props: Record<string, any>,
        ): void {
          logger.info('Will set props', component.id, props);
          if (
            shouldUpdateState(getState().components[component.id].props, props)
          ) {
            setState({
              modifiedBy: ModifiedBy.USER,
              components: update(getState().components, {
                [component.id]: {
                  props: {
                    $set: {
                      ...getState().components[component.id].props,
                      ...props,
                    },
                  },
                },
              }),
            });
          }
        },

        setComponentProp(
          component: ComponentConfig,
          prop: any,
          path: string,
        ): void {
          logger.info('Will set prop', component.id, prop, path);
          if (
            shouldUpdateState(
              getState().components[component.id].props[path],
              prop,
            )
          ) {
            setState({
              modifiedBy: ModifiedBy.USER,
              components: update(getState().components, {
                [component.id]: {
                  props: {
                    [path]: {
                      $set: prop,
                    },
                  },
                },
              }),
            });
          }
        },

        moveComponent(
          component: ComponentConfig,
          options: { direction: 'up' | 'down' } = { direction: 'up' },
        ) {
          logger.info('Will move component', component.id, options);
          const ctxComponent = getState().components[component.id];
          const queryPosition =
            options.direction === 'up'
              ? ctxComponent.position - 1
              : ctxComponent.position + 1;
          const componentToSwitchPositionsId = get(
            find(values(getState().components), { position: queryPosition }),
            'id',
            '',
          );
          if (
            !(
              !componentToSwitchPositionsId ||
              !getState().components[componentToSwitchPositionsId] ||
              !getState().components[component.id]
            )
          ) {
            setState({
              modifiedBy: ModifiedBy.USER,
              components: update(getState().components, {
                [component.id]: {
                  position: {
                    $set: getState().components[componentToSwitchPositionsId]
                      .position,
                  },
                },
                [componentToSwitchPositionsId]: {
                  position: {
                    $set: getState().components[component.id].position,
                  },
                },
              }),
            });
          }
        },
        moveComponentUp(component: ComponentConfig): void {
          getState().moveComponent(component, { direction: 'up' });
        },

        moveComponentDown(component: ComponentConfig): void {
          getState().moveComponent(component, { direction: 'down' });
        },

        getComponentById(id: string): U.Nullable<ContextComponent> {
          return get(getState().components, id);
        },

        onSaveConfiguration(): void {
          logger.info('Will save configuration', getState().components);
          getState().onSave(getState().components);
        },

        isComponentVisible(component: ComponentConfig): boolean {
          return (get(
            getState().components,
            `${component.id}.visible`,
          ) as unknown) as boolean;
        },

        canMoveUp(component: ComponentConfig): boolean {
          return (
            Number(get(getState().components, `${component.id}.position`)) !== 0
          );
        },

        canMoveDown(component: ComponentConfig): boolean {
          return (
            Number(get(getState().components, `${component.id}.position`)) <
            size(filter(getState().components, { visible: true }))
          );
        },

        isPreviewActive: false,

        allSelected: false,

        togglePreviewActive(): void {
          setState({ isPreviewActive: !getState().isPreviewActive });
        },

        // PROPS
        onSave(components: Record<string, ContextComponent>): void {
          logger.warn('onSave not configured');
          return noop(components);
        },

        initialVisibleState: false,

        onComponentsChanged(
          components: Record<string, ContextComponent>,
        ): void {
          logger.warn('onComponentsChanged not configured');
          return noop(components);
        },

        onReconcileComponents(
          newComponents: Record<string, ContextComponent>,
          prevState: Record<string, ContextComponent>,
        ): Record<string, ContextComponent> {
          logger.warn(
            'onReconcileComponents not configured, using default function',
          );
          return defaultOnReconcileComponents(newComponents, prevState);
        },

        setupProps({
          onSave,
          initialVisibleState,
          onComponentsChanged,
          onReconcileComponents,
        }: Omit<ReactPageEditorModuleProps, 'setupProps'>): void {
          logger.info('Will setup props');
          setState({
            onSave: onSave || getState().onSave,
            initialVisibleState:
              initialVisibleState || getState().initialVisibleState,
            onComponentsChanged:
              onComponentsChanged || getState().onComponentsChanged,
            onReconcileComponents:
              onReconcileComponents || getState().onReconcileComponents,
          });
        },
      }),
      {
        name: `react-page-editor-module-${CASH_KEY}`,
      },
    ),
  ),
);

export const useSetupReactPageEditorModule = (
  props: Omit<ReactPageEditorModuleProps, 'setupProps'>,
): void => {
  logger.info('SetupReactPageEditorModule Will setup props', props);
  const { components } = useReactPageEditorModule();
  const [canCallOnComponentsChanged, setCanCallOnComponentsChanged] = useState(
    false,
  );
  useDeepCompareEffect(() => {
    logger.info('SetupReactPageEditorModule Setup props');
    useReactPageEditorModule.getState().setupProps(props);
  }, [props]);

  useEffect(() => {
    logger.info('SetupReactPageEditorModule Setup timeout');

    const timeout = setTimeout(
      () =>
        setCanCallOnComponentsChanged(
          !!useReactPageEditorModule.getState().onComponentsChanged,
        ),
      10000,
    );
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  const ref = useRef(null);

  useDeepCompareEffect(() => {
    if (
      canCallOnComponentsChanged &&
      !isEmpty(components) &&
      useReactPageEditorModule.getState().modifiedBy === ModifiedBy.USER
    ) {
      if (!isEqual(components, ref.current)) {
        logger.info(`SetupReactPageEditorModule On components changed`);
        useReactPageEditorModule.getState().onComponentsChanged(components);
        ref.current = components;
      }
    }
  }, [canCallOnComponentsChanged, components]);
};

export const useEditorContext = useReactPageEditorModule;

export function useComponentsRenderPosition() {
  const { components } = useEditorContext();
  const positions = sortBy(
    map(components, item => ({ id: item.id, position: item.position })),
    ['position'],
  );

  const hash = hashObject(positions);
  return useDeepCompareMemo(() => positions, [hash]);
}

export const useRegisterComponentOnMount = (
  component: ComponentForEditor,
  position: number,
): void => {
  const ref = useRef(false);
  const { registerComponent, componentsIds } = useEditorContext();
  useEffect(() => {
    if (!ref.current && !some(componentsIds, component.config.id)) {
      registerComponent(component, position);
      ref.current = true;
    }
  }, [component, componentsIds, position, registerComponent]);
};

export const useSetComponentProps = (): ReactPageEditorContextValue['setComponentProps'] => {
  const { setComponentProps } = useEditorContext();
  return setComponentProps;
};

export const useSetComponentProp = (): ReactPageEditorContextValue['setComponentProp'] => {
  const { setComponentProp } = useEditorContext();
  return setComponentProp;
};

export function useComponentProps<P extends O.Object = O.Object>(
  id: string,
): U.Nullable<P> {
  const { getComponentById } = useEditorContext();
  return useDeepCompareMemo<P>(() => get(getComponentById(id), 'props') as P, [
    get(getComponentById(id), 'props'),
  ]);
}

export function useIsComponentVisible(component: ContextComponent): boolean {
  const { isComponentVisible } = useEditorContext();
  return isComponentVisible(component);
}

export function useIsEditorEmpty(): boolean {
  const { components } = useEditorContext();

  return useDeepCompareMemo(
    () => !some(values(components), { visible: true }),
    [components],
  );
}

function haveComponentsWithSamePosition(
  components: Record<string, ContextComponent>,
): boolean {
  return some(
    groupBy(components, 'position'),
    componentsAtPosition => size(componentsAtPosition) > 1,
  );
}

function fixComponentPosition(
  components: Record<string, ContextComponent>,
): Record<string, ContextComponent> {
  // Group components by their position
  const clonedComponents = cloneDeep(components);
  const groupedComponents = groupBy(clonedComponents, 'position');

  // Iterate through grouped components to identify and fix conflicts
  forEach(groupedComponents, (componentsAtPosition, position) => {
    if (componentsAtPosition.length > 1) {
      const adjustedComponents = componentsAtPosition.map(
        (component, index) => ({
          ...component,
          position: Number(position) + index,
        }),
      );

      // Merge the adjusted components back into the clonedComponents
      merge(clonedComponents, keyBy(adjustedComponents, 'id'));
    }
  });

  return update(components, { $set: clonedComponents });
}

let IS_ALL_SELECTED_REF = false;
useReactPageEditorModule.subscribe(state => {
  const allSelected = areAllComponentsSelected(state.components);
  if (IS_ALL_SELECTED_REF !== allSelected) {
    IS_ALL_SELECTED_REF = allSelected;
    useReactPageEditorModule.setState({
      allSelected,
    });
  }
  if (haveComponentsWithSamePosition(state.components)) {
    useReactPageEditorModule.setState({
      components: fixComponentPosition(state.components),
    });
  }
});
