import { Epic } from '@redux-basic-module/interfaces';
import { ofType } from '@redux-operators/of-type';
import { mapAsync } from '@shared-utils/array';
import {
  defaultTo,
  filter as lodashFilter,
  forEach,
  get,
  head,
  includes,
  isEmpty,
} from 'lodash';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import {
  hydrateError,
  hydrateStart,
  hydrateSuccess,
  persistHydrateActionsError,
  persistHydrateActionsSuccess,
  selectors,
} from './reducer';
import { HydrateStartPayload, HydrateSuccessPayload } from './types';
import persistHydrateAction from './utils/persistHydrateAction';
import retrieveHydrateAction from './utils/retrieveHydrateAction';

async function retrieveAllActionsToHydrate(
  namespaces: string[],
  appId: string,
) {
  const data = await mapAsync(namespaces, name =>
    retrieveHydrateAction(name, appId),
  );
  return lodashFilter(data, item => !isEmpty(item));
}

async function persistHydrateActions(state, action) {
  const persistedStores = selectors.persistedStores(state);
  const appId = selectors.appId(state);
  const namespaceToSave = head(
    lodashFilter(persistedStores, item => includes(action.type, item)),
  );
  const stateToSave = get(state, namespaceToSave);
  return persistHydrateAction(namespaceToSave, stateToSave, appId);
}

const hydrateEpic: Epic<HydrateStartPayload, HydrateSuccessPayload> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType(hydrateStart.type),
    switchMap(({ payload }) => {
      const appId = selectors.appId(state$.value);
      return from(retrieveAllActionsToHydrate(payload.namespaces, appId)).pipe(
        mergeMap(data => {
          const actions = [
            ...defaultTo(data, []),
            hydrateSuccess({ persistedStores: payload.namespaces }),
          ];
          return of(...actions);
        }),
        catchError(err => of(hydrateError({ error: err }))),
      );
    }),
  );

const persistState: Epic = (action$, state$) =>
  action$.pipe(
    filter(action => !includes(action.type, '_HYDRATE')),
    filter(action => {
      let shouldSaveState = false;
      const persistedStores = selectors.persistedStores(state$.value);
      forEach(persistedStores, item => {
        if (includes(action.type, item)) {
          shouldSaveState = true;
        }
      });
      return shouldSaveState;
    }),
    mergeMap(action =>
      from(persistHydrateActions(state$.value, action)).pipe(
        map(() => persistHydrateActionsSuccess()),
        catchError(err => of(persistHydrateActionsError({ error: err }))),
      ),
    ),
  );

export default [hydrateEpic, persistState];
