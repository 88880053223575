import includes from 'lodash/includes';
import { Action } from 'redux';

export const isAnyType = <Input extends Action, Type extends Input['type']>(
  action: Input,
  ...types: [Type, ...Type[]]
): boolean => includes(types, action.type);

export const isType = <Input extends Action, Type extends Input['type']>(
  action: Input,
  type: Type,
): boolean => action.type === type;

export const isTypeLike = <Input extends Action>(
  action: Input,
  pattern: string,
): boolean => action.type.indexOf(pattern) > -1;
