import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setFormStateAction } from '../actions';
import { SetFormStateOptions } from '../types';
import useFormId from './useFormId';

function useSetFormState(): (setOptions: SetFormStateOptions) => void {
  const dispatch = useDispatch();
  const formId = useFormId();
  return useCallback(
    (setOptions: SetFormStateOptions) => {
      dispatch(setFormStateAction(formId, setOptions));
    },
    [formId, dispatch],
  );
}

export default useSetFormState;
