import defaultResolver from '@i18n/default-resolver';
import logger from '@logger/core';
import { VALIDATION_ERRORS } from '@resource/bundle-names';
import * as VALIDATION_ERROR_SHAPES from '@resource/i18n-shapes/validationErrors';
import { DEFAULT } from '@resource/i18n-shapes/validationErrors';
import { bundleSelector } from '@resource/redux-bundle';
import { configure } from 'class-validator-i18n';
import camelCase from 'lodash/camelCase';
import forIn from 'lodash/forIn';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import { IModuleStore } from 'redux-dynamic-modules';

const log = logger('resource').extend('class-validator-i18n');

function findDefaultText(validationName: string): string {
  const keyToMatch = `${validationName}`;
  let foundText = DEFAULT.text;
  forIn(VALIDATION_ERROR_SHAPES, obj => {
    if (isObject(obj) && isString(obj.key) && obj.key === keyToMatch) {
      foundText = obj.text;
    }
  });
  if (foundText === DEFAULT.text) {
    log.error(
      `Could not find default i18n shape for validation with name=${validationName}`,
    );
  }
  return foundText;
}

export function configureClassValidatorI18n(store: IModuleStore<any>): void {
  configure({
    translate: (
      validator,
      validatorArgs,
      validationArguments,
      name: string,
    ) => {
      const resourceKey = camelCase(name);
      const state = store.getState();
      const bundle = bundleSelector(state);
      const i18nShape = {
        bundleName: VALIDATION_ERRORS,
        key: resourceKey,
        values: {
          ...validationArguments,
        },
        text: findDefaultText(resourceKey),
      };
      return defaultResolver(i18nShape, bundle);
    },
  });
}
