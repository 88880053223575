import { nanoid } from 'nanoid';
import * as React from 'react';
import { ImageSourcePropType } from 'react-native';

import { Style } from '../types';

export type AvatarSize = 'tiny' | 'small' | 'medium' | 'large' | 'giant';
export type AvatarVariant = 'circle' | 'rounded' | 'square';
export type AvatarShape = 'round' | 'rounded' | 'square';

export interface AvatarProps {
  variant?: AvatarVariant;
  shape?: AvatarShape;
  size?: AvatarSize;
  source?: ImageSourcePropType;
  src?: string;
  style?: Style;
}

export const AVATAR_UUID = nanoid();
export type AvatarType = React.FC<AvatarProps>;
