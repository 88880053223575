import head from 'lodash/head';
import includes from 'lodash/includes';
import join from 'lodash/join';

export function getStaticUrl(): string {
  if (window?.location?.origin?.includes('editor')) {
    return 'https://editor.rec-propage.com/static';
  }
  return 'https://www.rec-propage.com/static';
}

export function getStaticUrlWithParams(...params: string[]): string {
  const firstItem = head(params);
  if (includes(firstItem, 'https://')) {
    return join(params, '');
  }
  return `${getStaticUrl()}${join(params, '')}`;
}
