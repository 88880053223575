import { MutableRefObject, useEffect, useRef } from 'react';

// https://stackoverflow.com/questions/59780268/cleanup-memory-leaks-on-an-unmounted-component-in-react-hooks
export const useIsMountedRef = (): MutableRefObject<boolean> => {
  // eslint-disable-next-line no-underscore-dangle
  const _isMounted = useRef(true); // Initial value _isMounted = true
  useEffect(
    () => () => {
      // ComponentWillUnmount in Class Component
      _isMounted.current = false;
    },
    [],
  );

  return _isMounted;
};
