import { LogLevel } from '@logger/core';
import {
  Action,
  ActionCreator,
  BaseMeta,
  CreateDispatchHookOnMountReturn,
  CreateDispatchHookReturn,
  ErrorPayload,
  GetDynamicModule,
  Handlers,
  Selector,
} from '@redux-basic-module/interfaces';
import { HttpErrorJSON } from '@service-layer-utils/errors';
import { Epic as _Epic } from 'redux-observable';

export type Epic<
  StartPayload = null,
  SuccessPayload = null,
  State = any
> = _Epic<
  Action<string, StartPayload>,
  Action<string, SuccessPayload | any>,
  State
>;

export const INITIAL_STATE = {
  data: null,
  isLoading: false,
  error: null,
  success: false,
  lastStartPayload: null,
  lastCacheDate: null,
};

export type LastStartPayload<StartPayload> = StartPayload | null;

export interface RequestStartMeta extends BaseMeta {
  cache?: {
    msTtl?: string;
    ignoreCache?: boolean;
  };
}

export interface State<StartPayload, SuccessPayload> {
  data: SuccessPayload | null;
  isLoading: boolean;
  success: boolean;
  error: HttpErrorJSON | null;
  lastStartPayload: StartPayload | null;
  lastCacheDate: number | null;
}

export interface Selectors<StartPayload, SuccessPayload> {
  data: Selector<SuccessPayload | null>;
  isLoading: Selector<boolean>;
  success: Selector<boolean>;
  error: Selector<HttpErrorJSON | null>;
  lastStartPayload: Selector<StartPayload | null>;
  lastCacheDate: Selector<number | null>;
}

export interface Actions<
  StartPayload,
  SuccessPayload,
  Meta extends BaseMeta = BaseMeta
> {
  requestStart: ActionCreator<string, StartPayload, Meta & RequestStartMeta>;
  start: ActionCreator<string, StartPayload, Meta>;
  success: ActionCreator<string, SuccessPayload, Meta>;
  error: ActionCreator<string, ErrorPayload, Meta>;
  dismissError: ActionCreator<string, null, Meta>;
  reset: ActionCreator<string, null, Meta>;
  cancel: ActionCreator<string, null, Meta>;
}

export interface GetHeaders<P, R> {
  (payload: P, state: R): Record<string, any>;
}
export interface GetBody<P, R> {
  (payload: P, state: R): Record<string, any>;
}
export interface GetPath<P, R> {
  (payload: P, state: R): string;
}

export interface Filter<P, R> {
  (payload: P, state: R): boolean;
}

export enum RequestType {
  post = 'POST',
  get = 'GET',
}

export interface EpicConfig<
  RootState,
  StartPayload,
  SuccessPayload,
  Meta extends BaseMeta = BaseMeta
> {
  actions: Actions<StartPayload, SuccessPayload, Meta>;
  filter?: Filter<StartPayload, RootState>;
  cache?: {
    msTtl?: string; // ms format
  };
  requestOptions: {
    type: RequestType;
    getPath: GetPath<StartPayload, RootState>;
    getHeaders?: GetHeaders<StartPayload, RootState>;
    getBody?: GetBody<StartPayload, RootState>;
  };
  logs?: {
    levels?: {
      start: LogLevel;
      success: LogLevel;
      error: LogLevel;
    };
    requestParams: boolean | ((payload: StartPayload) => any);
    result: boolean | ((payload: SuccessPayload) => any);
  };
}

export interface AsyncModule<RootState, StartPayload, SuccessPayload> {
  getDynamicModule: GetDynamicModule<RootState>;
  selectorHooks: SelectorHooks<StartPayload, SuccessPayload>;
  dispatchHooks: DispatchHooks<StartPayload>;
  selectors: Selectors<StartPayload, SuccessPayload>;
  actions: Actions<StartPayload, SuccessPayload>;
}

export interface DispatchHooks<StartPayload> {
  useAsyncStart: CreateDispatchHookReturn<StartPayload>;
  useAsyncStartOnMount: CreateDispatchHookOnMountReturn<StartPayload>;
  useDismissError: CreateDispatchHookReturn;
  useCancelRequest: CreateDispatchHookReturn;
  useResetModule: CreateDispatchHookReturn;
}

export interface useData<SuccessPayload> {
  (): SuccessPayload;
}
export interface UseLastStartPayload<StartPayload> {
  (): LastStartPayload<StartPayload>;
}
export interface UseIsLoading {
  (): boolean;
}
export interface UseSuccess {
  (): boolean;
}
export interface UseError {
  (): HttpErrorJSON | null;
}

export interface SelectorHooks<StartPayload, SuccessPayload> {
  useData: useData<SuccessPayload>;
  useLastStartPayload: UseLastStartPayload<StartPayload>;
  useIsLoading: UseIsLoading;
  useSuccess: UseSuccess;
  useError: UseError;
}

export interface AsyncModuleConfig<RootState, StartPayload, SuccessPayload> {
  namespace: string;
  actionName: string;
  mainEpicConfig: Omit<
    EpicConfig<RootState, StartPayload, SuccessPayload>,
    'actions'
  >;
  extraEpicConfigs?: EpicConfig<RootState, StartPayload, SuccessPayload>[];
  buildExtraEpics?: (
    actions: Actions<StartPayload, SuccessPayload>,
    selectors: Selectors<StartPayload, SuccessPayload>,
  ) => Epic<StartPayload>[];
  buildExtraHandlers?: (
    actions: Actions<StartPayload, SuccessPayload>,
    selectors: Selectors<StartPayload, SuccessPayload>,
  ) => Handlers<State<StartPayload, SuccessPayload>, any>;
}
