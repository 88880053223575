import { useDeepCompareMemo } from '@react-utils/hooks';
import { isEmpty, noop, some } from 'lodash';
import React, { ReactNode, useState } from 'react';
import uuid from 'uuid-random';
import { ObjectSchema } from 'yup';

import {
  FastFormOptions,
  FastFormValues,
  FormId,
  OptionalFastFormOptions,
} from '../core';
import {
  useOnSubmitErrorCallback,
  useOnSubmitSuccessCallback,
  useStartFormState,
} from '../module/hooks/internal';
import FastFormContext from '.';

function defaultFastFormOptions<
  T extends Record<string, any>
>(): FastFormOptions<T> {
  return {
    enableReinitialize: true,
    deleteFormOnUnmount: false,
    cleanValuesOnReinitialize: false,
    mergeInitialValues: undefined,
    onPressEnter: true,
    isValuesEmpty: (values: FastFormValues<T>) =>
      isEmpty(some<T>(values, isEmpty)),
  };
}

type OwnProps<T extends Record<string, any>> = {
  formId?: FormId;
  initialValues: T;
  validationSchema?: ObjectSchema<T>;
  children: ReactNode;
  options?: OptionalFastFormOptions<T>;
  onSubmitSuccess?: (values: T) => void;
  onSubmitError?: () => void;
};

function OnSubmitErrorWatcher() {
  useOnSubmitErrorCallback();
  return null;
}

function OnSubmitSuccessWatcher() {
  useOnSubmitSuccessCallback();
  return null;
}

function FastFormProvider<T>({
  formId,
  initialValues,
  validationSchema,
  children,
  options = {},
  onSubmitSuccess,
  onSubmitError,
}: OwnProps<T>): any {
  const [ownFormId] = useState(() => {
    if (formId) {
      return formId;
    }
    return uuid();
  });
  const cachedOptions = useDeepCompareMemo(
    (): FastFormOptions<T> => ({
      ...defaultFastFormOptions<T>(),
      ...options,
    }),
    [options],
  );
  const config = useDeepCompareMemo(
    () => ({
      options: cachedOptions,
      formId: ownFormId,
      validationSchema,
      validationDescription: validationSchema && validationSchema.describe(),
      onSubmitSuccess: onSubmitSuccess || noop,
      onSubmitError: onSubmitError || noop,
    }),
    [
      validationSchema,
      cachedOptions,
      ownFormId,
      // onSubmitSuccess, XXX: onSubmitSuccess removed from dependencies array because it was causing a bug
      onSubmitError,
    ],
  );
  useStartFormState(config, initialValues);
  return (
    <FastFormContext.Provider value={config}>
      {onSubmitSuccess && <OnSubmitSuccessWatcher />}
      {onSubmitError && <OnSubmitErrorWatcher />}
      {children}
    </FastFormContext.Provider>
  );
}

FastFormProvider.defaultProps = {
  formId: undefined,
  options: undefined,
  onSubmitSuccess: undefined,
  onSubmitError: undefined,
};

export default FastFormProvider;
