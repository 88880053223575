import { History } from 'history';
import ReactGA from 'react-ga4';

function listenForHistory(history: History): void {
  history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });
}

export default listenForHistory;
