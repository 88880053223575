import { css } from '@ui-system/css';
import { BUTTON_TEXT_UUID, BUTTON_UUID } from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';

import { makeFinalStyleHook } from './internal';

const useButtonDefaultStyle = makeStyle(
  () => css`
    border-radius: 0;
  `,
);

export const useButtonStyle = makeFinalStyleHook(
  BUTTON_UUID,
  useButtonDefaultStyle,
);

const useButtonTextDefaultStyle = makeStyle(() => css``);
export const useButtonTextStyle = makeFinalStyleHook(
  BUTTON_TEXT_UUID,
  useButtonTextDefaultStyle,
);
