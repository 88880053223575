import { css } from '@ui-system/css';
import { DividerProps as _DividerProps } from '@ui-system/interfaces';
import UI from '@ui-system/ui';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React from 'react';

export interface TreeDataItem {
  label: string;
  key: string;
  selected: boolean;
  disabled?: boolean;
  // eslint-disable-next-line unused-imports/no-unused-vars-ts
  children: TreeDataItem[];
}

export interface CustomProps {
  item: TreeDataItem;
}
interface Props {
  data: TreeDataItem[];
  Left?: React.FC<CustomProps>;
  Right?: React.FC<CustomProps>;
  Custom?: React.FC<CustomProps>;
  onChange: (key: string, parent: TreeDataItem) => void;
  laywer?: number;
  DividerProps?: _DividerProps;
  dense?: boolean;
}

const STYLE_DENSE = css`
  padding: 4px;
`;
export const Tree: React.FC<Props> = ({
  data,
  onChange,
  laywer,
  Left,
  Right,
  Custom,
  DividerProps,
  dense,
}: Props) => (
  <UI.Container direction="column" f={1}>
    {map(data, item => (
      <UI.Container direction="column" key={item.key}>
        {Custom ? (
          <Custom item={item} />
        ) : (
          <UI.Container
            align="center"
            justify="space-between"
            p={`0, 2, 0, ${String(laywer + 2)}`}
          >
            {Left && <Left item={item} />}
            <UI.Form.Checkbox
              checked={item.selected}
              disabled={item.disabled}
              onChange={() => onChange(item.key, item)}
              label={() => (
                <UI.Caption modifiers="uppercase">{item.label}</UI.Caption>
              )}
              key={item.key}
              checkStyle={dense && STYLE_DENSE}
            />
            {Right && <Right item={item} />}
          </UI.Container>
        )}
        <Tree
          data={item.children}
          onChange={onChange}
          laywer={laywer + 2}
          Custom={Custom}
          Left={Left}
          Right={Right}
          dense={dense}
        />
        {isEmpty(item.children) && <UI.Divider {...DividerProps} />}
      </UI.Container>
    ))}
  </UI.Container>
);

Tree.defaultProps = {
  laywer: 0,
  DividerProps: { color: 'white' },
};
