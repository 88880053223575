import { noop } from 'lodash';
import { useLayoutEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { FastFormContextValue } from '../../../core';
import { deleteAction, restartAction, startAction } from '../../actions';

function useStartFormState<T>(
  config: FastFormContextValue<T>,
  initialValues: T,
): void {
  const firstTime = useRef(true);
  const dispatch = useDispatch();
  // XXX: must use on layout effect to run dispatch start before others!
  useLayoutEffect(
    () => {
      const { options } = config;
      if (firstTime.current) {
        dispatch(
          startAction(
            config.formId,
            initialValues,
            options,
            config.validationSchema,
          ),
        );
        firstTime.current = false;
      } else if (options.enableReinitialize) {
        dispatch(
          restartAction(
            config.formId,
            initialValues,
            options,
            config.validationSchema,
          ),
        );
      }
      // CLEANUP
      if (options.deleteFormOnUnmount === true) {
        return () => {
          dispatch(deleteAction(config.formId));
        };
      }
      return noop;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    config.options.enableReinitialize ? [config, initialValues] : [],
  );
}

export default useStartFormState;
