import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import SelectMui from '@material-ui/core/Select';
import componentModifiers from '@ui-system/default-modifiers/form/select';
import defaultProps from '@ui-system/default-props/form/select';
import { useSelectStyle } from '@ui-system/default-styles';
import { SelectProps, SelectType } from '@ui-system/interfaces-form';
import UI from '@ui-system/ui';
import map from 'lodash/map';
import noop from 'lodash/noop';
import React, { ElementType, useCallback, useEffect, useState } from 'react';

const Select: SelectType = ({
  options,
  disabled,
  error,
  caption,
  label,
  onChange,
  value,
  style,
  modifiers,
  placeholderI18n,
  IconComponent,
  ...props
}: SelectProps) => {
  const [internalValue, setValue] = useState<string | number | void>(
    value?.value || '',
  );

  const getOnClick = useCallback(
    (option: SelectProps['options'][number]) => () => {
      setValue(option.value);
      onChange(option);
    },
    [onChange],
  );
  const finalStyle = useSelectStyle(
    style,
    `${modifiers}${error && ', error'}${disabled && ', disabled'}`,
    componentModifiers,
  );
  useEffect(() => {
    setValue(value?.value || '');
  }, [value]);

  // @ts-ignore
  return (
    <FormControl disabled={disabled} error={error} fullWidth>
      <UI.Caption i18n={label} />
      {/* @ts-ignore */}
      <SelectMui
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={internalValue}
        onChange={noop}
        disabled={disabled}
        error={error}
        style={finalStyle}
        IconComponent={IconComponent as ElementType}
        displayEmpty
        {...props}
      >
        <MenuItem value="">
          <UI.Caption modifiers={modifiers} i18n={placeholderI18n} />
        </MenuItem>
        {map(options, option => (
          <MenuItem
            value={option.value as string}
            key={option.value as string}
            onClick={getOnClick(option)}
          >
            <UI.Caption modifiers={modifiers}>{option.label}</UI.Caption>
          </MenuItem>
        ))}
      </SelectMui>
      <UI.Caption i18n={caption} />
    </FormControl>
  );
};

Select.defaultProps = defaultProps;
export default Select;
