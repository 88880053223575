import { hashObject } from '@hasher/object-hash';
import { useDeepCompareMemo } from '@react-utils/hooks';
import { deepMergeAll, EMPTY_OBJECT } from '@shared-utils/object';
import { ContainerProps, ContainerType } from '@ui-system/interfaces-container';
import { useTheme } from '@ui-system/theme';
import { O } from '@utils/ts';
import { isReactNative } from 'is-react-native';
import compact from 'lodash/compact';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import memoize from 'lodash/memoize';
import * as React from 'react';
import { useMemo } from 'react';

import { BaseContainer, BaseContainerPressable } from './ContainerView';
import Gradient from './Gradient';

function getStyle(...styles: O.Object[]): O.Object {
  return deepMergeAll(compact(styles));
}

const memoizedGetStyle = memoize(getStyle, (...styles) =>
  hashObject(compact(styles)),
);
// Re-usable logic for web/native
export const Container: ContainerType = ({
  children,
  gap,
  lastChildStyle,
  firstChildStyle,
  onClick,
  ...props
}: ContainerProps) => {
  const theme = useTheme();
  const style = useDeepCompareMemo(() => {
    const { direction } = props;
    if (!gap) return EMPTY_OBJECT;
    if ((direction === 'row' || !direction) && get(theme?.spacing, gap)) {
      return {
        marginLeft: get(theme?.spacing, gap),
      };
    }
    return {
      marginTop: get(theme?.spacing, gap),
    };
  }, [gap, props, theme?.spacing]);

  const otherProps = useDeepCompareMemo(
    () => ({
      ...props,
      [isReactNative() ? 'onPress' : 'onClick']: onClick,
      style: props.style,
    }),
    [onClick, props],
  );

  const Comp = useMemo<ContainerType>(
    () =>
      ((onClick
        ? BaseContainerPressable
        : BaseContainer) as unknown) as ContainerType,
    [onClick],
  );

  return (
    <Comp {...otherProps}>
      {!gap
        ? children
        : React.Children.map(children, (child, i) => {
            if (!child) return null;
            const contentStyle = get(child, 'props.style');
            if (isEmpty(style) && isEmpty(contentStyle)) return child;
            if (i === React.Children.count(children) - 1)
              return React.cloneElement(child as React.ReactElement<any, any>, {
                style: memoizedGetStyle(contentStyle, style, lastChildStyle),
              });
            if (i === 0) {
              return React.cloneElement(child as React.ReactElement<any, any>, {
                style: memoizedGetStyle(contentStyle, firstChildStyle),
              });
            }
            return React.cloneElement(child as React.ReactElement<any, any>, {
              style: memoizedGetStyle(contentStyle, style),
            });
          })}
    </Comp>
  );
};

Container.LinearGradient = Gradient;

export default Container;
