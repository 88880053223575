import { useFastFormField } from '@fast-form/module/hooks/fields';
import { I18nShape } from '@i18n/core';
import { CheckboxProps } from '@ui-system/interfaces-form';
import UI from '@ui-system/ui';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';

import { getFastFormErrorMessage } from '../utils';

interface Props
  extends Omit<
    CheckboxProps,
    'checked' | 'onChange' | 'labelI18n' | 'error' | 'caption'
  > {
  fieldPath: string;
  i18n?: I18nShape;
}
const FastFormCheckBox: React.FC<Props> = ({
  fieldPath,
  i18n,
  ...rest
}: Props) => {
  const { value, setValue, errors, canShowError } = useFastFormField<boolean>(
    fieldPath,
  );
  const caption = useMemo(() => getFastFormErrorMessage(errors), [errors]);
  return (
    <UI.Form.Checkbox
      checked={value}
      onChange={setValue}
      labelI18n={i18n}
      error={canShowError && !isEmpty(errors)}
      caption={canShowError && caption}
      {...rest}
    />
  );
};

export default FastFormCheckBox;
