import { F } from '@utils/ts';
import { isEmpty, noop } from 'lodash';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

export interface Context {
  run: boolean;
  hasFinishedTutorial: boolean;
  startTutorial: () => void;
  stopTutorial: () => void;
  toggleTutorial: () => void;
}

const INITIAL_VALUES: Context = {
  run: false,
  hasFinishedTutorial: false,
  startTutorial: noop,
  stopTutorial: noop,
  toggleTutorial: noop,
};

export const ReactTutorialStepsContext = createContext<Context>(
  INITIAL_VALUES as Context,
);

const TUTORIAL_SKIPPED = 'tutorialSkipped';

export const useContextValue = (): Context => {
  const [run, setRun] = useState<boolean>(false);
  const [hasFinishedTutorial] = useState(() => {
    const hasLocalStorage = localStorage.getItem(TUTORIAL_SKIPPED);
    return !isEmpty(hasLocalStorage);
  });

  const startTutorial = useCallback(() => setRun(true), []);
  const stopTutorial = useCallback(() => {
    localStorage.setItem(TUTORIAL_SKIPPED, 'true');
    setRun(false);
  }, []);
  const toggleTutorial = useCallback(() => setRun(prev => !prev), []);

  return {
    run,
    startTutorial,
    stopTutorial,
    toggleTutorial,
    hasFinishedTutorial,
  };
};

export const useReactTutorialStepsContext = (): Context => {
  const context = useContext(ReactTutorialStepsContext);
  if (!context) return null;
  return context;
};

export const useStartTutorial = (): F.Function => {
  const { startTutorial } = useReactTutorialStepsContext();
  return useCallback(() => startTutorial(), [startTutorial]);
};

export const useToggleTutorial = (): F.Function => {
  const { toggleTutorial } = useReactTutorialStepsContext();
  return useCallback(() => toggleTutorial(), [toggleTutorial]);
};

export const useStartTutorialOnRender = (): void => {
  const { startTutorial, hasFinishedTutorial } = useReactTutorialStepsContext();
  useEffect(() => {
    if (!hasFinishedTutorial) {
      startTutorial();
    }
  }, [hasFinishedTutorial, startTutorial]);
};

export const useStopTutorial = (): F.Function => {
  const { stopTutorial } = useReactTutorialStepsContext();
  return useCallback(() => stopTutorial(), [stopTutorial]);
};
