import { Actions, RequestStartMeta } from '@redux-async-module/interfaces';
import { createAction } from '@redux-basic-module/actions-utils';
import { BaseMeta, ErrorPayload } from '@redux-basic-module/interfaces';

import { createTypes } from './createTypes';

export function createActions<
  StartPayload,
  SuccessPayload,
  Meta extends BaseMeta = BaseMeta
>(
  namespace: string,
  actionName: string,
): Actions<StartPayload, SuccessPayload, Meta> {
  const types = createTypes(namespace, actionName);

  const requestStart = createAction<
    string,
    StartPayload,
    RequestStartMeta & Meta
  >(types.REQUEST_START);
  const start = createAction<string, StartPayload, Meta>(types.START);
  const success = createAction<string, SuccessPayload, Meta>(types.SUCCESS);
  const error = createAction<string, ErrorPayload, Meta>(types.ERROR);
  const dismissError = createAction<string, null, Meta>(types.DISMISS_ERROR);
  const reset = createAction<string, null, Meta>(types.RESET);
  const cancel = createAction<string, null, Meta>(types.CANCEL);

  return {
    requestStart,
    start,
    success,
    error,
    dismissError,
    reset,
    cancel,
  };
}
