import logger from '@logger/core';
import {
  CANCEL_PATTERN,
  DISMISS_ERROR_PATTERN,
  ERROR_PATTERN,
  REQUEST_START_PATTERN,
  RESET_PATTERN,
  START_PATTERN,
  SUCCESS_PATTERN,
} from '@redux-async-module/actions-utils';
import { Actions } from '@redux-async-module/interfaces';
import { Handlers } from '@redux-basic-module/interfaces';
import defaultTo from 'lodash/defaultTo';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import values from 'lodash/values';

const log = logger('logic-async-module');

export function createHandlers<State, StartPayload, SuccessPayload>(
  actions: Actions<StartPayload, SuccessPayload>,
  initialState: State,
): Handlers<State> {
  const handlers = {};
  forEach(values(actions), action => {
    if (includes(action.type, REQUEST_START_PATTERN)) {
      handlers[action.type] = state => state;
      return;
    }
    if (includes(action.type, START_PATTERN)) {
      handlers[action.type] = (state, { payload }) => ({
        ...state,
        lastStartPayload: defaultTo(payload, {}),
        success: false,
        isLoading: true,
        lastCacheDate: null,
      });
      return;
    }
    if (includes(action.type, SUCCESS_PATTERN)) {
      handlers[action.type] = (state, { payload, meta }) => ({
        ...state,
        data: defaultTo(payload, {}),
        success: true,
        isLoading: false,
        error: null,
        lastCacheDate: meta.lastCacheDate || null,
      });
      return;
    }
    if (includes(action.type, ERROR_PATTERN)) {
      handlers[action.type] = (state, { payload }) => ({
        ...state,
        error: defaultTo(payload, {}),
        success: false,
        isLoading: false,
      });
      return;
    }
    if (includes(action.type, DISMISS_ERROR_PATTERN)) {
      handlers[action.type] = state => ({
        ...state,
        error: null,
        success: false,
      });
      return;
    }
    if (includes(action.type, CANCEL_PATTERN)) {
      handlers[action.type] = state => ({ ...state, isLoading: false });
      return;
    }

    if (includes(action.type, RESET_PATTERN)) {
      handlers[action.type] = () => initialState;
      return;
    }
    handlers[action.type] = state => state;
    log.error(`No case was provided for the action type: ${action.type}`);
  });
  return handlers;
}
