import { EMPTY_OBJECT } from '@shared-utils/object';
import {
  ComponentModifiersFunction,
  Modifiers,
  Style,
} from '@ui-system/interfaces';
import { useMediaQueries } from '@ui-system/media-query';
import { useTheme } from '@ui-system/theme';
import { U } from '@utils/ts';
import noop from 'lodash/noop';
import { useMemo } from 'react';

import { applyModifiers } from './applyModifiers';

export function useModifiers(
  modifiers: U.Nullable<Modifiers>,
  componentModifiers: U.Nullable<ComponentModifiersFunction> = noop as ComponentModifiersFunction,
  previousStyle: U.Nullable<Style> = undefined,
): Style {
  const theme = useTheme();
  const mediaQueries = useMediaQueries();
  return useMemo(
    () =>
      applyModifiers(
        modifiers,
        componentModifiers
          ? componentModifiers(theme, mediaQueries)
          : EMPTY_OBJECT,
        previousStyle,
      ),
    [componentModifiers, mediaQueries, modifiers, previousStyle, theme],
  );
}
