import {
  createRecProductVideoComponents,
  RecProduct,
  RecProductProps,
} from '@pro-page-components/rec-product';
import { useChildrenVisibilityEditor } from '@pro-page-components/utils/useChildrenVisibility';
import { useRestrictComponentByRegion } from '@pro-page-components/utils/useRestrictComponentByRegion';
import * as React from 'react';
import { ComponentForEditor } from 'react-page-editor/state';

import config from './config.json';
import defaultProps from './defaultProps.json';

const { Video, VideoEditor } = createRecProductVideoComponents({
  name: 'REC Alpha Black Series Video',
  id: 'REC_ALPHA_BLACK_VIDEO',
  path: '.....',
  parent: config.id,
});

export const RecAlphaBlackSeries: ComponentForEditor<RecProductProps> = (
  props: RecProductProps,
) => {
  const restrict = useRestrictComponentByRegion(config);
  if (restrict) return null;
  return <RecProduct VideoComponent={Video} {...props} />;
};

export const RecAlphaBlackSeriesEditor: ComponentForEditor<RecProductProps> = (
  props: RecProductProps,
) => {
  // eslint-disable-next-line no-underscore-dangle,react/destructuring-assignment
  const visible = useChildrenVisibilityEditor(props.__config);
  if (!visible) return null;
  return (
    <RecAlphaBlackSeries
      scale={0.9}
      skipVisibilityCheck
      VideoComponent={VideoEditor}
      {...props}
    />
  );
};
RecAlphaBlackSeries.children = [Video];

RecAlphaBlackSeries.defaultProps = defaultProps as RecProductProps;
RecAlphaBlackSeries.config = config;
RecAlphaBlackSeriesEditor.defaultProps = defaultProps as RecProductProps;
RecAlphaBlackSeriesEditor.config = config;
RecAlphaBlackSeriesEditor.children = [VideoEditor];
