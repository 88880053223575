import React from 'react';

export function pleaseImplement<T extends React.FC>(
  name: string,
  information?: string,
): T {
  const Component = ({ children }: { children: React.ReactNode }) => (
    <div>
      <h4>
        PLEASE IMPLEMENT {name} {information}
      </h4>
      {children}
    </div>
  );
  return (Component as unknown) as T;
}
