import { FastFormInput, FastFormSubmitButton } from '@fast-form/components';
import HtmlParser from '@html-parser/core';
import FastFormProvider from '@lib/fast-form/context/FastFormProvider';
import Link from '@material-ui/core/Link';
import { ScaleProps, SectionWrapper } from '@rec/section-wrapper';
import {
  BACK,
  COMPANY_ADDRESS,
  COMPANY_NAME,
  CONTACT,
  EDIT,
  EMAIL,
  IMPRINT,
  LEGAL_INFORMATION,
  PHONE,
  SAVE,
  WEB,
} from '@resource/i18n-shapes';
import { getFieldPathsV2 } from '@shared-utils/object';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import { IsNotEmpty } from 'class-validator-i18n';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import values from 'lodash/values';
import normalizeUrl from 'normalize-url';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ComponentForEditor,
  useComponentProps,
  useSetComponentProps,
  useViewModeContext,
} from 'react-page-editor/state';

import config from './config.json';
import defaultProps from './defaultProps.json';

const STYLE = css`
  letter-spacing: 5px;
`;

class ContactFormData {
  phone = '';

  email = '';

  web = '';

  @IsNotEmpty()
  companyName = '';

  @IsNotEmpty()
  companyAddress = '';
}
const INITIAL_VALUES = new ContactFormData();
const FIELD_PATHS = getFieldPathsV2(INITIAL_VALUES);

interface ContactProps extends ScaleProps {
  title?: string;
  phone?: string;
  email?: string;
  web?: string;
  companyName?: string;
  companyAddress?: string;
  Form?: React.FC;
  Toggle?: React.ReactElement;
}
export const Contact: ComponentForEditor<ContactProps> = ({
  title,
  phone,
  email,
  web,
  companyName,
  companyAddress,
  Form,
  Toggle,
  scale,
}: ContactProps) => {
  const { isMobile } = useViewModeContext();
  const content = HtmlParser(title);
  return (
    <SectionWrapper bg="primary" padding scale={scale}>
      <UI.Container gap={4} direction="column" p={isMobile ? '0, 4' : null}>
        {!Form && (
          <UI.Container align="center">
            <UI.H4 modifiers="light, uppercase" style={STYLE}>
              {content}
            </UI.H4>
            {Toggle && Toggle}
          </UI.Container>
        )}
        {!Form ? (
          <UI.Container direction="column" gap={4}>
            <UI.Container gap={1} direction="column">
              {phone && (
                <UI.Container align="center" gap={1}>
                  <UI.Body2 i18n={PHONE} suffix=":" modifiers="bold" />
                  <Link href={`tel:${phone}`} target="_blank">
                    <UI.Body2>{phone}</UI.Body2>
                  </Link>
                </UI.Container>
              )}
              {email && (
                <UI.Container align="center" gap={1}>
                  <UI.Body2 i18n={EMAIL} suffix=":" modifiers="bold" />
                  <Link href={`mailto:${email}`} target="_blank">
                    <UI.Body2>{email}</UI.Body2>
                  </Link>
                </UI.Container>
              )}
              {web && (
                <UI.Container align="center" gap={1}>
                  <UI.Body2 i18n={WEB} suffix=":" modifiers="bold" />
                  <Link href={normalizeUrl(web)} target="_blank">
                    <UI.Body2>{web}</UI.Body2>
                  </Link>
                </UI.Container>
              )}
            </UI.Container>
            <UI.Container direction="column">
              <UI.Body2
                i18n={IMPRINT}
                suffix=":"
                modifiers="light, uppercase, letterSpacing1"
              />
              <UI.Body2>{companyName}</UI.Body2>
              <UI.Body2>{companyAddress}</UI.Body2>
            </UI.Container>
          </UI.Container>
        ) : (
          <Form />
        )}
      </UI.Container>
    </SectionWrapper>
  );
};

Contact.defaultProps = defaultProps;
Contact.config = config;

interface ToggleFormProps {
  onToggle: VoidFunction;
}
const ToggleForm: React.FC<ToggleFormProps> = ({
  onToggle,
}: ToggleFormProps) => (
  <UI.Container>
    <UI.Button onClick={onToggle} i18n={EDIT} accessoryLeft="edit" />
  </UI.Container>
);

export const ContactEditor: ComponentForEditor<ContactProps> = () => {
  const [showForm, setShowForm] = useState(true);
  const componentProps = useComponentProps<ContactProps>(Contact.config.id);
  const set = useSetComponentProps();
  const onSuccess = useCallback(
    (data: ContactFormData) => {
      set(Contact.config, data);
      setShowForm(false);
    },
    [set],
  );
  useEffect(() => {
    const hasSomePreeFilledField = !every(
      values(pick(componentProps, keys(new ContactFormData()))),
      isEmpty,
    );
    if (!hasSomePreeFilledField) {
      setShowForm(true);
    }
  }, [componentProps]);
  const initialValues = useMemo<ContactFormData>(() => {
    const formValues = new ContactFormData();
    formValues.companyName = componentProps?.companyName;
    formValues.companyAddress = componentProps?.companyAddress;
    formValues.web = componentProps?.web;
    formValues.email = componentProps?.email;
    formValues.phone = componentProps?.phone;
    return formValues;
  }, [
    componentProps?.companyAddress,
    componentProps?.companyName,
    componentProps?.email,
    componentProps?.phone,
    componentProps?.web,
  ]);
  const Form: React.FC = () => (
    <FastFormProvider<ContactFormData>
      initialValues={initialValues}
      onSubmitSuccess={onSuccess}
    >
      <UI.Container direction="column" gap={3}>
        <UI.H4
          modifiers="light, uppercase"
          i18n={LEGAL_INFORMATION}
          style={STYLE}
        />
        <UI.Container w={500}>
          <FastFormInput
            fieldPath={FIELD_PATHS.companyName.$path}
            placeholder={COMPANY_NAME}
          />
        </UI.Container>
        <UI.Container w={500}>
          <FastFormInput
            fieldPath={FIELD_PATHS.companyAddress.$path}
            placeholder={COMPANY_ADDRESS}
          />
        </UI.Container>
        <UI.H4 modifiers="light, uppercase" i18n={CONTACT} style={STYLE} />
        <UI.Container w={500}>
          <FastFormInput
            fieldPath={FIELD_PATHS.phone.$path}
            placeholder={PHONE}
          />
        </UI.Container>
        <UI.Container w={500}>
          <FastFormInput
            fieldPath={FIELD_PATHS.email.$path}
            placeholder={EMAIL}
          />
        </UI.Container>
        <UI.Container w={500}>
          <FastFormInput fieldPath={FIELD_PATHS.web.$path} placeholder={WEB} />
        </UI.Container>
        <UI.Container gap={4}>
          <FastFormSubmitButton modifiers="dark" i18n={SAVE} />
          <UI.Button i18n={BACK} onClick={() => setShowForm(false)} />
        </UI.Container>
      </UI.Container>
    </FastFormProvider>
  );
  return (
    <Contact
      Form={showForm ? Form : null}
      Toggle={!showForm && <ToggleForm onToggle={() => setShowForm(true)} />}
      scale={0.9}
      {...componentProps}
    />
  );
};
ContactEditor.defaultProps = Contact.defaultProps;
ContactEditor.config = config;
