import { Action } from 'redux';
import { BATCH, BatchAction } from 'redux-batched-actions';
import { EMPTY, from, of, OperatorFunction } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { isAnyType, isType } from './utils';

export function ofType<
  // All possible actions your app can dispatch
  Input extends Action | BatchAction,
  // The types you want to filter for
  Type extends Input['type'],
  // The resulting actions that match the above types
  Output extends Input = Extract<Input, Action<Type>>
>(...types: [Type, ...Type[]]): OperatorFunction<Input, Output> {
  const len = types.length;
  return mergeMap(action => {
    if (len === 1) {
      if (isType(action, types[0])) {
        return of(<Output>action);
      }
      if (isType(action, BATCH)) {
        const batchAction: BatchAction = <BatchAction>action;
        return from(
          <Output[]>(
            batchAction.payload.filter(childAction =>
              isType(childAction, types[0]),
            )
          ),
        );
      }
    } else if (isAnyType(action, types)) {
      return of(<Output>action);
    } else if (isType(action, BATCH)) {
      const batchAction: BatchAction = <BatchAction>action;
      return from(
        <Output[]>(
          batchAction.payload.filter(childAction =>
            isAnyType(childAction, types),
          )
        ),
      );
    }
    return EMPTY;
  });
}
