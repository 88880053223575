import HtmlParser from '@html-parser/core';
import { PdfThumbnail } from '@pdf/core';
import { useComponentServerProps } from '@pro-page-components/utils';
import {
  useChildrenVisibility,
  useChildrenVisibilityEditor,
} from '@pro-page-components/utils/useChildrenVisibility';
import { DOWNLOAD_DATASHEET, DOWNLOAD_FACTSHEET } from '@rec/i18n';
import { ScaleProps, SectionWrapper } from '@rec/section-wrapper';
import { getStaticUrlWithParams } from '@rec/static';
import { Color, css } from '@ui-system/css';
import UI from '@ui-system/ui';
import { openUrl } from 'browser';
// import download from 'downloadjs';
import * as React from 'react';
import { useCallback } from 'react';
import {
  ComponentConfig,
  ComponentForEditor,
  useViewModeContext,
} from 'react-page-editor/state';
import ReactPlayer from 'react-player';

import config from './config.json';
import defaultProps from './defaultProps.json';

const STYLE = css`
  letter-spacing: 3px;
`;

const MOBILE_STYLE = css`
  letter-spacing: 4px;
`;

const BUTTON_TEXT_STYLE = css`
  line-height: 1;
`;

export type VideoComponentProps = Pick<
  RecProductProps,
  'video' | 'videoTitle'
> & {
  skipVisibilityCheck?: boolean;
  __config: ComponentConfig;
};

export type VideoComponent = ComponentForEditor<VideoComponentProps>;

export const RecProductVideo: React.FC<VideoComponentProps> = ({
  videoTitle,
  video,
  skipVisibilityCheck,
  __config,
}: VideoComponentProps) => {
  const isVisible = useChildrenVisibility(__config, skipVisibilityCheck);
  const { isMobile } = useViewModeContext();
  if (!isVisible) return null;
  return (
    <UI.Container
      p={isMobile ? 1 : '4, 8, 0, 8'}
      gap={4}
      direction="column"
      align="center"
      id={__config.id}
    >
      <UI.Container f={1} direction="column" gap={4} align="center">
        {!isMobile ? (
          <UI.H4 modifiers="primary">{videoTitle}</UI.H4>
        ) : (
          <UI.H6 modifiers="primary">{videoTitle}</UI.H6>
        )}
        <ReactPlayer
          url={video}
          width={!isMobile ? 720 : '100%'}
          height={!isMobile ? 480 : undefined}
        />
      </UI.Container>
    </UI.Container>
  );
};

export const RecProductVideoEditor: React.FC<VideoComponentProps> = (
  props: VideoComponentProps,
) => {
  // eslint-disable-next-line no-underscore-dangle,react/destructuring-assignment
  const isVisible = useChildrenVisibilityEditor(props.__config);
  if (!isVisible) return null;
  return <RecProductVideo skipVisibilityCheck {...props} />;
};

export function createRecProductVideoComponents(
  componentConfig: ComponentConfig,
): {
  Video: VideoComponent;
  VideoEditor: VideoComponent;
} {
  function getConfig(parentConfig: ComponentConfig) {
    return {
      ...componentConfig,
      id: `${parentConfig.id}.children.${componentConfig.id}`,
      parent: parentConfig.id,
    };
  }
  const Video: VideoComponent = ({
    __config,
    ...rest
  }: VideoComponentProps) => {
    // eslint-disable-next-line no-underscore-dangle
    const __configVideo = getConfig(__config);
    return <RecProductVideo __config={__configVideo} {...rest} />;
  };

  Video.config = componentConfig;

  const VideoEditor: VideoComponent = ({
    __config,
    ...rest
  }: VideoComponentProps) => {
    // eslint-disable-next-line no-underscore-dangle
    const __configVideoEditor = getConfig(__config);
    return (
      <RecProductVideoEditor
        __config={__configVideoEditor}
        skipVisibilityCheck
        {...rest}
      />
    );
  };

  VideoEditor.config = Video.config;

  return {
    Video,
    VideoEditor,
  };
}

export interface RecProductProps extends ScaleProps {
  title?: string;
  text?: string;
  datasheet?: string;
  factsheet?: string;
  datasheetThumb?: string;
  datasheetText?: string;
  image?: string;
  bg?: Color;
  video?: string;
  videoTitle?: string;
  // LOCAL PROPS ALWAYS
  VideoComponent?: React.FC<{
    video?: string;
    videoTitle?: string;
    __config: ComponentConfig;
  }>;
  __config: ComponentConfig;
  skipVisibilityCheck?: boolean;
}
export const RecProduct: ComponentForEditor<RecProductProps> = ({
  scale,
  VideoComponent,
  __config,
  skipVisibilityCheck,
  ...rest
}: RecProductProps) => {
  const {
    title,
    text,
    videoTitle,
    bg,
    video,
    datasheetText,
    datasheet,
    datasheetThumb,
    image,
    factsheet,
  } = useComponentServerProps<
    Omit<RecProductProps, 'scale' | 'VideoComponent' | '__config'>
  >(__config, rest);
  const titleContent = HtmlParser(title);
  const textContent = HtmlParser(text);
  const datasheetTextContent = HtmlParser(datasheetText);
  const handleDownloadDatasheetPdf = useCallback(async () => {
    await openUrl(getStaticUrlWithParams(datasheet));
    // download(getStaticUrlWithParams(datasheet));
  }, [datasheet]);
  const handleDownloadFactsheetPdf = useCallback(async () => {
    await openUrl(getStaticUrlWithParams(factsheet));
    // download(getStaticUrlWithParams(datasheet));
  }, [factsheet]);
  const isVisible = useChildrenVisibility(__config, skipVisibilityCheck);
  const { isMobile } = useViewModeContext();
  if (!isVisible) return null;
  return (
    <SectionWrapper bg={bg} padding scale={scale} id={__config.id}>
      <UI.Container m={isMobile ? '2, 0, 4, 0' : '0, 0, 6, 0'}>
        {isMobile ? (
          <UI.H6 modifiers={bg === 'black' ? 'light' : ''} style={MOBILE_STYLE}>
            {titleContent}
          </UI.H6>
        ) : (
          <UI.H4 modifiers={bg === 'black' ? 'light' : ''} style={STYLE}>
            {titleContent}
          </UI.H4>
        )}
      </UI.Container>
      <UI.Container gap={isMobile ? 0 : 4}>
        {isMobile ? (
          <UI.Container f={4} justify="center" direction="column" gap={4}>
            <UI.Body2 modifiers={bg === 'black' ? 'light' : ''}>
              {textContent}
            </UI.Body2>
            <UI.Container justify="center" direction="row">
              <UI.Container w={160} direction="column" gap={4}>
                <PdfThumbnail
                  url={getStaticUrlWithParams(datasheetThumb)}
                  height="auto"
                  width={160}
                />
                <UI.Container>
                  <UI.Button
                    onClick={handleDownloadDatasheetPdf}
                    accessoryLeft="download"
                  >
                    <UI.Body2
                      i18n={DOWNLOAD_DATASHEET}
                      style={BUTTON_TEXT_STYLE}
                    />
                  </UI.Button>
                </UI.Container>
                {factsheet && (
                  <UI.Container>
                    <UI.Button
                      onClick={handleDownloadFactsheetPdf}
                      accessoryLeft="download"
                    >
                      <UI.Body2
                        i18n={DOWNLOAD_FACTSHEET}
                        style={BUTTON_TEXT_STYLE}
                      />
                    </UI.Button>
                  </UI.Container>
                )}
              </UI.Container>
              <UI.Container direction="column" f={2} align="flex-end">
                <UI.Image
                  src={getStaticUrlWithParams(image)}
                  height="100%"
                  width="160px"
                />
              </UI.Container>
            </UI.Container>
            <UI.Body2 modifiers={bg === 'black' ? 'light' : ''}>
              {datasheetTextContent}
            </UI.Body2>
          </UI.Container>
        ) : (
          <>
            <UI.Container f={4} justify="center" direction="column" gap={4}>
              <UI.Body2 modifiers={bg === 'black' ? 'light' : ''}>
                {textContent}
              </UI.Body2>
              <UI.Container justify="center" direction="row">
                <UI.Container direction="column" gap={2} w={160}>
                  <PdfThumbnail
                    url={getStaticUrlWithParams(datasheetThumb)}
                    height="auto"
                    width={160}
                  />
                  <UI.Button
                    onClick={handleDownloadDatasheetPdf}
                    accessoryLeft="download"
                  >
                    <UI.Body2
                      i18n={DOWNLOAD_DATASHEET}
                      style={BUTTON_TEXT_STYLE}
                    />
                  </UI.Button>
                  {factsheet && (
                    <UI.Button
                      onClick={handleDownloadFactsheetPdf}
                      accessoryLeft="download"
                      i18n={DOWNLOAD_FACTSHEET}
                    >
                      <UI.Body2
                        i18n={DOWNLOAD_FACTSHEET}
                        style={BUTTON_TEXT_STYLE}
                      />
                    </UI.Button>
                  )}
                </UI.Container>
                <UI.Body2 modifiers={bg === 'black' ? 'light' : ''}>
                  {datasheetTextContent}
                </UI.Body2>
              </UI.Container>
            </UI.Container>
            <UI.Container direction="column" f={2} align="flex-end">
              <UI.Image
                src={getStaticUrlWithParams(image)}
                height={400}
                width="auto"
              />
            </UI.Container>
          </>
        )}
      </UI.Container>
      {VideoComponent && (
        <VideoComponent
          video={video}
          videoTitle={videoTitle}
          __config={__config}
        />
      )}
    </SectionWrapper>
  );
};

RecProduct.defaultProps = (defaultProps as unknown) as RecProductProps;
RecProduct.config = config;
