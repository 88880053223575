import './wrapper.css';

import { useIcon } from '@lib/icons-set-provider';
import IconButton from '@material-ui/core/IconButton';
import defaultProps from '@ui-system/default-props/icon';
import { IconProps } from '@ui-system/interfaces-icon';
import { useHex } from '@ui-system/theme/colors';
import * as React from 'react';
import { SyntheticEvent, useCallback, useMemo } from 'react';
import { Props, ReactSVG } from 'react-svg';

const beforeInjection: Props['beforeInjection'] = svg => {
  svg.setAttribute('display', 'flex');
  svg.setAttribute('height', 'inherit');
};
const Icon: React.FC<IconProps> = ({
  name,
  size,
  color,
  onClick,
}: IconProps) => {
  const icon = useIcon(name);
  const fill = useHex(color);
  const style = useMemo(
    () => ({
      height: size,
      width: size,
      fill,
      cursor: onClick ? 'pointer' : undefined,
    }),
    [fill, onClick, size],
  );
  const handleClick = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      onClick();
    },
    [onClick],
  );

  if (!icon) return null;
  if (onClick)
    return (
      <IconButton onClick={handleClick}>
        <ReactSVG
          src={icon}
          style={style}
          beforeInjection={beforeInjection}
          className="web-icon-wrapper-class"
        />
      </IconButton>
    );
  return (
    <ReactSVG
      src={icon}
      style={style}
      beforeInjection={beforeInjection}
      className="web-icon-wrapper-class"
    />
  );
};

Icon.defaultProps = defaultProps;

export default Icon;

export const AnimatedIcon = null;
