import { I18nShape } from '@i18n/core';
import { REC_PRO_PAGE as bundleName } from '@resource/bundle-names';

import { TOOLBOX_NAMESPACE } from './constants';

export const WELCOME_TO_THE_REC_TOOLBOX_TITLE: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.welcomeToTheRecToolboxTitle`,
  text: 'Welcome to the <strong>REC Toolbox</strong>',
  bundleName,

  metadata: {
    description: 'Welcome title for the toolbox page',
  },
};

export const WELCOME_TO_THE_REC_PRO_PAGE_TITLE: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.welcomeToTheRecProPageTitle`,
  text: 'Welcome to the <strong>REC ProPage</strong>',
  bundleName,

  metadata: {
    description: 'Welcome title for the auth modal',
  },
};

export const FOR_SECURITY_REASONS_ENTER_YOUR_EMAIL: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.forSecurityReasonsEnterYourEmail`,
  text:
    'For security reasons, please enter the email you are using to log into the REC ProPortal.',
  bundleName,
};
export const FOR_SECURITY_REASONS_ENTER_YOUR_TAN: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.forSecurityReasonsEnterYourTAN`,
  text: 'Now please enter the code that we sent to your email',
  bundleName,
};

export const WELCOME_TO_THE_REC_TOOLBOX_DESCRIPTION: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.welcomeToTheRecToolboxDescription`,
  text:
    '<strong>Enrich your website and media channels with professional REC marketing content.</strong> Keep your customers informed about the latest REC news, products and benefits. Just browse the following categories and copy or download the content of your interest.',
  bundleName,

  metadata: {
    description: 'Welcome description for the toolbox page',
  },
};

export const ADD_TO_MY_REC_PRO_PAGE: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.addToMyRecProPage`,
  text: 'Add to my REC ProPage',
  bundleName,
};

export const OPEN_PRO_PAGE_EDITOR: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.openProPageEditor`,
  text: 'Open ProPage Editor',
  bundleName,
};

export const EDIT_AND_PUBLISH_MY_REC_PRO_PAGE: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.editAndPublishMyRecProPage`,
  text: 'EDIT AND PUBLISH My RECProPage',
  bundleName,
};

export const SHARE_ON_SOCIAL_NETWORKS: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.shareOnSocialNetworks`,
  text: 'Share on your social networks',
  bundleName,
};

export const CREATE_A_REC_PRO_PAGE: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.createARecProPage`,
  text: 'Create a REC ProPage',
  bundleName,
};

export const CREATE_A_REC_PRO_PAGE_DESCRIPTION: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.createARecProPageDescription`,
  text:
    'With the REC ProPage you can create a customised and dynamic REC marketing page, which you can easily integrate to your media channels, just by copying a single URL to the channel of your choice.',
  bundleName,
};

export const CREATE_A_REC_PRO_PAGE_CALL_TO_ACTION: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.createARecProPageCallToAction`,
  text: 'Create your REC ProPage, share and conquer new customers!',
  bundleName,
};

export const REC_TOOLBOX: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.recToolbox`,
  text: 'REC <strong>Toolbox</strong>',
  bundleName,
};

export const REC_PROPAGE: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.recProPage`,
  text: 'REC <strong>ProPage</strong>',
  bundleName,
};

export const CONTENT_TO_DRIVE_SALES: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.contentToDriveSales`,
  text: "Content to Drive Sales on installers' websites with REC Group",
  bundleName,
};
export const CREATE_YOUR_CUSTOMIZED: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.createYourCustomized`,
  text:
    'Create and share your own REC ProPage, a customizable and dynamic website with just a few clicks!',
  bundleName,
};

export const CREATE_NOW: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.createNow`,
  text: 'Create now',
  bundleName,
};

export const CONTENT: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.content`,
  text: 'Content',
  bundleName,
};

export const LANGUAGES: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.languages`,
  text: 'Languages',
  bundleName,
};

export const SELECT_ALL: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.selectAll`,
  text: 'Select all',
  bundleName,
};

export const SELECT_A_PANEL_MODEL: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.selectAPanelModel`,
  text: 'Select a panel model',
  bundleName,
};

export const PANELS_MODELS: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.panelsModels`,
  text: 'Panels models',
  bundleName,
};

export const WEB_TOOLBOX: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.webToolbox`,
  text: '<strong>REC Web Toolbox</strong>',
  bundleName,
};

export const WEB_TOOLBOX_DESCRIPTION: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.webToolboxDescription`,
  text:
    'Using the <strong>REC Toolbox</strong>, you can add prepared REC content into your own existing website to enrich and update it. Just browse the categories and decide which REC content you need, then copy and paste into your current website platform.',
  bundleName,
};

export const OPEN_REC_TOOLBOX: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.openToolbox`,
  text: 'open REC Toolbox',
  bundleName,
};

export const PROPAGE_DESCRIPTION: I18nShape = {
  key: `${TOOLBOX_NAMESPACE}.proPageDescription`,
  text:
    'With an <strong>REC ProPage</strong> you can create and share a customized and dynamic webpage with the latest REC content. This can be easily integrated into your own website, media channels or used as a stand- alone website.',
  bundleName,
};
