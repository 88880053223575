import {
  useHasLoggedUser,
  useLogout,
  useSessionExpired,
  useUserEmail,
} from '@authentication/redux-auth-data/hooks';
import { useSignInByEmailStart } from '@authentication/redux-sign-in-by-email';
import { UserRole } from '@authentication/shared-enums';
import { EN } from '@i18n/utils';
import { useLocation, useNavigateTo, useQueryParams } from '@lib/navigation';
import { containsAny } from '@lib/utils-string';
import { SUPPORTED_LANGUAGES } from '@rec/constants';
import { useUserPageUrls } from '@rec/logic-live-is-available';
import { EDITOR } from '@rec/routes';
import { createDispatchHook } from '@redux-basic-module/hooks-utils';
import { usePersistedStoredFinalized } from '@redux-persisted/module/hooks';
import {
  useChangeLanguage,
  useCurrentLanguage,
} from '@resource/redux-language';
import { extractLanguageAndCountryFromLocaleString } from '@resource/utils';
import { openUrl } from 'browser';
import { isNil, omitBy } from 'lodash';
import qs from 'querystring';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { U } from 'ts-toolbelt';

import { setUserInfo } from './actions';
import { emailSelector, userInfoSelector } from './selectors';
import { SetUserInfoPayload, State } from './types';

function useUserInfoEmail(): string {
  return useSelector(emailSelector);
}
export const useSetUserInfo = createDispatchHook(setUserInfo);

export function useUserInfo(): State {
  const currentLanguage = useCurrentLanguage();
  const userEmail = useUserEmail();
  const info = useSelector(userInfoSelector);
  return {
    email: info?.email || userEmail,
    language: info?.language || currentLanguage,
    country: info?.country,
  };
}

const useDefaultCountryAndLanguage = () =>
  useMemo(
    () => extractLanguageAndCountryFromLocaleString(navigator?.language),
    [],
  );

export const useRouteCountryAndLanguage = (): {
  language: U.Nullable<string>;
  country: U.Nullable<string>;
} => {
  const { language, country } = useQueryParams<SetUserInfoPayload>();
  return useMemo(
    () => ({
      language,
      country,
    }),
    [language, country],
  );
};

interface LocaleData {
  language: string;
  country: string;
}
export const useInitialLocaleData = (): LocaleData => {
  const info = useSelector(userInfoSelector);
  const defaultLocale = useDefaultCountryAndLanguage();
  const routeLocale = useRouteCountryAndLanguage();
  return useMemo<LocaleData>(
    () =>
      ({
        ...omitBy(defaultLocale, isNil),
        ...omitBy(info, isNil),
        ...omitBy(routeLocale, isNil),
      } as LocaleData),
    [routeLocale, defaultLocale, info],
  );
};

export function useSetUserInfoOnMountForToolBox(): void {
  const { language, country } = useInitialLocaleData();
  const setLanguage = useChangeLanguage();
  const set = useSetUserInfo();
  const navigate = useNavigateTo();
  const location = useLocation();

  useEffect(() => {
    if (language) {
      setLanguage({
        language,
      });
    }

    set({
      language,
      country,
    });
  }, [country, language, location.pathname, navigate, set, setLanguage]);
}

export function useOpenEditorPage(): () => void {
  const params = useQueryParams<SetUserInfoPayload>();
  return useCallback(() => {
    (async () => {
      await openUrl(
        `${window.location.origin}${EDITOR}?${qs.stringify(params)}`,
      );
    })();
  }, [params]);
}

export const CheckSupportedLanguages: React.FC = () => {
  const { language } = useUserInfo();
  const setLanguage = useChangeLanguage();
  const set = useSetUserInfo();
  useEffect(() => {
    if (language && !SUPPORTED_LANGUAGES.includes(language)) {
      setLanguage({
        language: EN,
      });
      set({
        language: EN,
      });
    }
  }, [language, set, setLanguage]);
  return null;
};
export const CheckRecProPageAuth: React.FC = () => {
  const { error } = useUserPageUrls();
  const persistedDone = usePersistedStoredFinalized();
  const signInByEmailStart = useSignInByEmailStart();
  const email = useUserInfoEmail();
  const logout = useLogout();
  useEffect(() => {
    if (containsAny(error?.message, '401', '403') && persistedDone) {
      logout();
      if (email) {
        signInByEmailStart({ email, role: UserRole.USER });
      }
    }
  }, [email, error, logout, persistedDone, signInByEmailStart]);

  return null;
};

export function useHandleRecProPageAuth(): void {
  const hasLoggedUser = useHasLoggedUser();
  const sessionExpired = useSessionExpired();
  const signInByEmailStart = useSignInByEmailStart();
  const email = useUserInfoEmail();
  const hasValidLogin = hasLoggedUser && !sessionExpired;
  const persistedDone = usePersistedStoredFinalized();
  useEffect(() => {
    if (!hasValidLogin && email && persistedDone) {
      signInByEmailStart({ email, role: UserRole.USER });
    }
  }, [email, hasValidLogin, persistedDone, signInByEmailStart]);
}
