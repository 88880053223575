import { I18nShape } from '@i18n/core';
import { REQUEST_ERROR } from '@resource/i18n-shapes';
import { HttpErrorJSON } from '@service-layer-utils/errors';
import { Color, css } from '@ui-system/css';
import { ContainerProps } from '@ui-system/interfaces-container';
import UI from '@ui-system/ui';
import get from 'lodash/get';
import * as React from 'react';

const SEVERITY_COLOR_MAP: Record<string, Color> = {
  warn: 'error',
  info: 'info',
  fatal: 'error',
  trace: 'info',
  debug: 'info',
  emerg: 'error',
};
interface Props extends ContainerProps {
  error: HttpErrorJSON;
  onDismiss: VoidFunction;
}

export const ErrorCard: React.FC<Props> = ({
  error,
  onDismiss,
  ...rest
}: Props) => {
  const i18n: I18nShape = get(error as HttpErrorJSON, 'i18n', REQUEST_ERROR);

  if (!error) return null;
  return (
    <UI.Container
      justify="space-between"
      gap={2}
      m={4}
      p={4}
      bg={SEVERITY_COLOR_MAP[error.level]}
      align="center"
      shadow={2}
      borderRadius={20}
      {...rest}
    >
      <UI.Typography
        style={css`
          flex: 3;
        `}
        i18n={i18n}
      />
      <UI.Icon name="close" color="black" size={50} onClick={onDismiss} />
    </UI.Container>
  );
};
