import { createSelector } from '@redux-basic-module/selector-utils';

import { NAMESPACE, State } from './types';

export const errorSelector = createSelector<State['error']>([
  NAMESPACE,
  'error',
]);

export const dataSelector = createSelector<State['data']>([NAMESPACE, 'data']);

export const loadingSelector = createSelector<State['isLoading']>([
  NAMESPACE,
  'isLoading',
]);

export const publishModeSelector = createSelector<State['publishMode']>([
  NAMESPACE,
  'publishMode',
]);
