import { createActions } from '@redux-async-module/actions-utils';
import { createEpic } from '@redux-async-module/epic-utils';
import { fixEpicArrayToString } from '@redux-async-module/epic-utils/fixEpicToString';
import { createHandlers } from '@redux-async-module/handlers-utils';
import {
  AsyncModule,
  AsyncModuleConfig,
  INITIAL_STATE,
  RequestStartMeta,
  Selectors,
  State,
} from '@redux-async-module/interfaces';
import {
  createDispatchHook,
  createDispatchHookOnMount,
  createSelectorHook,
} from '@redux-basic-module/hooks-utils';
import {
  Action,
  BaseMeta,
  ErrorPayload,
  GetDynamicModule,
} from '@redux-basic-module/interfaces';
import { createReducer } from '@redux-basic-module/reducer-utils';
import { createSelector } from '@redux-basic-module/selector-utils';
import { HttpErrorJSON } from '@service-layer-utils/errors';
import { call } from '@shared-utils/function';
import defaultTo from 'lodash/defaultTo';
import flatMap from 'lodash/flatMap';
import merge from 'lodash/merge';

export function createModule<RootState, StartPayload, SuccessPayload>(
  config: AsyncModuleConfig<RootState, StartPayload, SuccessPayload>,
): AsyncModule<RootState, StartPayload, SuccessPayload> {
  const {
    namespace,
    mainEpicConfig,
    buildExtraEpics,
    extraEpicConfigs,
    actionName,
    buildExtraHandlers,
  } = config;

  // create actions kit start, success, error, etc
  const actions = createActions<StartPayload, SuccessPayload>(
    namespace,
    actionName,
  );

  const selectors: Selectors<StartPayload, SuccessPayload> = {
    isLoading: createSelector<boolean>([namespace, 'isLoading'], false),
    success: createSelector<boolean>([namespace, 'success'], false),
    data: createSelector<SuccessPayload>([namespace, 'data']),
    error: createSelector<HttpErrorJSON | null>([namespace, 'error'], null),
    lastStartPayload: createSelector<StartPayload>([
      namespace,
      'lastStartPayload',
    ]),
    lastCacheDate: createSelector<number | null>([namespace, 'lastCacheDate']),
  };
  // create data hooks
  const useData = createSelectorHook<SuccessPayload | null>(selectors.data);
  const useLastStartPayload = createSelectorHook<StartPayload | null>(
    selectors.lastStartPayload,
  );
  const useIsLoading = createSelectorHook<boolean>(selectors.isLoading);
  const useSuccess = createSelectorHook<boolean>(selectors.success);
  const useError = createSelectorHook<HttpErrorJSON | null>(selectors.error);

  // create action hooks
  const useAsyncStart = createDispatchHook<
    StartPayload,
    RequestStartMeta & BaseMeta
  >(actions.requestStart);

  const useAsyncStartOnMount = createDispatchHookOnMount<
    StartPayload,
    RequestStartMeta & BaseMeta
  >(actions.requestStart);

  const useDismissError = createDispatchHook<ErrorPayload>(
    actions.dismissError,
  );
  const useCancelRequest = createDispatchHook(actions.cancel);
  const useResetModule = createDispatchHook(actions.reset);

  // create the handlers (handlers are the same as the switch case inside the reducer)
  const handlers = merge(
    createHandlers<
      State<StartPayload, SuccessPayload>,
      StartPayload,
      SuccessPayload
    >(actions, INITIAL_STATE),
    defaultTo(call(buildExtraHandlers, actions, selectors), {}),
  );

  // create the reducer
  const reducer = createReducer<
    State<StartPayload, SuccessPayload>,
    Action<string, StartPayload | SuccessPayload>
  >(namespace, INITIAL_STATE, handlers);

  // create the main epic based on the actions kit
  const mainEpics = createEpic<
    RootState,
    StartPayload,
    SuccessPayload,
    BaseMeta
  >(
    {
      actions,
      ...mainEpicConfig,
    },
    selectors,
  );

  // Merge other epic configs you may pass
  const epics = [
    ...mainEpics,
    ...flatMap(extraEpicConfigs, epicConfig =>
      createEpic(epicConfig, selectors),
    ),
    ...fixEpicArrayToString(
      defaultTo(
        call<
          ReturnType<typeof buildExtraEpics>,
          [typeof actions, typeof selectors]
        >(buildExtraEpics, actions, selectors),
        [],
      ),
    ),
  ];

  // return the getDynamicModuleFunction ready to use it, just rename it
  const getDynamicModule: GetDynamicModule<RootState> = () => ({
    id: namespace,
    reducerMap: {
      [namespace]: reducer,
    },
    epics,
  });
  return {
    getDynamicModule,
    dispatchHooks: {
      useAsyncStart,
      useAsyncStartOnMount,
      useDismissError,
      useResetModule,
      useCancelRequest,
    },
    selectorHooks: {
      useSuccess,
      useData,
      useLastStartPayload,
      useIsLoading,
      useError,
    },
    actions,
    selectors,
  };
}
