import { LogLevel } from '@logger/core';

export const DEFAULT_HEX_COLORS: Record<LogLevel, string> = {
  [LogLevel.TRACE]: '#18b3c4',
  [LogLevel.DEBUG]: '#1fbf8e',
  [LogLevel.INFO]: '#00ca53',
  [LogLevel.WARN]: '#ff7a7a',
  [LogLevel.ERROR]: '#ff0000',
  [LogLevel.EMERG]: '#990000',
  [LogLevel.FATAL]: '#4f0000',
};

export const LOG_API_PATH = '/log';
