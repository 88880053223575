import logger from '@logger/core';
import { openUrl } from 'browser';
import { includes, isEmpty, isString, uniqueId } from 'lodash';
import normalizeUrl from 'normalize-url';
import React, { ComponentType, Fragment, ReactElement } from 'react';
import ReactHtmlParser, { processNodes, Transform } from 'react-html-parser';
import { U } from 'ts-toolbelt';

import tagsToModifier, { tableAttributesToProps } from './tagsMap';

const log = logger('html-parser');

const TABLE_TAGS = ['table', 'tr', 'td', 'th', 'thead', 'tbody'];
// const MODIFIERS_TO_AVOID_IN_A = [
//   'body1',
//   'body2',
//   'caption1',
//   'caption2',
//   'span',
//   'h1',
//   'h2',
//   'h3',
//   'h4',
//   'h5',
//   'h6',
//   'p',
// ];
const generateModifiers = (
  modifiers: string,
  componentModifier: string,
): string => `${modifiers}, ${componentModifier}`;

const Render: React.FC = ({ children }: React.PropsWithChildren<any>) => (
  <>{children}</>
);

const HtmlParser = (
  html: string,
  Component: U.Nullable<ComponentType<unknown> | string>,
  modifiers: U.Nullable<string>,
  TypographyComponent: ComponentType<unknown>,
  variant: U.Nullable<string>,
): U.Nullable<ReactElement[]> => {
  try {
    if (!isString(html) || isEmpty(html)) {
      return null;
    }
    const RenderComponent = Component || Render;
    const transform: Transform = node => {
      let children = null;
      const props = { ...node.attribs };
      if (props) {
        if (props.style && !includes(TABLE_TAGS, node.name)) delete props.style;
        if (props.class) delete props.class;
      }
      if (node.children) {
        children = processNodes(node.children, transform);
      }
      if (node.type === 'tag' && node.name === 'img') {
        return React.createElement('img', { ...node.attribs });
      }
      if (node.type === 'tag' && node.name === 'ul') {
        return React.createElement('ul', { key: uniqueId() }, children);
      }
      if (node.type === 'tag' && node.name === 'ol') {
        return React.createElement('ol', { key: uniqueId() }, children);
      }
      if (node.type === 'tag' && node.name === 'li') {
        return React.createElement('li', { key: uniqueId() }, children);
      }
      if (node.type === 'tag' && node.name === 'strong') {
        return React.createElement('strong', { key: uniqueId() }, children);
      }
      if (node.type === 'tag' && node.name === 'u') {
        return React.createElement('u', { key: uniqueId() }, children);
      }
      if (node.type === 'tag' && node.name === 'u') {
        return React.createElement('u', { key: uniqueId() }, children);
      }
      if (node.type === 'tag' && node.name === 'h1') {
        return React.createElement(
          'h1',
          { key: uniqueId(), style: { margin: 0 } },
          children,
        );
      }
      if (node.type === 'tag' && node.name === 'h2') {
        return React.createElement(
          'h2',
          { key: uniqueId(), style: { margin: 0 } },
          children,
        );
      }
      if (node.type === 'tag' && node.name === 'h3') {
        return React.createElement(
          'h3',
          { key: uniqueId(), style: { margin: 0 } },
          children,
        );
      }
      if (node.type === 'tag' && node.name === 'h4') {
        return React.createElement(
          'h4',
          { key: uniqueId(), style: { margin: 0 } },
          children,
        );
      }
      if (node.type === 'tag' && node.name === 'h5') {
        return React.createElement(
          'h5',
          { key: uniqueId(), style: { margin: 0 } },
          children,
        );
      }
      if (node.type === 'tag' && node.name === 'h6') {
        return React.createElement(
          'h6',
          { key: uniqueId(), style: { margin: 0 } },
          children,
        );
      }
      if (node.type === 'tag' && includes(TABLE_TAGS, node.name)) {
        tableAttributesToProps(props);
        return React.createElement(
          node.name,
          { key: uniqueId(), ...props },
          children,
        );
      }
      if (node.type === 'tag' && node.name === 'a') {
        const customModifiers = 'link';
        const { onclick, href } = node.attribs;
        if (!isEmpty(onclick)) {
          const linkRegex = /'(.+)'/;
          const url = linkRegex.exec(onclick);
          return React.createElement(
            Component || TypographyComponent,
            {
              key: uniqueId(),
              // @ts-ignore
              href: url[1],
              target: '_blank',
              modifiers: customModifiers,
              component: 'span',
              variant,
              paragraph: false,
              onClick: () => openUrl(normalizeUrl(href)),
              style: {
                cursor: 'pointer',
              },
            },
            children,
          );
        }
        return React.createElement(
          Component || TypographyComponent,
          {
            key: uniqueId(),
            modifiers: customModifiers,
            onClick: () => openUrl(normalizeUrl(href)),
            component: 'span',
            variant,
            paragraph: false,
            style: {
              cursor: 'pointer',
            },
            ...props,
          },
          children,
        );
      }
      if (node.type === 'tag' && node.name !== 'br' && node.name !== 'a') {
        if (node.name === 'p') {
          return React.createElement(
            TypographyComponent,
            {
              key: uniqueId(),
              ...props,
            },
            children,
          );
        }
        const componentModifier = tagsToModifier[node.name] || 'body2';
        const customModifiers = generateModifiers(modifiers, componentModifier);
        if (['html', 'body', 'head'].indexOf(node.name) > -1) {
          return React.createElement(Fragment, { key: uniqueId() }, children);
        }
        return React.createElement(
          RenderComponent,
          {
            key: uniqueId(),
            variant: customModifiers,
            ...props,
          },
          children,
        );
      }
      if (node.type === 'text') {
        const customModifiers = generateModifiers(modifiers, 'body2');
        return React.createElement(
          RenderComponent,
          {
            key: uniqueId(),
            modifiers: customModifiers,
            ...props,
          },
          node.data,
        );
      }
      if (node.type === 'tag' && node.name === 'br') {
        return React.createElement('br', { key: uniqueId() });
      }
      return undefined;
    };
    const options = {
      transform,
    };
    return ReactHtmlParser(html, options);
  } catch (error) {
    log.error('Error during conversion of question html=', html, error);
    return null;
  }
};

export default HtmlParser;
