import pxPerfect from '@ui-system/px-perfect';
import { Theme } from '@ui-system/theme';
import { F } from '@utils/ts';
import { isString, join, map, split, take } from 'lodash';

import createFromProps from '../create-from-props';
import { FromProps, Margin } from '../types';

const applyMargin: F.Function<[Theme, Margin]> & FromProps = (
  theme,
  margin: Margin,
) => {
  let values;
  let value;
  if (isString(margin)) values = map(split(margin, ','), item => Number(item));
  if (theme && theme.spacing[margin] && !values) value = theme.spacing[margin];
  if (value) return `margin: ${pxPerfect(value)}`;
  if (values) {
    const realValues = map(take(values, 4), vl =>
      pxPerfect(theme.spacing[vl as number]),
    );
    return `margin: ${join(realValues, ' ')}`;
  }
  return '';
};
applyMargin.fromProps = createFromProps(applyMargin, 'm');

export default applyMargin;
