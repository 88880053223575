import { Theme } from '@ui-system/theme';
import { F } from '@utils/ts';

import createFromProps from './create-from-props';
import { FromProps } from './types';

export const applyDevBorder: F.Function<[Theme, boolean]> & FromProps = (
  theme,
  dev: boolean,
) => {
  if (dev) return 'border: 1px solid red';
  return '';
};
applyDevBorder.fromProps = createFromProps(applyDevBorder, '___dev');
