import HtmlParser from '@html-parser/core';
import { useComponentServerProps } from '@pro-page-components/utils';
import { ScaleProps, SectionWrapper } from '@rec/section-wrapper';
import { getStaticUrlWithParams } from '@rec/static';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import * as React from 'react';
import {
  ComponentForEditor,
  useComponentProps,
  useViewModeContext,
} from 'react-page-editor/state';

import config from './config.json';
import defaultProps from './defaultProps.json';

const STYLE = css`
  letter-spacing: 3px;
`;

const MOBILE_STYLE = css`
  letter-spacing: 4px;
`;

interface RecCertifiedSolarProfessionalsProps extends ScaleProps {
  image?: string;
  title?: string;
  textLeft?: string;
  textRight?: string;
}
export const RecCertifiedSolarProfessionals: ComponentForEditor<RecCertifiedSolarProfessionalsProps> = ({
  scale,
  ...rest
}: RecCertifiedSolarProfessionalsProps) => {
  const {
    image,
    textLeft,
    textRight,
    title,
  } = useComponentServerProps<RecCertifiedSolarProfessionalsProps>(
    config,
    rest,
  );
  const { isMobile } = useViewModeContext();
  const titleContent = HtmlParser(title);
  const contentLeft = HtmlParser(textLeft);
  const contentRight = HtmlParser(textRight);
  return (
    <SectionWrapper bg="white" padding scale={scale}>
      <UI.Container direction="column">
        <UI.Container m="0, 0, 4, 0">
          {isMobile ? (
            <UI.H5 modifiers="primary, bold, uppercase" style={MOBILE_STYLE}>
              {titleContent}
            </UI.H5>
          ) : (
            <UI.H4 modifiers="primary, bold, uppercase" style={STYLE}>
              {titleContent}
            </UI.H4>
          )}
        </UI.Container>
        <UI.Container direction={isMobile ? 'column' : 'row'} f={1} gap={2}>
          <UI.Container
            direction="column"
            align={isMobile ? 'center' : null}
            f={1}
            gap={6}
          >
            <UI.Image
              src={getStaticUrlWithParams(image)}
              height={isMobile ? 'auto' : 130}
              width={isMobile ? 300 : 'auto'}
              resizeMode="cover"
            />
            <UI.Container w={isMobile ? null : 450} f={1}>
              <UI.Body2>{contentLeft}</UI.Body2>
            </UI.Container>
          </UI.Container>
          <UI.Container direction="column" f={1} gap={2}>
            <UI.Container>
              <UI.Body2>{contentRight}</UI.Body2>
            </UI.Container>
          </UI.Container>
        </UI.Container>
      </UI.Container>
    </SectionWrapper>
  );
};

export const RecCertifiedSolarProfessionalsEditor: ComponentForEditor<RecCertifiedSolarProfessionalsProps> = () => {
  const componentProps = useComponentProps<RecCertifiedSolarProfessionalsProps>(
    RecCertifiedSolarProfessionals.config.id,
  );
  return <RecCertifiedSolarProfessionals scale={0.9} {...componentProps} />;
};

RecCertifiedSolarProfessionals.defaultProps = defaultProps as RecCertifiedSolarProfessionalsProps;
RecCertifiedSolarProfessionals.config = config;
RecCertifiedSolarProfessionalsEditor.defaultProps = defaultProps as RecCertifiedSolarProfessionalsProps;
RecCertifiedSolarProfessionalsEditor.config = config;
