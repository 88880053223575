import { createStore, IModule, IModuleStore } from 'redux-dynamic-modules';
import { getObservableExtension } from 'redux-dynamic-modules-observable';

interface IState {
  [key: string]: Record<string, any>;
}

export * from 'redux-dynamic-modules';

const createDynamicStore = (modules: IModule<any>[]): IModuleStore<IState> =>
  createStore(
    {
      initialState: {
        /** initial state */
      },
      enhancers: [],
      extensions: [getObservableExtension()],
    },
    ...modules,
  );

export default createDynamicStore;
