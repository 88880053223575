import createLogger, {
  getMessageChannel,
  LogLevel,
  Message,
} from '@logger/core';
import { JsonConsoleLoggerOptions } from '@logger/json-console-transport';
import { LogDataDTOClient } from '@logger/utils';
import map from 'lodash/map';
import { EMPTY, Observable } from 'rxjs';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { bufferTime, catchError, filter, switchMap } from 'rxjs/operators';

const TIME_TO_WAIT = 10000;

const LOG_GROUP = 'logger-api-transport';
const logger = createLogger(LOG_GROUP);

function convertMessageToJSON(
  msg: Message,
  options: JsonConsoleLoggerOptions,
): LogDataDTOClient {
  const { addParams, fullMessage: enableFullMessage } = options;

  const {
    message,
    fullMessage,
    date,
    group,
    level,
    params,
    messageArgs,
    operation,
  } = msg;
  return {
    params: {
      ...(options.staticParams || {}),
      ...(params || {}),
      ...(addParams ? addParams(msg) : {}),
      messageArgs,
    },
    message: enableFullMessage === true ? fullMessage || message : message,
    date,
    level,
    group,
    operation,
  };
}

function createSubscribeToLogger(options: ApiLoggerOptions) {
  const { apiUrl, getHeaders } = options;
  function subscribeToLogger(msgs: Message[]): Observable<AjaxResponse> {
    const jsonArray: LogDataDTOClient[] = map(msgs, msg =>
      convertMessageToJSON(msg, options),
    );
    const customHeaders = getHeaders != null ? getHeaders() : {};
    const headers = {
      'Content-Type': 'application/json',
      ...customHeaders,
    };
    return ajax.post(apiUrl, { logs: jsonArray }, headers);
  }
  return subscribeToLogger;
}

function logResult(): void {
  // logger.debug('Sent logs with success');
}

function logsFromOtherComponents(msg: Message) {
  return msg.group.indexOf(LOG_GROUP) === -1;
}

function hasLogs(msgs: Array<Message>) {
  return msgs && msgs.length > 0;
}

function immediateLog(msg: Message) {
  return (
    msg &&
    (msg.level === LogLevel.FATAL ||
      msg.level === LogLevel.ERROR ||
      msg.level === LogLevel.EMERG)
  );
}

function notImmediateLog(msg: Message) {
  return msg && !immediateLog(msg);
}

function logError(error: AjaxError | Error) {
  const res = error instanceof AjaxError ? error.response : error;
  const status = error instanceof AjaxError ? error.status : -1;
  logger.error(`Error when trying to log messages, status=${status}`, res);
  return EMPTY;
}

export type ApiLoggerOptions = {
  apiUrl: string;
  getHeaders?: () => Record<string, string>;
} & JsonConsoleLoggerOptions;

export function registerApiLoggerTransport(options: ApiLoggerOptions): void {
  const sendLogs = createSubscribeToLogger(options);

  const subject = getMessageChannel();

  subject
    .pipe(
      filter(Boolean),
      filter(logsFromOtherComponents),
      filter(notImmediateLog),
      bufferTime(TIME_TO_WAIT),
      filter(hasLogs),
      switchMap(msgs => sendLogs(msgs).pipe(catchError(logError))),
      catchError(logError),
    )
    .subscribe(logResult);

  subject
    .pipe(
      filter(Boolean),
      filter(logsFromOtherComponents),
      filter(immediateLog),
      switchMap(msg => sendLogs([msg]).pipe(catchError(logError))),
      catchError(logError),
    )
    .subscribe(logResult);
}
