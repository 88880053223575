import { Hex } from '@ui-system/color';
import { Color } from '@ui-system/css';

import { useTheme } from '../useTheme';
import { getColorByName } from './getColorByName';
import { ColorMode } from './interfaces';

export function useColors(): ColorMode {
  const theme = useTheme();
  return theme.colors[theme.mode];
}

export function useColor(color: Color): Hex {
  const colors = useColors();
  return getColorByName(color, colors);
}
