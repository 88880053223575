import { useToggleTutorial } from '@lib/react-tutorial-steps';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import React from 'react';

const ROUND = css`
  border-radius: 100%;
`;

export const Toggle: React.FC = () => {
  const toggleTutorial = useToggleTutorial();
  return (
    <UI.Container
      align="center"
      bg="gray.A500"
      justify="center"
      style={ROUND}
      h={20}
      w={20}
    >
      <UI.Icon
        name="question"
        color="white"
        size={13}
        onClick={toggleTutorial}
      />
    </UI.Container>
  );
};

export { default as TutorialProvider } from './TutorialProvider';
export { default as useEditorTutorialSteps } from './useEditorTutorialSteps';
export { default as useYourCompanyTutorialSteps } from './useYourCompanyTutorialSteps';
