import HtmlParser from '@html-parser/core';
import { Typography as MuiTypography } from '@material-ui/core';
import { createTypographySet } from '@ui-system/common-typography';
import componentModifiers from '@ui-system/default-modifiers/typography';
import { useTypographyStyle } from '@ui-system/default-styles';
import { useFormattedDate, useTranslatedMessage } from '@ui-system/deps';
import {
  TypographyProps,
  TypographyType,
} from '@ui-system/interfaces-typography';
import compact from 'lodash/compact';
import join from 'lodash/join';
import * as React from 'react';
import { useMemo } from 'react';
import { STRING_HTML_REGEX } from 'regex';

function addPrefixSuffix(...texts: string[]): string {
  return join(compact(texts), '');
}
const Typography: TypographyType = ({
  i18n,
  children,
  style,
  modifiers,
  date,
  prefix,
  suffix,
  values,
  variant,
  ...rest
}: TypographyProps) => {
  const formatedDate = useFormattedDate(date);
  const text = useTranslatedMessage(i18n, values);
  const finalStyle = useTypographyStyle(style, modifiers, componentModifiers);
  let content = null;
  if (STRING_HTML_REGEX.test(text)) {
    content = HtmlParser(text, { modifiers, variant });
  }

  const finalValue = useMemo(() => {
    if (text) return addPrefixSuffix(prefix, text, suffix);
    return children;
  }, [children, prefix, suffix, text]);

  return (
    <MuiTypography {...rest} style={finalStyle} variant={variant}>
      {formatedDate || content || finalValue}
    </MuiTypography>
  );
};

export const {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Subtitle1,
  Subtitle2,
  Body1,
  Body2,
  Caption,
  ButtonText,
  Overline,
} = createTypographySet(Typography);

export default Typography;
