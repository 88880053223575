import {
  CreateSelectorHookReturn,
  Selector,
} from '@redux-basic-module/interfaces';
import { createSelector } from '@redux-basic-module/selector-utils';
import { useSelector } from 'react-redux';

export function createSelectorHookFromEntries<ItemType>(
  entries: string[],
): CreateSelectorHookReturn<ItemType> {
  return function useSelectorHook(): ItemType {
    const selector = createSelector<ItemType>(entries);
    return useSelector(selector);
  };
}

export function createSelectorHook<ItemType>(
  selector: Selector<ItemType>,
): CreateSelectorHookReturn<ItemType> {
  return function useSelectorHook(): ItemType {
    return useSelector(selector);
  };
}
