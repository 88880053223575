import { AuthModal } from '@authentication/auth-modal';
import { useHasAValidLogin } from '@authentication/redux-auth-data/hooks';
import { useAuthenticateBySSOTokenError } from '@authentication/redux-authenticate-by-sso';
import { useQueryParams } from '@lib/navigation';
import { Contact } from '@pro-page-components/contact';
import { SocialMedia } from '@pro-page-components/social-media';
import { YourCompany } from '@pro-page-components/your-company';
import { usePageContentContext } from '@react-page-editor/final-renderer';
import {
  useDeepCompareCallback,
  useDeepCompareMemo,
  useIsOpen,
} from '@react-utils/hooks';
import {
  DESKTOP_VIEW,
  MOBILE_VIEW,
  PREVIEW,
  PUBLISH,
  WATCH_VIDEO,
  WELCOME_TO_THE_REC_PRO_PAGE_TITLE,
} from '@rec/i18n';
import {
  generateKey,
  useCreateUpdateDraft,
  useCreateUpdateDraftData,
  useIsLoadingCreateUpdateDraft,
} from '@rec/redux-page-creator-draft';
import {
  PublishMode,
  useIsPublishModeNotIdle,
  useSetPublishMode,
} from '@rec/redux-page-creator-live';
import {
  useRetrieveDraft,
  useRetrieveDraftData,
} from '@rec/redux-retrieve-draft';
import { getStaticUrlWithParams } from '@rec/static';
import {
  CheckRecProPageAuth,
  CheckSupportedLanguages,
  useHandleRecProPageAuth,
  useSetUserInfo,
  useUserInfo,
} from '@rec/user-info';
import { PublishModal } from '@rec/view-live';
import { usePersistedStoredFinalized } from '@redux-persisted/module/hooks';
import { EDIT, SAVE } from '@resource/i18n-shapes';
import { useLanguagesList } from '@resource/languages-list';
import {
  useChangeLanguage,
  useCurrentLanguage,
} from '@resource/redux-language';
import { extractLanguageAndCountryFromLocaleString } from '@resource/utils';
import { EMPTY_OBJECT } from '@shared-utils/object';
import { Color, css } from '@ui-system/css';
import { ErrorCard } from '@ui-system/error-card';
import { OptionType, Style } from '@ui-system/interfaces';
import { makeStyle } from '@ui-system/style';
import UI from '@ui-system/ui';
import { openUrl } from 'browser';
import { delay } from 'lodash';
import concat from 'lodash/concat';
import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import set from 'lodash/set';
import uniq from 'lodash/uniq';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ContextComponent,
  ModifiedBy,
  PreviewMode,
  traverseComponentsTree,
  useReactPageEditorModule,
  useViewModeContext,
  ViewModeContextProvider,
} from 'react-page-editor/state';
import {
  useEditorContext,
  useIsEditorEmpty,
  useSetupReactPageEditorModule,
} from 'react-page-editor/state/module';
import { Tooltip } from 'tooltip';

import { EditorBody } from './EditorBody';
import { Loader } from './Loader';
import { Preview } from './Preview';
import { TutorialVideo } from './TutorialVideo';

interface HeaderConfig {
  hideHeaderTopRightLogo?: boolean;
}
export function useHeaderConfig(): HeaderConfig {
  const { contents } = usePageContentContext();
  const retrieveDraftData = useRetrieveDraftData();
  const createUpdateDraftData = useCreateUpdateDraftData();
  const draft = useDeepCompareMemo(
    () => createUpdateDraftData || retrieveDraftData,
    [retrieveDraftData, createUpdateDraftData],
  );
  return useMemo(
    () =>
      contents?.headerConfig || draft?.contents?.headerConfig || EMPTY_OBJECT,
    [contents, draft?.contents?.headerConfig],
  );
}
export function useUpdateHeaderConfig(): (config: HeaderConfig) => void {
  const createOrUpdateDraft = useCreateUpdateDraft();
  const retrieveDraftData = useRetrieveDraftData();
  const createUpdateDraftData = useCreateUpdateDraftData();
  const userInfo = useUserInfo();
  const draft = useDeepCompareMemo(
    () => createUpdateDraftData || retrieveDraftData,
    [retrieveDraftData, createUpdateDraftData],
  );
  const { components } = useEditorContext();

  return useCallback(
    headerConfig => {
      createOrUpdateDraft({
        key: generateKey(userInfo.email),
        contents: {
          supportedLanguages: draft?.contents?.supportedLanguages
            ? uniq(
                concat(draft?.contents?.supportedLanguages, userInfo.language),
              )
            : [userInfo.language],
          language: userInfo.language,
          components,
          headerConfig,
          country:
            userInfo.country ||
            extractLanguageAndCountryFromLocaleString(navigator?.language)
              .country,
        },
      });
    },
    [
      components,
      createOrUpdateDraft,
      draft?.contents?.supportedLanguages,
      userInfo.country,
      userInfo.email,
      userInfo.language,
    ],
  );
}

export function useHeaderConfigState(): {
  headerConfig: HeaderConfig;
  updateHeaderConfig: (headerConfig: HeaderConfig) => void;
} {
  const headerConfig = useHeaderConfig();
  const updateHeaderConfig = useUpdateHeaderConfig();
  return {
    headerConfig,
    updateHeaderConfig,
  };
}
function useProPageHandlers() {
  const createOrUpdateDraft = useCreateUpdateDraft();
  const retrieveDraftData = useRetrieveDraftData();
  const createUpdateDraftData = useCreateUpdateDraftData();
  const userInfo = useUserInfo();
  const draft = useDeepCompareMemo(
    () => createUpdateDraftData || retrieveDraftData,
    [retrieveDraftData, createUpdateDraftData],
  );
  const headerConfig = useHeaderConfig();
  const onSave = useDeepCompareCallback(
    components => {
      createOrUpdateDraft({
        key: generateKey(userInfo.email),
        contents: {
          supportedLanguages: draft?.contents?.supportedLanguages
            ? uniq(
                concat(draft?.contents?.supportedLanguages, userInfo.language),
              )
            : [userInfo.language],
          language: userInfo.language,
          components,
          headerConfig,
          country:
            userInfo.country ||
            extractLanguageAndCountryFromLocaleString(navigator?.language)
              .country,
        },
      });
    },
    [
      headerConfig,
      createOrUpdateDraft,
      draft?.contents?.supportedLanguages,
      userInfo.country,
      userInfo.email,
      userInfo.language,
    ],
  );
  const onComponentsChanged = useCallback(onSave, [onSave]);
  return useDeepCompareMemo(
    () => ({
      onSave,
      onComponentsChanged,
    }),
    [onSave, onComponentsChanged],
  );
}

const ROUND = css`
  border-radius: 100%;
`;

const LAST_CHILD = css`
  margin-left: 100px;
`;

const BANNER_STYLE = css`
  position: fixed;
  bottom: 0;
  width: 100%;
`;

const useStyle = makeStyle<{ selected: boolean }>(({ theme, utils, props }) =>
  props.selected
    ? css`
        border: 2px solid ${theme.colors[theme.mode].primary.main};
        ${utils.applyBackgroundColor(theme, 'primary')}
      `
    : css`
        border: 2px solid ${theme.colors[theme.mode].primary.main};
      `,
);

interface PreviewModeProps {
  iconColor: Color;
  style: Style;
  onClick: VoidFunction;
}

const Banner = () => (
  <UI.Container style={BANNER_STYLE}>
    <UI.Image
      src={getStaticUrlWithParams('/images/banners/banner_toolbox.jpg')}
      height={650}
      width="100%"
      resizeMode="cover"
    />
  </UI.Container>
);
function usePreviewModeProps(mode: PreviewMode): PreviewModeProps {
  const { mode: contextMode, setMode } = useViewModeContext();
  const style = useStyle({ selected: contextMode === mode });
  const onClick = useCallback(() => {
    setMode(mode);
  }, [mode, setMode]);

  return useMemo<PreviewModeProps>(
    () => ({
      iconColor: contextMode === mode ? 'black' : 'primary',
      style,
      onClick,
    }),
    [onClick, contextMode, style, mode],
  );
}
const PreviewButton = props => {
  const { isPreviewActive, togglePreviewActive } = useEditorContext();
  const { setDesktopMode } = useViewModeContext();
  const desktopIconProps = usePreviewModeProps(PreviewMode.DESKTOP);
  const mobileIconProps = usePreviewModeProps(PreviewMode.MOBILE);
  return (
    <UI.Container gap={1} {...props}>
      <UI.Button
        onClick={() => {
          togglePreviewActive();
          setDesktopMode();
        }}
        i18n={PREVIEW}
        variant={isPreviewActive ? 'contained' : 'outlined'}
      />
      {isPreviewActive && (
        <UI.Container gap={1}>
          <Tooltip i18n={DESKTOP_VIEW}>
            <UI.Container
              align="center"
              justify="center"
              onClick={desktopIconProps.onClick}
              style={desktopIconProps.style}
              p="0, 2"
            >
              <UI.Icon
                name="desktop"
                size={20}
                color={desktopIconProps.iconColor}
              />
            </UI.Container>
          </Tooltip>
          <Tooltip i18n={MOBILE_VIEW}>
            <UI.Container
              align="center"
              style={mobileIconProps.style}
              justify="center"
              onClick={mobileIconProps.onClick}
              p="0, 2"
            >
              <UI.Icon
                name="mobile"
                size={20}
                color={mobileIconProps.iconColor}
              />
            </UI.Container>
          </Tooltip>
        </UI.Container>
      )}
    </UI.Container>
  );
};

const HEADER_STYLE = css`
  position: fixed;
  z-index: 9999;
`;

function useLanguagesOptions(): OptionType[] {
  const currentLanguage = useCurrentLanguage();
  const languages = useLanguagesList(currentLanguage);
  return useMemo<OptionType[]>(
    () =>
      map(languages, item => ({
        label: item.name,
        value: item.code,
      })),
    [languages],
  );
}

const SELECT_STYLE = css`
  background: black;
  border: none;
`;

const STYLE = css`
  margin-top: 4px;
  cursor: pointer;
  right: 8px;
`;
const IconComponent: React.FC = props => (
  <UI.Container style={STYLE} {...props}>
    <UI.Icon name="down" size={15} color="primary" />
  </UI.Container>
);
const LanguageSelector = props => {
  const options = useLanguagesOptions();
  const { isPreviewActive } = useEditorContext();

  const changeLanguage = useChangeLanguage();
  const setUserInfo = useSetUserInfo();
  const currentLanguage = useCurrentLanguage();
  const [value, setValue] = useState<OptionType>(
    find(options, op => op.value === currentLanguage),
  );
  const onChange = useCallback(
    (item: OptionType) => {
      changeLanguage({
        language: item.value as string,
      });
      setUserInfo({ language: item.value as string });
      setValue(item);
    },
    [changeLanguage, setUserInfo],
  );
  if (isPreviewActive) return null;
  return (
    <UI.Container {...props} m="0, 4, 0, 0">
      <UI.Form.Select
        options={options}
        onChange={onChange}
        value={value}
        style={SELECT_STYLE}
        modifiers="primary, uppercase"
        IconComponent={IconComponent}
        disableUnderline
      />
    </UI.Container>
  );
};

const SaveButton = props => {
  const isSaving = useIsLoadingCreateUpdateDraft();
  const { onSaveConfiguration } = useEditorContext();
  return (
    <UI.Container {...props}>
      <UI.Button
        onClick={onSaveConfiguration}
        i18n={SAVE}
        variant="outlined"
        loading={isSaving}
      />
    </UI.Container>
  );
};
const Header: React.FC = () => {
  const { isPreviewActive, togglePreviewActive } = useEditorContext();
  const setPublishMode = useSetPublishMode();
  const isPublishModeNotIdle = useIsPublishModeNotIdle();
  const hasValidLogin = useHasAValidLogin();
  const isEditorEmpty = useIsEditorEmpty();
  const { setDesktopMode } = useViewModeContext();
  const { isOpen, toggle } = useIsOpen();
  return (
    <UI.Page.Header style={isPreviewActive ? HEADER_STYLE : null}>
      <TutorialVideo toggle={toggle} visible={isOpen} />
      <UI.Container
        bg="black"
        f={1}
        p={1}
        align="center"
        justify="space-between"
      >
        <UI.Image
          src={getStaticUrlWithParams('/images/logos/logo_pro_page.png')}
          height={50}
          width={150}
        />
        <UI.Container p={1} align="center" lastChildStyle={LAST_CHILD}>
          <UI.Container gap={1} className="my-fifth-step">
            {hasValidLogin && (
              <UI.Container>
                <UI.Button
                  onClick={() => {
                    if (isPreviewActive) togglePreviewActive();
                    setPublishMode(PublishMode.IDLE);
                    setDesktopMode();
                  }}
                  i18n={EDIT}
                  variant={
                    !isPreviewActive && !isPublishModeNotIdle
                      ? 'contained'
                      : 'outlined'
                  }
                />
              </UI.Container>
            )}
            {hasValidLogin && <SaveButton />}
            {hasValidLogin && <PreviewButton />}
            {hasValidLogin && !isEditorEmpty && (
              <UI.Container>
                <UI.Button
                  onClick={() => {
                    setPublishMode(PublishMode.STARTED);
                    setDesktopMode();
                    if (isPreviewActive) togglePreviewActive();
                  }}
                  i18n={PUBLISH}
                  variant={isPublishModeNotIdle ? 'contained' : 'outlined'}
                />
              </UI.Container>
            )}
          </UI.Container>
          <UI.Container align="center" m="0, 0, 0, 8" gap={2}>
            {hasValidLogin && <LanguageSelector />}
            {hasValidLogin && (
              <UI.Container gap={2} align="center" onClick={toggle}>
                <UI.Subtitle2
                  i18n={WATCH_VIDEO}
                  modifiers="primary, uppercase"
                />
                <UI.Container
                  align="center"
                  bg="primary"
                  h={25}
                  w={25}
                  style={ROUND}
                  justify="center"
                >
                  <UI.Icon name="next" color="black" size={18} />
                </UI.Container>
              </UI.Container>
            )}
            {window.opener && (
              <UI.Container
                align="center"
                bg="primary"
                h={25}
                w={25}
                style={ROUND}
                justify="center"
                onClick={window.close}
              >
                <UI.Icon name="close" color="black" size={20} />
              </UI.Container>
            )}
          </UI.Container>
        </UI.Container>
      </UI.Container>
    </UI.Page.Header>
  );
};

function useHandleRetrieveSavedDraft() {
  const retrieve = useRetrieveDraft();
  const { language, email } = useUserInfo();
  const hasAValidLogin = useHasAValidLogin();
  useEffect(() => {
    if (email && language && hasAValidLogin) {
      retrieve({
        key: generateKey(email),
      });
    }
  }, [email, hasAValidLogin, language, retrieve]);
}

const HandleDraftFromServer = () => {
  const data = useRetrieveDraftData();
  const hasAValidLogin = useHasAValidLogin();
  useEffect(() => {
    if (data?.contents?.components && hasAValidLogin) {
      useReactPageEditorModule
        .getState()
        .setComponents(data?.contents?.components, ModifiedBy.USER);
    }
  }, [data, hasAValidLogin]);

  return null;
};

const AuthModalAndEffect = () => {
  useHandleRecProPageAuth();

  return <AuthModal title={WELCOME_TO_THE_REC_PRO_PAGE_TITLE} />;
};

const Content = () => {
  const { accessToken } = useQueryParams();
  const error = useAuthenticateBySSOTokenError();
  const hasValidLogin = useHasAValidLogin();
  useEffect(() => {
    if (!hasValidLogin && !accessToken) {
      openUrl('https://proportal.recgroup.com/', '_self').then().catch();
    }
  }, [accessToken, error, hasValidLogin]);
  const onDismiss = useCallback(() => {
    localStorage.clear();
    delay(
      () => openUrl('https://proportal.recgroup.com/', '_self').then().catch(),
      500,
    );
  }, []);
  if (error && !hasValidLogin)
    return (
      <UI.Modal visible hideOriginalClose>
        <ErrorCard error={error} onDismiss={onDismiss} />
      </UI.Modal>
    );
  return hasValidLogin ? <EditorBody /> : <Banner />;
};

const COMPONENTS_TO_KEEP_PROPS_ON_RECONCILE = [
  Contact.config.id,
  YourCompany.config.id,
  SocialMedia.config.id,
];

function onReconcileComponents(
  newComponents: Record<string, ContextComponent>, // FROM DRAFT ON THE SERVER
  oldComponents: Record<string, ContextComponent>, // FROM REGISTERED COMPONENTS
): Record<string, ContextComponent> {
  const newState: Record<string, ContextComponent> = {};
  traverseComponentsTree(oldComponents, (key, component) => {
    const newComponent = get(newComponents, key, component);
    if (
      oldComponents[key] &&
      newComponents[key] &&
      includes(COMPONENTS_TO_KEEP_PROPS_ON_RECONCILE, key)
    ) {
      set(newState, key, {
        ...newComponent,
        name: component?.name || newComponent?.name,
        props: newComponent?.props,
      });
      return;
    }
    set(newState, key, {
      ...newComponent,
      name: component?.name || newComponent?.name,
      children: component.children,
    });
  });
  return newState;
}

const SetupReactProPageEditor = () => {
  const { onSave, onComponentsChanged } = useProPageHandlers();
  useHandleRetrieveSavedDraft();
  const props = useMemo(
    () => ({
      onSave,
      onComponentsChanged,
      onReconcileComponents,
      initialVisibleState: false,
    }),
    [onComponentsChanged, onSave],
  );
  useSetupReactPageEditorModule(props);
  return null;
};
const Page = () => {
  const hasValidLogin = useHasAValidLogin();
  return (
    <ViewModeContextProvider>
      <Preview />
      <HandleDraftFromServer />
      <SetupReactProPageEditor />
      <CheckSupportedLanguages />
      {hasValidLogin && <CheckRecProPageAuth />}
      <Loader />
      <UI.Page gridTemplateRows="60px calc(100vh - 60px) 0" overflow="hidden">
        <Header />
        <UI.Page.Content>
          <PublishModal />
          <AuthModalAndEffect />
          <Content />
        </UI.Page.Content>
      </UI.Page>
    </ViewModeContextProvider>
  );
};

export const Editor: React.FC = () => {
  const persistedDone = usePersistedStoredFinalized();
  if (!persistedDone) return null;
  return <Page />;
};
