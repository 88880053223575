import { PerfectScroll } from '@perfect-scroll/core/PerfectScroll';
import { Footer, Header } from '@pro-page-components/header-and-footer';
import { ReactPageFinalRenderer } from '@react-page-editor/final-renderer';
import { css } from '@ui-system/css';
import { makeStyle } from '@ui-system/style';
import UI from '@ui-system/ui';
import * as React from 'react';
import { useEditorContext, useViewModeContext } from 'react-page-editor/state';

import { COMPONENTS_MAP } from './componentsMap';

const PREVIEW_STYLE = css`
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 9999;
  width: 100%;
`;

const PREVIEW_CONTAINER_MOBILE = css`
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: calc(100vh - 60px);
  justify-content: center;
`;

const STYLE = css`
  max-width: 360px;
  background-color: white;
`;

const useBezelsStyle = makeStyle(
  ({ theme, utils }) => css`
    border: solid ${theme.colors[theme.mode].gray.A100};
    border-left-width: 15px;
    border-right-width: 15px;
    border-top-width: 30px;
    border-bottom-width: 50px;
    border-radius: 10px;
    ${utils.applyBackgroundColor(theme, 'white')}
    ${utils.applyMargin(theme, '2, 0')}
  `,
);
export const Preview: React.FC = () => {
  const { components, isPreviewActive } = useEditorContext();
  const { isMobile } = useViewModeContext();
  const bezelsStyle = useBezelsStyle();
  if (!isPreviewActive) return null;
  if (isMobile) {
    return (
      <UI.Container style={PREVIEW_CONTAINER_MOBILE} bg="background">
        <UI.Container style={bezelsStyle} shadow={3}>
          <PerfectScroll>
            <UI.Container style={STYLE}>
              <ReactPageFinalRenderer
                contents={null}
                language={null}
                country={null}
                components={components}
                componentsMap={COMPONENTS_MAP}
                Header={Header}
                ExtraComponent={Footer}
              />
            </UI.Container>
          </PerfectScroll>
        </UI.Container>
      </UI.Container>
    );
  }
  return (
    <ReactPageFinalRenderer
      contents={null}
      language={null}
      country={null}
      components={components}
      componentsMap={COMPONENTS_MAP}
      wrapInAContainer
      containerStyle={PREVIEW_STYLE}
      Header={Header}
      Footer={Footer}
    />
  );
};
