import React from 'react';

import Email from './Email';
import TANCode from './TANCode';

interface AuthStatics {
  Email: typeof Email;
  TANCode: typeof TANCode;
}

interface Props {
  children: React.ReactNode;
}

const Auth: React.FC<Props> & AuthStatics = ({ children }: Props) => (
  <>{children}</>
);

Auth.TANCode = TANCode;
Auth.Email = Email;

export default Auth;
