import {
  API_ERRORS,
  AUTHENTICATION,
  CAPTION,
  COMMON,
  COMMON_SENTENCES,
  DEVICE,
  INTERACTIONS,
  LABELS,
  PLACEHOLDERS,
  REC_PRO_PAGE,
  VALIDATION_ERRORS,
} from '@resource/bundle-names';

export const BUNDLE_NAMES = [
  VALIDATION_ERRORS,
  API_ERRORS,
  AUTHENTICATION,
  CAPTION,
  COMMON,
  COMMON_SENTENCES,
  DEVICE,
  INTERACTIONS,
  LABELS,
  PLACEHOLDERS,
  REC_PRO_PAGE,
];
