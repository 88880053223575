import ResourceResolverContextProvider from '@i18n/context';
import { ContextProps } from '@i18n/core';
import { useBundle, useLoadBundleOnMount } from '@resource/redux-bundle';
import { useCurrentLanguage } from '@resource/redux-language';
import * as React from 'react';

type Props = { bundleNames: string[] } & Omit<
  ContextProps,
  'locale' | 'bundle'
>;

const ResourceProvider: React.FC<Props> = ({
  children,
  bundleNames,
  ...rest
}: Props) => {
  const language = useCurrentLanguage();
  const bundle = useBundle();
  useLoadBundleOnMount({ bundleNames });
  return (
    <ResourceResolverContextProvider
      locale={language}
      bundle={bundle}
      {...rest}
    >
      {children}
    </ResourceResolverContextProvider>
  );
};

export default ResourceProvider;
