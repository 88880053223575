import { FastFormInput } from '@fast-form/components';
import React from 'react';

import { EmailFieldProps, EmailFieldType } from '../core';
import { FIELD_PATHS } from './EmailFormData';

const EmailField: EmailFieldType = ({ placeholder }: EmailFieldProps) => (
  <FastFormInput
    fieldPath={FIELD_PATHS.email}
    placeholder={placeholder}
    autoCapitalize="none"
    autoCorrect={false}
    keyboardType="email-address"
  />
);

export default EmailField;
