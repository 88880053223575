import { deepmerge } from '@ui-system/deps';
import { Modifiers, Style } from '@ui-system/interfaces';
import { U } from '@utils/ts';
import each from 'lodash/each';
import split from 'lodash/split';
import trim from 'lodash/trim';

export const applyModifiers = (
  modifiers: U.Nullable<Modifiers>,
  componentModifiers: U.Nullable<Record<string, Style>>,
  previousStyle: U.Nullable<Style> = undefined,
): U.Nullable<Style> => {
  if (!modifiers) return previousStyle;
  let acc = null;
  const stylesToApply = split(modifiers, ',');
  each(stylesToApply, style => {
    acc = {
      ...acc,
      ...componentModifiers[trim(style)],
    };
  });
  return deepmerge(previousStyle, acc);
};
