import { deepmerge } from '@shared-utils/object';
import { Components } from '@ui-system/interfaces';
import { A } from '@utils/ts';
import update from 'immutability-helper';
import noop from 'lodash/noop';
import { createContext, useCallback, useContext, useState } from 'react';

export type SetComponent = (
  newComponentName: keyof Components,
  NewComponent: Components[typeof newComponentName],
) => void;

export class RootComponentsContextValue {
  components: Components = {} as Components;

  setComponent: SetComponent = noop;
}

const INITIAL_VALUE = new RootComponentsContextValue();

export const RootComponentsContext = createContext<RootComponentsContextValue>(
  INITIAL_VALUE,
);

export const useRootComponentsContextValue = (
  components: Components,
): RootComponentsContextValue => {
  const [componentsState, setComponents] = useState(components);
  const setComponent = useCallback(
    (
      newComponentName: keyof Components,
      NewComponent: A.At<Components, typeof newComponentName>,
    ) => {
      setComponents(prev =>
        update(prev, {
          [newComponentName]: {
            $set: NewComponent,
          },
        }),
      );
    },
    [],
  );
  return {
    components: componentsState,
    setComponent,
  };
};

export const useRootComponentsContext = (): RootComponentsContextValue =>
  useContext(RootComponentsContext);

export const useFeatureComponentsProviderValue = (
  partialComponents: Partial<Components>,
): RootComponentsContextValue => {
  const { components } = useRootComponentsContext();
  return useRootComponentsContextValue(
    deepmerge(components, partialComponents),
  );
};

export const useUISystem = (): Components => {
  const { components } = useRootComponentsContext();
  return components;
};

export const useSetComponent = (): SetComponent => {
  const { setComponent } = useRootComponentsContext();
  return setComponent;
};
