import 'react-dropzone-uploader/dist/styles.css';

import {
  DEFAULT_COMPRESS_OPTIONS,
  useUploadFileToGoogleStorage,
} from '@google-storage/hooks';
import { I18nShape } from '@i18n/core';
import {
  CHANGE_IMAGE,
  OR_UPLOAD_IMAGE,
  SHOWCASE_YOUR_REC_PROTRUST,
  UPLOAD_IMAGES_OF_YOUR_BUSINESS,
  UPLOAD_IMAGES_OF_YOUR_BUSINESS_DESCRIPTION,
  UPLOAD_YOUR_LOGO,
  UPLOAD_YOUR_LOGO_DESCRIPTION,
  UPLOAD_YOUR_LOGO_TIP,
  UPLOAD_YOUR_PHOTOS_TIP,
} from '@rec/i18n';
import { EMPTY_ARRAY } from '@shared-utils/array';
import { useButtonStyle } from '@ui-system/default-styles';
import { makeStyle } from '@ui-system/style';
import { getColorByName } from '@ui-system/theme/colors';
import UI from '@ui-system/ui';
import defaultTo from 'lodash/defaultTo';
import filter from 'lodash/filter';
import head from 'lodash/head';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import uniqWith from 'lodash/uniqWith';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  useComponentProps,
  useSetComponentProp,
} from 'react-page-editor/state';

import config from './config.json';
import { useControledCarousel } from './useControledCarousel';
import { YourCompany } from './YourCompany';

const useStyle = makeStyle(
  ({ css, theme }) => css`
    border: 1px dotted ${getColorByName('gray.A300', theme.colors[theme.mode])};
  `,
);

const GOOGLE_STORAGE_CONFIG = {
  bucketName: 'public-files-rec-propage',
  compressOptions: DEFAULT_COMPRESS_OPTIONS,
};

interface YourCompanyProps {
  logo?: string;
  images?: { original: string }[];
  text?: string;
  Editor?: React.FC;
}

interface NoImageProps {
  i18n: I18nShape;
  i18nTip: I18nShape;
}
const NoImage = ({ i18n, i18nTip, ...rest }: NoImageProps) => (
  <>
    <UI.Container align="center" w={220} gap={2} {...rest}>
      <UI.Icon name="drag" size={40} />
      <UI.Body2 i18n={i18n} />
    </UI.Container>
    <UI.Caption i18n={i18nTip} />
  </>
);
export const UploadCardLogo: React.FC = props => {
  const componentProps = useComponentProps<YourCompanyProps>(
    YourCompany.config.id,
  );
  const [logo, setLogo] = useState(null);
  useEffect(() => {
    setLogo(componentProps.logo);
  }, [componentProps.logo]);

  const {
    isLoading,
    uploadFile,
    uploadInfo,
    progress,
  } = useUploadFileToGoogleStorage(GOOGLE_STORAGE_CONFIG);
  const setComponentProp = useSetComponentProp();

  const onDrop = useCallback(
    acceptedFiles => {
      uploadFile(head(acceptedFiles));
      // Do something with the files
    },
    [uploadFile],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const style = useStyle();
  useEffect(() => {
    if (uploadInfo) {
      setComponentProp(config, uploadInfo.mediaLink, 'logo');
      setLogo(uploadInfo.mediaLink);
    }
  }, [setComponentProp, uploadInfo]);

  const handleRemove = useCallback(() => {
    setComponentProp(config, null, 'logo');
    setLogo(null);
  }, [setComponentProp]);

  const button = useButtonStyle({}, null, () => ({}));

  return (
    <UI.Container
      style={style}
      direction="column"
      f={1}
      p={2}
      gap={2}
      m="0, 1"
      justify="space-between"
      shadow={isDragActive ? 4 : undefined}
      opacity={isLoading ? 0.5 : 1}
      {...props}
      {...getRootProps()}
      className="my-second-step"
    >
      <UI.Body2 modifiers="primary, uppercase, bold" i18n={UPLOAD_YOUR_LOGO} />
      {!logo ? (
        <NoImage
          i18n={UPLOAD_YOUR_LOGO_DESCRIPTION}
          i18nTip={UPLOAD_YOUR_LOGO_TIP}
        />
      ) : (
        <UI.Container justify="center" direction="column" align="center">
          <UI.Image src={logo} height={130} width={130} resizeMode="contain" />
          <UI.Container
            // @ts-ignore
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              handleRemove();
            }}
            p="1, 3"
            f={1}
          >
            <UI.Icon name="trash" color="black" size={20} />
          </UI.Container>
        </UI.Container>
      )}
      <UI.Container>
        <UI.Container p="2, 4" m="1, 0" style={button}>
          <input {...getInputProps()} style={{ display: 'none' }} />
          {progress > 1 && progress < 100 ? (
            <UI.ButtonText modifiers="primary">{progress}%</UI.ButtonText>
          ) : (
            <UI.ButtonText
              i18n={!logo ? OR_UPLOAD_IMAGE : CHANGE_IMAGE}
              modifiers="primary"
            />
          )}
        </UI.Container>
      </UI.Container>
    </UI.Container>
  );
};

export const UploadCardGallery: React.FC = props => {
  const componentProps = useComponentProps<YourCompanyProps>(
    YourCompany.config.id,
  );
  const [images, setImages] = useState(EMPTY_ARRAY);
  useEffect(() => {
    setImages(componentProps.images);
  }, [componentProps.images]);
  const {
    isLoading,
    uploadFiles,
    uploadInfos,
    progress,
  } = useUploadFileToGoogleStorage(GOOGLE_STORAGE_CONFIG);
  const setComponentProp = useSetComponentProp();
  const style = useStyle();
  const onDrop = useCallback(
    acceptedFiles => {
      uploadFiles(acceptedFiles);
      // Do something with the files
    },
    [uploadFiles],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  // @ts-ignore
  const button = useButtonStyle({}, null, () => ({}));
  useEffect(() => {
    if (uploadInfos) {
      const newImages = map(uploadInfos, item => ({
        original: item.mediaLink,
      }));
      setImages(prev => {
        const newStateAndProps = uniqWith(
          [
            ...defaultTo(prev, EMPTY_ARRAY),
            ...defaultTo(newImages, EMPTY_ARRAY),
          ],
          isEqual,
        );
        setComponentProp(config, newStateAndProps, 'images');
        return newStateAndProps;
      });
    }
  }, [setComponentProp, uploadInfos]);

  const handleRemove = useCallback(
    ({ original }: { original: string }) => {
      setImages(prev => {
        const newStateAndProps = filter(
          prev,
          item => item.original !== original,
        );
        setComponentProp(config, newStateAndProps, 'images');
        return newStateAndProps;
      });
    },
    [setComponentProp],
  );
  const {
    items,
    goNext,
    isPossibleToGoNext,
    isPossibleToGoBack,
    goBack,
  } = useControledCarousel(images, 5);
  return (
    <UI.Container
      style={style}
      f={2}
      opacity={isLoading ? 0.5 : 1}
      {...props}
      {...getRootProps()}
      shadow={isDragActive ? 4 : undefined}
      className="my-third-step"
    >
      <UI.Container
        direction="column"
        p={2}
        justify="space-between"
        f={1}
        gap={2}
      >
        <UI.Body2
          modifiers="primary, uppercase, bold"
          i18n={UPLOAD_IMAGES_OF_YOUR_BUSINESS}
        />
        <NoImage
          i18n={UPLOAD_IMAGES_OF_YOUR_BUSINESS_DESCRIPTION}
          i18nTip={UPLOAD_YOUR_PHOTOS_TIP}
        />
        <UI.Container>
          <UI.Container p="2, 4" style={button}>
            <input {...getInputProps()} style={{ display: 'none' }} />
            {progress > 1 && progress < 100 ? (
              <UI.ButtonText modifiers="primary">{progress}%</UI.ButtonText>
            ) : (
              <UI.ButtonText i18n={OR_UPLOAD_IMAGE} modifiers="primary" />
            )}
          </UI.Container>
        </UI.Container>
      </UI.Container>
      <UI.Container
        direction="column"
        justify="space-around"
        p={2}
        gap={2}
        f={1}
        {...props}
      >
        <UI.Container gap={1}>
          {isPossibleToGoBack && (
            <UI.Container
              align="center"
              bg="gray.A100"
              // @ts-ignore
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                goBack();
              }}
            >
              <UI.Icon name="back" size={15} color="primary" />
            </UI.Container>
          )}
          {map(items, img => (
            <UI.Container
              key={img.original}
              direction="column"
              m="0, 1"
              gap={1}
            >
              <UI.Image
                src={img.original}
                height={70}
                width={70}
                resizeMode="cover"
              />
              <UI.Container
                // @ts-ignore
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleRemove(img);
                }}
              >
                <UI.Icon name="trash" color="black" size={20} />
              </UI.Container>
            </UI.Container>
          ))}
          {isPossibleToGoNext && (
            <UI.Container
              align="center"
              bg="gray.A100"
              // @ts-ignore
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                goNext();
              }}
            >
              <UI.Icon name="next" size={15} color="primary" />
            </UI.Container>
          )}
        </UI.Container>
        <UI.Body2 i18n={SHOWCASE_YOUR_REC_PROTRUST} />
      </UI.Container>
    </UI.Container>
  );
};
