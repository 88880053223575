import MaterialCard from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import MaterialCardHeader from '@material-ui/core/CardHeader';
import { Image } from '@ui-system/common-image';
import defaultProps from '@ui-system/default-props/card';
import {
  CardActionAreaType,
  CardActionsType,
  CardContentType,
  CardHeaderProps,
  CardHeaderType,
  CardProps,
  CardType,
} from '@ui-system/interfaces-card';
import Typography from '@ui-system/material-typography';
import React, { CSSProperties } from 'react';

const Card: CardType = ({ style, children, elevation, square }: CardProps) => (
  <MaterialCard
    style={style as CSSProperties}
    elevation={elevation}
    square={square}
  >
    {children}
  </MaterialCard>
);

const CardHeader: CardHeaderType = ({
  i18nTitle,
  i18nDescription,
  titleModifiers,
  descriptionModifiers,
  titleVariant,
  descriptionVariant,
  action,
  accessory,
}: CardHeaderProps) => (
  <MaterialCardHeader
    title={
      <Typography
        i18n={i18nTitle}
        variant={titleVariant}
        modifiers={titleModifiers}
      />
    }
    subheader={
      <Typography
        i18n={i18nDescription}
        variant={descriptionVariant}
        modifiers={descriptionModifiers}
      />
    }
    action={action}
    avatar={accessory}
    disableTypography
  />
);

Card.ActionArea = CardActionArea as CardActionAreaType;
Card.Media = Image;
Card.Header = CardHeader;
Card.Content = CardContent as CardContentType;
Card.Actions = CardActions as CardActionsType;

Card.defaultProps = defaultProps;

export default Card;
