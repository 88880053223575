import { css } from '@ui-system/css';
import { ContainerProps } from '@ui-system/interfaces-container';
import { makeStyle } from '@ui-system/style';
import UI from '@ui-system/ui';
import * as React from 'react';
import { useViewModeContext } from 'react-page-editor/state';

interface Props extends ContainerProps {
  children: React.ReactNode;
  Escape?: React.FC;
  padding?: boolean;
  scale?: number;
}
const STYLE = css`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
`;

export const useStyle = makeStyle<Pick<Props, 'scale'>>(
  ({ props }) => css`
    ${props.scale ? `transform: scale(${props.scale});` : ''}
  `,
);
function getPadding(
  padding: boolean,
  scale?: number,
  isMobile?: boolean,
): string {
  if (padding && !scale && isMobile) return '4, 3';
  if (padding && !scale) return '13, 0';
  if (padding && scale && isMobile) return '3, 1';
  if (padding && scale) return '3, 0';
  return '';
}

export type ScaleProps = Pick<Props, 'scale'>;

export const SectionWrapper: React.FC<Props> = ({
  children,
  Escape,
  padding,
  scale,
  ...rest
}: Props) => {
  const style = useStyle({ scale });
  const { isMobile } = useViewModeContext();

  return (
    <UI.Container
      direction="column"
      f={1}
      bg={rest.bg}
      shadow={3}
      h={rest.h}
      p={getPadding(padding, scale, isMobile)}
    >
      {Escape && <Escape />}
      <UI.Container direction="column" f={1} style={STYLE} {...rest}>
        {scale ? (
          <UI.Container direction="column" style={style}>
            {children}
          </UI.Container>
        ) : (
          children
        )}
      </UI.Container>
    </UI.Container>
  );
};
