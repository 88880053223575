import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import 'react-image-gallery/styles/css/image-gallery.css';

import { FastFormSubmitButton } from '@fast-form/components';
import HtmlParser from '@html-parser/core';
import FastFormProvider from '@lib/fast-form/context/FastFormProvider';
import { useReactPageContentContextLanguage } from '@react-page-editor/final-renderer';
import { useDeepCompareMemo } from '@react-utils/hooks';
import {
  DO_YOU_HAVE_HYPERLINKS_IN_YOUR_TEXT,
  DO_YOU_HAVE_HYPERLINKS_IN_YOUR_TEXT_TOOLTIP,
  SAVE_TEXT,
  TEXT_ABOUT_YOUR_BUSINESS,
} from '@rec/i18n';
import { useRetrieveDraftData } from '@rec/redux-retrieve-draft';
import { ScaleProps, SectionWrapper } from '@rec/section-wrapper';
import { useUserInfo } from '@rec/user-info';
import { useCurrentLanguage } from '@resource/redux-language';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import { useBoolean } from 'ahooks';
import { IsNotEmpty } from 'class-validator-i18n';
import get from 'lodash/get';
import map from 'lodash/map';
import omit from 'lodash/omit';
import size from 'lodash/size';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useAsync } from 'react-async-hook';
import ImageGallery from 'react-image-gallery';
import {
  ComponentForEditor,
  useComponentProps,
  useEditorContext,
  useSetComponentProp,
  useViewModeContext,
} from 'react-page-editor/state';
import { Carousel } from 'react-responsive-carousel';
import requestImageSize from 'request-image-size';
import { Tooltip } from 'tooltip';

import config from './config.json';
import defaultProps from './defaultProps.json';
import linkHelp from './link-help.png';
import { TextEditor } from './TextEditor';
import { UploadCardGallery, UploadCardLogo } from './UploadCard';

export interface Texts {
  en: string | null;
  de: string | null;
  nl: string | null;
  fr: string | null;
  it: string | null;
  es: string | null;
  pl: string | null;
  ja: string | null;
  no: string | null;
  sv: string | null;
}
export interface YourCompanyProps extends ScaleProps {
  logo?: string;
  images?: { original: string }[];
  text?: string;
  texts?: Texts;
  Editor?: React.FC;
}

interface DimensionsRequest {
  height?: number;
  width?: number;
}

interface DimensionsResponse {
  height: number;
  width: number;
  type: string;
  downloaded: number;
}

async function getResizedImageDimensions(
  imageUrl: string,
  dimensionsRequest: DimensionsRequest = {},
): Promise<DimensionsResponse | null> {
  if (!imageUrl) return null;
  const sizes = await requestImageSize(imageUrl);
  if (dimensionsRequest.width) {
    return {
      ...sizes,
      width: dimensionsRequest.width,
      height: Math.ceil(sizes.height * (dimensionsRequest.width / sizes.width)),
    };
  }
  if (dimensionsRequest.height) {
    return {
      ...sizes,
      height: dimensionsRequest.height,
      width: Math.ceil(sizes.width * (dimensionsRequest.height / sizes.height)),
    };
  }
  return sizes;
}

interface ItemProps {
  item: { original: string } | null;
  isMobile?: boolean;
}

const IMAGE_CONFIG = {
  height: 180,
};
const Item: React.FC<ItemProps> = ({ item, isMobile }: ItemProps) => {
  const { result } = useAsync(getResizedImageDimensions, [
    item?.original,
    IMAGE_CONFIG,
  ]);
  if (!item) return null;
  if (!isMobile && result && result?.width < result.height) {
    return (
      <UI.Container m="0, 1">
        <UI.Image
          src={item.original}
          height={result?.height}
          width={result?.width}
          resizeMode="contain"
        />
      </UI.Container>
    );
  }
  return (
    <UI.Container key={item.original} m="0, 1" f={isMobile ? 1 : null}>
      <UI.Image
        src={item.original}
        height={IMAGE_CONFIG.height}
        width="100%"
        resizeMode="contain"
      />
    </UI.Container>
  );
};

Item.defaultProps = {
  isMobile: false,
};

interface CarouselWrapperProps {
  images?: { original: string }[];
  isMobile?: boolean;
}

const STYLE = css`
  position: relative;
  top: -35px;
  right: 10px;
`;
const CarouselWrapper: React.FC<CarouselWrapperProps> = ({
  images,
  isMobile,
}: CarouselWrapperProps) => {
  const [visible, { toggle, setFalse }] = useBoolean();

  return (
    <>
      {visible && (
        <UI.Modal visible={visible} toggle={setFalse} hideOriginalClose>
          <UI.Container justify="center" align="flex-start" p={10}>
            <ImageGallery items={images} />
            <UI.Container style={STYLE}>
              <UI.Icon name="close" size={25} onClick={toggle} />
            </UI.Container>
          </UI.Container>
        </UI.Modal>
      )}
      {size(images) > (isMobile ? 1 : 2) ? (
        <UI.Container f={6}>
          <Carousel
            centerMode
            infiniteLoop
            showStatus={false}
            centerSlidePercentage={isMobile ? 100 : 30}
            autoPlay
            showIndicators={false}
            showArrows
            onClickItem={() => toggle()}
          >
            {map(images, item => (
              <Item item={item} key={item.original} isMobile={isMobile} />
            ))}
          </Carousel>
        </UI.Container>
      ) : (
        <UI.Container
          f={6}
          justify={isMobile ? 'center' : 'flex-end'}
          onClick={toggle}
        >
          {map(images, item => (
            <Item item={item} key={item.original} isMobile={isMobile} />
          ))}
        </UI.Container>
      )}
    </>
  );
};

CarouselWrapper.defaultProps = {
  isMobile: false,
};

export const YourCompany: ComponentForEditor<YourCompanyProps> = ({
  Editor,
  text,
  texts,
  images,
  logo,
  scale,
}: YourCompanyProps) => {
  const language = useReactPageContentContextLanguage();
  const appLanguage = useCurrentLanguage();
  const finalText = texts[appLanguage || language] || text;
  const content = HtmlParser(finalText);
  const { isMobile } = useViewModeContext();
  return (
    <SectionWrapper bg="white" padding scale={scale}>
      {Editor ? (
        <Editor />
      ) : (
        <UI.Container direction="column" gap={2}>
          <UI.Container f={1} gap={4} direction={isMobile ? 'column' : 'row'}>
            {logo && (
              <UI.Container f={2}>
                <UI.Image
                  src={logo}
                  height={isMobile ? 100 : IMAGE_CONFIG.height}
                  width={isMobile ? '100%' : '256px'}
                  resizeMode="contain"
                />
              </UI.Container>
            )}
            {!isMobile && <CarouselWrapper images={images} />}
          </UI.Container>
          <UI.Container p={2}>
            <UI.Body2>{content}</UI.Body2>
          </UI.Container>
          {isMobile && <CarouselWrapper images={images} isMobile />}
        </UI.Container>
      )}
    </SectionWrapper>
  );
};

YourCompany.defaultProps = defaultProps;
YourCompany.config = config;

class Form {
  @IsNotEmpty()
  text = '';

  texts: Texts = {
    en: null,
    de: null,
    nl: null,
    fr: null,
    it: null,
    es: null,
    pl: null,
    ja: null,
    no: null,
    sv: null,
  };
}
const LinkHelp = () => (
  <UI.Container direction="column" gap={1} p={2}>
    <UI.Caption
      modifiers="light"
      i18n={DO_YOU_HAVE_HYPERLINKS_IN_YOUR_TEXT_TOOLTIP}
    />
    <UI.Image src={linkHelp} height={40} width="auto" />
  </UI.Container>
);

const OPTIONS = { enableReinitialize: true };
const Editor = () => {
  const setComponentProp = useSetComponentProp();
  const componentProps = useComponentProps<YourCompanyProps>(
    YourCompany.config.id,
  );
  const onSuccess = useCallback(
    data => {
      if (data) {
        setComponentProp(config, data?.text, 'text');
        setComponentProp(config, data?.texts, 'texts');
      }
    },
    [setComponentProp],
  );
  const { language } = useUserInfo();
  const initialValues = useDeepCompareMemo(() => {
    const values = new Form();
    values.text = componentProps.text;
    values.texts[language] = componentProps.text;
    if (componentProps.texts) values.texts = componentProps.texts;
    return values;
  }, [componentProps.text, componentProps.texts, language]);
  return (
    <FastFormProvider
      initialValues={initialValues}
      onSubmitSuccess={onSuccess}
      options={OPTIONS}
    >
      <UI.Container direction="column" gap={2}>
        <UI.Container>
          <UploadCardLogo />
          <UploadCardGallery />
        </UI.Container>
        <UI.Container direction="column" gap={2} className="my-fourth-step">
          <UI.Container gap={4}>
            <UI.Body2
              i18n={TEXT_ABOUT_YOUR_BUSINESS}
              modifiers="primary, uppercase, bold"
            />
            <Tooltip overlay={<LinkHelp />}>
              <UI.Caption i18n={DO_YOU_HAVE_HYPERLINKS_IN_YOUR_TEXT} />
            </Tooltip>
          </UI.Container>
          <TextEditor />
          <UI.Container justify="space-between">
            <FastFormSubmitButton i18n={SAVE_TEXT} />
          </UI.Container>
        </UI.Container>
      </UI.Container>
    </FastFormProvider>
  );
};

export const YourCompanyEditor: ComponentForEditor<YourCompanyProps> = () => {
  const componentProps = useComponentProps<YourCompanyProps>(
    YourCompany.config.id,
  );
  const data = useRetrieveDraftData();
  const { setComponentProps } = useEditorContext();
  useEffect(() => {
    const props = get(data, 'contents.YOUR_COMPANY.props');
    if (props) {
      setComponentProps(config, props);
    }
  }, [data, setComponentProps]);
  return (
    <YourCompany
      {...omit(componentProps, ['Editor'])}
      Editor={Editor}
      scale={0.9}
    />
  );
};

// @ts-ignore
YourCompanyEditor.defaultProps = YourCompany.defaultProps;
YourCompanyEditor.config = config;
