import { EMPTY_ARRAY } from '@shared-utils/array';
import { filter, find, get } from 'lodash';
import { useMemo } from 'react';
import { SchemaDescription } from 'yup';

import { FieldPath } from '../../../core';
import useFieldCurrentConfig from './useFieldCurrentConfig';

export type FieldConfigurationContextValue = {
  configured: boolean;
  required: boolean;
  maxLength: number | null;
  minLength: number | null;
  type: 'string' | 'number' | 'date' | 'object' | 'array' | string | null; // XXX: yup types
  tests: Array<{
    name: string;
    params: Record<string, any>;
  }>;
};

export interface Options {
  requiredValidationNames: Array<string>;
}

const DEFAULT_OPTIONS = {
  requiredValidationNames: ['required'],
};

const INITIAL_VALUES = {
  required: false,
  maxLength: null,
  minLength: null,
  configured: false,
  type: null,
  tests: EMPTY_ARRAY,
};

const getTest = (validationDescription: SchemaDescription, testName: string) =>
  find(validationDescription.tests, test => test.name === testName);

const hasTest = (validationDescription: SchemaDescription, testName: string) =>
  getTest(validationDescription, testName) != null;

function hasAnyTest(
  validationDescription: SchemaDescription,
  testNames: string[],
) {
  return (
    filter<string>(testNames, testName =>
      hasTest(validationDescription, testName),
    ).length > 0
  );
}

function getTestParam<V>(
  validationDescription: SchemaDescription,
  testName: string,
  paramName: string,
  defaultValue?: V,
) {
  const test = getTest(validationDescription, testName);
  if (!test) {
    return defaultValue || undefined;
  }
  return get(test.params, paramName, defaultValue);
}

const calculateFieldConfig = (
  currentConfig: SchemaDescription | null,
  givenOptions?: Options,
): FieldConfigurationContextValue => {
  const options = givenOptions || DEFAULT_OPTIONS;
  if (!currentConfig) return { ...INITIAL_VALUES };
  return {
    ...INITIAL_VALUES,
    configured: true,
    required: hasAnyTest(currentConfig, options.requiredValidationNames),
    maxLength: getTestParam(currentConfig, 'max', 'max', null),
    minLength: getTestParam(currentConfig, 'min', 'min', null),
    type: currentConfig.type,
    tests: currentConfig.tests,
  };
};

const useFieldConfiguration = (
  fieldPath: FieldPath,
  options?: Options,
): ReturnType<typeof calculateFieldConfig> => {
  const fieldCurrentConfig = useFieldCurrentConfig(fieldPath);
  return useMemo(() => calculateFieldConfig(fieldCurrentConfig, options), [
    options,
    fieldCurrentConfig,
  ]);
};

export default useFieldConfiguration;
