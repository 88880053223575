import { selectors } from '@authentication/redux-auth-data/reducer';
import { RootState as AuthDataRootState } from '@authentication/redux-auth-data/types';
import { getAuthenticateByEmailTanUrl } from '@authentication/shared-routes';
import { RequestType } from '@redux-async-module/interfaces';
import { createModule } from '@redux-async-module/module-utils';
import { selectors as languageSelectors } from '@resource/redux-language/reducer';

import {
  AuthenticateByEmailTanStartPayload,
  AuthenticateByEmailTanSuccessPayload,
  NAMESPACE,
  RootState,
} from './types';

const {
  getDynamicModule,
  actions,
  dispatchHooks,
  selectorHooks,
} = createModule<
  RootState,
  AuthenticateByEmailTanStartPayload,
  AuthenticateByEmailTanSuccessPayload
>({
  namespace: NAMESPACE,
  actionName: 'AUTH_BY_EMAIL_TAN',
  mainEpicConfig: {
    requestOptions: {
      type: RequestType.post,
      getPath: (_, state) =>
        getAuthenticateByEmailTanUrl(languageSelectors.currentLanguage(state)),
      getBody: ({ tanCode }, state: RootState & AuthDataRootState) => {
        const { email } = selectors.loginAttemptData(state);
        return {
          tanCode,
          email,
        };
      },
    },
  },
});

export const authenticateByEmailTanActions = actions;

export const {
  useIsLoading: useAuthenticateByEmailTanIsLoading,
  useError: useAuthenticateByEmailTanError,
  useSuccess: useAuthenticateByEmailTanSuccess,
  useLastStartPayload: useAuthenticateByEmailTanLastStartPayload,
} = selectorHooks;

export const {
  useAsyncStart: useAuthenticateByEmailTanStart,
  useDismissError: useAuthenticateByEmailTanDismissError,
} = dispatchHooks;

export const getAuthenticateByEmailTanModule = getDynamicModule;
