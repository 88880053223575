import { useDeepCompareCallback } from '@react-utils/hooks';
import {
  createDispatchHook,
  createSelectorHook,
} from '@redux-basic-module/hooks-utils';

import { changeLanguage, selectors } from './reducer';
import { UseChangeCurrentLanguage } from './types';

export const useCurrentLanguage = createSelectorHook(selectors.currentLanguage);

export const useChangeLanguageInternal = createDispatchHook(changeLanguage);

export const useChangeLanguage: UseChangeCurrentLanguage = () => {
  const changeCurrentLanguage = useChangeLanguageInternal();
  const currentLanguage = useCurrentLanguage();
  return useDeepCompareCallback(
    payload => {
      if (currentLanguage !== payload.language) {
        changeCurrentLanguage(payload);
      }
    },
    [changeCurrentLanguage, currentLanguage],
  );
};
