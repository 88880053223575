import './rich-text.css';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import React, { useCallback, useMemo } from 'react';
import { F } from 'ts-toolbelt';

interface Props {
  onChange: (value: string) => void;
  onBlur: F.Function;
  value: string;
  language: string;
}

const STYLE = css`
  min-height: 300px;
  margin-bottom: 50px;
  width: 100%;
`;

ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      '|',
      'outdent',
      'indent',
      '|',
      'blockQuote',
      'undo',
      'redo',
    ],
  },
  language: 'en',
};

export const RichTextEditor: React.FC<Props> = ({
  onChange,
  onBlur,
  value,
  language,
}: Props) => {
  const config = useMemo(() => ({ language }), [language]);
  const handleChange = useCallback(
    (event, editor) => {
      const data = editor.getData();
      onChange(data);
    },
    [onChange],
  );
  if (value === undefined) return null;
  return (
    <UI.Container style={STYLE}>
      <CKEditor
        config={config}
        editor={ClassicEditor}
        data={value}
        onChange={handleChange}
        onBlur={onBlur}
      />
    </UI.Container>
  );
};
