import { FastFormCheckBox } from '@fast-form/components';
import { useValue } from '@fast-form/module/hooks/fields';
import { useNavigateToRoute } from '@lib/navigation/hooks';
import UI from '@ui-system/ui';
import React, { useCallback } from 'react';

import { TermsAndConditionsProps, TermsAndConditionsType } from '../core';
import { FIELD_PATHS } from './EmailFormData';

const TermsAndConditions: TermsAndConditionsType = ({
  label,
  linkLabel,
  route,
}: TermsAndConditionsProps) => {
  const navigateTo = useNavigateToRoute(route);
  const email = useValue(FIELD_PATHS.email);
  const onClick = useCallback(() => navigateTo({ email }), [email, navigateTo]);
  return (
    <UI.Container direction="row" justify="center" align="center">
      <FastFormCheckBox fieldPath={FIELD_PATHS.terms} />
      <UI.Container m={1} gap={1}>
        <UI.Caption i18n={label} modifiers="uppercase" />
        <UI.Caption
          i18n={linkLabel}
          onClick={onClick}
          modifiers="uppercase, link"
        />
      </UI.Container>
    </UI.Container>
  );
};

export default TermsAndConditions;
