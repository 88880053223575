import { I18nShape } from '@i18n/core';
import { LogLevel } from '@logger/core';
import { ValidationError } from 'class-validator-i18n';

export class HttpErrorJSON {
  status: number;

  type: string;

  reason: string;

  validationErrors?: ValidationError[];

  retrySecs?: number;

  i18n: I18nShape;

  level: LogLevel;

  originalError?: any;
}
