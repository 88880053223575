import { createAction } from '@redux-basic-module/actions-utils';
import { ErrorPayload, Handlers } from '@redux-basic-module/interfaces';
import { createReducer } from '@redux-basic-module/reducer-utils';
import { createSelector } from '@redux-basic-module/selector-utils';

import { INITIAL_STATE, NAMESPACE } from './constants';
import {
  HydrateStartPayload,
  HydrateSuccessPayload,
  Selectors,
  State,
} from './types';

export const hydrateStart = createAction<
  '_persisted/hydrate-start',
  HydrateStartPayload
>(`_persisted/hydrate-start`);

export const hydrateSuccess = createAction<
  '_persisted/hydrate-success',
  HydrateSuccessPayload
>(`_persisted/hydrate-success`);

export const hydrateError = createAction<
  '_persisted/hydrate-error',
  ErrorPayload
>(`_persisted/hydrate-error`);

export const persistHydrateActionsSuccess = createAction<
  '_persisted/persist-hydrate-actions-success',
  null
>(`_persisted/persist-hydrate-actions-success`);

export const persistHydrateActionsError = createAction<
  '_persisted/persist-hydrate-actions-error',
  ErrorPayload
>(`_persisted/persist-hydrate-actions-error`);

type Action = ReturnType<typeof hydrateSuccess> &
  ReturnType<typeof hydrateError> &
  ReturnType<typeof hydrateStart>;

const HANDLERS: Handlers<
  State,
  HydrateStartPayload | HydrateSuccessPayload | ErrorPayload
> = {
  [hydrateStart.type]: (
    state,
    { payload }: ReturnType<typeof hydrateStart>,
  ) => ({
    ...state,
    success: false,
    appId: payload.appId,
    persistedStores: payload.namespaces,
    finalized: false,
  }),
  [hydrateSuccess.type]: (
    state,
    { payload }: ReturnType<typeof hydrateSuccess>,
  ) => ({
    ...state,
    success: true,
    persistedStores: payload.persistedStores,
    finalized: true,
  }),
  [hydrateError.type]: (
    state,
    { payload }: ReturnType<typeof hydrateError>,
  ) => ({
    ...state,
    success: false,
    error: payload.error,
    persistedStores: [],
    finalized: true,
  }),
};

export const selectors: Selectors = {
  success: createSelector([NAMESPACE, 'success']),
  persistedStores: createSelector([NAMESPACE, 'persistedStores']),
  appId: createSelector([NAMESPACE, 'appId']),
  finalized: createSelector([NAMESPACE, 'finalized']),
};

const reducer = createReducer<State, Action>(
  NAMESPACE,
  INITIAL_STATE,
  HANDLERS,
);
export default reducer;
