import { U } from '@utils/ts';

export const NAMESPACE = 'rec-user-info';

export const INITIAL_STATE = {
  email: null,
  language: null,
  country: null,
};

export type SetUserInfoPayload = {
  email: U.Nullable<string>;
  language: U.Nullable<string>;
  country: U.Nullable<string>;
};

export type State = {
  email: U.Nullable<string>;
  language: U.Nullable<string>;
  country: U.Nullable<string>;
};

export interface RootState {
  [NAMESPACE]: State;
}
