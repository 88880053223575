import { Epic } from '@redux-basic-module/interfaces';
import { ofType } from '@redux-operators/of-type';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  // tap,
  withLatestFrom,
} from 'rxjs/operators';

import { ValidationError } from '../../core';
import {
  ErrorType,
  handleValidation,
  normalizeClassValidatorErrors,
} from '../../utils';
import { validateSuccessAction } from '../actions';
import { VALIDATE_START } from '../constants';
import { allFormsSelector } from '../stateSelector';
import type {
  AllForms,
  RootState,
  State,
  ValidateStartAction,
  ValidateSuccessAction,
} from '../types';
// import logger from '../../log';

type PipeData = [ValidateStartAction<Record<string, any>>, AllForms<any>];

// @ts-ignore
const onValidateStart: Epic = (
  action$: ActionsObservable<ValidateStartAction<Record<string, any>>>,
  state$: Observable<RootState<State<any>>>,
): Observable<ValidateSuccessAction> =>
  action$.pipe(
    ofType(VALIDATE_START),
    withLatestFrom(state$.pipe(map(allFormsSelector))),
    // tap(() => logger.info('Validate form start')),
    switchMap(
      ([
        {
          payload: { formId, validationSchema, onlyWatchedFields },
        },
        allForms,
      ]: PipeData) =>
        from(
          handleValidation(
            validationSchema,
            (allForms[formId] || {}).currentValues,
          ),
        ).pipe(
          map(() => validateSuccessAction(formId, [], onlyWatchedFields)),
          catchError((error: ValidationError) => {
            const errorPayload = validationSchema
              ? error.inner
              : normalizeClassValidatorErrors((error as unknown) as ErrorType);
            return of(
              validateSuccessAction(formId, errorPayload, onlyWatchedFields),
            );
          }),
        ),
    ),
  );

export default onValidateStart;
