import includes from 'lodash/includes';
import size from 'lodash/size';

export function containsAny(
  value: string | undefined,
  ...items: string[]
): boolean {
  let contain = false;
  let i = 0;
  while (!contain && i < size(items)) {
    if (includes(value, items[i])) {
      contain = true;
    }
    i += 1;
  }
  return contain;
}
