export async function openUrl(url: string, target = '_blank'): Promise<void> {
  return new Promise((resolve, reject) => {
    try {
      window.open(url, target);
      resolve();
    } catch (e) {
      reject(e);
    }
  });
}
