import forEach from 'lodash/forEach';

export async function mapAsync<Item, AsyncReturn>(
  arr: Item[],
  asyncFn: (item: Item, i: number, array: Item[]) => Promise<AsyncReturn>,
): Promise<AsyncReturn[]> {
  const promises = [];
  forEach(arr, (item, i) => {
    promises.push(asyncFn(item, i, arr));
  });
  return Promise.all(promises);
}
