import { getFieldPaths } from '@shared-utils/object';
import { IsNotEmpty } from 'class-validator-i18n';
import { O } from 'ts-toolbelt';

export class TANCodeFormData {
  @IsNotEmpty()
  tanCode: string;
}

export const FIELD_PATHS = getFieldPaths<
  TANCodeFormData,
  Record<keyof O.Paths<TANCodeFormData>[number], string>
>(new TANCodeFormData());
