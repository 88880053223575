import { useSubmitStart } from '@fast-form/module';
import UI from '@ui-system/ui';
import React from 'react';

import { SubmitButtonProps, SubmitButtonType } from '../core';

const SubmitButton: SubmitButtonType = ({
  i18n,
  icon,
  loading,
}: SubmitButtonProps) => {
  const onClick = useSubmitStart();

  return (
    <UI.Button
      i18n={i18n}
      onClick={onClick}
      accessoryRight={icon}
      loading={loading}
    />
  );
};

export default SubmitButton;
