import { EMPTY_OBJECT } from '@shared-utils/object';
import get from 'lodash/get';
import noop from 'lodash/noop';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { ContextComponent } from '../state';

class ReactPageContentContextValue {
  components: Record<string, ContextComponent> = EMPTY_OBJECT;

  language = null;

  country = null;

  setLanguage: (language: string) => void = noop;

  contents: Record<any, any> | null = null;
}
const INITIAL_VALUE = new ReactPageContentContextValue();

const PageContentContext = createContext<ReactPageContentContextValue>(
  INITIAL_VALUE,
);

interface PageContentContextProviderProps {
  components: Record<string, ContextComponent>;
  language: string | null;
  country: string | null;
  contents: Record<any, any> | null;
  children: React.ReactNode;
}
export const ReactPageContentContextProvider: React.FC<PageContentContextProviderProps> = ({
  children,
  components,
  language,
  contents,
  country,
}: PageContentContextProviderProps) => {
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const setLanguage = useCallback((lang: string) => {
    setCurrentLanguage(lang);
  }, []);
  const value = useMemo(
    () => ({
      components,
      language: currentLanguage,
      contents,
      setLanguage,
      country,
    }),
    [components, currentLanguage, contents, setLanguage, country],
  );
  return (
    <PageContentContext.Provider value={value}>
      {children}
    </PageContentContext.Provider>
  );
};

export const usePageContentContext = (): ReactPageContentContextValue =>
  useContext(PageContentContext);

export const useIsComponentVisibleById = (id: string): boolean => {
  const { components } = usePageContentContext();
  return useMemo<boolean>(() => get(components, id)?.visible, [components, id]);
};

export const useReactPageContentContextLanguage = (): string | null => {
  const { language } = usePageContentContext();
  return language;
};

export const useReactPageContentContextContents = (): ReactPageContentContextValue['contents'] => {
  const { contents } = usePageContentContext();
  return contents;
};

export const useReactPageContentContextCountry = (): ReactPageContentContextValue['country'] => {
  const { country } = usePageContentContext();
  return country;
};
