import { FieldPath, ValidationError } from '../../../core';
import { useWatchField } from '../internal';
import useErrorMessages from './useErrorMessages';
import useIsFieldTouchedOrFormSubmitted from './useIsFieldTouchedOrFormSubmitted';
import useIsFormSubmitted from './useIsFormSubmitted';
import useSetValue, { SetValueCb } from './useSetValue';
import useValue from './useValue';

interface Return<T> {
  value: T;
  setValue: SetValueCb<T>;
  errors: ValidationError | null;
  canShowError: boolean;
}
function useFastFormField<T>(fieldPath: FieldPath): Return<T> {
  useWatchField(fieldPath);
  const value: T = useValue<T>(fieldPath);
  const setValue = useSetValue<T>(fieldPath);
  const errors = useErrorMessages(fieldPath);
  const isTouched = useIsFieldTouchedOrFormSubmitted(fieldPath);
  const isFormSubmitted = useIsFormSubmitted();
  return {
    value,
    setValue,
    errors,
    canShowError: isTouched && isFormSubmitted,
  };
}

export default useFastFormField;
