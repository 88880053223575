import { I18nShape } from '@i18n/core';
import { DE } from '@i18n/utils';
import { INTERACTIONS as bundleName } from '@resource/bundle-names';

const NAMESPACE = 'button';

export const I_ACCEPT: I18nShape = {
  key: `${NAMESPACE}.iAccept`,
  text: 'I Accept',
  bundleName,
  translation: {
    [DE]: 'Ich akzeptiere',
  },
};

export const BACK: I18nShape = {
  key: `back`,
  text: 'Back',
  bundleName,
  translation: {
    [DE]: 'Zurück',
  },
};

export const DISMISS: I18nShape = {
  key: `dismiss`,
  text: 'Dismiss',
  bundleName,
  translation: {
    [DE]: 'Abweisen',
  },
};

export const SKIP: I18nShape = {
  key: `skip`,
  text: 'Skip',
  bundleName,
  translation: {
    [DE]: 'Überspringen',
  },
};

export const EDIT: I18nShape = {
  key: `edit`,
  text: 'Edit',
  bundleName,
  translation: {
    [DE]: 'Bearbeiten',
  },
};

export const SUBMIT: I18nShape = {
  key: `submit`,
  text: 'Submit',
  bundleName,
  translation: {
    [DE]: 'Abschicken',
  },
};

export const REDEEM: I18nShape = {
  key: `redeem`,
  text: 'Redeem',
  bundleName,
  translation: {
    [DE]: 'Einlösen',
  },
};

export const APPLY: I18nShape = {
  key: `apply`,
  text: 'Apply',
  bundleName,
  translation: {
    [DE]: 'Anwenden',
  },
};

export const APPROVE: I18nShape = {
  key: `approve`,
  text: 'Approve',
  bundleName,
  translation: {
    [DE]: 'Zulassen',
  },
};

export const REJECT: I18nShape = {
  key: `reject`,
  text: 'Reject',
  bundleName,
  translation: {
    [DE]: 'Abweisen',
  },
};
