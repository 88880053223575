import { isProductionEnvironment } from '@lib/config-loader';
import { ConsoleLoggerTransportOptions } from '@logger/console-transport';
import { LogLevel, MessageOperationStatus } from '@logger/core';
import { DEFAULT_HEX_COLORS } from '@logger/utils';
import figures from 'figures';
import memoize from 'lodash/memoize';
import split from 'lodash/split';
import logSymbols from 'log-symbols';
import randomColor from 'random-color';

import chalk from './chalk';
import { normalizeLevel } from './normalizeLevel';

const generateRandomColor = memoize((s: string) =>
  chalk.hex(randomColor().hexString())(s.toUpperCase()),
);

const EMPTY_TRACE_ID = ''.padEnd(5);
const GROUP_COLORS = [122, 117, 106, 109, 110];

export const DEFAULT_NODE_CONSOLE_LOGGER_TRANSPORT_OPTIONS: ConsoleLoggerTransportOptions = {
  // filter: (msg) => return true,
  getTraceId: msg => {
    const traceId =
      msg.traceId || msg.params?.traceId || msg.params?.ctx?.traceId;
    const devTraceId = msg.params?.actionId || msg.params?.ctx?.actionId;
    if (!isProductionEnvironment()) {
      return devTraceId || traceId || EMPTY_TRACE_ID;
    }
    return traceId || EMPTY_TRACE_ID;
  },
  formatters: {
    traceId: generateRandomColor,
    group: (group: string): string => {
      const groupKeys = split(group, ':');
      return groupKeys
        .map((groupKey, i) =>
          chalk.ansi256(GROUP_COLORS[i] || GROUP_COLORS[0])(groupKey),
        )
        .join(` ${figures.arrowRight} `);
    },
    date: chalk.underline,
    level: (level: LogLevel): string => {
      const color =
        DEFAULT_HEX_COLORS[level] || DEFAULT_HEX_COLORS[LogLevel.INFO];
      return chalk.hex(color)(normalizeLevel(level));
    },
    operationName: s => `[${chalk.magenta(s)}]`,
    operationStatus: (status: MessageOperationStatus): string => {
      switch (status) {
        case MessageOperationStatus.ERROR:
          return chalk.red(logSymbols.error);
        case MessageOperationStatus.OK:
          return chalk.green(logSymbols.success);
        case MessageOperationStatus.WARN:
          return chalk.yellow(logSymbols.warning);
        case MessageOperationStatus.START:
          return chalk.blue(logSymbols.info);
        default:
          return logSymbols.info;
      }
    },
    operationTime: (time: string) => `[${chalk.bold(time)}]`,
  },
};
