import MuiModal from '@material-ui/core/Modal';
import { css } from '@ui-system/css';
import { ModalProps, ModalType } from '@ui-system/interfaces-modal';
import UI from '@ui-system/ui';
import React from 'react';

const SIZE = 30;
const PADDING = 1;
const BORDER_RADIUS = SIZE + PADDING / 2;
const STYLE = css`
  padding: ${PADDING}px;
  width: ${SIZE}px;
  height: ${SIZE}px;
  border-radius: ${BORDER_RADIUS}px;
  position: relative;
  margin-left: auto;
  top: ${SIZE + 10}px;
  margin-right: 10px;
`;
const Children = React.forwardRef(
  (
    {
      children,
      toggle,
      hideOriginalClose,
    }: React.PropsWithChildren<{
      toggle: () => void;
      children: React.ReactNode;
      hideOriginalClose?: boolean;
    }>,
    ref,
  ) => (
    <>
      {!hideOriginalClose && (
        <UI.Container
          bg="primary"
          align="center"
          justify="center"
          style={STYLE}
          onClick={toggle}
        >
          <UI.Icon name="close" size={SIZE - 5} color="white" />
        </UI.Container>
      )}
      {React.cloneElement(children as React.ReactElement<any, any>, {
        ref,
      })}
    </>
  ),
);
const Modal: ModalType = ({
  style,
  children,
  visible,
  toggle,
  hideOriginalClose,
}: ModalProps): React.ReactElement => (
  <>
    <MuiModal style={style} open={visible} onClose={toggle}>
      <Children toggle={toggle} hideOriginalClose={hideOriginalClose}>
        {children}
      </Children>
    </MuiModal>
  </>
);

export default Modal;
