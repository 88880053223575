import { Color } from '@ui-system/css';
import { I18nShape } from '@ui-system/deps';
import { Modifiers, Style } from '@ui-system/interfaces/types';
import { nanoid } from 'nanoid';
import * as React from 'react';

export type ButtonColor = 'inherit' | 'primary' | 'secondary' | 'default';
export type ButtonVariant = 'text' | 'outlined' | 'contained';

export interface ButtonProps {
  onClick: () => any;
  i18n?: I18nShape | string;
  children?: string | React.ReactNode;
  color?: ButtonColor;
  variant?: ButtonVariant;
  round?: boolean;
  style?: Style;
  modifiers?: Modifiers;
  accessoryRight?: string;
  accessoryLeft?: string;
  iconSize?: number;
  iconColor?: Color;
  loading?: boolean;
  disabled?: boolean;
  id?: string;
}

export const BUTTON_UUID = nanoid();
export const BUTTON_TEXT_UUID = nanoid();

export type ButtonType = React.FC<ButtonProps>;
