import FastFormProvider from '@lib/fast-form/context/FastFormProvider';
import { ContainerType } from '@ui-system/interfaces-container';
import UI from '@ui-system/ui';
import { noop } from 'lodash';
import React from 'react';

import {
  EmailFieldType,
  ErrorType,
  SubmitButtonType,
  TermsAndConditionsType,
} from '../core';
import EmailField from './EmailField';
import { EmailFormData } from './EmailFormData';
import Error from './Error';
import SubmitButton from './SubmitButton';
import TermsAndConditions from './TermsAndConditions';

interface AuthStatics {
  Container: ContainerType;
  EmailField: EmailFieldType;
  SubmitButton: SubmitButtonType;
  TermsAndConditions: TermsAndConditionsType;
  Error: ErrorType;
}

interface Props {
  children: React.ReactNode;
  onSuccess?: (values: EmailFormData) => void;
  onFail?: () => void;
  id?: string;
}

const FastFormData = new EmailFormData();

const FAST_FORM_OTIONS = {
  enableReinitialize: true,
};
const Email: React.FC<Props> & AuthStatics = ({
  children,
  onSuccess,
  onFail,
  id,
}: Props) => (
  <FastFormProvider
    initialValues={FastFormData}
    onSubmitSuccess={onSuccess}
    onSubmitError={onFail}
    formId={id}
    options={FAST_FORM_OTIONS}
  >
    {children}
  </FastFormProvider>
);
Email.defaultProps = {
  onSuccess: noop,
  onFail: noop,
  id: undefined,
};

// @ts-ignore
const Container: AuthStatics['Container'] = props => (
  <UI.Container {...props} />
);
Email.SubmitButton = SubmitButton;
Email.EmailField = EmailField;
Email.TermsAndConditions = TermsAndConditions;
Email.Container = Container;
Email.Error = Error;

export default Email;
