import { getFastFormModule } from '@fast-form/module';
// import { HEALTH_CHECK_MODULE } from '@health-check/logic-check-health';
import createDynamicStore from '@lib/redux-dynamic-store';
import { getMarketingCategoriesModule } from '@rec/redux-marketing-categories';
import { getPersistStoreModule } from '@redux-persisted/module';
import { getBundleModule } from '@resource/redux-bundle';
import { getLanguageModule } from '@resource/redux-language';

const DEFAULT_LANGUAGE = 'en';

const BUNDLE_MODULE = getBundleModule();
const LANGUAGE_MODULE = getLanguageModule(DEFAULT_LANGUAGE);

export default createDynamicStore([
  getFastFormModule(),
  BUNDLE_MODULE,
  LANGUAGE_MODULE,
  // HEALTH_CHECK_MODULE,
  getMarketingCategoriesModule(),
  getPersistStoreModule([BUNDLE_MODULE.id, LANGUAGE_MODULE.id], 'rec-pro-page'),
]);
