import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';

export const NavigationRouter = BrowserRouter;
export const NavigationLink = Link;
export const NavigationRoute = Route;
export const NavigationSwitch = Switch;
export const AnimatedNavigationSwitch = Switch;

export * from './hooks';
export * from './listeners';
