import { Bundle } from '@i18n/core';
import { State } from '@redux-async-module/interfaces';

export { INITIAL_STATE } from '@redux-async-module/interfaces';

export const NAMESPACE = 'bundle';

export type BundleNames = string[];

export interface LoadMessagesStartPayload {
  bundleNames: BundleNames;
}

export type LoadMessagesSuccessPayload = Bundle;

export interface RootState {
  [NAMESPACE]: State<LoadMessagesStartPayload, LoadMessagesSuccessPayload>;
}
