import { useQueryParams } from '@lib/navigation';
import { CreatedLiveDTO } from '@page-creator/services-create-live';
import { getPageLiveUrl } from '@page-creator/shared-routes';
import axios from 'axios';
// import { useEffect } from 'react';
import { useAsync } from 'react-async-hook';
import { ContextComponent } from 'react-page-editor/state';
import { U } from 'ts-toolbelt';

const fetchPageByUrlPath = async (
  urlPath: string,
): Promise<U.Nullable<CreatedLiveDTO>> => {
  const response = await axios.get(getPageLiveUrl(urlPath));
  return response.data;
};

// function useSavePageToLocalStorage(
//   urlPath: string,
//   contents: Record<string, any>,
// ) {
//   useEffect(() => {
//     localStorage.setItem(urlPath, JSON.stringify(contents));
//   }, [contents, urlPath]);
// }

export function usePageByUrlPath(
  urlPath: string,
): {
  loading: boolean;
  error: null | Error;
  result: null | Record<string, ContextComponent>;
  contents: null | Record<string, any>;
  language: null | string;
  country: null | string;
} {
  const page = useAsync(fetchPageByUrlPath, [urlPath]);
  // useSavePageToLocalStorage(urlPath, page.result?.contents);
  const { language } = useQueryParams<{ language: string }>();
  return {
    loading: page.loading,
    error: page.error,
    result: page.result?.contents?.components,
    contents: page.result?.contents,
    language: language || page.result?.contents?.language,
    country: page.result?.contents?.country,
  };
}
