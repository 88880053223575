import {
  createRecProductVideoComponents,
  RecProduct,
  RecProductProps,
} from '@pro-page-components/rec-product';
import { useChildrenVisibilityEditor } from '@pro-page-components/utils/useChildrenVisibility';
import * as React from 'react';
import { ComponentForEditor } from 'react-page-editor/state';

import config from './config.json';
import defaultProps from './defaultProps.json';

const { Video, VideoEditor } = createRecProductVideoComponents({
  name: 'REC Alpha Pure-RX Series Video',
  id: 'REC_ALPHA_PURE_RX_VIDEO',
  path: '.....',
  parent: config.id,
});

export const RecAlphaPureRxSeries: ComponentForEditor<RecProductProps> = (
  props: RecProductProps,
) => <RecProduct VideoComponent={Video} {...props} />;

export const RecAlphaPureRxSeriesEditor: ComponentForEditor<RecProductProps> = (
  props: RecProductProps,
) => {
  // eslint-disable-next-line no-underscore-dangle,react/destructuring-assignment
  const visible = useChildrenVisibilityEditor(props.__config);
  if (!visible) return null;
  return (
    <RecAlphaPureRxSeries
      scale={0.9}
      skipVisibilityCheck
      VideoComponent={VideoEditor}
      {...props}
    />
  );
};

RecAlphaPureRxSeries.children = [Video];
RecAlphaPureRxSeries.defaultProps = (defaultProps as unknown) as RecProductProps;
RecAlphaPureRxSeries.config = config;
RecAlphaPureRxSeriesEditor.defaultProps = (defaultProps as unknown) as RecProductProps;
RecAlphaPureRxSeriesEditor.config = config;
RecAlphaPureRxSeriesEditor.children = [VideoEditor];
