import { Info } from '@ui-system/interfaces-list';
import { nanoid } from 'nanoid';
import React from 'react';

export type CarouselProps = {
  data: any[];
  renderItem: (info: Info<any>) => React.ReactElement;
  sliderWidth: number;
  itemWidth: number;
  innerRef?: React.Ref<any>;
  loop?: boolean;
} & Record<string, any>;

export const CAROUSEL_UUID = nanoid();

export type CarouselType = React.FC<CarouselProps>;
