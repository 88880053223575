import { Container } from '@ui-system/common-container';
import { useImageStyle } from '@ui-system/default-styles/image';
import { ImageProps } from '@ui-system/interfaces-image';
import UI from '@ui-system/ui';
import React, { CSSProperties } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Image: React.FC<ImageProps> = ({
  src,
  preview,
  height,
  width,
  style,
  resizeMode,
  ...rest
}: ImageProps) => {
  const imageStyle = useImageStyle(style as CSSProperties, '', null, {
    height,
    width,
    resizeMode,
  });
  if (!src && preview) {
    return (
      <Container style={imageStyle} {...rest}>
        {/* XXX: This is due to react native accepting number, I think
        // @ts-ignore */}
        <img src={preview} alt="preview" style={imageStyle} />
      </Container>
    );
  }

  return (
    <Container style={imageStyle} {...rest}>
      {/* <img src={src} style={style} /> */}
      <LazyLoadImage
        src={src}
        placeholder={<UI.Skeleton height={height} width={width} />}
        alt=""
        style={imageStyle}
        height={height}
        width={width}
        effect="blur"
        visibleByDefault
      />
    </Container>
  );
};

export default Image;
