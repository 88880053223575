import { ActionCreator, BaseMeta } from '@redux-basic-module/interfaces';
import { nanoid } from 'nanoid';

export function createAction<
  Type extends string,
  Payload = null,
  Meta extends BaseMeta = BaseMeta
>(type: Type): ActionCreator<Type, Payload, Meta> {
  const action = (payload?: Payload, meta?: Meta) => ({
    type,
    payload,
    meta: {
      ...(meta || {}),
      actionId: meta?.actionId || nanoid(5),
    },
  });
  action.type = type;
  // @ts-ignore
  return action;
}
