import { getMessageChannel, LogLevel, Message } from '@logger/core';
import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/types';
import { filter } from 'rxjs/operators';

export function convertLevelToSeverity(level: LogLevel): Severity {
  switch (level) {
    case LogLevel.INFO:
      return Severity.Info;
    case LogLevel.WARN:
      return Severity.Warning;
    case LogLevel.ERROR:
      return Severity.Error;
    case LogLevel.FATAL:
      return Severity.Fatal;
    case LogLevel.EMERG:
      return Severity.Critical;
    default:
      return Severity.Debug;
  }
}

function sendMessageToSentry(
  msg: Message,
  options: SentryExpoLoggerOptions,
): any {
  const { addParams, fullMessage: enableFullMessage } = options;

  const { message, fullMessage, date, group, level, params, operation } = msg;
  Sentry.addBreadcrumb({
    message: enableFullMessage === true ? fullMessage || message : message,
    timestamp: date.getTime(),
    level: convertLevelToSeverity(level),
    data: {
      ...(options.staticParams || {}),
      ...(operation || {}),
      ...(params || {}),
      ...(addParams ? addParams(msg) : {}),
      group,
    },
  });
}

function createSubscribeToLogger(options: SentryExpoLoggerOptions) {
  function subscribeToLogger(msg: Message) {
    sendMessageToSentry(msg, options);
  }
  return subscribeToLogger;
}

export type SentryExpoLoggerOptions = {
  staticParams?: Record<string, any>;
  addParams?: (msg: Message) => Record<string, any>;
  fullMessage?: boolean;
};

export function registerSentryReactLoggerTransport(
  options: SentryExpoLoggerOptions,
): void {
  const subscriber = createSubscribeToLogger(options);
  getMessageChannel().pipe(filter(Boolean)).subscribe(subscriber);
}
