import { ResizeMode } from '@ui-system/interfaces-image';
import UI from '@ui-system/ui';
import React from 'react';

interface PdfProps {
  url: string;
  height: number | string;
  width: number | string;
  resizeMode?: ResizeMode;
}

export const PdfThumbnail: React.FC<PdfProps> = ({
  url,
  height,
  width,
  resizeMode,
}: PdfProps) => (
  <UI.Container>
    <UI.Animation>
      <UI.Image
        height={height}
        width={width}
        src={url}
        resizeMode={resizeMode}
      />
    </UI.Animation>
  </UI.Container>
);
