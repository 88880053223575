import { map } from 'lodash';
import includes from 'lodash/includes';
import { U } from 'ts-toolbelt';

import AMERICA_COUNTRIES from './regions/amer.json';
import APAC_COUNTRIES from './regions/apac.json';
import EMEA_COUNTRIES from './regions/emea.json';

export const AMERICA_COUNTRY_CODES = map(AMERICA_COUNTRIES, 'id');
export const APAC_COUNTRY_CODES = map(APAC_COUNTRIES, 'id');
export const EMEA_COUNTRY_CODES = map(EMEA_COUNTRIES, 'id');

export enum WorldRegion {
  EMEA = 'EMEA',
  APAC = 'APAC',
  AMER = 'AMER',
}

export function getRegionByCountry(
  country: U.Nullable<string>,
): string[] | null {
  if (!country) return null;
  if (includes(AMERICA_COUNTRY_CODES, country)) {
    return AMERICA_COUNTRY_CODES;
  }
  if (includes(APAC_COUNTRY_CODES, country)) {
    return APAC_COUNTRY_CODES;
  }
  return EMEA_COUNTRY_CODES;
}

export function getRegionNameByCountry(
  country: U.Nullable<string>,
): WorldRegion | null {
  if (!country) return null;
  if (includes(AMERICA_COUNTRY_CODES, country)) {
    return WorldRegion.AMER;
  }
  if (includes(APAC_COUNTRY_CODES, country)) {
    return WorldRegion.APAC;
  }
  return WorldRegion.EMEA;
}
