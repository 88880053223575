import { useCallback, useMemo, useState } from 'react';

interface UseIsOpen {
  (): {
    isOpen: boolean;
    toggle: () => void;
    show: () => void;
    hide: () => void;
  };
}
export const useIsOpen: UseIsOpen = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState);

  const toggle = useCallback(() => {
    setIsOpen(visible => !visible);
  }, [setIsOpen]);
  const show = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const hide = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  return useMemo(
    () => ({
      isOpen,
      toggle,
      show,
      hide,
    }),
    [isOpen, toggle, show, hide],
  );
};
