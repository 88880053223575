import { useHasLoggedUser } from '@authentication/redux-auth-data/hooks';
import {
  useAuthenticateByEmailTanDismissError,
  useAuthenticateByEmailTanError,
  useAuthenticateByEmailTanIsLoading,
} from '@authentication/redux-authenticate-by-email-tan';
import { useAuthenticateBySSOTokenStartOnMount } from '@authentication/redux-authenticate-by-sso';
import {
  useReSendTANCode,
  useSignInByEmailError,
  useSignInByEmailIsLoading,
  useSignInByEmailLastStartPayload,
  useSignInByEmailStart,
} from '@authentication/redux-sign-in-by-email';
import { UserRole } from '@authentication/shared-enums';
import Auth from '@authentication/view';
import {
  EmailFormData,
  FIELD_PATHS as EMAIL_FIELD_PATHS,
} from '@authentication/view/EmailFormData';
import { FIELD_PATHS } from '@authentication/view/TANCodeFormData';
import {
  FastFormCheckBox,
  FastFormInput,
  FastFormSubmitButton,
} from '@fast-form/components';
import { useTranslateFunction } from '@i18n/context';
import { I18nShape } from '@i18n/core';
import { useQueryParams } from '@lib/navigation';
import { useNotifications } from '@lib/use-notifications';
import { useDebouncedBoolean } from '@react-utils/hooks';
import { SUPPORTED_LANGUAGES } from '@rec/constants';
import {
  FOR_SECURITY_REASONS_ENTER_YOUR_EMAIL,
  FOR_SECURITY_REASONS_ENTER_YOUR_TAN,
  INVALID_EMAIL_ADDRESS,
  TERMS_AND_CONDITIONS_CHECKBOX_LABEL,
} from '@rec/i18n';
import { useNavigateToEditor } from '@rec/routes/useNavigateToEditor';
import {
  useInitialLocaleData,
  useSetUserInfo,
  useUserInfo,
} from '@rec/user-info';
import {
  ASTERISTICS_PLACEHOLDER,
  DID_NOT_RECEIVE,
  EMAIL,
  NEXT,
  RESEND_SECURITY_TOKEN,
  SELECT_YOUR_COUNTRY,
  SEND_AGAIN,
} from '@resource/i18n-shapes';
import { useChangeLanguage } from '@resource/redux-language';
import { extractLanguageAndCountryFromLocaleString } from '@resource/utils';
import { COUNTRIES } from '@shared/countries-list';
import { CountryOption } from '@shared/countries-list/types';
import { useCountriesOptionsList } from '@shared/countries-list/useCountriesByLanguage';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import { F } from '@utils/ts';
import noop from 'lodash/noop';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

const STYLE = css`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 998;
`;

const BUTTON_STYLE = css`
  border: 1px solid black;
  margin-top: 15px;
  margin-left: 15px;
  width: 150px;
  height: 33px;
`;

interface AuthModalProps {
  title: I18nShape;
}

// interface Props extends ContainerProps {
//   error: HttpErrorJSON;
//   onDismiss: VoidFunction;
// }
//
// const CLOSE_STYLE = css`
//   position: relative;
//   top: -15px;
//   right: -15px;
// `;

// export const ErrorCard: React.FC<Props> = ({
//   error,
//   onDismiss,
//   ...rest
// }: Props) => {
//   const i18n: I18nShape = get(error as HttpErrorJSON, 'i18n', REQUEST_ERROR);
//
//   if (!error) return null;
//   return (
//     <UI.Container
//       justify="space-between"
//       gap={2}
//       m={4}
//       p={8}
//       bg="white"
//       align="center"
//       shadow={2}
//       borderRadius={20}
//       {...rest}
//     >
//       <UI.Container direction="column">
//         <UI.Typography i18n={i18n} />
//       </UI.Container>
//       <UI.Container style={CLOSE_STYLE} onClick={onDismiss}>
//         <UI.Icon name="close" color="black" size={20} />
//       </UI.Container>
//     </UI.Container>
//   );
// };

const ErrorAuth: React.FC = () => {
  const authError = useAuthenticateByEmailTanError();
  const dismissAuth = useAuthenticateByEmailTanDismissError();
  const handleDismiss = useCallback(() => {
    dismissAuth();
  }, [dismissAuth]);
  if (!authError) return null;
  return (
    <UI.Container m="2, 0">
      <UI.Caption
        modifiers="error"
        i18n={authError?.i18n}
        onClick={handleDismiss}
      />
    </UI.Container>
  );
};

const Submit: React.FC = () => {
  const isLoading = useAuthenticateByEmailTanIsLoading();
  return <FastFormSubmitButton disabled={isLoading} style={BUTTON_STYLE} />;
};

const NextButton: React.FC = () => {
  const isLoading = useSignInByEmailIsLoading();
  return (
    <FastFormSubmitButton
      i18n={NEXT}
      disabled={isLoading}
      style={BUTTON_STYLE}
    />
  );
};

const TanInput: React.FC = () => {
  const isLoading = useAuthenticateByEmailTanIsLoading();
  const lastStartPayload = useSignInByEmailLastStartPayload();
  if (!lastStartPayload?.email) return null;
  return (
    <UI.Animation>
      <UI.Container gap={2} align="flex-start">
        <FastFormInput
          fieldPath={FIELD_PATHS.tanCode}
          placeholder={ASTERISTICS_PLACEHOLDER}
          disabled={isLoading}
        />
        <Submit />
      </UI.Container>
    </UI.Animation>
  );
};

const UserEmail: React.FC = props => {
  const signInError = useSignInByEmailError();
  return (
    <UI.Animation>
      <UI.Container
        gap={2}
        align="flex-start"
        {...props}
        w={400}
        direction="column"
      >
        <FastFormInput
          fieldPath={EMAIL_FIELD_PATHS.email}
          placeholder={EMAIL}
          disabled={false}
        />
        {signInError && (
          <UI.Caption modifiers="error" i18n={INVALID_EMAIL_ADDRESS} />
        )}
      </UI.Container>
    </UI.Animation>
  );
};

const SEND_AGAIN_STYLE = css`
  cursor: pointer;
`;
const AcceptTerms = props => (
  <UI.Animation>
    <UI.Container align="center" gap={2} direction="column" {...props}>
      <FastFormCheckBox
        fieldPath={EMAIL_FIELD_PATHS.terms}
        label={() => <UI.Body2 i18n={TERMS_AND_CONDITIONS_CHECKBOX_LABEL} />}
      />
    </UI.Container>
  </UI.Animation>
);

const ReSendTANCode: React.FC = props => {
  const visible = useDebouncedBoolean(5000);
  const reSendTan = useReSendTANCode();
  const isLoading = useSignInByEmailIsLoading();
  const { addNotification } = useNotifications();
  const translate = useTranslateFunction();
  const resendTanCode = useCallback(() => {
    reSendTan();
    addNotification(
      <UI.Typography modifiers="light">
        {translate(RESEND_SECURITY_TOKEN)}
      </UI.Typography>,
      {
        variant: 'success',
      },
    );
  }, [addNotification, reSendTan, translate]);
  const lastStartPayload = useSignInByEmailLastStartPayload();
  if (!visible || !lastStartPayload?.email) return null;
  return (
    <UI.Animation>
      <UI.Container m="2, 0" align="center" gap={2} {...props}>
        <UI.Body2 i18n={DID_NOT_RECEIVE} suffix="?" />
        <UI.Body2
          style={SEND_AGAIN_STYLE}
          onClick={resendTanCode}
          i18n={SEND_AGAIN}
          modifiers="bold, uppercase, link"
          loading={isLoading}
        />
      </UI.Container>
    </UI.Animation>
  );
};

enum AuthStep {
  COUNTRY_STEP = 'COUNTRY_STEP',
  NONE = 'NONE',
  EMAIL_SENT = 'EMAIL_SENT',
}

const InitialStep = () => {
  const signInByEmailStart = useSignInByEmailStart();
  const handleSignInByEmail = useCallback(
    (data: EmailFormData) => {
      signInByEmailStart({ email: data.email, role: UserRole.USER });
    },
    [signInByEmailStart],
  );
  return (
    <UI.Container direction="column" align="center" m={1}>
      <UI.Body2 i18n={FOR_SECURITY_REASONS_ENTER_YOUR_EMAIL} />
      <Auth.Email onSuccess={handleSignInByEmail}>
        <UserEmail />
        <AcceptTerms />
        <NextButton />
      </Auth.Email>
    </UI.Container>
  );
};
interface CountryStepProps {
  setAuthStep: F.Function;
}

interface CountriesSelectProps {
  value: CountryOption;
  onChange: (option: CountryOption) => any;
}
const CountriesSelect: React.FC<CountriesSelectProps> = ({
  value,
  onChange,
  ...props
}: CountriesSelectProps) => {
  const options = useCountriesOptionsList();
  return (
    <UI.Form.Select
      options={options}
      onChange={onChange}
      value={value}
      {...props}
    />
  );
};
const CountryStep = ({ setAuthStep }: CountryStepProps) => {
  const { country, language } = useUserInfo();
  const setUserInfo = useSetUserInfo();
  const setLanguage = useChangeLanguage();
  const [value, setValue] = useState<CountryOption>(null);

  useEffect(() => {
    if (SUPPORTED_LANGUAGES.includes(language)) {
      setValue({
        label: COUNTRIES.getName(
          country?.toLowerCase(),
          extractLanguageAndCountryFromLocaleString(language).language,
        ),
        value: country,
      });
    }
  }, [country, language, setLanguage]);

  const onChange = useCallback(
    (item: CountryOption) => {
      setUserInfo({ country: item.value });
      setValue(item);
    },
    [setUserInfo],
  );

  const onNext = useCallback(() => {
    setAuthStep(AuthStep.NONE);
  }, [setAuthStep]);

  return (
    <UI.Container direction="column" align="center" m={1} bg="black" gap={6}>
      <UI.H4 modifiers="light" i18n={SELECT_YOUR_COUNTRY} />
      <CountriesSelect onChange={onChange} value={value} />
      <UI.Button onClick={onNext} i18n={NEXT} />
    </UI.Container>
  );
};

const EmailSentStep = () => (
  <UI.Container direction="column" align="center" m={1}>
    <UI.Body2 i18n={FOR_SECURITY_REASONS_ENTER_YOUR_TAN} />
    <TanInput />
    <ErrorAuth />
    <ReSendTANCode />
  </UI.Container>
);

const STEPS_MAP = new Map();

STEPS_MAP.set(AuthStep.COUNTRY_STEP, CountryStep);
STEPS_MAP.set(AuthStep.NONE, InitialStep);
STEPS_MAP.set(AuthStep.EMAIL_SENT, EmailSentStep);

function useSSO(): boolean {
  const { country, language } = useInitialLocaleData();
  const setUserInfo = useSetUserInfo();
  const setLanguage = useChangeLanguage();
  const { accessToken } = useQueryParams();
  useEffect(() => {
    if (SUPPORTED_LANGUAGES.includes(language)) {
      setLanguage({ language });
      setUserInfo({
        language,
        country,
      });
    } else {
      setUserInfo({
        country,
      });
    }
  }, [country, language, setLanguage, setUserInfo]);
  useAuthenticateBySSOTokenStartOnMount();
  return !!accessToken;
}
export const AuthModal: React.FC<AuthModalProps> = (
  props: // title,
  AuthModalProps,
) => {
  // const authenticateByEmailTanStart = useAuthenticateByEmailTanStart();
  // const signInError = useSignInByEmailError();
  noop(props);
  const hasLoggedUser = useHasLoggedUser();
  // const [authStep, setAuthStep] = useState<AuthStep>(AuthStep.COUNTRY_STEP);

  // const handleSubmitTan = useCallback(
  //   (data: TANCodeFormData) => {
  //     authenticateByEmailTanStart({ tanCode: data.tanCode });
  //   },
  //   [authenticateByEmailTanStart],
  // );

  // const success = useSignInByEmailSuccess();

  // useEffect(() => {
  //   if (success) {
  //     setAuthStep(AuthStep.EMAIL_SENT);
  //   }
  // }, [success]);

  // useEffect(() => {
  //   if (signInError) {
  //     setAuthStep(AuthStep.NONE);
  //   }
  // }, [signInError]);
  const navigate = useNavigateToEditor();
  useEffect(() => {
    if (hasLoggedUser) {
      navigate();
    }
  }, [hasLoggedUser, navigate]);

  const isSSO = useSSO();
  if (hasLoggedUser || isSSO) return null;
  return null;
  // const Step = STEPS_MAP.get(authStep);
  //
  // const isCountryStep = authStep === AuthStep.COUNTRY_STEP;
  //
  // return (
  //   <UI.Container style={STYLE}>
  //     <UI.Container
  //       bg={isCountryStep ? 'black' : 'gray.A100'}
  //       f={1}
  //       h={500}
  //       p={10}
  //       align="center"
  //       justify="center"
  //     >
  //       {isCountryStep ? (
  //         <CountryStep setAuthStep={setAuthStep} />
  //       ) : (
  //         <Auth.TANCode onSuccess={handleSubmitTan}>
  //           <UI.Container direction="column" align="center" gap={3}>
  //             <UI.Container>
  //               <UI.H4 i18n={title} />
  //             </UI.Container>
  //             <UI.Body2 i18n={TERMS_AND_CONDITIONS_DESCRIPTION} />
  //             <UI.Divider color="primary" />
  //             <Step />
  //           </UI.Container>
  //         </Auth.TANCode>
  //       )}
  //     </UI.Container>
  //   </UI.Container>
  // );
};
