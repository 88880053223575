import HtmlParser from '@html-parser/core';
import { PdfThumbnail } from '@pdf/core';
import {
  RecAlphaBlackSeries,
  RecAlphaBlackSeriesEditor,
} from '@pro-page-components/rec-alpha-black-series';
import {
  RecAlphaPure2Series,
  RecAlphaPure2SeriesEditor,
} from '@pro-page-components/rec-alpha-pure-2-series';
import {
  RecAlphaPureRSeries,
  RecAlphaPureRSeriesEditor,
} from '@pro-page-components/rec-alpha-pure-r-series';
import {
  RecAlphaPureRxSeries,
  RecAlphaPureRxSeriesEditor,
} from '@pro-page-components/rec-alpha-pure-rx-series';
import {
  RecAlphaPureSeries,
  RecAlphaPureSeriesEditor,
} from '@pro-page-components/rec-alpha-pure-series';
import {
  RecAlphaSeries,
  RecAlphaSeriesEditor,
} from '@pro-page-components/rec-alpha-series';
import {
  RecNPeak2BlackSeries,
  RecNPeak2BlackSeriesEditor,
} from '@pro-page-components/rec-n-peak-2-black-series';
import {
  RecNPeak2Series,
  RecNPeak2SeriesEditor,
} from '@pro-page-components/rec-n-peak-2-series';
import {
  RecNPeak3BlackSeries,
  RecNPeak3BlackSeriesEditor,
} from '@pro-page-components/rec-n-peak-3-black-series';
import {
  RecNPeakBlackSeries,
  RecNPeakBlackSeriesEditor,
} from '@pro-page-components/rec-n-peak-black-series';
import {
  RecNPeakSeries,
  RecNPeakSeriesEditor,
} from '@pro-page-components/rec-n-peak-series';
import { RecProductProps } from '@pro-page-components/rec-product';
import {
  RecTwinPeak4BlackSeries,
  RecTwinPeak4BlackSeriesEditor,
} from '@pro-page-components/rec-twin-peak-4-black-series';
import {
  RecTwinPeak4Series,
  RecTwinPeak4SeriesEditor,
} from '@pro-page-components/rec-twin-peak-4-series';
import {
  RecTwinPeak5BlackSeries,
  RecTwinPeak5BlackSeriesEditor,
} from '@pro-page-components/rec-twin-peak-5-black-series';
import {
  RecTwinPeak5Series,
  RecTwinPeak5SeriesEditor,
} from '@pro-page-components/rec-twin-peak-5-series';
import {
  useComponentOptions,
  useComponentServerProps,
  useSetPropsByLanguage,
} from '@pro-page-components/utils';
import { useDeepCompareMemo } from '@react-utils/hooks';
import { ScaleProps, SectionWrapper } from '@rec/section-wrapper';
import { getStaticUrlWithParams } from '@rec/static';
import { DOWNLOAD } from '@resource/i18n-shapes';
import { Color, css } from '@ui-system/css';
import { OptionType } from '@ui-system/interfaces-form';
import UI from '@ui-system/ui';
import { openUrl } from 'browser';
import map from 'lodash/map';
import * as React from 'react';
import { useCallback } from 'react';
import {
  ComponentForEditor,
  useComponentProps,
  useViewModeContext,
} from 'react-page-editor/state';

import config from './config.json';
import defaultProps from './defaultProps.json';

const STYLE = css`
  letter-spacing: 3px;
`;

interface RecProductsProps extends ScaleProps {
  title?: string;
  text?: string;
  datasheet?: string;
  datasheetThumb?: string;
  datasheetText?: string;
  bg?: Color;
  childrens?: ComponentForEditor<RecProductProps>[];
}
const CHILDREN = [
  RecAlphaSeries,
  RecAlphaPureSeries,
  RecAlphaPure2Series,
  RecAlphaPureRSeries,
  RecAlphaPureRxSeries,
  RecAlphaBlackSeries,
  RecNPeakSeries,
  RecNPeakBlackSeries,
  RecNPeak2Series,
  RecNPeak2BlackSeries,
  RecNPeak3BlackSeries,
  RecTwinPeak4Series,
  RecTwinPeak4BlackSeries,
  RecTwinPeak5BlackSeries,
  RecTwinPeak5Series,
];
const CHILDREN_EDITOR = [
  RecAlphaSeriesEditor,
  RecAlphaPureSeriesEditor,
  RecAlphaPure2SeriesEditor,
  RecAlphaPureRSeriesEditor,
  RecAlphaPureRxSeriesEditor,
  RecAlphaBlackSeriesEditor,
  RecNPeakSeriesEditor,
  RecNPeakBlackSeriesEditor,
  RecNPeak2SeriesEditor,
  RecNPeak2BlackSeriesEditor,
  RecNPeak3BlackSeriesEditor,
  RecTwinPeak4SeriesEditor,
  RecTwinPeak4BlackSeriesEditor,
  RecTwinPeak5BlackSeriesEditor,
  RecTwinPeak5SeriesEditor,
];

interface ChildrenItemProps {
  Child: ComponentForEditor<RecProductProps>;
}
const ChildrenItem = ({ Child }: ChildrenItemProps) => {
  const id = `${config.id}.children.${Child.config.id}`;
  const ctxProps = useComponentProps(id);
  const props = useComponentServerProps<
    Omit<RecProductProps, 'scale' | 'VideoComponent' | '__config'>
  >(Child.config, ctxProps);
  // eslint-disable-next-line no-underscore-dangle
  const __config = useDeepCompareMemo(
    () => ({ ...Child.config, id, parent: config.id }),
    [Child.config, id],
  );
  return <Child __config={__config} {...props} />;
};
export const RecProducts: ComponentForEditor<RecProductsProps> = ({
  scale,
  childrens,
  ...rest
}: RecProductsProps) => {
  const {
    text,
    datasheetText,
    bg,
    datasheet,
    datasheetThumb,
    title,
  } = useComponentServerProps<RecProductsProps>(config, rest);
  const titleContent = HtmlParser(title);
  const textContent = HtmlParser(text);
  const datasheetTextContent = HtmlParser(datasheetText);
  const handleDownloadPdf = useCallback(async () => {
    await openUrl(getStaticUrlWithParams(datasheet));
  }, [datasheet]);
  const { isMobile } = useViewModeContext();
  return (
    <>
      <SectionWrapper bg={bg} padding scale={scale}>
        <UI.Container gap={4} direction={isMobile ? 'column' : 'row'}>
          {!isMobile && (
            <UI.Container p="0, 8, 0, 0">
              <UI.Container justify="center" direction="column">
                <PdfThumbnail
                  url={getStaticUrlWithParams(datasheetThumb)}
                  height={300}
                  width="auto"
                />
                <UI.Button
                  onClick={handleDownloadPdf}
                  accessoryLeft="download"
                  i18n={DOWNLOAD}
                />
              </UI.Container>
            </UI.Container>
          )}
          <UI.Container f={4} direction="column">
            <UI.Container gap={4} direction="column">
              {isMobile ? (
                <UI.H5 modifiers="bold, primary" style={STYLE}>
                  {titleContent}
                </UI.H5>
              ) : (
                <UI.H4 modifiers="bold, primary" style={STYLE}>
                  {titleContent}
                </UI.H4>
              )}
              <UI.Body2 modifiers="light">{textContent}</UI.Body2>
              {isMobile && (
                <UI.Container p="0, 8, 0, 0" justify="center">
                  <UI.Container justify="center" direction="column">
                    <PdfThumbnail
                      url={getStaticUrlWithParams(datasheetThumb)}
                      height={300}
                      width="auto"
                    />
                    <UI.Button
                      onClick={handleDownloadPdf}
                      accessoryLeft="download"
                      i18n={DOWNLOAD}
                    />
                  </UI.Container>
                </UI.Container>
              )}
              <UI.Body2 modifiers="light">{datasheetTextContent}</UI.Body2>
            </UI.Container>
          </UI.Container>
        </UI.Container>
      </SectionWrapper>
      {map(childrens || CHILDREN, Child => (
        <ChildrenItem Child={Child} key={Child.config.id} />
      ))}
    </>
  );
};

export const RecProductsEditor: ComponentForEditor<RecProductsProps> = () => {
  const { options } = useComponentOptions<Record<string, OptionType[]>>(
    RecProductsEditor.config,
  );

  useSetPropsByLanguage(RecProductsEditor.config, options);
  const componentProps = useComponentProps<RecProductsProps>(
    RecProductsEditor.config.id,
  );

  return (
    <RecProducts scale={0.9} childrens={CHILDREN_EDITOR} {...componentProps} />
  );
};

RecProducts.defaultProps = defaultProps as RecProductsProps;
RecProducts.config = config;
RecProducts.children = CHILDREN;

RecProductsEditor.defaultProps = defaultProps as RecProductsProps;
RecProductsEditor.config = config;
RecProductsEditor.children = CHILDREN_EDITOR;
