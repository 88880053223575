import { Shadow } from '@ui-system/css';
import { Style } from '@ui-system/interfaces/types';
import { nanoid } from 'nanoid';
import React from 'react';
// import { Elevation } from '@ui-system/css';

export interface Info<DataItem> {
  item: DataItem;
  index: number;
}

export interface ListProps {
  data: any[];
  renderItem: (info: Info<any>) => React.ReactElement;
  style?: Style;
  contentContainerStyle?: Style;
  onLoadMore?: (info: { distanceFromEnd: number }) => void;
  keyExtractor: (item?: Info<any> | string) => string;
  // itemElevation?: Elevation;
  itemElevation?: Shadow;
  // getItemLayout?: () => void;
  // onLoadMore: () => void;
  // initialNumToRender?: number;
  // HeaderComponent?: ReactNode;
  // ListFooterComponent?: ReactNode;
}

export const LIST_UUID = nanoid();
export const LIST_CONTENT_CONTAINER_UUID = nanoid();

export type ListType = React.FC<ListProps>;
