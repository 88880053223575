import { EN, EN_US } from '@i18n/utils';
import { getStaticUrl } from '@rec/static';
import { userInfoSelector } from '@rec/user-info/selectors';
import { Epic } from '@redux-basic-module/interfaces';
import { ofType } from '@redux-operators/of-type';
import { selectors } from '@resource/redux-language/reducer';
import axios from 'axios';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { loadCategories } from './actions';

async function getMarketingCategoriesAndPanel(
  language: string,
  country: string,
) {
  let finalLanguage: string = language;
  if (country === 'US') {
    finalLanguage = EN_US;
  }
  try {
    const categories = await axios.get(
      `${getStaticUrl()}/toolbox-data/MARKETING_CATEGORIES/data-${finalLanguage}.json`,
    );
    const panels = await axios.get(
      `${getStaticUrl()}/toolbox-data/REC_PANELS/data-${finalLanguage}.json`,
    );
    return {
      panels: panels.data,
      categories: categories.data,
    };
  } catch (e) {
    const categories = await axios.get(
      `${getStaticUrl()}/toolbox-data/MARKETING_CATEGORIES/data-${EN}.json`,
    );
    const panels = await axios.get(
      `${getStaticUrl()}/toolbox-data/REC_PANELS/data-${EN}.json`,
    );
    return {
      panels: panels.data,
      categories: categories.data,
    };
  }
}
const onLoadCategoriesEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(loadCategories.start.type),
    withLatestFrom(
      state$.pipe(map(selectors.currentLanguage)),
      state$.pipe(map(userInfoSelector)),
    ),
    switchMap(([, language, userInfo]) =>
      from(getMarketingCategoriesAndPanel(language, userInfo.country)).pipe(
        map(data => loadCategories.success(data)),
        catchError(err => of(loadCategories.error({ error: err }))),
      ),
    ),
  );

export const EPICS = [onLoadCategoriesEpic];
