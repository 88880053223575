import '@lib/config-loader';
import './setupEnvironmentConfigs';
import './fonts/stylesheet.css';

import IconSetProvider from '@lib/icons-set-provider';
import { BUNDLE_NAMES } from '@rec/bundlenames';
import { REC_THEME } from '@rec/theme';
import { COMPONENTS, StylesProvider } from '@rec/ui-system';
import { configureClassValidatorI18n } from '@resource/class-validator-i18n';
import ResourceProvider from '@resource/provider';
import { UiSystemProvider } from '@ui-system/config';
import noop from 'lodash/noop';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';

import { configureLogger } from './configureLogger';
import store from './configureStore';
import Router from './navigation';

configureClassValidatorI18n(store);
configureLogger(store).then(noop);

const App: React.FC = () => (
  <Provider store={store}>
    <UiSystemProvider theme={REC_THEME} components={COMPONENTS}>
      <StylesProvider>
        <ResourceProvider bundleNames={BUNDLE_NAMES}>
          <IconSetProvider>
            <CookiesProvider>
              <Router />
            </CookiesProvider>
          </IconSetProvider>
        </ResourceProvider>
      </StylesProvider>
    </UiSystemProvider>
  </Provider>
);

// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.store = store;
}

export default App;
