import { useHistory } from '@lib/navigation';
import React, { ReactNode, useEffect } from 'react';

import listenForHistory from './history';

type Props = {
  children: ReactNode;
};

export const HistoryListener: React.FC<Props> = ({ children }: Props) => {
  const history = useHistory();
  useEffect(() => {
    listenForHistory(history);
  }, [history]);
  return <>{children}</>;
};
