export const ROUTE_AUTHENTICATE = '/authentication';
export const PATH_AUTHENTICATE_BY_EMAIL_TAN = '/by-email-and-tan';
export const ROUTE_AUTHENTICATE_BY_EMAIL_TAN =
  ROUTE_AUTHENTICATE + PATH_AUTHENTICATE_BY_EMAIL_TAN;

export const PATH_AUTHENTICATE_BY_EMAIL_PASSWORD = '/by-email-and-password';
export const ROUTE_AUTHENTICATE_BY_EMAIL_PASSWORD =
  ROUTE_AUTHENTICATE + PATH_AUTHENTICATE_BY_EMAIL_PASSWORD;

export const PATH_IDENTIFY_BY_EMAIL_TAN = '/identify-by-email';
export const ROUTE_IDENTIFY_BY_EMAIL_TAN =
  ROUTE_AUTHENTICATE + PATH_IDENTIFY_BY_EMAIL_TAN;

export const ROUTE_SIGN_IN = '/sign-in';
export const PATH_SIGN_IN_BY_EMAIL = '/by-email';
export const ROUTE_SIGN_IN_BY_EMAIL = ROUTE_SIGN_IN + PATH_SIGN_IN_BY_EMAIL;

export const ROUTE_USER_APIKEY = '/user-apikey';
export const PATH_CREATE_USER_APIKEY = '';

export const ROUTE_USER_AUTHENTICATION_STATUS = '/user-authentication-status';
export const PATH_GET_USER_AUTHENTICATION_STATUS = '';

export const ROUTE_AUTHENTICATE_SSO = '/authentication-sso';
export const PATH_AUTHENTICATE_SSO_BY_SSO_TOKEN = '/by-token';
export const ROUTE_AUTHENTICATE_BY_SSO_TOKEN =
  ROUTE_AUTHENTICATE_SSO + PATH_AUTHENTICATE_SSO_BY_SSO_TOKEN;
