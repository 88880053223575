import { ResourceMetadataDTO, ResourceType } from '@resource/core';
import React from 'react';

export interface I18nShape {
  key: string;
  bundleName: string;
  text: string;
  type?: ResourceType;
  values?: Record<string, any>;
  metadata?: ResourceMetadataDTO;
  translation?: Record<string, string>;
}

export class I18nFactory {
  bundleName: string;

  namespace: string;

  create: (
    values: Pick<
      I18nShape,
      'key' | 'text' | 'type' | 'values' | 'metadata' | 'translation'
    >,
  ) => I18nShape;

  constructor(bundleName: string, namespace: string) {
    this.bundleName = bundleName;
    this.namespace = namespace;
    this.create = ({ key, ...values }) => ({
      ...values,
      key: `${this.namespace}.${key}`,
      bundleName,
    });
    return this;
  }
}
export interface Resolver {
  (
    i18n: I18nShape,
    bundle: Bundle,
    locale: Locale,
    values?: Record<string, any>,
  ): string;
}

export interface Bundle {
  [key: string]: string;
  hash: string;
}

export interface DateFormatMap {
  [key: string]: string;
}

export type Locale = string;

export interface ContextValue {
  resolver: Resolver;
  bundle: Bundle;
  locale: Locale;
  dateFormatMap: DateFormatMap;
}

export interface ContextProps {
  children: React.ReactNode;
  resolver?: Resolver;
  bundle: Bundle;
  locale: Locale;
  dateFormatMap?: DateFormatMap;
}

export interface UseTranslateFunction {
  (): (i18nOrText: I18nShape | string, values?: Record<string, any>) => string;
}
export interface UseTranslatedMessage {
  (i18nOrText?: I18nShape | string, values?: Record<string, any>):
    | string
    | null;
}
