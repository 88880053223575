import { CachedResult, CacheOptions, IsCache } from '@cache/types';
import createLogger from '@logger/core';
import autoBind from 'auto-bind';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import ms from 'ms';
import store from 'store2';

const logger = createLogger('@cache/browser');

export class BrowserCache implements IsCache {
  private readonly currentStore: store.StoreAPI;

  private readonly ttlStore: store.StoreAPI;

  private readonly options: CacheOptions;

  constructor(options: CacheOptions) {
    this.options = options;
    const { namespace } = options;
    this.currentStore = store.namespace(namespace);
    this.ttlStore = store.namespace(`${namespace}-ttl`);
    autoBind(this);
  }

  private isExpired(key: string): { expired: boolean; expiresIn: Date } {
    const ttlDate = this.ttlStore.get(key);
    if (!ttlDate) {
      return { expired: false, expiresIn: null };
    }
    const expiresIn = new Date(ttlDate);
    return {
      expired: moment().isAfter(expiresIn),
      expiresIn,
    };
  }

  get<T>(cacheKey: string): Promise<CachedResult<T>> {
    if (isEmpty(cacheKey)) {
      throw new Error('Missing cacheKey');
    }
    const data = this.currentStore.get(cacheKey);
    const { expired, expiresIn } = this.isExpired(cacheKey);

    if (data && expired) {
      this.currentStore.remove(cacheKey);
      this.ttlStore.remove(cacheKey);
      logger.debug({
        message: `Removing key=${cacheKey} from cache since expired`,
        params: {
          cacheKey,
        },
      });
    }

    const result = {
      data: expired ? undefined : data,
      key: cacheKey,
      success: !isEmpty(data) && !expired,
      expiresIn,
    };

    logger.debug({
      message: `Trying to fetch data from cache key=${cacheKey}, result=${result.success}, expiresIn=${expiresIn}`,
      params: {
        cacheKey,
      },
    });

    return Promise.resolve(result);
  }

  set<T>(cacheKey: string, data: T, msValue?: string): Promise<void> {
    if (isEmpty(cacheKey)) {
      throw new Error('Missing cacheKey');
    }
    this.currentStore.set(cacheKey, data);
    let expirationDate = null;
    if (msValue) {
      expirationDate = moment().add(ms(msValue), 'milliseconds').toDate();
      this.ttlStore.set(cacheKey, expirationDate.getTime());
    }
    logger.debug({
      message: `Stored value into cache key=${cacheKey}, expirationDate=${expirationDate}`,
      params: {
        cacheKey,
      },
    });
    return Promise.resolve();
  }
}
