import { useTranslateFunction } from '@i18n/context';
import { I18nShape } from '@i18n/core';
import {
  ReactTutorialStepsProviderType,
  Step,
  useToggleTutorial,
} from '@lib/react-tutorial-steps';
import { css } from '@ui-system/css';
import UI from '@ui-system/ui';
import find from 'lodash/find';
import * as React from 'react';
import { memo } from 'react';
import {
  ComponentForEditor,
  ComponentHandlerProps,
  ComponentHandlerType,
  useEditorContext,
  useRegisterComponentOnMount,
} from 'react-page-editor/state';

const ROUND = css`
  border-radius: 100%;
`;
export const Toggle: React.FC = () => {
  const toggleTutorial = useToggleTutorial();
  return (
    <UI.Container
      align="center"
      bg="gray.A500"
      justify="center"
      style={ROUND}
      h={22}
      w={22}
    >
      <UI.Icon
        name="question"
        color="white"
        size={15}
        onClick={toggleTutorial}
      />
    </UI.Container>
  );
};
export interface TutorialConfig {
  steps: Step[];
  Provider: ReactTutorialStepsProviderType;
}
export function useComponentHandler<P>(
  Component: ComponentForEditor,
  tutorialConfig?: TutorialConfig,
  customCreateComponentHandler?: (
    Component: ComponentForEditor<P>,
  ) => ComponentHandlerType<P>,
  i18ns?: I18nShape[],
): ComponentHandlerType {
  if (customCreateComponentHandler)
    return customCreateComponentHandler(Component);
  const Provider = tutorialConfig?.Provider || React.Fragment;

  return memo(({ position, ...props }: ComponentHandlerProps) => {
    const translate = useTranslateFunction();

    const label = translate(
      find(i18ns, { key: `components.${Component.config.id}` }),
    );
    useRegisterComponentOnMount(Component, position as number);
    const {
      moveComponentUp,
      moveComponentDown,
      toggleComponent,
      isComponentVisible,
      canMoveUp,
      canMoveDown,
      getComponentById,
    } = useEditorContext();
    if (!isComponentVisible(Component.config)) return null;
    return (
      <Provider steps={tutorialConfig?.steps}>
        <UI.Container direction="column" id={Component.config.id}>
          <UI.Container
            gap={2}
            shadow={2}
            justify="flex-end"
            p="2, 10"
            bg="white"
            align="center"
          >
            {tutorialConfig && <Toggle />}
            <UI.H6 modifiers="uppercase">
              {label || Component.config.name}
            </UI.H6>
            <UI.Container
              onClick={() => toggleComponent(Component.config)}
              align="center"
            >
              <UI.Icon
                size={20}
                name={isComponentVisible(Component.config) ? 'close' : 'hide'}
                color="black"
              />
            </UI.Container>
            {canMoveUp(Component.config) && (
              <UI.Container
                align="center"
                bg="gray.A500"
                justify="center"
                h={22}
                w={22}
              >
                <UI.Icon
                  size={15}
                  name="up"
                  color="white"
                  onClick={() => moveComponentUp(Component.config)}
                />
              </UI.Container>
            )}
            {canMoveDown(Component.config) && (
              <UI.Container
                align="center"
                bg="gray.A500"
                justify="center"
                h={22}
                w={22}
              >
                <UI.Icon
                  size={15}
                  name="down"
                  color="white"
                  onClick={() => moveComponentDown(Component.config)}
                />
              </UI.Container>
            )}
          </UI.Container>
          <UI.Divider />
          {isComponentVisible(Component.config) && (
            // <UI.Animation>
            <Component {...props} />
            // </UI.Animation>
          )}
        </UI.Container>
      </Provider>
    );
  });
}
