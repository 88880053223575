import { get, isObject, isString, keys, merge, split } from 'lodash';
import React, { createContext, useContext, useMemo } from 'react';
import invariant from 'tiny-invariant';

import DEFAULT_ICONS_SET from './defaultIconsMapping';
import {
  ContextValue,
  DefaultIconSet,
  IconSetProviderType,
  IconsSetProviderProps,
} from './interfaces';

const IconSetContext = createContext<ContextValue>(DEFAULT_ICONS_SET);

export const useIconSetContext = (): ContextValue => {
  const context = useContext(IconSetContext);
  invariant(
    context,
    'You must declare <IconSetProvider> before using this feature, no context found.',
  );
  return context;
};

export const useIcon = (name: string): string => {
  const iconsSet = useIconSetContext();
  const arrName = split(name, '-');
  const iconName = arrName[0];
  const level = arrName[1];
  const unknownKey =
    (isObject(iconsSet[iconName]) && keys(iconsSet[iconName])[0]) || null;
  const defaultValue =
    (isString(iconsSet[iconName]) && iconsSet[iconName]) ||
    get(iconsSet, `${iconName}.${unknownKey}`) ||
    null;
  return useMemo(() => {
    if (!name) return null;
    const icon =
      get(iconsSet, `${iconName}.${level || unknownKey}`) || defaultValue;
    if (!icon) {
      throw new Error(
        `The provided icon name "${name}" was not found in any icon set!`,
      );
    }
    return icon;
  }, [defaultValue, iconName, iconsSet, level, name, unknownKey]);
};

function useValue<CustomSet>(customSet: CustomSet): DefaultIconSet & CustomSet {
  return useMemo(() => merge(DEFAULT_ICONS_SET, customSet), [customSet]);
}

function IconSetProvider<CustomIconSet>({
  children,
  customSet = {},
}: IconsSetProviderProps<CustomIconSet>): IconSetProviderType {
  const value = useValue<CustomIconSet | Record<string, any>>(customSet);
  return (
    <IconSetContext.Provider value={value}>{children}</IconSetContext.Provider>
  );
}

export default IconSetProvider;
