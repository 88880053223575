import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { FieldPath } from '../../../core';
import { setFieldUnwatchedAction, setFieldWatchedAction } from '../../actions';
import useFormId from '../useFormId';

function useWatchField(fieldPath: FieldPath): void {
  const dispatch = useDispatch();
  const formId = useFormId();
  useEffect(() => {
    dispatch(setFieldWatchedAction(formId, fieldPath));
    return () => {
      dispatch(setFieldUnwatchedAction(formId, fieldPath));
    };
  }, [dispatch, fieldPath, formId]);
}

export default useWatchField;
