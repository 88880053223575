import { I18nShape } from '@i18n/core';
import { DE } from '@i18n/utils';
import { REC_PRO_PAGE as bundleName } from '@resource/bundle-names';
import { ResourceType } from '@resource/core';

import { SIGN_IN as NAMESPACE } from './constants';

const SIGN_EN = `
  <mj-wrapper padding="10px" background-color="#e6e6e6">
    <mj-section text-align="left">
      <mj-column>
        <mj-image src="https://static.rec-propage.com/images/banners/banner_sign.jpg" />
      </mj-column>
    </mj-section>
    <mj-section padding="10px">
      <mj-column>
        <mj-text font-size="11px">
          <div class="columnTextSize">
            <div>REC Solar EMEA GmbH</div>
            <div>Balanstr. 71a, </div>
            <div>81541 München</div>
            <div>Phone: +49-(0) 89 442 385 90 </div>
            <div>Fax: +49 (0) 89 442 385 999 </div>
            <div>Email: recsolar.emea@recgroup.com </div>
            <div>Managing Director: Cemil Seber </div>
            <div>Court of Registration: Munich, HRB 172504 </div>
            <div>VAT No: DE258811811 </div>
          </div>
        </mj-text>
      </mj-column>
      <mj-column >
        <mj-text font-size="11px">
          <div class="columnTextSize">
            <div>REC Americas, LLC</div>
            <div>330 James Way, Suite #170</div>
            <div>Pismo Beach, CA 93449</div>
            <div>USA</div>
          </div>
        </mj-text>
        <mj-text font-size="11px">
          <div class="columnTextSize">
            <div>REC Solar Pte. Ltd.</div>
            <div>152 Beach Road Gateway East </div>
            <div>(Level 2-01/04) </div>
            <div>Singapore </div>
            <div>189721 Singapore </div>
          </div>
        </mj-text>
      </mj-column>
    </mj-section>
    <mj-section>
      <mj-column>
        <mj-text font-size="11px" align="center">Designed and developed by Lagoasoft LDA. Lisbon </mj-text>
      </mj-column>
    </mj-section>
  </mj-wrapper>
`;
export const SIGN_IN_BY_TAN_EMAIL_SUBJECT: I18nShape = {
  key: `${NAMESPACE}.signInByTanEmail.subject`,
  text: 'REC ProPage - Security Token',
  bundleName,
  translation: {
    [DE]: 'REC ProPage - Sicherheitstoken',
  },
  type: ResourceType.TEXT,
  metadata: {
    description: 'Subject of the e-mail for the sign in by e-mail feature',
  },
};

export const SIGN_IN_BY_TAN_EMAIL_HTML: I18nShape = {
  key: `${NAMESPACE}.signInByTanEmail.html`,
  text: `
  <mjml>
    <mj-head>
      <mj-style>
        .columnTextSize {
          line-height: 18px;
        }
      </mj-style>
    </mj-head>
    <mj-body>
      <mj-section background-color="#000000">
        <mj-column>
          <mj-image src="https://static.rec-propage.com/images/logos/logo_pro_page.png" />
        </mj-column>
        <mj-column>
          <mj-spacer />
          <mj-text font-size="18px" align="center" container-background-color="#000000" color="#ffffff">
            <span style="color:#f09c06;">SECURITY</span>
            <span>TOKEN</span>
          </mj-text>
        </mj-column>
      </mj-section>
      <mj-section background-size="cover" background-repeat="no-repeat" background-url="https://static.rec-propage.com/images/banners/banner_toolbox.jpg">
        <mj-column>
          <mj-spacer height="80px" />
        </mj-column>
      </mj-section>
      <mj-wrapper border="0 solid #000000" padding="50px 30px" padding-top="0" background-color="#F4F4F4">
        <mj-section>
          <mj-column>
            <mj-text font-size="14px" align="center">Dear Solar Professional, </mj-text>
            <mj-spacer height="10px" />
            <mj-text font-size="14px" align="center">In order to give you the highest level of security, it is necessary to enter a randomly generated security code in the REC ProPortal to be able to use the REC ProPage Editor. </mj-text>
            <mj-spacer height="30px" />
            <mj-text font-size="14px" align="center">Please enter the following code to start using the REC ProPage Editor: </mj-text>
            <mj-spacer />
            <mj-text font-size="28px" align="center">
              <span>{{tanCode}}</span>
              <a href="" class="link"></a>
            </mj-text>
            <mj-spacer />
            <mj-text font-size="14px" align="center">Best Regards, <br />the REC Team. </mj-text>
          </mj-column>
        </mj-section>
      </mj-wrapper>
      ${SIGN_EN}
    </mj-body>
  </mjml>
  `,
  bundleName,
  translation: {
    [DE]: `
  <mjml>
    <mj-head>
      <mj-style>
        .columnTextSize {
          line-height: 18px;
        }
      </mj-style>
    </mj-head>
    <mj-body>
      <mj-section background-color="#000000">
        <mj-column>
          <mj-image src="https://static.rec-propage.com/images/logos/logo_pro_page.png" />
        </mj-column>
        <mj-column>
          <mj-spacer />
          <mj-text font-size="18px" align="center" container-background-color="#000000" color="#ffffff">
            <span style="color:#f09c06;">SECURITY</span>
            <span>TOKEN</span>
          </mj-text>
        </mj-column>
      </mj-section>
      <mj-section background-size="cover" background-repeat="no-repeat" background-url="https://static.rec-propage.com/images/banners/banner_toolbox.jpg">
        <mj-column>
          <mj-spacer height="80px" />
        </mj-column>
      </mj-section>
      <mj-wrapper border="0 solid #000000" padding="50px 30px" padding-top="0" background-color="#F4F4F4">
        <mj-section>
          <mj-column>
            <mj-text font-size="14px" align="center">Dear Solar Professional, </mj-text>
            <mj-spacer height="10px" />
            <mj-text font-size="14px" align="center">In order to give you the highest level of security, it is necessary to enter a randomly generated security code in the REC ProPortal to be able to use the REC ProPage Editor. </mj-text>
            <mj-spacer height="30px" />
            <mj-text font-size="14px" align="center">Please enter the following code to start using the REC ProPage Editor: </mj-text>
            <mj-spacer />
            <mj-text font-size="28px" align="center">
              <span>{{tanCode}}</span>
              <a href="" class="link"></a>
            </mj-text>
            <mj-spacer />
            <mj-text font-size="14px" align="center">Best Regards, <br />the REC Team. </mj-text>
          </mj-column>
        </mj-section>
      </mj-wrapper>
      ${SIGN_EN}
    </mj-body>
  </mjml>
    `,
  },
  type: ResourceType.MJML_HANDLEBARS,
  metadata: {
    description: 'HTML of the e-mail for the sign in by e-mail feature',
    params: [
      {
        fieldName: 'tanCode',
        description: 'Authentication Code',
      },
    ],
  },
};

export const CONGRATULATIONS_EMAIL_SUBJECT: I18nShape = {
  key: `${NAMESPACE}.congratulationsEmail.subject`,
  text: 'REC ProPage successfully published',
  bundleName,
  translation: {
    [DE]: 'REC ProPage erfolgreich veröffentlicht',
  },
  type: ResourceType.TEXT,
  metadata: {
    description:
      'Subject of the e-mail for the success on creating a REC ProPage feature',
  },
};

export const CONGRATULATIONS_EMAIL_HTML: I18nShape = {
  key: `${NAMESPACE}.congratulationsEmail.html`,
  text: `
  <mjml>
    <mj-head>
      <mj-font name="OpenSans" href="https://fonts.googleapis.com/css2?family=Open+Sans"/>
      <mj-style>
        .columnTextSize {
          line-height: 18px;
        }
       </mj-style>
    </mj-head>
    <mj-body>
      <mj-section background-color="#000000">
        <mj-column>
          <mj-image src="https://static.rec-propage.com/images/logos/logo_pro_page.png" />
        </mj-column>
        <mj-column>
          <mj-spacer/>
          <mj-text font-size="18px" align="center" container-background-color="#000000" color="#ffffff">
          <span style="color:#f09c06;">CONGRATULATIONS!</span>
          </mj-text>
        </mj-column>
      </mj-section>
      <mj-section background-size="cover" background-repeat="no-repeat" background-url="https://static.rec-propage.com/images/banners/banner_toolbox.jpg">
        <mj-column>
          <mj-spacer height="80px"/>
        </mj-column>
      </mj-section>
      <mj-wrapper border="0 solid #000000" padding="50px 30px" padding-top="0" background-color="#F4F4F4">
        <mj-section>
          <mj-column>
            <mj-text font-size="14px" align="center">Dear REC Certified Solar Professional,
            </mj-text>
            <mj-text font-size="16px" align="center"><strong>Congratulations!</strong>
            </mj-text>
             <mj-text align="center" font-size="16px">
              You have successfully published your REC ProPage and it is now permanently available through this link:
            </mj-text>
            <mj-spacer/>
            <mj-text font-size="28px" align="center">
              <a href="" class="link">www.rec-propage.com/{{urlPath}}</a>
            </mj-text>
            <mj-spacer/>
            <mj-text align="center" font-size="16px">
            You can now send this link to your customers or post it on your social media channels. Alternatively you can integrate this into your existing website as a page dedicated to your work with REC. To learn how to do this, please refer to our tutorial here.
            </mj-text>
            <mj-spacer/>
            <mj-text font-size="14px" align="center">Best Regards,
              <br/>the REC Team.
            </mj-text>
            <mj-spacer />
            <mj-text align="center" font-size="10px">
            You have received this mail as someone has just tried to create an REC ProPage in the REC ProPortal via your account. If this was not you, please contact REC and change your password.</mj-text>
          </mj-column>
        </mj-section>
      </mj-wrapper>
      ${SIGN_EN}
    </mj-body>
  </mjml>
  `,
  bundleName,
  translation: {
    [DE]: `
    <mjml>
    <mj-head>
      <mj-font name="OpenSans" href="https://fonts.googleapis.com/css2?family=Open+Sans"/>
      <mj-style>
        .columnTextSize {
          line-height: 18px;
        }
       </mj-style>
    </mj-head>
    <mj-body>
      <mj-section background-color="#000000">
        <mj-column>
          <mj-image src="https://static.rec-propage.com/images/logos/logo_pro_page.png" />
        </mj-column>
        <mj-column>
          <mj-spacer/>
          <mj-text font-size="18px" align="center" container-background-color="#000000" color="#ffffff">
          <span style="color:#f09c06;">CONGRATULATIONS!</span>
          </mj-text>
        </mj-column>
      </mj-section>
      <mj-section background-size="cover" background-repeat="no-repeat" background-url="https://static.rec-propage.com/images/banners/banner_toolbox.jpg">
        <mj-column>
          <mj-spacer height="80px"/>
        </mj-column>
      </mj-section>
      <mj-wrapper border="0 solid #000000" padding="50px 30px" padding-top="0" background-color="#F4F4F4">
        <mj-section>
          <mj-column>
            <mj-text font-size="14px" align="center">Dear REC Certified Solar Professional,
            </mj-text>
            <mj-text font-size="16px" align="center"><strong>Congratulations!</strong>
            </mj-text>
             <mj-text align="center" font-size="16px">
              You have successfully published your REC ProPage and it is now permanently available through this link:
            </mj-text>
            <mj-spacer/>
            <mj-text font-size="28px" align="center">
              <a href="" class="link">www.rec-propage.com/{{urlPath}}</a>
            </mj-text>
            <mj-spacer/>
            <mj-text align="center" font-size="16px">
            You can now send this link to your customers or post it on your social media channels. Alternatively you can integrate this into your existing website as a page dedicated to your work with REC. To learn how to do this, please refer to our tutorial here.
            </mj-text>
            <mj-spacer/>
            <mj-text font-size="14px" align="center">Best Regards,
              <br/>the REC Team.
            </mj-text>
            <mj-spacer />
            <mj-text align="center" font-size="10px">
            You have received this mail as someone has just tried to create an REC ProPage in the REC ProPortal via your account. If this was not you, please contact REC and change your password.</mj-text>
          </mj-column>
        </mj-section>
      </mj-wrapper>
      ${SIGN_EN}
    </mj-body>
  </mjml>
    `,
  },
  type: ResourceType.MJML_HANDLEBARS,
  metadata: {
    description:
      'HTML of the e-mail for the the success on creating a REC ProPage feature',
    params: [
      {
        fieldName: 'urlPath',
        description: 'URL Path',
      },
    ],
  },
};
