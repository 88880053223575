import { useEffect, useState } from 'react';

import { useIsMountedRef } from './useIsMountedRef';

export function useDebouncedValue<T>(
  initialValue: T,
  postDebounceValue: T,
  time = 500,
): T {
  const [value, setValue] = useState(initialValue);
  const isMounted = useIsMountedRef();
  useEffect(() => {
    const timeout = setTimeout(() => {
      setValue(postDebounceValue);
    }, time);
    return () => {
      clearTimeout(timeout);
    };
  }, [time, postDebounceValue, isMounted]);

  return value;
}

export function useDebouncedBoolean(time = 500): boolean {
  return useDebouncedValue(false, true, time);
}
