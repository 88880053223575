import { Color } from '@ui-system/css';
import { Style } from '@ui-system/interfaces/types';
import { nanoid } from 'nanoid';
import * as React from 'react';

export interface IconProps {
  color?: Color;
  style?: Style;
  modifiers?: string;
  name: string;
  size: number;
  onClick?: () => void;
}

export const ICON_UUID = nanoid();

export type IconType = React.FC<IconProps>;
