import {
  ROUTE_AUTHENTICATE_BY_EMAIL_PASSWORD,
  ROUTE_AUTHENTICATE_BY_EMAIL_TAN,
  ROUTE_AUTHENTICATE_BY_SSO_TOKEN,
  ROUTE_SIGN_IN_BY_EMAIL,
} from '@authentication/shared-constants';
import { getApiBasePath } from '@controller-layer-utils/core';
import qs from 'qs';

export function getAuthenticateByEmailTanUrl(language: string): string {
  return `${getApiBasePath()}${ROUTE_AUTHENTICATE_BY_EMAIL_TAN}?${qs.stringify({
    language,
  })}`;
}

export function getAuthenticateBySSOToken(language: string): string {
  return `${getApiBasePath()}${ROUTE_AUTHENTICATE_BY_SSO_TOKEN}?${qs.stringify({
    language,
  })}`;
}

export function getAuthenticateByEmailAndPasswordUrl(language: string): string {
  return `${getApiBasePath()}${ROUTE_AUTHENTICATE_BY_EMAIL_PASSWORD}?${qs.stringify(
    {
      language,
    },
  )}`;
}
export function getSignInByEmailUrl(language: string): string {
  return `${getApiBasePath()}${ROUTE_SIGN_IN_BY_EMAIL}?${qs.stringify({
    language,
  })}`;
}
