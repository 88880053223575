import { Style } from '@ui-system/interfaces';
import { F } from '@utils/ts';
import { nanoid } from 'nanoid';
import React from 'react';

export interface StyleProps {
  style?: Style;
}

export interface TableProps extends React.PropsWithChildren<StyleProps> {
  stickyHeader?: boolean;
  size?: 'small' | 'medium';
}

export interface TableCellProps extends React.PropsWithChildren<StyleProps> {
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
  onClick?: F.Function;
}
export interface TableRowProps extends React.PropsWithChildren<StyleProps> {
  hover?: boolean;
  selected?: boolean;
}
export interface TableStatics {
  Head: TableHeadType;
  Body: TableBodyType;
  Row: TableRowType;
  Cell: TableCellType;
}

export type TableHeadProps = React.PropsWithChildren<StyleProps>;
export type TableBodyProps = React.PropsWithChildren<StyleProps>;

export type TableType = React.FC<TableProps> & TableStatics;
export type TableHeadType = React.FC<TableHeadProps>;
export type TableBodyType = React.FC<TableBodyProps>;
export type TableRowType = React.FC<TableRowProps>;
export type TableCellType = React.FC<TableCellProps>;

export const TABLE_UUID = nanoid();
export const TABLE_ROW_UUID = nanoid();
