import { Environment } from '../Environment';
import { getEnvironment } from './getEnvironment';

export function isTestEnvironment(): boolean {
  return getEnvironment() === Environment.TEST;
}

export function isStagingEnvironment(): boolean {
  return getEnvironment() === Environment.STAGING;
}

export function isDevEnvironment(): boolean {
  return getEnvironment() === Environment.DEVELOPMENT;
}

export function isTestOrDevEnvironment(): boolean {
  return isDevEnvironment() || isTestEnvironment();
}

export function isProductionEnvironment(): boolean {
  return getEnvironment() === Environment.PRODUCTION;
}
