import { useMemo } from 'react';

import { useAccessToken } from './hooks';
import { selectors } from './reducer';
import { RootState } from './types';

export * from './types';

function defaultTransformToken(token: string) {
  return `Bearer ${token}`;
}

interface AuthHeaders {
  [key: string]: string;
}

export function getAuthHeaders(
  state: RootState,
  authKey = 'Authorization',
  transformToken = defaultTransformToken,
): AuthHeaders {
  const authData = selectors.authData(state);
  if (!authData) return {};
  return {
    [authKey]: transformToken(authData.token),
  };
}

export function getAuthHeadersFromEpicConfig<StartPayload>(
  payload: StartPayload,
  state: RootState & any,
): ReturnType<typeof getAuthHeaders> {
  return getAuthHeaders(state);
}

export function useAuthenticatedHeaders(
  authKey = 'Authorization',
  transformToken = defaultTransformToken,
): AuthHeaders {
  const token = useAccessToken();
  return useMemo(
    () => ({
      [authKey]: transformToken(token),
    }),
    [authKey, token, transformToken],
  );
}
