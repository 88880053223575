import HtmlParser from '@html-parser/core';
import { EN } from '@i18n/utils';
import {
  useComponentOptions,
  useComponentServerProps,
  useSetPropsByLanguage,
  useTextSelection,
} from '@pro-page-components/utils';
import {
  useIsComponentVisibleById,
  useReactPageContentContextContents,
  useReactPageContentContextLanguage,
} from '@react-page-editor/final-renderer';
import { ScaleProps, SectionWrapper } from '@rec/section-wrapper';
import { getStaticUrlWithParams } from '@rec/static';
import { useCurrentLanguage } from '@resource/redux-language';
import { extractLanguageAndCountryFromLocaleString } from '@resource/utils';
import { css } from '@ui-system/css';
import { OptionType } from '@ui-system/interfaces-form';
import UI from '@ui-system/ui';
import { head } from 'lodash';
import omit from 'lodash/omit';
import * as React from 'react';
import { useMemo } from 'react';
import {
  ComponentForEditor,
  useComponentProps,
  useEditorContext,
  useViewModeContext,
} from 'react-page-editor/state';
import ReactPlayer from 'react-player';

import config from './config.json';
import defaultProps from './defaultProps.json';

const STYLE = css`
  letter-spacing: 3px;
`;

const MOBILE_STYLE = css`
  letter-spacing: 4px;
`;

type VideoComponentProps = Pick<RecGroupProps, 'video'> & {
  skipVisibilityCheck?: boolean;
};
type VideoComponent = ComponentForEditor<VideoComponentProps>;
interface RecGroupProps extends ScaleProps {
  image?: string;
  text?: string;
  title?: string;
  video?: string;
  // EDITOR PROPS
  TextSelector?: React.FC;
  VideoComponent?: VideoComponent;
}
const CorporateVideo: VideoComponent = ({
  video,
  skipVisibilityCheck,
  ...rest
}: VideoComponentProps) => {
  const isVisible = useIsComponentVisibleById(
    `${config.id}.children.${CorporateVideo.config.id}`,
  );
  if (!isVisible && !skipVisibilityCheck) return null;
  return (
    <UI.Container
      direction="column"
      gap={4}
      id={`${CorporateVideo.config.parent}.children.${CorporateVideo.config.id}`}
      {...rest}
    >
      <ReactPlayer url={video} width="100%" />
    </UI.Container>
  );
};

CorporateVideo.config = {
  id: 'CorporateVideo',
  name: 'Corporate Video',
  path: 'CorporateVideo',
  parent: config.id,
};

const CorporateVideoEditor: VideoComponent = ({
  video,
  ...rest
}: VideoComponentProps) => {
  const { isComponentVisible } = useEditorContext();

  if (
    !isComponentVisible({
      ...CorporateVideo.config,
      id: `${CorporateVideo.config.parent}.children.${CorporateVideo.config.id}`,
    })
  )
    return null;
  return <CorporateVideo video={video} skipVisibilityCheck {...rest} />;
};

CorporateVideoEditor.config = CorporateVideo.config;
const OPTIONS = {
  omitServerKeys: ['text'],
};
export const RecGroup: ComponentForEditor<RecGroupProps> = ({
  TextSelector,
  VideoComponent,
  scale,
  ...rest
}: RecGroupProps) => {
  const { image, text, video, title, options } = useComponentServerProps<
    RecGroupProps & { options: Record<string, any>[] }
  >(config, { ...rest, options: [] }, OPTIONS);

  const fallbackText = useMemo(() => head(options)?.value, [options]);

  const content = HtmlParser(text);
  const fallbackContent = HtmlParser(fallbackText);

  const titleContent = HtmlParser(title);
  const { isMobile } = useViewModeContext();

  const systemLanguage = useCurrentLanguage();
  const pageLanguage = useReactPageContentContextLanguage();
  const contents = useReactPageContentContextContents();
  const finalContent = useMemo(() => {
    if (
      contents &&
      (pageLanguage !== contents.language ||
        systemLanguage !== contents.language)
    ) {
      return fallbackContent;
    }
    return content;
  }, [content, contents, fallbackContent, pageLanguage, systemLanguage]);

  return (
    <SectionWrapper bg="background" padding scale={scale}>
      <UI.Container gap={4} direction={isMobile ? 'column' : 'row'}>
        <UI.Container f={2} justify={isMobile ? 'center' : 'flex-start'}>
          <UI.Image
            src={getStaticUrlWithParams(image)}
            height="100px"
            width="256px"
            resizeMode="contain"
          />
        </UI.Container>
        <UI.Container direction="column" f={6} gap={2}>
          {isMobile ? (
            <UI.H5 modifiers="primary, bold, uppercase" style={MOBILE_STYLE}>
              {titleContent}
            </UI.H5>
          ) : (
            <UI.H4 modifiers="primary, bold, uppercase" style={STYLE}>
              {titleContent}
            </UI.H4>
          )}
          {TextSelector && <TextSelector />}
          <UI.Body2>{finalContent}</UI.Body2>
          {VideoComponent ? (
            <VideoComponent video={video} />
          ) : (
            <CorporateVideo video={video} />
          )}
        </UI.Container>
      </UI.Container>
    </SectionWrapper>
  );
};

RecGroup.defaultProps = defaultProps;
RecGroup.config = config;
RecGroup.children = [CorporateVideo];

const middleware = (props: RecGroupProps) => omit(props, 'text');

export const RecGroupEditor: ComponentForEditor<RecGroupProps> = () => {
  const { options: propsMap } = useComponentOptions<
    RecGroupProps & { options?: Record<string, OptionType[]> }
  >(RecGroup.config);
  const currentLanguage = useCurrentLanguage();
  const parsedLanguage = extractLanguageAndCountryFromLocaleString(
    currentLanguage,
  ).language;
  // @ts-ignore
  const { text, TextSelector } = useTextSelection(
    RecGroup,
    propsMap &&
      (((propsMap[parsedLanguage]?.options ||
        propsMap[EN]?.options) as unknown) as OptionType[]),
  );
  useSetPropsByLanguage(RecGroup.config, propsMap, middleware);
  const { video, image } = useComponentProps<RecGroupProps>(RecGroup.config.id);
  return (
    <RecGroup
      text={text}
      TextSelector={TextSelector}
      VideoComponent={CorporateVideoEditor}
      image={image}
      video={video}
      scale={0.9}
    />
  );
};

RecGroupEditor.defaultProps = RecGroup.defaultProps;
RecGroupEditor.config = config;
RecGroupEditor.children = [CorporateVideoEditor];
