import { getAuthenticateBySSOToken } from '@authentication/shared-routes';
import { useQueryParams } from '@lib/navigation';
import { useInitialLocaleData } from '@rec/user-info';
import { RequestType } from '@redux-async-module/interfaces';
import { createModule } from '@redux-async-module/module-utils';
import { useEffect } from 'react';

import {
  AuthenticateBySSOTokenStartPayload,
  AuthenticateBySSOTokenSuccessPayload,
  NAMESPACE,
  RootState,
} from './types';

const {
  getDynamicModule,
  actions,
  dispatchHooks,
  selectorHooks,
} = createModule<
  RootState,
  AuthenticateBySSOTokenStartPayload,
  AuthenticateBySSOTokenSuccessPayload
>({
  namespace: NAMESPACE,
  actionName: 'AUTH_BY_SSO',
  mainEpicConfig: {
    requestOptions: {
      type: RequestType.post,
      getPath: ({ language }) => getAuthenticateBySSOToken(language),
      getBody(request) {
        localStorage.clear();
        return request;
      },
    },
  },
});

export const authenticateBySSOTokenActions = actions;

export const {
  useIsLoading: useAuthenticateBySSOTokenIsLoading,
  useError: useAuthenticateBySSOTokenError,
  useSuccess: useAuthenticateBySSOTokenSuccess,
  useLastStartPayload: useAuthenticateBySSOTokenLastStartPayload,
} = selectorHooks;

export const {
  useAsyncStart: useAuthenticateBySSOTokenStart,
  useAsyncStartOnMount: useAuthenticateBySSOTokenStartOnMountInternal,
  useDismissError: useAuthenticateBySSOTokenDismissError,
} = dispatchHooks;

export function useAuthenticateBySSOTokenStartOnMount(): void {
  const { language } = useInitialLocaleData();
  const { accessToken } = useQueryParams();
  const start = useAuthenticateBySSOTokenStart();
  useEffect(() => {
    if (accessToken)
      start({
        language,
        accessToken,
      });
  }, [accessToken, language, start]);
}
export const getAuthenticateBySSOTokenModule = getDynamicModule;
