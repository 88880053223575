import { createActions } from '@redux-async-module/actions-utils';
import { createAction } from '@redux-basic-module/actions-utils';

import { NAMESPACE, PublishMode, StartPayload, SuccessPayload } from './types';

export const createUpdateLive = createActions<StartPayload, SuccessPayload>(
  NAMESPACE,
  'CREATE_UPDATE_LIVE',
);

export const setPublishMode = createAction<string, PublishMode>(
  `${NAMESPACE}/SET_PUBLISH_MODE`,
);
