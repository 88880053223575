import { Color } from '@ui-system/css';
import { nanoid } from 'nanoid';
import * as React from 'react';

export interface RatingProps {
  maxRating: number;
  iconsSize?: number;
  onChange?: (value: number) => void;
  initialValue?: number;
  disabled?: boolean;
  color?: Color;
  errorColor?: Color;
  error?: boolean;
}

export const RATING_UUID = nanoid();

export type RatingType = React.FC<RatingProps>;
