import { useDeepCompareMemo } from '@react-utils/hooks';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { MediaQueryAllQueryable, useMediaQuery } from 'react-responsive';

import { PreviewMode, ViewModeContextValue } from './types';

const INITIAL_VALUE = new ViewModeContextValue();

const ViewModeContext = createContext<ViewModeContextValue>(INITIAL_VALUE);

function useViewModeContextValue(): ViewModeContextValue {
  const [mode, setModeState] = useState(PreviewMode.DESKTOP);
  const setDesktopMode = useCallback(() => {
    setModeState(PreviewMode.DESKTOP);
  }, []);

  const setMobileMode = useCallback(() => {
    setModeState(PreviewMode.MOBILE);
  }, []);

  const setMode = useCallback((newMode: PreviewMode) => {
    setModeState(newMode);
  }, []);

  return useDeepCompareMemo(
    () => ({
      mode,
      setDesktopMode,
      setMobileMode,
      isDesktop: mode === PreviewMode.DESKTOP,
      isMobile: mode === PreviewMode.MOBILE,
      setMode,
    }),
    [mode, setDesktopMode, setMobileMode, setMode],
  );
}
export const ViewModeContextProvider: React.FC<
  React.PropsWithChildren<any>
> = ({ children }: React.PropsWithChildren<any>) => {
  const value = useViewModeContextValue();
  return (
    <ViewModeContext.Provider value={value}>
      {children}
    </ViewModeContext.Provider>
  );
};

export const useViewModeContext = (): ViewModeContextValue =>
  useContext(ViewModeContext);

const DEFAULT_QUERY = { query: '(max-width: 1024px)' };

interface AutoUpdateViewModeProps {
  settings?: Partial<MediaQueryAllQueryable & { query?: string }>;
}
export const AutoUpdateViewMode: React.FC<AutoUpdateViewModeProps> = ({
  settings,
}: AutoUpdateViewModeProps) => {
  const { setMobileMode, setDesktopMode } = useViewModeContext();
  const isTabletOrMobile = useMediaQuery(settings);
  useEffect(() => {
    if (isTabletOrMobile) {
      setMobileMode();
    } else {
      setDesktopMode();
    }
  }, [isTabletOrMobile, setDesktopMode, setMobileMode]);

  return null;
};
AutoUpdateViewMode.defaultProps = {
  settings: DEFAULT_QUERY,
};
