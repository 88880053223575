import { isDevEnvironment } from '@lib/config-loader';
import ReactGA from 'react-ga4';
import { Container } from 'typedi';

import { GA_TRACKING_ID } from './tokens';

function initialize(): void {
  if (!Container.has(GA_TRACKING_ID)) {
    return;
  }
  const trackingID = Container.get(GA_TRACKING_ID);
  ReactGA.initialize(trackingID, {
    gaOptions: { debug: isDevEnvironment() },
    gtagOptions: { debug: isDevEnvironment() },
    testMode: isDevEnvironment(),
  });
  ReactGA.pageview(window.location.pathname + window.location.search);
}

export default initialize;
