import { useHeaderConfig, useHeaderConfigState } from '@page-creator/context';
import { Contact } from '@pro-page-components/contact';
import {
  YourCompany,
  YourCompanyProps,
} from '@pro-page-components/your-company';
import {
  usePageContentContext,
  useReactPageContentContextContents,
  useReactPageContentContextLanguage,
} from '@react-page-editor/final-renderer';
import { useDeepCompareEffect } from '@react-utils/hooks';
import { useIsPublishModeNotIdle } from '@rec/redux-page-creator-live';
import { getStaticUrlWithParams } from '@rec/static';
import { useInitialLocaleData } from '@rec/user-info';
import { useLanguagesList } from '@resource/languages-list';
import { useChangeLanguage } from '@resource/redux-language';
import { css } from '@ui-system/css';
import { OptionType } from '@ui-system/interfaces-form';
import UI from '@ui-system/ui';
import { openUrl } from 'browser';
import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import size from 'lodash/size';
import normalizeUrl from 'normalize-url';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ComponentForEditor,
  useComponentProps,
  useViewModeContext,
} from 'react-page-editor/state';

import config from './configHeader.json';
import defaultProps from './defaultPropsHeader.json';

interface HeaderProps {
  logo?: string;
  HeaderTopRightLogoEditor?: React.FC;
  hideHeaderTopRightLogo?: boolean;
}

const HEADER_STYLE = css`
  z-index: 997;
`;

function useLanguagesOptions(): OptionType[] {
  const currentLanguage = useReactPageContentContextLanguage();
  const contents = useReactPageContentContextContents();

  const languages = useLanguagesList(
    currentLanguage,
    contents?.supportedLanguages,
  );
  return useMemo<OptionType[]>(
    () =>
      !contents?.supportedLanguages
        ? []
        : map(languages, item => ({
            label: item.name,
            value: item.code,
          })),
    [contents?.supportedLanguages, languages],
  );
}

const SELECT_STYLE = css`
  border: none;
`;

const STYLE = css`
  margin-top: 4px;
  cursor: pointer;
  right: 8px;
`;
const IconComponent: React.FC = props => (
  <UI.Container style={STYLE} {...props}>
    <UI.Icon name="down" size={15} color="primary" />
  </UI.Container>
);

const LanguageSelector = props => {
  const options = useLanguagesOptions();
  const currentLanguage = useReactPageContentContextLanguage();
  const { setLanguage, contents } = usePageContentContext();

  const changeLanguage = useChangeLanguage();

  const [value, setValue] = useState<OptionType>(
    find(options, op => op.value === currentLanguage),
  );
  const onChange = useCallback(
    (item: OptionType) => {
      setValue(item);
      setLanguage(item.value as string);
      changeLanguage({ language: item.value as string });
    },
    [changeLanguage, setLanguage],
  );
  const ref = useRef(null);
  const { language } = useInitialLocaleData();

  useDeepCompareEffect(() => {
    const finalLanguage = includes(contents?.supportedLanguages, language)
      ? language
      : contents?.language;
    if (ref.current !== finalLanguage && finalLanguage) {
      setLanguage(finalLanguage);
      setValue(find(options, op => op.value === finalLanguage));
      changeLanguage({ language: finalLanguage as string });
      ref.current = finalLanguage;
    }
  }, [
    contents?.language,
    changeLanguage,
    setLanguage,
    options,
    language,
    contents?.supportedLanguages,
  ]);

  useEffect(() => {
    setValue(find(options, op => op.value === currentLanguage));
  }, [currentLanguage, options]);
  if (isEmpty(options)) {
    return null;
  }
  if (size(options) === 1) return null;
  return (
    <UI.Container {...props} m="0, 4, 0, 0">
      <UI.Form.Select
        options={options}
        onChange={onChange}
        value={value}
        style={SELECT_STYLE}
        modifiers="primary, uppercase"
        IconComponent={IconComponent}
        disableUnderline
      />
    </UI.Container>
  );
};

export const Header: ComponentForEditor<HeaderProps> = ({
  logo,
  HeaderTopRightLogoEditor,
  hideHeaderTopRightLogo,
}: HeaderProps) => {
  const ctx = usePageContentContext();
  const yourCompanyProps = useComponentProps<YourCompanyProps>(
    YourCompany.config.id,
  );

  const contactProps = useComponentProps<YourCompanyProps>(Contact.config.id);

  const finalLogo = useMemo(() => {
    const possibleLogoFromCTX = get(ctx, [
      'components',
      YourCompany.config.id,
      'props',
      'logo',
    ]);
    const possibleLogoFromEditorProps = get(yourCompanyProps, ['logo']);
    return logo || possibleLogoFromCTX || possibleLogoFromEditorProps;
  }, [ctx, logo, yourCompanyProps]);

  const finalUrl = useMemo(() => {
    const possibleUrlFromCTX = get(ctx, [
      'components',
      Contact.config.id,
      'props',
      'web',
    ]);
    const possibleUrlFromEditorProps = get(contactProps, 'web');
    return possibleUrlFromCTX || possibleUrlFromEditorProps;
  }, [contactProps, ctx]);
  const { isMobile } = useViewModeContext();
  const {
    hideHeaderTopRightLogo: hideHeaderTopRightLogoFromConfig,
  } = useHeaderConfig();
  const hideHeaderTopRightLogoFromPropsAndContents =
    hideHeaderTopRightLogoFromConfig || hideHeaderTopRightLogo;

  return (
    <UI.Page.Header>
      <UI.Container
        id="HEADER"
        style={HEADER_STYLE}
        justify="space-between"
        f={isMobile ? null : 1}
        w="100%"
        align="center"
        p="0, 4"
        bg="white"
        shadow={1}
      >
        <UI.Container
          onClick={async () => {
            if (finalUrl) {
              await openUrl(normalizeUrl(finalUrl));
            }
          }}
        >
          {finalLogo && (
            <UI.Image
              src={finalLogo}
              height={isMobile ? 40 : 50}
              width="auto"
            />
          )}
        </UI.Container>
        <UI.Container p="1, 0">
          <LanguageSelector />
          {HeaderTopRightLogoEditor && <HeaderTopRightLogoEditor />}
          {!hideHeaderTopRightLogoFromPropsAndContents && (
            <UI.Image
              src={getStaticUrlWithParams(
                '/images/logos/Certified_Solar_professional.png',
              )}
              height={isMobile ? 40 : 50}
              width="auto"
            />
          )}
        </UI.Container>
      </UI.Container>
    </UI.Page.Header>
  );
};

export const HeaderEditor: React.FC = () => {
  const isPublishModeNotIdle = useIsPublishModeNotIdle();
  const {
    headerConfig: { hideHeaderTopRightLogo },
    updateHeaderConfig,
  } = useHeaderConfigState();
  const toggle = useCallback(() => {
    updateHeaderConfig({ hideHeaderTopRightLogo: !hideHeaderTopRightLogo });
  }, [hideHeaderTopRightLogo, updateHeaderConfig]);

  const HeaderTopRightLogo = useCallback(
    () => (
      <UI.Container align="center" p={2}>
        <UI.Container onClick={() => toggle()}>
          <UI.Icon name={!hideHeaderTopRightLogo ? 'hide' : 'view'} size={25} />
        </UI.Container>
      </UI.Container>
    ),
    [hideHeaderTopRightLogo, toggle],
  );
  if (isPublishModeNotIdle) return null;
  return (
    <Header
      HeaderTopRightLogoEditor={HeaderTopRightLogo}
      hideHeaderTopRightLogo={hideHeaderTopRightLogo}
    />
  );
};

Header.defaultProps = defaultProps;
Header.config = config;
