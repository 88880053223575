import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getFormSubmitCountSelector } from '../stateSelector';
import { RootState } from '../types';
import useFormId from './useFormId';

function useFormSubmitCount<T>(): number {
  const formId = useFormId();
  const selector = useCallback(
    (state: RootState<T>) => getFormSubmitCountSelector(state, formId),
    [formId],
  );
  return useSelector(selector);
}

export default useFormSubmitCount;
