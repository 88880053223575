import { I18nShape } from '@i18n/core';
import { DE } from '@i18n/utils';
import { COMMON_SENTENCES as bundleName } from '@resource/bundle-names';

export const I_ACCEPT_THE: I18nShape = {
  key: `iAcceptThe`,
  text: 'I accept the',
  bundleName,
  translation: {
    [DE]: 'Ich akzeptiere',
  },
  metadata: {
    description:
      'Text used concatenated with the Terms and Conditions link, to form a label',
  },
};

export const TERMS_AND_CONDITIONS: I18nShape = {
  key: `termsAndConditions`,
  text: 'Terms and Conditions',
  bundleName,
  translation: {
    [DE]: 'Teilnahmebedingungen',
  },
  metadata: {
    description: 'Terms and condition sentence used on links or headers',
  },
};

export const DATA_PROTECTION: I18nShape = {
  key: `dataProtection`,
  text: 'Data protection',
  bundleName,
  translation: {
    [DE]: 'Datenschutzbedingungen',
  },
  metadata: {
    description: 'Data protection sentence used on links or headers',
  },
};

export const NEW_ADDRESS: I18nShape = {
  key: `newAddress`,
  text: 'New address',
  bundleName,
  translation: {
    [DE]: 'Neue Adresse',
  },
  metadata: {
    description: 'New address title',
  },
};

export const EDIT_ADDRESS: I18nShape = {
  key: `editAddress`,
  text: 'Edit address',
  bundleName,
  translation: {
    [DE]: 'Adresse bearbeiten',
  },
  metadata: {
    description: 'Edit address title',
  },
};

export const COUNTRY_SELECT_PLACEHOLDER: I18nShape = {
  key: `countriesSelectPlaceholder`,
  text: 'Select a country',
  bundleName,
  translation: {
    [DE]: 'Land wählen',
  },
  metadata: {
    description: 'Default placeholder for countries select component',
  },
};

export const SELECT_A_CATEGORY: I18nShape = {
  key: `selectACategory`,
  text: 'Select a category',
  bundleName,

  metadata: {
    description: 'Common resource to select a category sentence',
  },
};

export const COPY_TEXT: I18nShape = {
  key: `copyText`,
  text: 'Copy text',
  bundleName,

  metadata: {
    description: 'Common resource to copy a text sentence',
  },
};

export const COPY_LINK: I18nShape = {
  key: `copyLink`,
  text: 'Copy Link',
  bundleName,

  metadata: {
    description: 'Common resource to copy a link sentence',
  },
};
export const COPY_SNIPPET: I18nShape = {
  key: `copySnippet`,
  text: 'Copy Snippet',
  bundleName,

  metadata: {
    description: 'Common resource to copy a link sentence',
  },
};

export const ERROR_LOADING_THE_DOCUMENT: I18nShape = {
  key: 'errorLoadingTheDocument',
  text: 'Error loading the document',
  bundleName,

  metadata: {
    description: 'Common resource to Error loading the document sentence',
  },
};

export const CREATE_NOW: I18nShape = {
  key: 'createNow',
  text: 'Create now',
  bundleName,

  metadata: {
    description: 'Common resource to create now sentence',
  },
};

export const ACCEPT_ALL: I18nShape = {
  key: 'acceptAll',
  text: 'Accept all',
  bundleName,
};

export const DECLINE: I18nShape = {
  key: 'decline',
  text: 'Decline',
  bundleName,
};

export const SELECT_YOUR_COUNTRY: I18nShape = {
  key: 'selectYourCountry',
  text: 'Select your country',
  bundleName,
};
