import UI from '@ui-system/ui';
import { ComponentType, ReactElement } from 'react';
import { U } from 'ts-toolbelt';

import Parser from './Parser';

interface Options {
  Component?: U.Nullable<ComponentType<unknown> | string>;
  modifiers?: U.Nullable<string>;
  variant?: U.Nullable<string>;
}

const HtmlParser = (
  html: string,
  options?: Options,
): U.Nullable<ReactElement[]> =>
  Parser(
    html,
    options?.Component,
    options?.modifiers,
    UI.Typography,
    options?.variant,
  );

export default HtmlParser;
