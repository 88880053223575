import {
  TypographyProps,
  TypographySet,
  TypographyType,
} from '@ui-system/interfaces-typography';
import * as React from 'react';

const createTypographySet: (
  TypographyComponent: TypographyType,
) => TypographySet = (TypographyComponent: TypographyType) => {
  const H1: TypographyType = (props: TypographyProps) => (
    <TypographyComponent {...props} />
  );
  const H2: TypographyType = (props: TypographyProps) => (
    <TypographyComponent {...props} />
  );
  const H3: TypographyType = (props: TypographyProps) => (
    <TypographyComponent {...props} />
  );
  const H4: TypographyType = (props: TypographyProps) => (
    <TypographyComponent {...props} />
  );
  const H5: TypographyType = (props: TypographyProps) => (
    <TypographyComponent {...props} />
  );
  const H6: TypographyType = (props: TypographyProps) => (
    <TypographyComponent {...props} />
  );
  const Subtitle1: TypographyType = (props: TypographyProps) => (
    <TypographyComponent {...props} />
  );
  const Subtitle2: TypographyType = (props: TypographyProps) => (
    <TypographyComponent {...props} />
  );
  const Body1: TypographyType = (props: TypographyProps) => (
    <TypographyComponent {...props} />
  );
  const Body2: TypographyType = (props: TypographyProps) => (
    <TypographyComponent {...props} />
  );
  const ButtonText: TypographyType = (props: TypographyProps) => (
    <TypographyComponent {...props} />
  );
  const Caption: TypographyType = (props: TypographyProps) => (
    <TypographyComponent {...props} />
  );
  const Overline: TypographyType = (props: TypographyProps) => (
    <TypographyComponent {...props} />
  );

  H1.defaultProps = {
    variant: 'h1',
  };
  H2.defaultProps = {
    variant: 'h2',
  };
  H3.defaultProps = {
    variant: 'h3',
  };
  H4.defaultProps = {
    variant: 'h4',
  };
  H5.defaultProps = {
    variant: 'h5',
  };
  H6.defaultProps = {
    variant: 'h6',
  };
  Subtitle1.defaultProps = {
    variant: 'subtitle1',
  };
  Subtitle2.defaultProps = {
    variant: 'subtitle2',
  };
  Body1.defaultProps = {
    variant: 'body1',
  };
  Body2.defaultProps = {
    variant: 'body2',
  };
  Caption.defaultProps = {
    variant: 'caption',
  };
  ButtonText.defaultProps = {
    variant: 'button',
  };
  Overline.defaultProps = {
    variant: 'overline',
  };
  return {
    H1,
    H2,
    H3,
    H4,
    H5,
    H6,
    Subtitle1,
    Subtitle2,
    Body1,
    Body2,
    Caption,
    ButtonText,
    Overline,
  };
};

export default createTypographySet;
