import {
  Color,
  Margin,
  OpacityRange,
  Padding,
  Shadow,
} from '@ui-system/css/utils/types';
import { Style } from '@ui-system/interfaces/types';
import { nanoid } from 'nanoid';
import React, { ReactNode } from 'react';

export type Align =
  | 'center'
  | 'flex-start'
  | 'flex-end'
  | 'stretch'
  | 'baseline';
export type Justify =
  | 'center'
  | 'flex-start'
  | 'flex-end'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';

export interface ContainerProps {
  children?: ReactNode;
  style?: Style | Record<string, any>;
  className?: string;
  m?: Margin;
  p?: Padding;
  align?: Align;
  f?: number; // FLEX
  bg?: Color;
  shadow?: Shadow;
  opacity?: OpacityRange;
  justify?: Justify;
  w?: number | string;
  h?: number | string;
  gap?: number;
  onClick?: () => void;
  direction?: 'row' | 'column';
  borderRadius?: number;
  borderBottomRightRadius?: number;
  borderBottomLeftRadius?: number;
  borderTopRightRadius?: number;
  borderTopLeftRadius?: number;
  lastChildStyle?: Style;
  firstChildStyle?: Style;
  ___dev?: boolean;
  onLayout?: () => void;
  visible?: boolean;
  id?: string;
}

export interface GradientCoordinates {
  x: number;
  y: number;
}

export enum GradientEnum {
  Linear = 'linear',
  LinearReversed = 'linearReversed',
  Horizontal = 'horizontal',
  Diagonal = 'diagonal',
}
export type GradientType =
  | 'linear'
  | 'linearReversed'
  | 'horizontal'
  | 'diagonal';

export interface LinearGradientProps extends Omit<ContainerProps, 'children'> {
  colors: string | string[];
  start?: GradientCoordinates;
  end?: GradientCoordinates;
  height?: number | string; // DOES NOT ACCEPT px, only %
  children?: ReactNode;
  gradientType?: GradientType;
}
interface ContainerStatics {
  LinearGradient: LinearGradientType;
}

export const CONTAINER_UUID = nanoid();

export type ContainerType = React.FC<ContainerProps> & ContainerStatics;
export type LinearGradientType = React.FC<LinearGradientProps>;
