export const PAGE_CREATOR_ROUTE_DRAFT = '/page-creator/draft';
export const PAGE_CREATOR_PATH_CREATE_DRAFT = '';
export const PAGE_CREATOR_PATH_RETRIEVE_DRAFT = '';

export const PAGE_CREATOR_ROUTE_PREVIEW = '/page-creator/preview';
export const PAGE_CREATOR_PATH_CREATE_PREVIEW = '';

export const PAGE_CREATOR_ROUTE_LIVE = '/page-creator/live';
export const PAGE_CREATOR_PATH_CREATE_LIVE = '';
export const PAGE_CREATOR_PATH_IS_OWNED = '/is-owned';
export const PAGE_CREATOR_PATH_USER_URLS = '/user-urls';

export const PUBLIC_ROUTE_LIVE = '/page/live';
export const PUBLIC_PATH_IS_AVAILABLE = '/is-available';
export const PUBLIC_PATH_RETRIEVE = '';
