import { ThemeMode } from '../colors';
import { ThemeTypography, TypographyColors } from './interfaces';

export const BASE_TYPOGRAPHY_COLORS: TypographyColors = {
  [ThemeMode.LIGHT]: {
    onPrimary: '#fff',
    onSecondary: '#000',
    onBackground: '#000',
    placeholder: '#555555',
  },
  [ThemeMode.DARK]: {
    onPrimary: '#fff',
    onSecondary: '#fff',
    onBackground: '#fff',
    placeholder: '#555555',
  },
};

const BASE_TYPOGRAPHY: ThemeTypography = {
  fontFamily: {
    regular: 'OpenSans-Regular',
    bold: 'OpenSans-Bold',
    italic: 'OpenSans-Italic',
    thin: 'OpenSans-Light',
    boldItalic: 'OpenSans-BoldItalic',
    thinItalic: 'OpenSans-LightItalic',
  },
  colors: BASE_TYPOGRAPHY_COLORS,
};

export default BASE_TYPOGRAPHY;
