import { getRegionNameByCountry } from '@libs/constants-country-regions';
import { useReactPageContentContextCountry } from '@react-page-editor/final-renderer';
import { useUserInfo } from '@rec/user-info';
import includes from 'lodash/includes';
import { ComponentConfig } from 'react-page-editor/state';

export function usePageRestrictionCountry(): string {
  const { country } = useUserInfo();
  const pageContextCountry = useReactPageContentContextCountry();
  return pageContextCountry || country;
}
export function useRestrictComponentByRegion(config: ComponentConfig): boolean {
  const country = usePageRestrictionCountry();
  const region = getRegionNameByCountry(country);
  return includes(config.deactivated, region);
}
