import { css, shorthand } from '@ui-system/css';
import { createComponentModifiers } from '@ui-system/modifiers';

const componentModifiers = createComponentModifiers(({ theme }) => ({
  fullWidth: css`
    flex: 1;
    color: ${theme.colors[theme.mode].primary.main};
  `,
  primary: {
    backgroundColor: theme.colors[theme.mode].primary.main,
    borderColor: theme.colors[theme.mode].primary.main,
  },
  error: {
    backgroundColor: theme.colors[theme.mode].error,
    borderColor: theme.colors[theme.mode].error,
    color: theme.colors[theme.mode].white,
  },
  success: {
    backgroundColor: theme.colors[theme.mode].success,
    borderColor: theme.colors[theme.mode].success,
    color: theme.colors[theme.mode].white,
  },
  secondary: {
    backgroundColor: theme.colors[theme.mode].secondary.main,
    borderColor: theme.colors[theme.mode].secondary.main,
  },
  info: {
    backgroundColor: theme.colors[theme.mode].info,
    borderColor: theme.colors[theme.mode].info,
  },
  square: css`
    ${shorthand.square}
  `,
  dark: {
    backgroundColor: theme.colors[theme.mode].black,
    borderColor: theme.colors[theme.mode].black,
    color: theme.colors[theme.mode].white,
  },
}));

export default componentModifiers;
