import { useQueryParams } from '@lib/navigation';
import { useDeepCompareMemo } from '@react-utils/hooks';
import {
  createDispatchHook,
  createDispatchHookOnMount,
  createSelectorHook,
} from '@redux-basic-module/hooks-utils';
import { U } from '@utils/ts';
import filter from 'lodash/filter';
import some from 'lodash/some';
import { useCallback, useEffect, useMemo } from 'react';
import { F } from 'ts-toolbelt';

import { loadCategories, selectCategory } from './actions';
import {
  dataSelector,
  loadingSelector,
  panelsSelector,
  selectedSelector,
} from './selectors';

export const useSelectedMarketingCategory = createSelectorHook(
  selectedSelector,
);
export const useMarketingCategoryData = createSelectorHook(dataSelector);

export const useAllPanelsData = createSelectorHook(panelsSelector);

export function usePanelsData(): ReturnType<typeof useAllPanelsData> {
  const panels = useAllPanelsData();
  return useDeepCompareMemo(() => filter(panels, item => item.value), [
    panels,
  ]) as ReturnType<typeof useAllPanelsData>;
}
export const useIsLoadingMarketingCategory = createSelectorHook(
  loadingSelector,
);

export const useLoadMarketingCategoriesOnMount = createDispatchHookOnMount(
  loadCategories.start,
);
export const useSelectMarketingCategory = createDispatchHook(selectCategory);

const DEFAULT_CATEGORY = {
  value: 'REC_GROUP',
  label: 'REC Group',
};
export function useSelectDefaultCategory(): F.Function {
  const select = useSelectMarketingCategory();
  return useCallback(() => {
    select({ category: DEFAULT_CATEGORY });
  }, [select]);
}
export function useSelectFirstMarketingCategoryOnMount(): void {
  const select = useSelectDefaultCategory();
  const { toolbox } = useQueryParams();
  useEffect(() => {
    if (toolbox === 'true') {
      select();
    }
  }, [select, toolbox]);
}
export function useIsPanel(id: U.Nullable<string>): boolean {
  const panels = usePanelsData();
  return useMemo(() => some(panels, item => item.value === id), [id, panels]);
}
