import { Action as _Action, Handlers } from '@redux-basic-module/interfaces';
import { defaultTo } from 'lodash';

export function createReducer<State, Action extends _Action<string, any>>(
  namespace: string,
  initialState: State,
  handlers: Handlers<State, any>,
): (state: State, action: Action) => State {
  const handlersWithHydratedAction = handlers;
  handlersWithHydratedAction[`${namespace}_HYDRATE`] = (
    state,
    action,
  ): State => ({
    ...(defaultTo(state, {}) as State),
    ...defaultTo(action.payload, {}),
  });
  return function reducer(state = initialState, action: Action): State {
    if (action.type in handlersWithHydratedAction) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}
