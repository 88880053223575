import { containsAny } from '@lib/utils-string';
import { styled } from '@ui-system/css';
import {
  ButtonProps,
  ButtonType,
  Components,
  PageType,
} from '@ui-system/interfaces';
import { components } from '@ui-system/material';
import { Theme } from '@ui-system/theme';

const { Button, Page } = components;
const NewButton: ButtonType = (styled(Button)`
  background-color: ${({
    theme,
    variant,
    disabled,
  }: { theme: Theme } & ButtonProps) => {
    if (disabled) return theme.colors[theme.mode].gray.A200;
    if (variant === 'contained') return theme.colors[theme.mode].primary.main;
    if (variant === 'text') return 'transparent';
    if (variant === 'outlined') return 'transparent';
    return theme.colors[theme.mode].white;
  }};
  ${({ theme, variant, disabled }: { theme: Theme } & ButtonProps) => {
    if (disabled)
      return `border-color: ${theme.colors[theme.mode].gray.A200}  !important;`;
    switch (variant) {
      case 'contained':
        return '';
      case 'outlined':
        return `
            * {
                color: ${theme.colors[theme.mode].primary.main};
            }

            svg {
                fill: ${theme.colors[theme.mode].primary.main};
            }
        `;
      case 'text':
        return '';
      default:
        return '';
    }
  }}
  :hover {
    background-color: ${({ theme }: { theme: Theme } & ButtonProps) =>
      theme.colors[theme.mode].primary.main};
    * {
      color: ${({ theme, modifiers }: { theme: Theme } & ButtonProps) => {
        if (containsAny(modifiers, 'dark')) return 'unset';
        return theme.colors[theme.mode].black;
      }} !important;
    }
    svg {
      fill: ${({ theme, modifiers }: { theme: Theme } & ButtonProps) => {
        if (containsAny(modifiers, 'dark')) return 'unset';
        return theme.colors[theme.mode].black;
      }} !important;
    }
  }
` as unknown) as ButtonType;

const NewContent: PageType = (styled(Page.Content)`
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  background-color: ${({ theme }: { theme: Theme }) =>
    theme.colors[theme.mode].background};
` as unknown) as PageType;

Page.Content = NewContent;

Page.defaultProps = {
  ...Page.defaultProps,
  gridTemplateRows: 'auto',
};

NewButton.defaultProps = {
  ...Button.defaultProps,
  variant: 'contained',
  iconSize: 25,
  iconColor: 'black',
};

const REC_COMPONENTS: Components = {
  ...components,
  Button: NewButton,
  Page,
};
export default REC_COMPONENTS;
