import { Theme } from '@ui-system/theme';
import { getColorByName } from '@ui-system/theme/colors';
import { F, U } from '@utils/ts';

import createFromProps from '../create-from-props';
import { Color, FromProps } from '../types';

const applyColor: F.Function<[Theme, U.Nullable<Color>, U.Nullable<boolean>?]> &
  FromProps = (theme, color, onlyColor = false): string => {
  const hex = getColorByName(color, theme.colors[theme.mode]);

  if (hex && !onlyColor) return `color: ${hex};`;
  if (hex && onlyColor) return `${hex}`;
  return '';
};

applyColor.fromProps = createFromProps(applyColor, 'color');

export default applyColor;
