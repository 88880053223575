import { createHandlers } from '@redux-async-module/handlers-utils';
import { Handler, Handlers } from '@redux-basic-module/interfaces';
import { createReducer } from '@redux-basic-module/reducer-utils';
import update from 'immutability-helper';
import set from 'lodash/set';

import { loadCategories, loadPanels, selectCategory } from './actions';
import {
  INITIAL_STATE,
  NAMESPACE,
  SelectCategoryPayload,
  State,
} from './types';

const HANDLERS = createHandlers(loadCategories, INITIAL_STATE);

const EXTRA_HANDLERS: Handlers<State> = {
  [loadCategories.success.type]: (
    state: State,
    action: ReturnType<typeof loadPanels.success>,
  ) =>
    update(state, {
      panels: {
        $set: action.payload.panels,
      },
      data: {
        $set: action.payload.categories,
      },
      isLoading: {
        $set: false,
      },
      error: {
        $set: null,
      },
      success: {
        $set: true,
      },
    }),
};

const SELECT_CATEGORY_HANDLER: Handler<State, SelectCategoryPayload> = (
  state,
  action,
) => update(state, { selected: { $set: action.payload.category } });

set(HANDLERS, selectCategory.type, SELECT_CATEGORY_HANDLER);

export const reducer = createReducer(NAMESPACE, INITIAL_STATE, {
  ...HANDLERS,
  ...EXTRA_HANDLERS,
});
