import { useDeepCompareMemo } from '@react-utils/hooks';
import { getStaticUrl } from '@rec/static';
import axios from 'axios';
import { useEffect, useState } from 'react';

interface useJSONDataFromStaticParams {
  path?: string;
  backupPath?: string;
}

const AXIOS_OPTIONS = {
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
};
export function useJSONDataFromStatic<T>({
  path,
  backupPath,
}: useJSONDataFromStaticParams): {
  data: T | null;
  loading: boolean;
  error: Error | null;
} {
  const [error, setError] = useState<Error | null>(null);
  const [backupError, setBackupError] = useState<Error | null>(null);
  const [data, setValue] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const url = `${getStaticUrl()}${path}`;
  useEffect(() => {
    (async () => {
      if (path) {
        try {
          setLoading(true);
          setError(null);
          setBackupError(null);
          const response = await axios.get<T>(url, AXIOS_OPTIONS);
          setLoading(false);
          setValue(response.data);
        } catch (e) {
          setError(e);
          setLoading(false);
        }
      }
    })();
  }, [url, path]);

  useEffect(() => {
    if (error && backupPath && !backupError) {
      (async () => {
        try {
          setLoading(true);
          const response = await axios.get(
            `${getStaticUrl()}${backupPath}`,
            AXIOS_OPTIONS,
          );
          setLoading(false);
          setValue(response.data);
          setError(null);
        } catch (e) {
          setBackupError(e);
          setLoading(false);
        }
      })();
    }
  }, [error, backupPath, backupError]);

  return useDeepCompareMemo(
    () => ({
      data,
      error,
      loading,
    }),
    [data, error, loading],
  );
}
