import { getApiBasePath } from '@controller-layer-utils/core';
import {
  ROUTE_LIST_RESOURCES,
  ROUTE_RESOURCE_BUNDLE,
  ROUTE_TRANSLATE_RESOURCE,
} from '@resource/constants';

export function getResourceBundleUrl(): string {
  return `${getApiBasePath()}${ROUTE_RESOURCE_BUNDLE}`;
}

export function getListResourcesUrl(): string {
  return `${getApiBasePath()}${ROUTE_LIST_RESOURCES}`;
}

export function getTranslateResourceUrl(): string {
  return `${getApiBasePath()}${ROUTE_TRANSLATE_RESOURCE}`;
}
