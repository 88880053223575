import { RecProduct, RecProductProps } from '@pro-page-components/rec-product';
import { useChildrenVisibilityEditor } from '@pro-page-components/utils/useChildrenVisibility';
import { useRestrictComponentByRegion } from '@pro-page-components/utils/useRestrictComponentByRegion';
import * as React from 'react';
import { ComponentForEditor } from 'react-page-editor/state';

import config from './config.json';
import defaultProps from './defaultProps.json';

export const RecTwinPeak5BlackSeries: ComponentForEditor<RecProductProps> = (
  props: RecProductProps,
) => {
  const restrict = useRestrictComponentByRegion(config);
  if (restrict) return null;
  return <RecProduct {...props} />;
};

export const RecTwinPeak5BlackSeriesEditor: ComponentForEditor<RecProductProps> = (
  props: RecProductProps,
) => {
  // eslint-disable-next-line no-underscore-dangle,react/destructuring-assignment
  const visible = useChildrenVisibilityEditor(props.__config);
  if (!visible) return null;
  return <RecTwinPeak5BlackSeries scale={0.9} skipVisibilityCheck {...props} />;
};

RecTwinPeak5BlackSeries.defaultProps = defaultProps as RecProductProps;
RecTwinPeak5BlackSeries.config = config;
RecTwinPeak5BlackSeriesEditor.defaultProps = defaultProps as RecProductProps;
RecTwinPeak5BlackSeriesEditor.config = config;
