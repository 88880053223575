import { getRetrieveDraftUrl } from '@page-creator/shared-routes';
import { createEpic } from '@redux-async-module/epic-utils';
import { RequestType } from '@redux-async-module/interfaces';

import { retrieveDraft } from './actions';
import { RootState, StartPayload, SuccessPayload } from './types';

export const EPICS = createEpic<RootState, StartPayload, SuccessPayload>({
  actions: retrieveDraft,
  requestOptions: {
    type: RequestType.get,
    getPath: ({ key }) => getRetrieveDraftUrl(key),
  },
});
