import { flatten, unflatten } from 'flat';
import keyBy from 'lodash/keyBy';
import lodashOmit from 'lodash/omit';

export function getFlatKeysArrayFromObject<
  T extends Record<string, any> = Record<string, any>
>(o: T, omit?: string[]): string[] {
  return (Object.getOwnPropertyNames(
    flatten(lodashOmit(o, omit)),
  ) as unknown) as string[];
}

export function getFieldPaths<
  T,
  AuxiliarKeys extends Record<string, any> = null,
  Omits extends string = null
>(o: T, omit?: string[]): Omit<Record<keyof T, string> & AuxiliarKeys, Omits> {
  const KEYS_ARRAY = getFlatKeysArrayFromObject(o, omit);
  return unflatten(lodashOmit(keyBy(KEYS_ARRAY), omit)) as Omit<
    Record<keyof T, string> & AuxiliarKeys,
    Omits
  >;
}
