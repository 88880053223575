import { Module } from '@redux-basic-module/interfaces';

import reducer, { changeLanguage } from './reducer';
import { NAMESPACE, RootState } from './types';

export * from './hooks';

export function getLanguageModule(language: string): Module<RootState> {
  return {
    id: NAMESPACE,
    reducerMap: {
      [NAMESPACE]: reducer,
    },
    initialActions: [changeLanguage({ language })],
  };
}
