import { createAction } from '@redux-basic-module/actions-utils';

import { NAMESPACE, SetUserInfoPayload } from './types';

const SET_USER_TYPE = `${NAMESPACE}/SET_USER`;

export const setUserInfo = createAction<
  typeof SET_USER_TYPE,
  Partial<SetUserInfoPayload>
>(SET_USER_TYPE);
