import { getFromLocalStorage } from '@lib/local-storage';
import { BaseMeta } from '@redux-basic-module/interfaces';

import { NAMESPACE as PERSISTED_NAMESPACE } from '../constants';
import keyGenerator from './keyGenerator';

export default async function retrieveHydrateAction<State>(
  namespace: string,
  appId: string,
): Promise<{ type: string; payload: State; meta: BaseMeta }> {
  const key = keyGenerator(appId, PERSISTED_NAMESPACE, namespace);
  try {
    return getFromLocalStorage<{
      type: string;
      payload: State;
      meta: BaseMeta;
    }>(key);
  } catch (e) {
    throw new Error(e);
  }
}
