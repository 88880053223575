import { LogLevel } from './LogLevel';

type BaseJsonLogData = {
  date?: Date;
  group?: string;
  level?: LogLevel;
  traceId?: string;
  message: string;
  fullMessage?: string;
  prefix?: string;
  messageArgs?: Array<any>;
  params?: Record<string, any> | null;
  logOptions?: MessageLogOptions;
  operation?: MessageOperation;
};

export type JsonLogData = {
  level: LogLevel;
} & BaseJsonLogData;

export type ConsoleLogFunction = (msg: string, ...any) => void;
export type LogFunction = (msg: BaseJsonLogData | string, ...any) => void;
export type JsonLogFunction = (msg: JsonLogData | string, ...any) => void;

export interface Logger {
  (string, ...any): string;
  extend: (namespace: string) => Logger;
  [LogLevel.TRACE]: LogFunction;
  [LogLevel.DEBUG]: LogFunction;
  [LogLevel.EMERG]: LogFunction;
  [LogLevel.ERROR]: LogFunction;
  [LogLevel.FATAL]: LogFunction;
  [LogLevel.INFO]: LogFunction;
  [LogLevel.WARN]: LogFunction;
  log: LogFunction;
  json: JsonLogFunction;
  defaultParams: Record<string, unknown>;
  setDefaultParams: (data: any) => Logger;
  getNamespace: () => string;
}

export type LoggerCreator = {
  (namespace: string): Logger;
};

export type Namespaces = string | Array<string> | null;

export type FormatLogMessageOptions = {
  message?: (s: string) => string | string[];
  level?: (level: LogLevel) => string | string[];
  group?: (s: string) => string | string[];
  date?: (s: string) => string | string[];
  operationName?: (s: string) => string | string[];
  operationStatus?: (s: MessageOperationStatus) => string | string[];
  operationTime?: (s: string) => string | string[];
  traceId?: (s: string) => string | string[];
};

export type MessageLogOptions = {
  formatters?: FormatLogMessageOptions;
};

export enum MessageOperationStatus {
  START = 'START',
  OK = 'DONE-OK',
  'ERROR' = 'DONE-ERR',
  'WARN' = 'WARN',
}

export type MessageOperation = {
  time?: string;
  name?: string;
  status?: MessageOperationStatus;
};

export type Message = {
  traceId?: string;
  group: string;
  message: string;
  fullMessage?: string; // for cases where we can decide which one to choose into transporter
  level: LogLevel;
  date: Date;
  messageArgs?: Array<any>;
  params?: Record<string, any> | null;
  logOptions?: MessageLogOptions;
  operation?: MessageOperation;
};
