import { createInstance } from '@ui-system/proxy/createInstance';
import UI from '@ui-system/ui';

import { Checkbox } from './checkbox';
import { Input } from './input';
import { Select } from './select';

const Form = createInstance(UI.Container, { Input, Select, Checkbox });

export default Form;
