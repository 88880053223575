import { I18nShape } from '@i18n/core';
import { ScaleProps, SectionWrapper } from '@rec/section-wrapper';
import { CANCEL, SAVE } from '@resource/i18n-shapes';
import UI from '@ui-system/ui';
import { openUrl } from 'browser';
import { compact, values } from 'lodash';
import delay from 'lodash/delay';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import * as React from 'react';
import { useCallback } from 'react';
import {
  ComponentForEditor,
  useComponentProps,
  useSetComponentProp,
  useViewModeContext,
} from 'react-page-editor/state';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import config from './config.json';
import defaultProps from './defaultProps.json';

interface SocialMediaProps extends ScaleProps {
  linkedin?: string | null;
  facebook?: string | null;
  twitter?: string | null;
  instagram?: string | null;
  youtube?: string | null;
  pinterest?: string | null;
  snapchat?: string | null;
  tiktok?: string | null;
  edit?: boolean;
}

interface SocialMediaLinkProps {
  item: AvailableSocialMedia;
  value: string | null;
  edit: boolean;
}

const useSocialMediaEditModule = create<{
  selected: string | null;
  value: string | null;
  warn: boolean;
  setValue(value: string | null): void;
  setWarn(value: boolean): void;
  setSelected(socialMedia: string): void;
}>()(
  devtools(
    set => ({
      selected: null,
      value: null,
      warn: false,
      setSelected(socialMedia) {
        set({ selected: socialMedia });
      },
      setValue(value) {
        set({ value });
      },
      setWarn(warn) {
        set({ warn });
      },
    }),
    {
      name: 'social-media',
    },
  ),
);

interface AvailableSocialMedia {
  name: string;
  placeholder: I18nShape;
  validation: (value: string) => boolean;
}

const INSTAGRAM_REGEX = /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_.]+$/;
const LINKEDIN_REGEX = /^(https?:\/\/)?(www\.)?linkedin\.com\/company\/[a-zA-Z0-9_\-.]+$/;
const FACEBOOK_REGEX = /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9_.]+$/;
const TIKTOK_REGEX = /^(https?:\/\/)?(www\.)?tiktok\.com\/@[\w.-]+$/;
const YOUTUBE_REGEX = /^(https?:\/\/)?(www\.)?youtube\.com\/(c|user)\/[a-zA-Z0-9_-]+$/;
const SNAPCHAT_REGEX = /^(https?:\/\/)?(www\.)?snapchat\.com\/add\/[a-zA-Z0-9_-]+$/;
const PINTEREST_REGEX = /^(https?:\/\/)?(www\.)?pinterest\.com\/[a-zA-Z0-9_-]+$/;
const TWITTER_REGEX = /^(https?:\/\/)?(www\.)?twitter\.com\/[a-zA-Z0-9_]+$/;

const AVAILABLE_SOCIAL_MEDIAS: Record<string, AvailableSocialMedia> = {
  instagram: {
    name: 'instagram',
    placeholder: {
      key: 'https://www.instagram.com/RECGroupSolar',
      text: 'https://www.instagram.com/RECGroupSolar',
      bundleName: 'none',
    },
    validation: (url: string) => INSTAGRAM_REGEX.test(url),
  },
  linkedin: {
    name: 'linkedin',
    placeholder: {
      key: 'https://www.linkedin.com/company/RECGroupSolar',
      text: 'https://www.linkedin.com/company/RECGroupSolar',
      bundleName: 'none',
    },
    validation: (url: string) => LINKEDIN_REGEX.test(url),
  },
  facebook: {
    name: 'facebook',
    placeholder: {
      key: 'https://www.facebook.com/RECGroupSolar',
      text: 'https://www.facebook.com/RECGroupSolar',
      bundleName: 'none',
    },
    validation: (url: string) => FACEBOOK_REGEX.test(url),
  },
  youtube: {
    name: 'youtube',
    placeholder: {
      key: 'https://www.youtube.com/c/RECGroupSolar',
      text: 'https://www.youtube.com/c/RECGroupSolar',
      bundleName: 'none',
    },
    validation: (url: string) => YOUTUBE_REGEX.test(url),
  },
  tiktok: {
    name: 'tiktok',
    placeholder: {
      key: 'https://www.tiktok.com/@RECGroupSolar',
      text: 'https://www.tiktok.com/@RECGroupSolar',
      bundleName: 'none',
    },
    validation: (url: string) => TIKTOK_REGEX.test(url),
  },
  snapchat: {
    name: 'snapchat',
    placeholder: {
      key: 'https://www.snapchat.com/add/RECGroupSolar',
      text: 'https://www.snapchat.com/add/RECGroupSolar',
      bundleName: 'none',
    },
    validation: (url: string) => SNAPCHAT_REGEX.test(url),
  },
  pinterest: {
    name: 'pinterest',
    placeholder: {
      key: 'https://www.pinterest.com/RECGroupSolar',
      text: 'https://www.pinterest.com/RECGroupSolar',
      bundleName: 'none',
    },
    validation: (url: string) => PINTEREST_REGEX.test(url),
  },
  twitter: {
    name: 'twitter',
    placeholder: {
      key: 'https://www.twitter.com/RECGroupSolar',
      text: 'https://www.twitter.com/RECGroupSolar',
      bundleName: 'none',
    },
    validation: (url: string) => TWITTER_REGEX.test(url),
  },
};

const SocialMediaInput = () => {
  const {
    selected,
    setSelected,
    value,
    setValue,
    warn,
    setWarn,
  } = useSocialMediaEditModule();
  const socialMedia = AVAILABLE_SOCIAL_MEDIAS[selected];

  const setComponentProp = useSetComponentProp();

  const onSave = useCallback(() => {
    if (socialMedia?.validation(value)) {
      setComponentProp(config, value, socialMedia?.name);
      setValue('');
      setSelected(null);
      setWarn(false);
    } else {
      setWarn(true);
    }
  }, [setComponentProp, setSelected, setValue, setWarn, socialMedia, value]);

  const onCancel = useCallback(() => {
    setValue('');
    setSelected(null);
    setWarn(false);
  }, [setSelected, setValue, setWarn]);

  const onClear = useCallback(() => {
    setComponentProp(config, null, socialMedia?.name);
    onCancel();
  }, [onCancel, setComponentProp, socialMedia?.name]);

  if (!socialMedia) return null;
  return (
    <UI.Container direction="column" gap={3}>
      <UI.Form.Input
        value={value}
        onChange={newValue => setValue(newValue as string)}
        placeholder={socialMedia?.placeholder}
      />
      <UI.Container gap={1} visible={warn}>
        <UI.Caption>Please follow the pattern:</UI.Caption>
        <UI.Caption i18n={socialMedia?.placeholder} modifiers="bold" />
      </UI.Container>
      <UI.Container gap={2} m="4,0" justify="space-between">
        <UI.Button onClick={onSave} i18n={SAVE} />
        <UI.Container gap={2}>
          <UI.Button onClick={onCancel} i18n={CANCEL} variant="outlined" />
          <UI.Button onClick={onClear} variant="text">
            <UI.Icon name="trash" size={25} />
          </UI.Button>
        </UI.Container>
      </UI.Container>
    </UI.Container>
  );
};
const SocialMediaLink = ({
  item,
  value,
  edit,
  ...rest
}: SocialMediaLinkProps) => {
  const {
    setSelected,
    selected,
    setWarn,
    setValue,
  } = useSocialMediaEditModule();
  const onSelect = useCallback(() => {
    setSelected(item.name);
    setWarn(false);
    setValue(value || '');
  }, [item.name, setSelected, setValue, setWarn, value]);
  const { isMobile } = useViewModeContext();

  return (
    <>
      <UI.Container
        visible={edit || !!value}
        onClick={() => {
          if (value && !edit) openUrl(value).then();
          else {
            onSelect();
          }
        }}
        opacity={
          (value && !selected) || selected === item.name || !edit ? 1 : 0.2
        }
        {...rest}
      >
        <UI.Icon name={item.name} size={isMobile ? 60 : 90} />
      </UI.Container>
      {edit && (
        <UI.Container onClick={onSelect}>
          <UI.Icon name={value ? 'check' : 'edit'} size={25} />
        </UI.Container>
      )}
    </>
  );
};
export const SocialMedia: ComponentForEditor<SocialMediaProps> = ({
  scale,
  edit,
  ...socialMediaValues
}: SocialMediaProps) => {
  const { isMobile } = useViewModeContext();
  if (!edit && isEmpty(compact(values(socialMediaValues)))) return null;
  return (
    <SectionWrapper bg="white" padding scale={scale}>
      <UI.Container
        gap={edit ? 2 : 6}
        justify="center"
        align={isMobile ? 'center' : undefined}
        m="4,0"
        direction={isMobile ? 'column' : 'row'}
      >
        {map(values(AVAILABLE_SOCIAL_MEDIAS), socialMedia => (
          <SocialMediaLink
            edit={edit}
            key={socialMedia?.name}
            value={socialMediaValues[socialMedia?.name] || null}
            item={socialMedia}
          />
        ))}
      </UI.Container>
      {edit && <SocialMediaInput />}
    </SectionWrapper>
  );
};

SocialMedia.defaultProps = defaultProps;
SocialMedia.config = config;

export const SocialMediaEditor: ComponentForEditor<SocialMediaProps> = () => {
  const componentProps = useComponentProps<SocialMediaProps>(
    SocialMedia.config.id,
  );
  return <SocialMedia scale={0.9} {...componentProps} edit />;
};

SocialMediaEditor.defaultProps = SocialMedia.defaultProps;
SocialMediaEditor.config = config;
