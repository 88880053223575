import { chain, get, isEmpty, keys, startsWith } from 'lodash';

import { FieldPath, FormId } from '../core';
import { NAMESPACE as PREFIX } from './constants';
import { RootState } from './types';
import { normalizeFieldPath } from './utils';

export function allFormsSelector<T>(state: RootState<T>): Record<string, any> {
  return state[PREFIX].forms;
}

export function getFormSelector<T>(state: RootState<T>, formId: FormId): T {
  return allFormsSelector<T>(state)[formId];
}

export function getFormCurrentValuesSelector<T>(
  state: RootState<T>,
  formId: FormId,
): T {
  return get(getFormSelector<T>(state, formId), 'currentValues');
}

export function getFormWatchedFieldsSelector<T>(
  state: RootState<T>,
  formId: FormId,
): any {
  return get(getFormSelector<T>(state, formId), 'watchedFieldPaths');
}

export function getFormSubmittedValuesSelector<T>(
  state: RootState<T>,
  formId: FormId,
): any {
  return get(getFormSelector<T>(state, formId), 'submittedValues');
}

export function getFormIsSuccessfullySubmittedSelector<T>(
  state: RootState<T>,
  formId: FormId,
): any {
  return get(
    getFormSelector<T>(state, formId),
    'flags.isSuccessfullySubmitted',
  );
}

export function getFormIsUnsuccessfullySubmittedSelector<T>(
  state: RootState<T>,
  formId: FormId,
): any {
  return get(
    getFormSelector<T>(state, formId),
    'flags.isUnsuccessfullySubmitted',
  );
}

export function getFormIsSuccessfullyValidatedSelector<T>(
  state: RootState<T>,
  formId: FormId,
): any {
  return get(
    getFormSelector<T>(state, formId),
    'flags.isSuccessfullyValidated',
  );
}

export function getIsFormValidatingSelector<T>(
  state: RootState<T>,
  formId: FormId,
): any {
  return get(getFormSelector<T>(state, formId), 'flags.isValidating');
}

export function getIsFormValidSelector<T>(
  state: RootState<T>,
  formId: FormId,
): any {
  return get(getFormSelector<T>(state, formId), 'flags.isValid');
}

export function getIsFormManuallyValidatedSelector<T>(
  state: RootState<T>,
  formId: FormId,
): any {
  return get(getFormSelector(state, formId), 'flags.isManuallyValidated');
}

export function getFormSubmitCountSelector<T>(
  state: RootState<T>,
  formId: FormId,
): any {
  return get(getFormSelector<T>(state, formId), 'flags.submitCount');
}

export function getFormTouchedFieldsSelector<T>(
  state: RootState<T>,
  formId: FormId,
): any {
  return get(getFormSelector<T>(state, formId), 'touchedFieldPaths');
}

export function getFormCurrentErrorsSelector<T>(
  state: RootState<T>,
  formId: FormId,
): any {
  return get(getFormSelector<T>(state, formId), 'currentErrors');
}

export function getFieldValueByFieldPathSelector<T, V>(
  state: RootState<T>,
  formId: FormId,
  fieldPath: FieldPath,
  defaultValue?: V,
): any {
  return get(
    getFormCurrentValuesSelector<T>(state, formId),
    fieldPath,
    defaultValue,
  );
}

export function getIsFieldTouchedSelector<T>(
  state: RootState<T>,
  formId: FormId,
  fieldPath: FieldPath,
): any {
  const touchedFields = getFormTouchedFieldsSelector<T>(state, formId);
  if (!touchedFields) {
    return false;
  }
  if (touchedFields[fieldPath]) {
    return touchedFields[fieldPath];
  }
  const normalizedFieldPath = normalizeFieldPath(fieldPath);
  return touchedFields[normalizedFieldPath] || false;
}

export function getIsFieldTouchedOrFormSubmittedSelector<T>(
  state: RootState<T>,
  formId: FormId,
  fieldPath: FieldPath,
): any {
  const isTouched = getIsFieldTouchedSelector<T>(state, formId, fieldPath);
  const submitCount = getFormSubmitCountSelector<T>(state, formId);
  return isTouched || submitCount > 0;
}

// export function getIsFieldTouchedAndFormSubmittedSelector<T>(
//   state: RootState<T>,
//   formId: FormId,
//   fieldPath: FieldPath,
// ): any {
//   const isTouched = getIsFieldTouchedSelector<T>(state, formId, fieldPath);
//   const submitCount = getFormSubmitCountSelector<T>(state, formId);
//   return isTouched && submitCount > 0;
// }

export function getFieldErrorsByFieldPathSelector<T, V>(
  state: RootState<T>,
  formId: FormId,
  fieldPath: FieldPath,
  defaultValue?: V,
): any {
  const errors = getFormCurrentErrorsSelector(state, formId);
  if (!errors) {
    return defaultValue;
  }
  if (errors[fieldPath]) {
    return errors[fieldPath] || defaultValue;
  }
  const normalizedFieldPath = normalizeFieldPath(fieldPath);
  return errors[normalizedFieldPath] || defaultValue;
}

export function getNestedFieldErrorsByFieldPathSelector<T, V>(
  state: RootState<T>,
  formId: FormId,
  fieldPath: FieldPath,
  defaultValue?: V,
): any {
  const errors = getFormCurrentErrorsSelector(state, formId);
  if (!errors || isEmpty(errors)) {
    return defaultValue;
  }
  const normalizedFieldPath = normalizeFieldPath(fieldPath);
  const allErrorFieldPaths = keys(errors);
  return chain(allErrorFieldPaths)
    .filter(errorFieldPath => errorFieldPath !== normalizedFieldPath)
    .filter(errorFieldPath => startsWith(errorFieldPath, normalizedFieldPath))
    .map(errorFieldPath => errors[errorFieldPath])
    .value();
}
