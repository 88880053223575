import { styled } from '@ui-system/css';
import {
  ContentProps,
  FooterProps,
  HeaderProps,
  PageProps,
  PageType,
} from '@ui-system/interfaces-page';
import React from 'react';

const BasePage = styled.div<PageProps>`
  margin: 0;
  box-sizing: border-box;
  display: grid;
  width: 100%;
  grid-template-rows: ${({ gridTemplateRows }) =>
    gridTemplateRows || '60px auto 200px'};
  ${({ overflow }: PageProps) => (overflow ? `overflow: ${overflow}` : '')};
  ${({ overflowY }: PageProps) => (overflowY ? `overflowY: ${overflowY}` : '')};
  ${({ overflowX }: PageProps) => (overflowX ? `overflowX: ${overflowX}` : '')};
`;

const Header = styled.div<HeaderProps>`
  margin: 0;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  z-index: 999;
`;

const Footer = styled.div<FooterProps>`
  margin: 0;
  box-sizing: border-box;
  display: flex;
  width: 100%;
`;

const Content = styled.div<ContentProps>`
  margin: 0;
  min-height: calc(100vh - 120px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  ${({ overflow }: ContentProps) => (overflow ? `overflow: ${overflow}` : '')};
  ${({ overflowY }: ContentProps) =>
    overflowY ? `overflowY: ${overflowY}` : ''};
  ${({ overflowX }: ContentProps) =>
    overflowX ? `overflowX: ${overflowX}` : ''};
`;

export const Page: PageType = ({ children, ...rest }: PageProps) => (
  <BasePage {...rest}>{children}</BasePage>
);
Page.Content = Content;
Page.Header = Header;
Page.Footer = Footer;
