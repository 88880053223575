import { useSubmitStart } from '@fast-form/module';
import { useFastFormField } from '@fast-form/module/hooks/fields';
import { useDeepCompareMemo } from '@react-utils/hooks';
import {
  useCreateUpdateDraftData,
  useIsLoadingCreateUpdateDraft,
} from '@rec/redux-page-creator-draft';
import { useRetrieveDraftData } from '@rec/redux-retrieve-draft';
import { useUserInfo } from '@rec/user-info';
import { useLanguagesList } from '@resource/languages-list';
import { useCurrentLanguage } from '@resource/redux-language';
import { OptionType } from '@ui-system/interfaces-form';
import UI from '@ui-system/ui';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import map from 'lodash/map';
import size from 'lodash/size';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { RichTextEditor } from './RichTextEditor';

function useLanguagesOptions(): OptionType[] {
  const retrieveDraftData = useRetrieveDraftData();

  const createUpdateDraftData = useCreateUpdateDraftData();

  const draft = useDeepCompareMemo(
    () => createUpdateDraftData || retrieveDraftData,
    [retrieveDraftData, createUpdateDraftData],
  );
  const currentLanguage = useCurrentLanguage();
  const languages = useLanguagesList(currentLanguage);
  return useMemo<OptionType[]>(
    () =>
      map(
        filter(languages, language =>
          includes(draft?.contents?.supportedLanguages, language.code),
        ),
        item => ({
          label: item.name,
          value: item.code,
        }),
      ),
    [draft?.contents?.supportedLanguages, languages],
  );
}

interface TextLanguagesTabsProps {
  onClickTab: (tab: string) => void;
  currentTab: string;
}
const TextLanguagesTabs: React.FC<TextLanguagesTabsProps> = ({
  onClickTab,
  currentTab,
}: TextLanguagesTabsProps) => {
  const languages = useLanguagesOptions();
  const isSaving = useIsLoadingCreateUpdateDraft();

  return (
    <>
      {size(languages) > 1 && (
        <UI.Container gap={1} m="2, 0">
          {map(languages, item => (
            <UI.Button
              disabled={isSaving}
              key={item.value as string}
              onClick={() => onClickTab(item.value as string)}
              variant={
                currentTab === item.value || isSaving ? 'contained' : 'text'
              }
            >
              <UI.Caption>{item.label}</UI.Caption>
            </UI.Button>
          ))}
        </UI.Container>
      )}
    </>
  );
};

interface EditorByLanguageProps {
  currentTab: string;
  visible: boolean;
}
const EditorByLanguage: React.FC<EditorByLanguageProps> = ({
  currentTab,
  visible,
}: EditorByLanguageProps) => {
  const { language } = useUserInfo();
  const { setValue: setDefaultValue } = useFastFormField<string>(`text`);
  const { value, setValue } = useFastFormField<string>(`texts.${currentTab}`);
  const start = useSubmitStart();

  const onChange = useCallback(
    (newValue: string) => {
      setValue(newValue);
      if (language === currentTab) {
        setDefaultValue(newValue);
      }
    },
    [currentTab, language, setDefaultValue, setValue],
  );
  useEffect(() => {
    if (value === undefined) {
      setValue(null);
    }
  }, [setValue, value]);
  if (!visible) return null;
  return (
    <UI.Container f={1}>
      <RichTextEditor
        value={value}
        onChange={onChange}
        onBlur={start}
        language={currentTab}
      />
    </UI.Container>
  );
};

let TAB_REF: string = null;
export const TextEditor: React.FC = props => {
  const { language } = useUserInfo();
  const start = useSubmitStart();
  const [currentTab, setCurrentTab] = useState(language);

  const onClickTab = useCallback(
    (tab: string) => {
      setCurrentTab(tab);
      TAB_REF = tab;
      start();
    },
    [start],
  );
  useEffect(() => {
    if (TAB_REF) {
      setCurrentTab(TAB_REF);
    }
  }, []);

  const languages = useLanguagesOptions();
  return (
    <UI.Container {...props} direction="column">
      <TextLanguagesTabs onClickTab={onClickTab} currentTab={currentTab} />
      {map(languages, lang => (
        <EditorByLanguage
          key={String(lang.value)}
          currentTab={currentTab}
          visible={lang.value === currentTab}
        />
      ))}
    </UI.Container>
  );
};
