import { useIsComponentVisibleById } from '@react-page-editor/final-renderer';
import { ComponentConfig, useEditorContext } from 'react-page-editor/state';

export function useChildrenVisibility(
  config: ComponentConfig,
  skipVisibilityCheck: boolean,
): boolean {
  const isVisible = useIsComponentVisibleById(config?.id);
  return !(!isVisible && !skipVisibilityCheck);
}

export function useChildrenVisibilityEditor(config: ComponentConfig): boolean {
  const { isComponentVisible } = useEditorContext();
  return isComponentVisible(config);
}
