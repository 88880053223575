import { I18nFactory } from '@i18n/core';
import { REC_PRO_PAGE as bundleName } from '@resource/bundle-names';

import { COMPONENTS } from './constants';

const Factory = new I18nFactory(bundleName, COMPONENTS);

export const REC_GROUP = Factory.create({
  key: 'REC_GROUP',
  text: 'REC GROUP',
});

export const HIGHLIGHTS_REC = Factory.create({
  key: 'HIGHLIGHTS_REC',
  text: 'REC BANNER WITH PHOTO SLIDESHOW',
});

export const YOUR_COMPANY = Factory.create({
  key: 'YOUR_COMPANY',
  text: 'Your Company',
});

export const SOCIAL_MEDIA = Factory.create({
  key: 'SOCIAL_MEDIA',
  text: 'Social Media',
});

export const CONTACT = Factory.create({
  key: 'CONTACT',
  text: 'Your Contact Details',
});

export const REC_ALPHA_PURE_SERIES = Factory.create({
  key: 'REC_ALPHA_PURE_SERIES',
  text: 'REC Alpha Pure Series',
});

export const REC_ALPHA_PURE_R_SERIES = Factory.create({
  key: 'REC_ALPHA_PURE_R_SERIES',
  text: 'REC Alpha Pure-R Series',
});
export const REC_ALPHA_PURE_RX_SERIES = Factory.create({
  key: 'REC_ALPHA_PURE_RX_SERIES',
  text: 'REC Alpha Pure-RX Series',
});
export const REC_ALPHA_PURE_2_SERIES = Factory.create({
  key: 'REC_ALPHA_PURE_2_SERIES',
  text: 'REC Alpha Pure 2 Series',
});
export const REC_ALPHA_SERIES = Factory.create({
  key: 'REC_ALPHA_SERIES',
  text: 'REC Alpha Series',
});

export const REC_N_PEAK_2_BLACK_SERIES = Factory.create({
  key: 'REC_N_PEAK_2_BLACK_SERIES',
  text: 'REC N-Peak 2 Black Series',
});

export const REC_N_PEAK_3_BLACK_SERIES = Factory.create({
  key: 'REC_N_PEAK_3_BLACK_SERIES',
  text: 'REC N-Peak 3 Black Series',
});

export const REC_N_PEAK_2_SERIES = Factory.create({
  key: 'REC_N_PEAK_2_SERIES',
  text: 'REC N-Peak 2 Series',
});

export const REC_CERTIFIED_SOLAR_PROFESSIONALS = Factory.create({
  key: 'REC_CERTIFIED_SOLAR_PROFESSIONALS',
  text: 'REC Certified Solar Professionals',
});

export const REC_N_PEAK_BLACK_SERIES = Factory.create({
  key: 'REC_N_PEAK_BLACK_SERIES',
  text: 'REC N-Peak Black Series',
});

export const REC_N_PEAK_SERIES = Factory.create({
  key: 'REC_N_PEAK_SERIES',
  text: 'REC N-Peak Series',
});

export const REC_PRO_TRUST_WARRANTY = Factory.create({
  key: 'REC_PRO_TRUST_WARRANTY',
  text: 'REC ProTrust Warranty',
});

export const REC_PRODUCT = Factory.create({
  key: 'REC_PRODUCT',
  text: 'REC Product',
});

export const REC_PRODUCTS = Factory.create({
  key: 'REC_PRODUCTS',
  text: 'REC Products',
});

export const REC_TWIN_PEAK_4_BLACK_SERIES = Factory.create({
  key: 'REC_TWIN_PEAK_4_BLACK_SERIES',
  text: 'REC Twin Peak 4 Black Series',
});

export const REC_TWIN_PEAK_5_BLACK_SERIES = Factory.create({
  key: 'REC_TWIN_PEAK_5_BLACK_SERIES',
  text: 'REC Twin Peak 5 Black Series',
});

export const REC_TWIN_PEAK_4_SERIES = Factory.create({
  key: 'REC_TWIN_PEAK_4_SERIES',
  text: 'REC Twin Peak 4 Series',
});

export const REC_TWIN_PEAK_5_SERIES = Factory.create({
  key: 'REC_TWIN_PEAK_5_SERIES',
  text: 'REC Twin Peak 5 Series',
});

export const REC_ALPHA_BLACK_SERIES = Factory.create({
  key: 'REC_ALPHA_BLACK_SERIES',
  text: 'REC Alpha Black Series',
});

export const CORPORATE_VIDEO = Factory.create({
  key: 'CorporateVideo',
  text: 'CORPORATE VIDEO',
});

export const REC_ALPHA_SERIES_VIDEO = Factory.create({
  key: 'REC_ALPHA_SERIES_VIDEO',
  text: 'REC ALPHA SERIES VIDEO',
});

export const REC_ALPHA_PURE_VIDEO = Factory.create({
  key: 'REC_ALPHA_PURE_VIDEO',
  text: 'REC ALPHA PURE VIDEO',
});

export const REC_ALPHA_PURE_R_VIDEO = Factory.create({
  key: 'REC_ALPHA_PURE_R_VIDEO',
  text: 'REC ALPHA PURE-R VIDEO',
});
export const REC_ALPHA_PURE_RX_VIDEO = Factory.create({
  key: 'REC_ALPHA_PURE_RX_VIDEO',
  text: 'REC ALPHA PURE-RX VIDEO',
});
export const REC_ALPHA_PURE_2_VIDEO = Factory.create({
  key: 'REC_ALPHA_PURE_2_VIDEO',
  text: 'REC ALPHA PURE 2 VIDEO',
});
export const REC_ALPHA_BLACK_VIDEO = Factory.create({
  key: 'REC_ALPHA_BLACK_VIDEO',
  text: 'REC ALPHA BLACK VIDEO',
});

export const REC_TWIN_PEAK_4_VIDEO = Factory.create({
  key: 'REC_TWIN_PEAK_4_VIDEO',
  text: 'REC TWIN PEAK 4 VIDEO',
});

export const REC_TWIN_PEAK_4_BLACK_VIDEO = Factory.create({
  key: 'REC_TWIN_PEAK_4_BLACK_VIDEO',
  text: 'REC TWIN PEAK 4 BLACK VIDEO',
});

export const REC_RCSP_LOGO = Factory.create({
  key: 'REC_RCSP_LOGO',
  text: 'REC RCSP LOGO',
});

export const REC_RCSP_LOGO_TOOLTIP = Factory.create({
  key: 'REC_RCSP_LOGO_TOOLTIP',
  text: 'REC CERTIFIED SOLAR PROFESSIONAL LOGO',
});
