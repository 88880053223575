import { useFastFormField } from '@fast-form/module/hooks/fields';
import { I18nShape } from '@i18n/core';
import { InputProps } from '@ui-system/interfaces-form';
import UI from '@ui-system/ui';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import React, { useMemo } from 'react';

import { getFastFormErrorMessage } from '../utils';

interface Props {
  fieldPath: string;
  placeholder: I18nShape;
  [props: string]: any;
}
const FastFormInput: React.FC<Props & Partial<InputProps>> = ({
  fieldPath,
  placeholder,
  textModifiers,
  modifiers,
  ...rest
}: Props & Partial<InputProps>) => {
  const { value, setValue, errors, canShowError } = useFastFormField<string>(
    fieldPath,
  );

  const caption = useMemo(() => getFastFormErrorMessage(errors), [errors]);

  return (
    <UI.Form.Input
      value={isNumber(value) ? String(value) : value}
      onChange={setValue}
      placeholder={placeholder}
      error={canShowError && !isEmpty(errors)}
      caption={caption}
      data-test-id={fieldPath}
      modifiers={modifiers || textModifiers}
      {...rest}
    />
  );
};

export default FastFormInput;
