import { generateColorKit } from '@ui-system/color';
import { createTheme, ThemeMode } from '@ui-system/theme';

export const REC_THEME = createTheme({
  colors: {
    [ThemeMode.LIGHT]: {
      primary: generateColorKit('#f09c06'),
      secondary: generateColorKit('#fab12c'),
      info: '#7A939F',
      background: '#F4F4F4',
    },
  },
  typography: {
    fontFamily: {
      regular: 'Open Sans',
      bold: 'Open Sans',
      italic: 'Open Sans',
      thin: 'Open Sans',
      boldItalic: 'Open Sans',
      thinItalic: 'Open Sans',
    },
  },
});
